import React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Icon from "@material-ui/core/Icon";
import { GrTrophy } from "react-icons/gr";

import { addStep, StepType } from "services/StepService";

// const _isSelected = ()

const SelectTable = ({ data, classes }) => {
  return (
    <Table className={classes.table} aria-labelledby="tableTitle">
      <TableBody>
        {data.map(suggestion => {
          return (
            <TableRow
              hover
              onClick={event => suggestion.onClick(event)}
              role="checkbox"
              tabIndex={-1}
              key={suggestion.id}
              classes={{
                root: classes.tableRow,
                hover: classes.tableRowHover
              }}
            >
              <TableCell
                component="th"
                scope="row"
                padding="none"
                classes={{
                  root: classes.tableCell
                }}
                title={suggestion.title}
              >
                <div className={classes.suggestedTemplateItem}>
                  <Icon
                    fontSize="small"
                    className={classes.suggestedTemplateIcon}
                  >
                    {suggestion.icon}
                  </Icon>
                  {suggestion.name}
                </div>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const premiumCapture = (confirmModal, feature) => {
  const modal = {
    title: "Premium Feature",
    icon: <GrTrophy style={{ margin: "0 8px -2px 0" }} />,
    paragraph:
      "This feature is currently in premium beta.  Request early access?",
    notification: "Early access requested!",
    action: () => console.log(`${feature} requested - log to analytics`)
  };
  confirmModal(modal);
};

const ChecklistSuggestions = ({
  classes,
  showSuggestions,
  hideSuggestions,
  confirmModal,
  updateSteps,
  setFocus
}) => {
  const templateData = [
    {
      id: 1,
      name: "Empty",
      icon: "content_paste",
      title: "An empty custom checklist",
      onClick: () => {
        hideSuggestions();
      }
    },
    {
      id: 2,
      name: "Basic Checklist",
      icon: "assignment_turned_in",
      title: "A basic checkbox checklist",
      onClick: () => {
        addStep([], updateSteps, 1, {}, StepType.CHECKBOX);
        addStep([], updateSteps, 2, {}, StepType.CHECKBOX);
        addStep([], updateSteps, 3, {}, StepType.CHECKBOX);
        addStep([], updateSteps, 4, {}, StepType.CHECKBOX);
        setFocus(1); // Updates the focus to the new step
      }
    },
    {
      id: 3,
      name: "Basic Display",
      icon: "image",
      title: "A page like visual display",
      onClick: () => premiumCapture(confirmModal, "display")
    },
    {
      id: 4,
      name: "Basic SOP",
      icon: "description",
      title: "An operating procedure for your team",
      onClick: () => premiumCapture(confirmModal, "sop")
    },
    {
      id: 5,
      name: "Basic Form",
      icon: "input",
      title: "A customizable form for inputing data",
      onClick: () => premiumCapture(confirmModal, "form")
    },
    {
      id: 6,
      name: "Advanced",
      icon: "stars",
      title: "Linked checklist and plugin samples",
      onClick: () => premiumCapture(confirmModal, "advanced")
    },
    {
      id: 7,
      name: "Templates",
      icon: "content_copy",
      title: "Prebuilt templates for common use cases",
      onClick: () => premiumCapture(confirmModal, "templates")
    },
    {
      id: 8,
      name: "Import",
      icon: "system_update_alt",
      title: "Import an excel file, csv, document, or more.",
      onClick: () => premiumCapture(confirmModal, "import")
    }
  ];
  const dashboardData = [
    {
      id: 1,
      name: "Empty",
      icon: "check_box_outline_blank",
      title: "An empty custom dashboard.",
      onClick: () => premiumCapture(confirmModal, "empty")
    },
    {
      id: 2,
      name: "Basic",
      icon: "dashboard",
      title: "A basic dashboard with sample chart and count widgets",
      onClick: () => premiumCapture(confirmModal, "basic")
    },
    {
      id: 3,
      name: "Schedule",
      icon: "date_range",
      title: "A calendar for scheduling tasks",
      onClick: () => premiumCapture(confirmModal, "schedule")
    },
    {
      id: 4,
      name: "Table",
      icon: "grid_on",
      title: "An excel like table for reports",
      onClick: () => premiumCapture(confirmModal, "table")
    },
    {
      id: 5,
      name: "Gallery",
      icon: "collections",
      title: "A gallery for photos and videos",
      onClick: () => premiumCapture(confirmModal, "gallery")
    },
    {
      id: 6,
      name: "Location",
      icon: "location_on",
      title: "A map to track your operations.",
      onClick: () => premiumCapture(confirmModal, "location")
    },
    {
      id: 7,
      name: "Leaderboard",
      icon: "leaderboard",
      title: "A leaderboard for you team or assets.",
      onClick: () => premiumCapture(confirmModal, "leaderboard")
    },
    {
      id: 8,
      name: "Messages",
      icon: "mail_outline",
      title: "A dashboard for emails and notifications",
      onClick: () => premiumCapture(confirmModal, "messages")
    }
  ];
  // TODO: implement arrow selection (&uarr; &darr; to select)
  if (!showSuggestions) {
    return null;
  }
  return (
    <div className={classes.suggestionWrapper}>
      <div style={{ padding: "0 0 24px" }}>
        Press Enter to start a checklist, or pick a template
      </div>
      <SelectTable data={templateData} classes={classes} />
      <div style={{ padding: "24px 0 8px" }}>DASHBOARDS</div>
      <SelectTable data={dashboardData} classes={classes} />
    </div>
  );
};

export default ChecklistSuggestions;
