import PricingPage from "views/Pages/PricingPage";
import LoginPage from "views/Pages/LoginPage";
import RegisterPage from "views/Pages/RegisterPage";
import InfoPage from "views/Pages/InfoPage";
import ContactPage from "views/Pages/ContactPage";
import VerificationPage from "views/Pages/VerificationPage";
// import LockScreenPage from "views/Pages/LockScreenPage.jsx";

// @material-ui/icons
import PersonAdd from "@material-ui/icons/PersonAdd";
import Fingerprint from "@material-ui/icons/Fingerprint";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
// import LockOpen from "@material-ui/icons/LockOpen";

import Terms from "components/Terms/Terms";
import Privacy from "components/Terms/Privacy";
import DataDeletion from "components/Terms/DataDeletion";

/**
 * Ladning Pages Routes
 */
const pagesRoutes = [
  {
    path: "/pages/info-page",
    name: "Info Page",
    short: "Info",
    mini: "IN",
    icon: InfoOutlined,
    component: InfoPage,
    showTopNav: true
  },

  {
    path: "/pages/pricing-page",
    name: "Pricing Page",
    short: "Pricing",
    mini: "PP",
    icon: MonetizationOn,
    component: PricingPage,
    showTopNav: true
  },

  {
    path: "/pages/register-page",
    name: "Register Page",
    short: "Register",
    mini: "RP",
    icon: PersonAdd,
    component: RegisterPage,
    showTopNav: true
  },

  {
    path: "/pages/login-page",
    name: "Login Page",
    short: "Login",
    mini: "LP",
    icon: Fingerprint,
    component: LoginPage,
    showTopNav: true
  },

  {
    path: "/pages/terms",
    name: "Terms Page",
    short: "Terms",
    mini: "TC",
    component: Terms,
    showFooter: true
  },

  {
    path: "/pages/privacy",
    name: "Privacy Page",
    short: "Privacy",
    mini: "PP",
    component: Privacy,
    showFooter: true
  },

  {
    path: "/pages/contact",
    name: "Contact Page",
    short: "Contact",
    mini: "CP",
    component: ContactPage,
    showFooter: true
  },

  {
    path: "/pages/data-deletion",
    name: "Data Deletion Page",
    short: "Data-Delete",
    mini: "DD",
    component: DataDeletion,
    showFooter: false
  },

  {
    path: "/pages/verify",
    name: "Contact Verification Page",
    short: "Verify",
    mini: "VR",
    component: VerificationPage,
    showFooter: false
  },

  // {
  //   path: "/pages/lock-screen-page",
  //   name: "Lock Screen Page",
  //   short: "Lock",
  //   mini: "LSP",
  //   icon: LockOpen,
  //   component: LockScreenPage
  // },
  {
    redirect: true,
    path: "/pages",
    pathTo: "/pages/info-page",
    name: "Info Page"
  },
  {
    redirect: true,
    path: "/",
    pathTo: "/pages"
  }
];

export default pagesRoutes;
