import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// Components
import Button from "components/CustomButtons/Button.jsx";

// Services
import notificationsStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";

// assets
import logoBlue from "assets/img/logo-blue.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function Notice({
  classes,
  isOpen,
  title,
  handleClose,
  size,
  intermediateAction
}) {
  const [signingIn, setSigningIn] = useState(false);
  const modalSize = size ? size : "medium";
  let paperClass;
  switch (modalSize) {
    case "large":
      paperClass = "modalLarge";
      break;
    case "medium":
      paperClass = "modalMedium";
      break;
    case "small":
      paperClass = "modalSmall";
      break;
    default:
      paperClass = "modalMedium";
  }

  useEffect(() => {
    if (signingIn) {
      handleClose();
    }
  }, [signingIn]);

  return (
    <div>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal + " " + classes[paperClass]
        }}
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={
              classes.modalCloseButton + " " + classes.noticeCloseButton
            }
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={handleClose}
          >
            <Close className={classes.modalClose} />
          </Button>
          <div className={classes.logoBlock}>
            <img
              src={logoBlue}
              alt="airsync ops logo"
              className={classes.logoImage}
            />
            <h4 className={classes.logoName}>
              <strong>
                <span className={classes.logoColor}>AIR</span>SYNC
              </strong>
            </h4>
          </div>
        </DialogTitle>
        <DialogContent
          id="notice-modal-slide-description"
          className={classes.modalBody + " " + classes.noticeBody}
        >
          <div>{title ? title : "Confirm?"}</div>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          {intermediateAction ? (
            signingIn ? (
              <div>
                <CircularProgress
                  className={classes.progress}
                  color="secondary"
                />
              </div>
            ) : (
              <Button
                onClick={() => setSigningIn(true)}
                color="transparent"
                className={classes.modalSmallFooterFirstButton}
              >
                Login
              </Button>
            )
          ) : (
            <Button
              onClick={handleClose}
              color="transparent"
              className={classes.modalSmallFooterFirstButton}
            >
              Close
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

Notice.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default withStyles(notificationsStyle)(Notice);
