import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// Components

// Sub Component
import ChecklistSelector from "components/ChecklistSelector/ChecklistSelector";

// Services

// Asset
import countWidgetStyle from "assets/jss/material-dashboard-pro-react/components/countWidgetStyle.jsx";

const ChecklistConfig = ({ widget, updateWidget, user }) => {
  const onChange = checklist => {
    let newWidget = Object.assign({}, widget);
    const newConfiguration = checklist
      ? {
          checklist
        }
      : {
          checklist: {}
        };
    newWidget.configuration = Object.assign(
      {},
      widget.configuration,
      newConfiguration
    );
    updateWidget(newWidget);
  };

  return (
    <ChecklistSelector
      checklist={widget.configuration.checklist}
      onChange={onChange}
      user={user}
    />
  );
};

export default withStyles(countWidgetStyle)(ChecklistConfig);
