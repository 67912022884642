/** Import React and 3rd party libs */
import React, { useState } from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// Components
import CustomInput from "components/CustomInput/CustomInput.jsx";

// Subcomponents
/** Import components used only by this component - relative path imports */

// Services
import { StepType } from "services/StepService";
import { verifyUrl } from "services/utility";

// Queries
/** Import all graphql queries */

// Assets
import stepsStyle from "assets/jss/material-dashboard-pro-react/components/stepsStyle.jsx";

// Variables
/** Declare all constant variables */

// Functions
const getInputProp = step => {
  switch (step.type) {
    case StepType.TEXT_INPUT:
      return "text";
    case StepType.NUMBER_INPUT:
      return "number";
    case StepType.LINK_INPUT:
    case StepType.LINK_EMBED:
      return "link";
    default:
      return "";
  }
};

const getInputType = step => {
  switch (step.type) {
    case StepType.TEXT_INPUT:
      return "Text";
    case StepType.NUMBER_INPUT:
      return "Number";
    case StepType.LINK_INPUT:
    case StepType.LINK_EMBED:
      return "Link";
    default:
      return ""; // Not used for other types
  }
};

const updateValue = (event, step, setInputState, setHelpText) => {
  let newStep = Object.assign({}, step);
  let value = Object.assign({}, step.value);
  let configuration = Object.assign({}, step.configuration);
  let error = false;

  switch (step.type) {
    case StepType.TEXT_INPUT:
      value.text = event.target.value;
      if (value.text) {
        setInputState("success");
      } else {
        setInputState("error");
      }
      break;

    case StepType.NUMBER_INPUT:
      value.number = event.target.value.trim();
      if (isNaN(value.number)) {
        setInputState("error");
        setHelpText("Value must be a number.");
        error = true;
      } else {
        setInputState("success");
        setHelpText("");
        if (value.number.slice(value.number.length - 1) === ".") {
          // If the number ends with a decimal allow updating the component without saving
          // so can continue to type the decimal numbers without parseFloat stripping the decimal
          error = true;
        } else {
          value.number = parseFloat(value.number); // Need for "" since passes isNaN check
        }
      }
      break;

    case StepType.LINK_INPUT:
      value.link = { url: event.target.value.trim() };
      if (verifyUrl(value.link.url)) {
        setInputState("success");
        setHelpText("");
      } else {
        setInputState("error");
        setHelpText("Value must be a link.");
        error = true;
      }
      break;

    case StepType.LINK_EMBED:
      configuration.link = { url: event.target.value.trim() };
      if (verifyUrl(configuration.link.url)) {
        setInputState("success");
        setHelpText("");
      } else {
        setInputState("error");
        setHelpText("Value must be a link.");
        error = true;
      }
      break;

    default:
    // Do Nothing
  }

  const isEmbed = step.type === StepType.LINK_EMBED;

  if (isEmbed) {
    newStep.configuration = configuration;
  } else {
    newStep.value = value;
  }

  return [newStep, error];
};

/**
 * Declare JSX Component - class or
 */
const FormInput = ({ classes, step, updateStepTuple, isDisabled }) => {
  const [inputState, setInputState] = useState("");
  const [helpText, setHelpText] = useState("");
  const inputProp = getInputProp(step);
  const inputType = getInputType(step);
  let inputValue =
    step.value && (step.value[inputProp] || step.value[inputProp] === 0)
      ? step.value[inputProp]
      : "";
  if (step.type === StepType.LINK_INPUT) {
    inputValue =
      step.value && step.value[inputProp] ? step.value[inputProp].url : "";
  }
  if (step.type === StepType.LINK_EMBED) {
    inputValue =
      step.configuration && step.configuration[inputProp]
        ? step.configuration[inputProp].url
        : "";
  }

  return (
    <CustomInput
      success={inputState === "success"}
      error={inputState === "error"}
      helpText={helpText}
      labelText={inputType}
      id={step.stepId}
      formControlProps={{
        fullWidth: true,
        variant: "outlined"
      }}
      style={{ marginTop: "8px" }}
      inputProps={{
        onChange: event =>
          updateStepTuple(updateValue(event, step, setInputState, setHelpText)),
        type: "text",
        value: inputValue,
        autoComplete: "off",
        disabled: isDisabled,
        endAdornment:
          inputState === "error" ? (
            <InputAdornment position="end">
              <Close className={classes.danger} />
            </InputAdornment>
          ) : (
            undefined
          )
      }}
    />
  );
};

// Data Connectors

export default withStyles(stepsStyle)(FormInput);
