import React from "react";

// react component for creating dynamic tables
import ReactTable from "react-table";
import { Link, withRouter } from "react-router-dom";

// Apollo Data Layer
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";

// Datetime handling
import moment from "moment";

// Amplify S3 Image Viewer
import { S3Image } from "aws-amplify-react";

// Lodash
import _ from "lodash";

// @material-ui/core components
import Avatar from "@material-ui/core/Avatar";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Language from "@material-ui/icons/Language";
import Edit from "@material-ui/icons/Edit";

// core components
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Confirm from "components/Modal/Confirm";

// sub components
import WidgetEditButtons from "../../WidgetComponents/WidgetEditButtons";

import { FindInstancesByTemplateIdFullQuery } from "api/queries";

import {
  urlName,
  ChecklistsModes,
  constructInstanceName
} from "services/ChecklistService";

// Queries
import {
  DeleteChecklistTemplate,
  DeleteChecklistInstance
} from "views/Checklists/ChecklistComponents/ChecklistQueries";

const isAircraft1 = checklists => {
  return (
    checklists[0] &&
    checklists[0].metadata &&
    checklists[0].metadata.template.checklistId ===
      "DRONE000-0000-0000-0000-0000AIRCRAFT"
  );
};

const isAircraft2 = checklists => {
  return (
    checklists[0] &&
    checklists[0].templateId === "DRONE000-0000-0000-0000-0000AIRCRAFT"
  );
};

const isPilot1 = checklists => {
  return (
    checklists[0] &&
    checklists[0].metadata &&
    checklists[0].metadata.template.checklistId ===
      "DRONE000-0000-0000-0000-0000000PILOT"
  );
};
const isPilot2 = checklists => {
  return (
    checklists[0] &&
    checklists[0].templateId === "DRONE000-0000-0000-0000-0000000PILOT"
  );
};

const EditButton = ({ id }) => {
  return (
    <Link
      key="edit"
      to={`/app/checklist/name?id=${id}&edit=true&instance=true`}
    >
      <Button
        justIcon
        round
        simple
        onClick={e => {
          e.stopPropagation();
        }}
        color="info"
        className="edit"
      >
        <Edit />
      </Button>{" "}
    </Link>
  );
};

const DeleteButton = ({ id, orgId, metadata, typeTemplate }) => {
  return (
    <Button
      key="delete"
      justIcon
      round
      simple
      onClick={e => {
        this.setState({
          confirmModal: true,
          confirmTitle: `Delete "${metadata.name}" Checklist?`,
          handleConfirm: () => {
            this.setState(
              {
                confirmModal: false
              },
              () => {
                const {
                  deleteChecklistTemplate,
                  deleteChecklistInstance
                } = this.props;
                if (typeTemplate) {
                  deleteChecklistTemplate(orgId, id, metadata.version);
                } else {
                  deleteChecklistInstance(orgId, id, metadata.version);
                }
              }
            );
          }
        });
        e.stopPropagation();
      }}
      color="danger"
      className="remove"
    >
      <Close />
    </Button>
  );
};

const getColumns = checklists => {
  if (isAircraft2(checklists)) {
    return [
      {
        Header: "",
        accessor: "photo",
        sortable: false,
        filterable: false,
        width: 80,
        style: { paddingLeft: "8px" }
      },
      {
        Header: "Name",
        accessor: "name"
      },
      {
        Header: "Status",
        accessor: "status"
      },
      {
        Header: "Manufacturer",
        accessor: "manufacturer"
      },
      {
        Header: "Last Maintenance",
        accessor: "lastMaintenance"
      },
      {
        Header: "Actions",
        accessor: "actions",
        sortable: false,
        filterable: false
      }
    ];
  }

  if (isPilot2(checklists)) {
    return [
      {
        Header: "",
        accessor: "photo",
        sortable: false,
        filterable: false,
        width: 80,
        style: { paddingLeft: "8px" }
      },
      {
        Header: "Name",
        accessor: "name"
      },
      {
        Header: "Status",
        accessor: "status"
      },
      {
        Header: "Last Flight",
        accessor: "lastFlight"
      },
      {
        Header: "Quals",
        accessor: "quals"
      },
      {
        Header: "Actions",
        accessor: "actions",
        sortable: false,
        filterable: false
      }
    ];
  }

  return [
    {
      Header: "",
      accessor: "photo",
      sortable: false,
      filterable: false,
      width: 80,
      style: { paddingLeft: "8px" }
    },
    {
      Header: "Name",
      accessor: "name"
    },
    {
      Header: "Manufacturer",
      accessor: "manufacturer"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "Last Maintenance",
      accessor: "lastMaintenance"
    },
    {
      Header: "Actions",
      accessor: "actions",
      sortable: false,
      filterable: false
    }
  ];
};

/**
 * Currently table widget is configured with a template id
 * The widget then pulls in all active and completed checklist instances for that template
 * TODO: Allow configurable columns and selecting columns in configuration
 * TODO: Generalize to replace checklists and logbooks pages
 * TODO: figure out actions
 */
class WidgetTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmModal: false,
      confirmTitle: "",
      handleConfirm: this.handleClose,
      handleClose: this.handleClose,
      typeTemplate: false
    };
  }

  handleClose = () => {
    this.setState({ confirmModal: false });
  };

  handleChecklistClick = (state, rowInfo) => {
    const item = state.data.find(obj => obj.id === rowInfo.original.id);
    if (item) {
      const { id, name, status } = item;
      const { typeTemplate } = this.state;
      const instanceString = typeTemplate ? "" : "&instance=true";
      let statusString = "";
      if (status === ChecklistsModes.ACTIVE) statusString = "&status=active";
      if (status === ChecklistsModes.COMPLETE)
        statusString = "&status=complete";
      const urlSafe = `/app/checklist/${urlName(
        name
      )}?id=${id}${instanceString}${statusString}`;
      this.props.history.push(urlSafe);
    }
  };

  constructAircraft = (checklists, user) => {
    return checklists.map(checklist => {
      const { id, metadata, steps } = checklist;
      const { typeTemplate } = this.state;
      const orgId = user.currentOrganization.id;
      const templateId = metadata.template.checklistId;
      let name = _.get(steps[0], "value.text");
      if (typeof _.get(steps[0], "value.text") === "undefined") {
        name = constructInstanceName(checklist);
      }
      const photoSrc = _.get(steps[1], "value.image_link");
      const manufacturer = _.get(steps[5], "value.text");
      const photo = <Avatar alt={name} src={photoSrc} />;

      // Fake currency and last flight
      // For both need other checklists (last flight, total flights last 30)
      const statusStr = Math.random() > 0.2 ? "UP" : "DOWN";
      const random30 = Math.floor(Math.random() * 30);
      let lastMaintenanceDate = new Date();
      let color;
      if (statusStr === "UP") {
        lastMaintenanceDate.setDate(lastMaintenanceDate.getDate() - random30);
        color = "green";
      } else {
        lastMaintenanceDate.setDate(
          lastMaintenanceDate.getDate() - random30 - 30
        );
        color = "red";
      }
      const lastMaintenance = moment(lastMaintenanceDate).format("MMM Do YYYY");
      const status = <span style={{ color }}>{statusStr}</span>;

      const Actions = [];
      Actions.push(<EditButton key={`edit-${id}`} id={id} />);
      Actions.push(
        <DeleteButton
          key={`delete-${id}`}
          id={id}
          orgId={orgId}
          metadata={metadata}
          typeTemplate={typeTemplate}
        />
      );
      const actions = <div className="actions-right">{Actions}</div>;

      return {
        id,
        templateId,
        photo,
        name,
        manufacturer,
        status,
        lastMaintenance,
        actions
      };
    });
  };

  constructPilot = (checklists, user) => {
    return checklists.map(checklist => {
      const { id, metadata, steps } = checklist;
      const orgId = user.currentOrganization.id;
      const { typeTemplate } = this.state;
      let name =
        _.get(steps[0], "value.text") + " " + _.get(steps[1], "value.text");
      if (typeof _.get(steps[0], "value.text") === "undefined") {
        name = constructInstanceName(checklist);
      }
      const photoSrc = _.get(steps[2], "value.image_link");
      // const photo = <Avatar alt={name} src={photoSrc} />;
      const photo = (
        <S3Image
          imgKey={photoSrc}
          level="protected"
          theme={{
            photoImg: {
              maxWidth: "100%",
              width: "40px",
              height: "40px",
              borderRadius: "40px",
              verticalAlign: "middle",
              border: "0"
            }
          }}
        />
      );
      const rawQuals = _.get(steps[8], "value.text");
      const quals = rawQuals && rawQuals.length ? rawQuals.slice(1, -1) : []; // Slice off []
      const templateId = metadata.template.checklistId;

      // Fake currency and last flight
      // For both need other checklists (last flight, total flights last 30)
      const statusStr = Math.random() > 0.2 ? "CURRENT" : "EXPIRED";
      const random30 = Math.floor(Math.random() * 30);
      let lastFlightDate = new Date();
      let color;
      if (statusStr === "CURRENT") {
        lastFlightDate.setDate(lastFlightDate.getDate() - random30);
        color = "green";
      } else {
        lastFlightDate.setDate(lastFlightDate.getDate() - random30 - 30);
        color = "red";
      }
      const lastFlight = moment(lastFlightDate).format("MMM Do YYYY");
      const status = <span style={{ color }}>{statusStr}</span>;

      const Actions = [];
      Actions.push(<EditButton key={`edit-${id}`} id={id} />);
      Actions.push(
        <DeleteButton
          key={`delete-${id}`}
          id={id}
          orgId={orgId}
          metadata={metadata}
          typeTemplate={typeTemplate}
        />
      );
      const actions = <div className="actions-right">{Actions}</div>;

      return {
        id,
        templateId,
        photo,
        name,
        status,
        quals,
        lastFlight,
        actions
      };
    });
  };

  constructChecklist = (checklists, user) => {
    // TEMP - Hardcode as figure out config handling
    if (isAircraft1(checklists)) {
      return this.constructAircraft(checklists, user);
    }
    if (isPilot1(checklists)) {
      return this.constructPilot(checklists, user);
    }
    return checklists.map(checklist => {
      const { id, metadata } = checklist;
      const orgId = user.currentOrganization.id;

      // const StarButton = (
      //   <Button
      //     key="star"
      //     justIcon
      //     round
      //     simple
      //     onClick={e => {
      //       alert("You've clicked LIKE button on " + metadata.name);
      //       e.stopPropagation();
      //     }}
      //     color="warning"
      //     className="like"
      //   >
      //     <Star />
      //   </Button>
      // );

      const Actions = [];
      Actions.push(<EditButton key={`edit-${id}`} id={id} />);
      Actions.push(
        <DeleteButton
          key={`delete-${id}`}
          id={id}
          orgId={orgId}
          metadata={metadata}
          typeTemplate={this.state.typeTemplate}
        />
      );

      return {
        id,
        name: metadata.name,
        tags: metadata.tags.map(tag => tag.toUpperCase()).join(", "),
        sharing: metadata.sharing.type,
        status: metadata.status,
        created: moment(metadata.created.time).format("MMM Do YYYY"),
        actions: (
          // we've added some custom button actions
          <div className="actions-right">{Actions}</div>
        )
      };
    });
  };

  render() {
    const {
      classes,
      mode,
      widget,
      editWidget,
      deleteWidget,
      data,
      user
    } = this.props;
    const {
      confirmModal,
      confirmTitle,
      handleConfirm,
      handleClose
    } = this.state;
    const { configuration } = widget;

    const { findInstancesByTemplateId } = data ? data : {};
    const items =
      findInstancesByTemplateId && findInstancesByTemplateId.items
        ? findInstancesByTemplateId.items
        : [];

    const checklists = this.constructChecklist(items, user);

    return (
      <Card>
        <CardHeader color="success" icon>
          <WidgetEditButtons
            mode={mode}
            classes={classes}
            editWidget={editWidget}
            deleteWidget={deleteWidget}
          />
          <CardIcon color="success">
            <Language />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>{configuration.title}</h4>
        </CardHeader>
        <CardBody>
          <ReactTable
            data={checklists}
            // filterable
            columns={getColumns(checklists)}
            getTrProps={(state, rowInfo) => {
              return {
                onClick: () => this.handleChecklistClick(state, rowInfo)
              };
            }}
            defaultPageSize={10}
            showPaginationBottom={true}
            className="-striped -highlight"
          />
        </CardBody>
        <Confirm
          isOpen={confirmModal}
          handleConfirm={handleConfirm}
          handleClose={handleClose}
          title={confirmTitle}
        />
      </Card>
    );
  }
}

const FindInstancesByTemplateId = graphql(FindInstancesByTemplateIdFullQuery, {
  options: props => {
    return {
      fetchPolicy: "network-only",
      variables: {
        templateId: props.widget.configuration.checklist.checklistId
      }
    };
  },
  skip: props => {
    return !(
      props.widget &&
      props.widget.configuration &&
      props.widget.configuration.checklist &&
      props.widget.configuration.checklist.checklistId
    );
  }
});

export default compose(
  FindInstancesByTemplateId,
  DeleteChecklistTemplate,
  DeleteChecklistInstance,
  withRouter
)(WidgetTable);
