// ##############################
// // // InfoPage view styles
// #############################

import {
  container,
  cardTitle,
  logoColor
} from "assets/jss/material-dashboard-pro-react.jsx";

import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

const contactPageStyle = {
  ...customCheckboxRadioSwitch,
  cardTitle: {
    ...cardTitle,
    textAlign: "center"
  },
  container: {
    ...container,
    position: "relative",
    zIndex: "3",
    paddingTop: "20vh",
    paddingBottom: "10vh"
  },
  cardSignup: {
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    padding: "40px 0px",
    margin: "0"
  },
  center: {
    textAlign: "center"
  },
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  form: {
    padding: "0 20px",
    position: "relative"
  },
  socialTitle: {
    fontSize: "18px"
  },
  inputAdornment: {
    marginRight: "18px",
    position: "relative"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  customFormControlClasses: {
    margin: "0 12px"
  },
  checkboxLabelControl: {
    margin: "0"
  },
  checkboxLabel: {
    marginLeft: "6px",
    color: "rgba(0, 0, 0, 0.26)"
  },
  section: {},
  logoImage: {
    width: "36px"
  },
  droneOps: {
    marginTop: "4px"
  },
  logoName: {
    margin: "0"
  },
  logoColor: {
    color: logoColor
  },
  tagLine: {
    fontWeight: "400",
    textAlign: "center"
  },
  waitlistTitle: {
    ...cardTitle,
    color: "#FFFFFF",
    marginBottom: "12px"
  },
  signupCardHeader: {
    textAlign: "center",
    padding: "30px !important;"
  },
  signupCardBody: {
    paddingBottom: "0"
  },
  signupCardFooter: {
    paddingTop: "0"
  },
  honey: {
    display: "none"
  },
  contact: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "500"
  }
};

export default contactPageStyle;
