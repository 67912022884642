// ##############################
// // // Footer styles
// #############################

import {
  defaultFont,
  container,
  containerFluid,
  primaryColor
} from "assets/jss/material-dashboard-pro-react.jsx";

const footerStyle = theme => ({
  block: {},
  left: {
    float: "left!important",
    display: "block",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      textAlign: "center"
    }
  },
  right: {
    margin: "0",
    fontSize: "14px",
    float: "right!important",
    padding: "15px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      textAlign: "center"
    }
  },
  footer: {
    bottom: "0",
    padding: "15px 0",
    ...defaultFont,
    zIndex: 4
  },
  container: {
    zIndex: 3,
    ...container,
    position: "relative"
  },
  containerFluid: {
    zIndex: 3,
    ...containerFluid,
    position: "relative"
  },
  a: {
    color: primaryColor,
    textDecoration: "none",
    backgroundColor: "transparent"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
    display: "flex",
    alignItems: "center"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0",
    width: "auto"
  },
  whiteColor: {
    "&,&:hover,&:focus": {
      color: "#FFFFFF"
    }
  },
  listItem: {
    display: "inline-block !important",
    textAlign: "center",
    width: "90px",
    padding: "0"
  },
  navLink: {
    color: "#FFFFFF",
    fontWeight: "300",
    paddingTop: "15px",
    paddingBottom: "15px",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    position: "relative",
    display: "inline-block",
    padding: "10px 10px",
    textDecoration: "none",
    "&:hover,&:focus": {
      color: "#FFFFFF"
    }
  }
});
export default footerStyle;
