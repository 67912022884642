import React from "react";
import cx from "classnames";

// @material-ui/core components
import Icon from "@material-ui/core/Icon";

// services
import { urlName } from "services/ChecklistService";

export const getNavLinkClass = (classes, activeRoute, prop) => {
  return (
    classes.itemLink +
    " " +
    cx({
      [" " + classes.collapseActive]: activeRoute(prop.path)
    })
  );
};

export const getNavLinkCollapseClass = (classes, color, activeRoute, prop) => {
  return (
    classes.collapseItemLink +
    " " +
    cx({
      [" " + classes[color]]: activeRoute(prop.path)
    })
  );
};

export const getNavLinkColorClass = (classes, color, activeRoute, prop) => {
  return (
    classes.itemLinkTopLevel +
    " " +
    cx({
      [" " + classes[color]]: activeRoute(prop.path)
    })
  );
};

export const getItemTextClass = (classes, miniActive) => {
  return (
    classes.itemText +
    " " +
    cx({
      [classes.itemTextMini]: miniActive
    })
  );
};

export const getCollapseItemTextClass = (classes, miniActive) => {
  return (
    classes.collapseItemText +
    " " +
    cx({
      [classes.collapseItemTextMini]: miniActive
    })
  );
};

export const getCarotClass = (classes, state, prop) => {
  return classes.caret + " " + (state[prop.state] ? classes.caretActive : "");
};

export const getLeftCarotClass = (classes, state, prop, isTemplate) => {
  const stateId = prop.id + "-" + (isTemplate ? "template" : "active");
  return (
    classes.leftCaret + " " + (state[stateId] ? classes.leftCaretActive : "")
  );
};

export const getItemIcon = prop => {
  if (typeof prop.icon === "string") {
    return <Icon style={getIconStyle(prop.icon)}>{prop.icon}</Icon>;
  }
  return <prop.icon />;
};

// Need to set font family for material outlined
const getIconStyle = icon => {
  const parts = icon.split("_");
  const last = parts.pop();
  let style = {};
  if (last === "outlined") {
    style = { fontFamily: "Material Icons Outlined" };
  }
  return style;
};

export const createdPinnedTemplateLinks = (
  checklists,
  sort,
  templateItems,
  nesting
) => {
  // Sort by name alphabetically to avoid re-ordering issues on update (i.e. pinned);
  // TODO: make sure for subchecklists sort by step order
  // TODO: Allow drag and drop (both top level pinned order and subchecklists - so can reorder steps or move levels)
  const isTemplateLink = !!templateItems;
  const defaultIcon = isTemplateLink ? "content_paste" : "assignment_outlined";
  const checklistLinks = checklists.map(template => {
    return {
      pinnedTemplate: isTemplateLink ? true : false,
      pinnedActive: !isTemplateLink ? true : false,
      templates: templateItems, // Pass in for now, but should query on caret open
      name: template.metadata.name,
      icon:
        template.metadata.banner &&
        template.metadata.banner.icon &&
        template.metadata.banner.icon.link
          ? template.metadata.banner.icon.link
          : defaultIcon,
      id: template.id,
      template: template,
      nesting: nesting,
      views: [] // Dynamically fill collabsilbe submenus in component
    };
  });

  if (sort) {
    checklistLinks.sort((c1, c2) => {
      const name1 = c1.name.toLowerCase();
      const name2 = c2.name.toLowerCase();
      if (name1 < name2) {
        return -1;
      } else if (name2 < name1) {
        return 1;
      }
      return 0;
    });
  }

  return checklistLinks;
};

export const createdPinnedDashboardLinks = (dashboards, sort) => {
  // Sort by name alphabetically to avoid re-ordering issues on update (i.e. pinned);
  // TODO: make sure for subchecklists sort by step order
  // TODO: Allow drag and drop (both top level pinned order and subchecklists - so can reorder steps or move levels)
  const defaultIcon = "dashboard";
  const dashboardLinks = dashboards.map(dashboard => {
    const { metadata, id } = dashboard;
    const { name } = metadata;
    return {
      // pinnedDashboard: true,
      id,
      name,
      icon:
        dashboard.metadata.banner &&
        dashboard.metadata.banner.icon &&
        dashboard.metadata.banner.icon.link
          ? dashboard.metadata.banner.icon.link
          : defaultIcon,
      path: `/app/dashboard/${urlName(name)}?id=${id}&edit=true`,
      views: [] // Dynamically fill collabsilbe submenus in component
    };
  });

  if (sort) {
    dashboardLinks.sort((c1, c2) => {
      const name1 = c1.name.toLowerCase();
      const name2 = c2.name.toLowerCase();
      if (name1 < name2) {
        return -1;
      } else if (name2 < name1) {
        return 1;
      }
      return 0;
    });
  }

  return dashboardLinks;
};
