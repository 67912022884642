import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import cx from "classnames";
import _, { flowRight as compose } from "lodash";
import { graphql } from "react-apollo";

// @material-ui/core components
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import Collapse from "@material-ui/core/Collapse";

import OrgCreation from "components/Modal/OrgCreation";

import { getConfig } from "site-config/site-config";

import {
  getItemTextClass,
  getListItemTextClass,
  LinkItemOrg
} from "./SidebarUtility";

import { CreateOrgMutation } from "api/mutations";

const getLogoClasses = (classes, bgColor) => {
  return (
    classes.logo +
    " " +
    cx({
      [classes.whiteAfter]: bgColor === "white"
    })
  );
};

const getUserOrgLinks = (user, handleOpen) => {
  const { organizations, switchOrg } = user;
  const navLinks = [];
  organizations.forEach((org, i) => {
    const { id, metadata } = org; // TODO: add config section to org database;
    const { name } = metadata; // Use org name from database
    const config = getConfig(id);
    const { logo } = config;
    const navLink = {
      id,
      name,
      logo,
      clickFn: () => switchOrg(id, user),
      itemMini: "M" + i
    };
    navLinks.push(navLink);
  });
  const newOrgLink = {
    id: "new-org",
    name: "New Org",
    icon: "plus",
    clickFn: () => handleOpen(),
    itemMini: "+"
  };
  navLinks.push(newOrgLink);
  return navLinks;
};

// TODO:
// 3. Build select functionality with switchOrg

const OrganizationComponent = ({
  classes,
  user,
  miniActive,
  bgColor,
  logoText,
  logo,
  openOrg,
  openCollapse,
  createOrg
}) => {
  // const openNewOrg =
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const handleClose = () => setCreateModalOpen(false);
  const handleOpen = () => setCreateModalOpen(true);

  const handleCreate = async orgName => {
    // Name is validated in component, so just save and refresh
    const metadata = { name: orgName };
    const results = await createOrg(metadata);
    if (results.error) {
      return console.error(`Error loading data [${results.error}]`);
    }
    const newOrg = results.data.createOrg;
    user.refreshOrg(newOrg);
    setCreateModalOpen(false);
  };

  const logoMini = classes.logoMini;
  const logoClasses = getLogoClasses(classes, bgColor);
  const userOrgLinks = getUserOrgLinks(user, handleOpen);

  const orgName = _.get(user, "currentOrganization.metadata.name");
  let _orgName = orgName ? orgName : logoText; // Default to config file for now.
  if (_orgName.length > 12) {
    _orgName = _orgName.slice(0, 12) + "...";
  }

  return (
    <div className={logoClasses} style={{ zIndex: 10 }}>
      <NavLink to="/" className={logoMini} style={{ float: "left" }}>
        <img src={logo} alt="logo" className={classes.img} />
      </NavLink>
      <List className={classes.list}>
        <ListItem className={classes.item + " " + classes.orgItem}>
          <div
            className={classes.itemLink + " " + classes.userCollapseButton}
            onClick={() => openCollapse("openOrg")}
          >
            <ListItemText
              primary={_orgName}
              secondary={
                <b className={getListItemTextClass(classes, openOrg)} />
              }
              disableTypography={true}
              className={
                getItemTextClass(classes, miniActive) +
                " " +
                classes.orgItemText
              }
            />
          </div>
          <Collapse in={openOrg} unmountOnExit>
            <List className={classes.list + " " + classes.collapseList}>
              {userOrgLinks.map(link => {
                return (
                  <LinkItemOrg
                    key={link.itemMini}
                    classes={classes}
                    link={link}
                    miniActive={miniActive}
                  />
                );
              })}
            </List>
          </Collapse>
        </ListItem>
      </List>
      <OrgCreation
        isOpen={createModalOpen}
        handleConfirm={handleCreate}
        handleClose={handleClose}
      />
    </div>
  );
};

// Only fetch if not passed in dynamically
const CreateOrg = graphql(CreateOrgMutation, {
  props: ({ mutate }) => ({
    createOrg: metadata => {
      return mutate({
        variables: {
          metadata
        }
      });
    }
  })
});

export default compose(CreateOrg)(OrganizationComponent);
