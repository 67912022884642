import _ from "lodash";
import { v4 as uuid } from "uuid";
import { ExpirationType } from "services/ChecklistService";

export const NEW_STEP_MARKER = "New Step";
export const NEW_DESCRIPTION_MARKER = "A description of the step...";

export const StepType = {
  CHECKBOX: "CHECKBOX", // name, description, info, link
  NOTE: "NOTE", // ..., isRequired = false
  CAUTION: "CAUTION", // ..., isRequired = false
  WARNING: "WARNING", // ..., isRequired = false
  TEXT_INPUT: "TEXT_INPUT",
  NUMBER_INPUT: "NUMBER_INPUT",
  LINK_INPUT: "LINK_INPUT",
  LINK_EMBED: "LINK_EMBED",
  CODE_EMBED: "CODE_EMBED",
  IMAGE_INPUT: "IMAGE_INPUT",
  IMAGE_DISPLAY: "IMAGE_DISPLAY",
  DATE_TIME_INPUT: "DATE_TIME_INPUT", // Expiration
  LIST_INPUT: "LIST_INPUT",
  // UPLOAD_INPUT: "UPLOAD_INPUT", // <Icon>attachment</Icon> publish or attachment or cloud_upload
  // SELECT_INPUT: "SELECT_INPUT",
  SUB_CHECKLIST: "SUB_CHECKLIST", // (more steps to complete) // Checklist ID
  COMPLETED_CHECKLIST: "COMPLETED_CHECKLIST", // (i.e. Pilot or Aircraft) // Checklist Type
  CALCULATED: "CALCULATED", // Formula
  TIME_CLOCK: "TIME_CLOCK",
  PLUGIN: "PLUGIN", // (? - weather, airports, location?) <Icon>widgets</Icon> insert_chart or widgets
  USER: "USER"
};

export const neverRequired = step => {
  return (
    step.type === StepType.NOTE ||
    step.type === StepType.CAUTION ||
    step.type === StepType.WARNING ||
    step.type === StepType.CALCULATED
  );
};

// TODO: finish for other types
export const getStepValue = step => {
  if (!step.value) {
    return null;
  }
  switch (step.type) {
    case StepType.TEXT_INPUT:
      return step.value.text;
    case StepType.NUMBER_INPUT:
      return step.value.number;
    case StepType.LINK_INPUT:
      return step.value.link;
    case StepType.LIST_INPUT:
      return step.value.list;
    default:
      return "";
  }
};

export const getStepRowClass = (classes, showRowDividers, popOutOpen) => {
  let rowClass = showRowDividers ? classes.tableRowDividers : classes.tableRow;
  if (popOutOpen) {
    rowClass = "";
  }
  return rowClass;
};

/**
 * Step Update Functions
 *
 * Used both in the app for updates and through voice commands
 */

export const updateStep = (step, steps, updateSteps, callback) => {
  const newSteps = steps.filter(_step => _step.stepId !== step.stepId);
  newSteps.push(step);
  updateSteps(newSteps, callback);
};

// Checkbox Update
export const toggleCheckbox = (step, sendEvent) => {
  const newStep = Object.assign({}, step);
  if (!step.value) {
    step.value = {
      checked: false
    };
  }
  const value = Object.assign({}, step.value);
  value.checked = !step.value.checked;
  newStep.value = value;

  const eventsEnabled = _.get(step, "eventConfig.isEnabled", false);
  if (eventsEnabled) {
    const jsonMetadata = _.get(step, "eventConfig.metadata", "{}");
    const metadata = JSON.parse(jsonMetadata);
    const uncheckedAction = metadata.unchecked || "Marked Reopened";
    const checkedAction = metadata.checked || "Marked Complete";
    const eventAction = value.checked ? checkedAction : uncheckedAction;
    const eventName = step.name;
    const eventMessage = `${eventName} ${eventAction}`;
    if (sendEvent) {
      sendEvent(eventMessage);
    } else {
      console.log("Error: sendEvent not passed in");
    }
  }

  return newStep;
};

const getDefaultStep = (position, item, type) => {
  // Item could be the checklist metadata if no steps or the previous step
  // Currently using this for "type", but could have other previously configured details
  // either in the checklist or on the previous step that get copied to the new default one
  const isStep = !!item.stepId;

  // Just clone previous, may want ot only start with certain types (checkbox, text, etc)
  let newType = type; // Explicitly used if passed in.
  if (!newType) {
    // If no type, use previous step type or Checkbox as default
    newType = isStep ? item.type : StepType.CHECKBOX;
  }

  return {
    stepId: uuid(),
    name: NEW_STEP_MARKER,
    description: "",
    type: newType,
    isRequired: true,
    position: position,
    info: {},
    expiration: [
      {
        type: ExpirationType.NEVER
      }
    ],
    configuration: {},
    value: {}, // Depends on step type (number, string, GUID, datetime)
    eventConfig: {
      isEnabled: false,
      filters: [],
      metadata: JSON.stringify({}) // Don't use actionNames since more dynamic
    }
  };
};

export const convertStep = (step, type, steps, updateSteps) => {
  const newSteps = steps.map(_step => {
    const newStep = Object.assign({}, _step);
    if (newStep.stepId === step.stepId) {
      newStep.type = type; // Later may need more default config set here by type
    }
    return newStep;
  }); // Clone
  updateSteps(newSteps);
};

// Don't use from a step which is memoized and may have stale state for other steps
export const addStep = (steps, updateSteps, position, item, type) => {
  const newSteps = addStepNoUpdate(steps, position, item, type);
  updateSteps(newSteps);
};

export const addStepNoUpdate = (steps, position, item, type) => {
  const newSteps = steps.map(step => {
    let newStep = Object.assign({}, step);
    if (position) {
      if (step.position >= position) {
        newStep.position = step.position + 1;
      }
    }
    return newStep;
  }); // Clone
  newSteps.push(getDefaultStep(position || steps.length + 1, item, type));
  return newSteps;
};

export const deleteStep = (step, steps, updateSteps) => {
  const newSteps = steps
    .map(_step => Object.assign({}, _step))
    .filter(_step => _step.stepId !== step.stepId);
  updateSteps(newSteps);
};

export const duplicateStep = (step, steps, updateSteps) => {
  const prevSteps = [];
  const nextSteps = [];
  steps.forEach(_step => {
    const newStep = Object.assign({}, _step);
    if (newStep.position <= step.position) {
      prevSteps.push(newStep);
    } else {
      nextSteps.push(newStep);
    }
  });
  const clonedStep = JSON.parse(JSON.stringify(step));
  clonedStep.stepId = uuid();
  clonedStep.name =
    clonedStep.name === NEW_STEP_MARKER
      ? clonedStep.name
      : clonedStep.name + " Copy";
  clonedStep.position = clonedStep.position + 1;
  const newSteps = prevSteps.concat([clonedStep], nextSteps);
  updateSteps(newSteps);
};
