import React, { useState, useRef, memo } from "react";
import { Draggable } from "react-beautiful-dnd";

// material-ui components
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Tooltip from "@material-ui/core/Tooltip";
import Popover from "@material-ui/core/Popover";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

// Core Components

// Sub Components
import ActionMenu from "components/ActionMenu/ActionMenu";
import StepButtons from "./StepComponents/StepButtons";

// Step Types
import Checkbox from "./StepTypes/Checkbox/Checkbox";
import Note from "./StepTypes/Note/Note";
import Input from "./StepTypes/Input/Input";
import Calculation from "./StepTypes/Calculation/Calculation";
import Completed from "./StepTypes/Completed/Completed";
import Subchecklist from "./StepTypes/Subchecklist/Subchecklist";
import Plugin from "./StepTypes/Plugin/Plugin";
import TimeClock from "./StepTypes/TimeClock/TimeClock";
import UserStep from "./StepTypes/UserStep/UserStep";
import CodeEmbedStep from "./StepTypes/CodeEmbedStep/CodeEmbedStep";

// Services
import { ChecklistMode } from "services/ChecklistService";
import {
  StepType,
  deleteStep,
  duplicateStep,
  addStepNoUpdate
} from "services/StepService";

// Assets
import stepsStyle from "assets/jss/material-dashboard-pro-react/components/stepsStyle.jsx";

// Functions
const getDraggingStyle = isDragging => {
  return isDragging
    ? { backgroundColor: "#F8F8F8", borderTop: "1px solid #dddddd" }
    : {};
};

const StepTypeSwitch = props => {
  switch (props.step.type) {
    case StepType.CHECKBOX:
      return <Checkbox {...props} />;
    case StepType.NOTE:
    case StepType.CAUTION:
    case StepType.WARNING:
      return <Note {...props} />;
    case StepType.TEXT_INPUT:
    case StepType.NUMBER_INPUT:
    case StepType.LINK_INPUT:
    case StepType.IMAGE_INPUT:
    case StepType.IMAGE_DISPLAY:
    case StepType.DATE_TIME_INPUT:
    case StepType.LINK_EMBED:
    case StepType.LIST_INPUT:
      return <Input {...props} />;
    case StepType.CODE_EMBED:
      return <CodeEmbedStep {...props} />;
    case StepType.CALCULATED:
      return <Calculation {...props} />;
    case StepType.COMPLETED_CHECKLIST:
      return <Completed {...props} />;
    case StepType.SUB_CHECKLIST:
      return <Subchecklist {...props} />;
    case StepType.TIME_CLOCK:
      return <TimeClock {...props} />;
    case StepType.PLUGIN:
      return <Plugin {...props} />;
    case StepType.USER:
      return <UserStep {...props} />;
    default:
      console.log("error", props.step.type);
      return null;
  }
};

const StepMenu = ({
  step,
  steps,
  updateSteps,
  close,
  setFocus,
  showActionMenuPopper,
  hideOtherMenus,
  menuIsOpen,
  configure,
  classes
}) => {
  let turnIntoMenuRef = useRef(null);
  return (
    <Paper
      onMouseEnter={() => {
        hideOtherMenus();
      }}
      onMouseLeave={() => (menuIsOpen ? null : close())}
      style={{ zIndex: 1500 }}
    >
      <MenuList style={{ width: "200px" }}>
        <MenuItem
          onClick={() => {
            deleteStep(step, steps, updateSteps);
            close();
            let validStep = step.position;
            if (validStep > steps.length - 1) {
              validStep = steps.length - 1;
            }
            setFocus(validStep); // BUG: not actually setting focus on input
          }}
          className={classes.menuItem}
          onMouseEnter={() => {
            // Hide all other menus
            hideOtherMenus();
          }}
        >
          <ListItemIcon className={classes.icon}>
            <Icon>delete</Icon>
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.primary }}
            inset={false}
            primary="Delete"
          />
        </MenuItem>
        <MenuItem
          onClick={() => {
            duplicateStep(step, steps, updateSteps);
            close();
            let validStep = step.position + 1;
            setFocus(validStep); // BUG: not actually setting focus on input
          }}
          className={classes.menuItem}
          onMouseEnter={() => {
            // Hide all other menus
            hideOtherMenus();
          }}
        >
          <ListItemIcon className={classes.icon}>
            <Icon>content_copy</Icon>
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.primary }}
            inset={false}
            primary="Duplicate"
          />
        </MenuItem>
        <MenuItem
          ref={node => {
            turnIntoMenuRef = node;
          }}
          className={classes.menuItem}
          onMouseEnter={() => {
            // Other menu items on mouse enter should close this and other menus
            showActionMenuPopper(turnIntoMenuRef, true);
          }}
        >
          <ListItemIcon className={classes.icon}>
            <Icon>repeat</Icon>
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.primary }}
            inset={false}
            primary="Turn Into"
          />
          <ListItemIcon style={{ minWidth: "24px" }}>
            <Icon>play_arrow</Icon>
          </ListItemIcon>
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={configure}
          onMouseEnter={() => {
            // Hide all other menus
            hideOtherMenus();
          }}
        >
          <ListItemIcon className={classes.icon}>
            <Icon>settings</Icon>
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.primary }}
            inset={false}
            primary="Configure"
          />
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onMouseEnter={() => {
            // Hide all other menus
            hideOtherMenus();
          }}
        >
          <ListItemIcon className={classes.icon}>
            <Icon>arrow_forward</Icon>
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.primary }}
            inset={false}
            primary="Move To"
          />
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onMouseEnter={() => {
            // Hide all other menus
            hideOtherMenus();
          }}
        >
          <ListItemIcon className={classes.icon}>
            <Icon>comment</Icon>
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.primary }}
            inset={false}
            primary="Comment"
          />
        </MenuItem>
      </MenuList>
    </Paper>
  );
};

// Memoize steps for performance
function areEqual(prevProps, nextProps) {
  // Only check step equality and focus - all other props shouldn't matter
  // Need to check focus so that when user clicks off a step, all other steps reference that steps new state
  // One possible expception is metadata, but that is just used by SubChecklist
  //    to create the parent and root references which shouldn't change
  // Later steps may play a role in updates when a step references an earlier step.
  //    but should be able to pull that out of config and check explicityly
  //    can update when any step changes arbitrarily because all steps will update all the time
  //    would need to memoize all step components if go that route.
  //    currently debugging how to pass in the handleKeyDown and not have it called with old checklist steps data.
  if (
    prevProps.step === nextProps.step &&
    prevProps.focus === nextProps.focus
  ) {
    return true;
  } else {
    return false;
  }
}

/**
 * A Step in a checklist
 *
 * See README for details
 *
 */
const Step = props => {
  let menuAnchorEl = useRef(null);
  const [editing, setEditState] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [noteOpen, setNoteState] = useState(false);
  const [stepMenu, setStepMenu] = useState({
    open: false,
    step: null,
    anchorEl: null
  });
  const [actionMenu, setActionMenu] = useState({
    open: false,
    step: null,
    anchorEl: null,
    anchorOrigin: {
      vertical: "top",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "bottom",
      horizontal: "right"
    },
    isTurnInto: false
  });
  const [actionMenuPopper, setActionMenuPopper] = useState({
    open: false,
    step: null,
    anchorEl: null,
    anchorOrigin: {
      vertical: "top",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "bottom",
      horizontal: "right"
    },
    isTurnInto: false
  });
  const {
    classes,
    step,
    steps,
    updateSteps,
    index,
    mode,
    focus,
    setFocus,
    handleKeyDown
  } = props;
  const isEditMode = mode === ChecklistMode.TEMPLATE_EDIT;
  const editVisible = isEditMode && showEdit;
  const showStepMenu = () => {
    const stepMenu = {
      open: true,
      step: step,
      anchorEl: menuAnchorEl
    };
    setStepMenu(stepMenu);
  };
  const showActionMenu = (ref, isTurnInto) => {
    let newActionMenu = {
      open: true,
      step: step,
      anchorEl: ref,
      anchorOrigin: {
        vertical: "top",
        horizontal: ref.selectionEnd * 8
      },
      transformOrigin: {
        vertical: "bottom",
        horizontal: 0
      },
      isTurnInto
    };
    setActionMenu(newActionMenu);
  };
  const showActionMenuPopper = (ref, isTurnInto) => {
    const newActionMenuPopper = {
      open: true,
      step: step,
      anchorEl: ref,
      isTurnInto
    };
    setActionMenuPopper(newActionMenuPopper);
  };
  const handleStepMenuClose = () => {
    setStepMenu({
      open: false,
      step: null,
      anchorEl: null
    });
    hideOtherMenus();
  };
  const handleActionMenuClose = step => {
    setActionMenu(
      Object.assign({}, actionMenu, {
        open: false,
        step: null,
        anchorEl: null
      })
    );
    if (step) {
      setFocus(step.position);
    }
  };
  const handleActionPopperClose = () => {
    setActionMenuPopper(
      Object.assign({}, actionMenu, {
        open: false,
        step: null,
        anchorEl: null
      })
    );
  };
  // Hides menus other then step menu (nested menus)
  const hideOtherMenus = () => {
    handleActionPopperClose();
  };
  const _handleKeyDown = (e, step, ref) => {
    if (e.key === "/") {
      showActionMenu(ref);
      // show step select menu => create new step of type (unless in new step) and delete / from previous
    } else {
      handleKeyDown(e, step);
    }
  };
  const menuIsOpen = actionMenuPopper.open; // Add sub step menus here
  const editProps = {
    editing,
    setEditState,
    noteOpen,
    setNoteState,
    editVisible
  };

  return (
    <Draggable
      draggableId={step.stepId}
      key={step.stepId}
      index={index}
      isDragDisabled={mode !== ChecklistMode.TEMPLATE_EDIT}
    >
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
          onMouseEnter={() => {
            // Set to negative one to force all steps to update prior to interacting with a different one.
            // This prevents a step from having stale data when it updates (i.e. through + button or action menu)
            if (focus !== -1 && focus !== step.position) {
              setFocus(-1); // Need to fix bug where -1 set, change checklist title, hover back, but don't update correctly
            }
            setShowEdit(true);
          }}
          onMouseLeave={() => setShowEdit(false)}
        >
          <div className={classes.fullRow}>
            <div className={classes.stepPlusMenu}>
              <Tooltip
                id="tooltip-top"
                title="Add Step"
                placement="top"
                disableFocusListener={true}
                classes={{ tooltip: classes.tooltip }}
              >
                <IconButton
                  aria-label="Icon"
                  className={
                    editVisible
                      ? classes.stepMenuButton
                      : classes.stepMenuButtonHide
                  }
                  classes={{ root: classes.iconButtonRoot }}
                  onClick={() => {
                    // Create a new step of the type
                    const position = step.position + 1;
                    const newSteps = addStepNoUpdate(steps, position, step);
                    updateSteps(newSteps);
                    setFocus(position);
                  }}
                >
                  <Icon className={classes.iconEdit} fontSize="small">
                    add
                  </Icon>
                </IconButton>
              </Tooltip>
            </div>
            <div className={classes.stepEditMenu}>
              <Tooltip
                id="tooltip-top"
                title={
                  <div>
                    <div>Drag to Move Step</div>
                    <div>Click to Open Menu</div>
                  </div>
                }
                placement="top"
                disableFocusListener={true}
                classes={{ tooltip: classes.tooltip }}
              >
                <IconButton
                  aria-label="Icon"
                  className={
                    editVisible
                      ? classes.stepMenuButton
                      : classes.stepMenuButtonHide
                  }
                  classes={{ root: classes.iconButtonRoot }}
                  ref={node => (menuAnchorEl = node)}
                  onClick={showStepMenu}
                >
                  <Icon
                    className={classes.iconEdit}
                    fontSize="small"
                    {...provided.dragHandleProps}
                  >
                    menu
                  </Icon>
                </IconButton>
              </Tooltip>
            </div>
            <Table
              className={editing ? classes.tableEditing : classes.table}
              style={getDraggingStyle(snapshot.isDragging)}
            >
              <TableBody>
                <StepTypeSwitch
                  {...props}
                  isEditMode={isEditMode}
                  editVisible={editVisible}
                  editing={editing}
                  setEditState={setEditState}
                  handleKeyDown={_handleKeyDown}
                  noteOpen={noteOpen}
                />
              </TableBody>
            </Table>
            <StepButtons {...props} {...editProps} />
          </div>
          <Popover
            open={stepMenu.open}
            anchorEl={stepMenu.anchorEl}
            anchorPosition={{ top: 200, left: 400 }}
            onClose={handleStepMenuClose}
            anchorOrigin={{
              vertical: "center",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "right"
            }}
          >
            <StepMenu
              step={step}
              steps={steps}
              updateSteps={updateSteps}
              classes={classes}
              close={handleStepMenuClose}
              setFocus={setFocus}
              menuIsOpen={menuIsOpen}
              showActionMenuPopper={showActionMenuPopper}
              hideOtherMenus={hideOtherMenus}
              configure={() => setEditState(true)}
            />
          </Popover>
          <Popover
            open={actionMenu.open}
            anchorEl={actionMenu.anchorEl}
            onClose={() => handleActionMenuClose(step)}
            anchorOrigin={{
              vertical: actionMenu.anchorOrigin.vertical,
              horizontal: actionMenu.anchorOrigin.horizontal
            }}
            transformOrigin={{
              vertical: actionMenu.transformOrigin.vertical,
              horizontal: actionMenu.transformOrigin.horizontal
            }}
            style={{ marginRight: "20px" }}
            classes={{ root: classes.actionMenuModal }}
          >
            <ActionMenu
              step={step}
              steps={steps}
              updateSteps={updateSteps}
              setFocus={setFocus}
              close={handleActionMenuClose}
              isTurnInto={actionMenu.isTurnInto}
            />
          </Popover>
          <Popper
            open={actionMenuPopper.open}
            anchorEl={actionMenuPopper.anchorEl}
            placement="right"
            style={{ zIndex: "1500" }}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <ActionMenu
                  step={step}
                  steps={steps}
                  updateSteps={updateSteps}
                  setFocus={setFocus}
                  close={handleActionPopperClose}
                  isTurnInto={actionMenuPopper.isTurnInto}
                  closeStepMenu={handleStepMenuClose}
                />
              </Fade>
            )}
          </Popper>
        </div>
      )}
    </Draggable>
  );
};

export default withStyles(stepsStyle)(memo(Step, areEqual));
