/** Import React and 3rd party libs */
import React from "react";

// @material-ui/core components
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Collapse from "@material-ui/core/Collapse";

// @material-ui/icons
import Ballot from "@material-ui/icons/Ballot";
import Info from "@material-ui/icons/Info";

// Components
/** Import generic components - internal and creative tim */

// Subcomponents
import StepTypeEdit from "../StepComponents/StepEdit/StepTypeEdit";
import StepInfoEdit from "../StepComponents/StepEdit/StepInfoEdit";

// Services
import { ChecklistMode, propertyExists } from "services/ChecklistService";

// Queries
/** Import all graphql queries */

// Assets
/** Import styles */

// Variables
/** Declare all constant variables */

// Functions
export const isInputDisabled = mode => {
  return (
    mode !== ChecklistMode.INSTANCE_EXEC && mode !== ChecklistMode.INSTANCE_EDIT
  );
};

// JSX Functions
export const getCommonTabs = (step, updateStep) => {
  return [
    {
      tabName: "Type",
      tabIcon: Ballot,
      tabContent: <StepTypeEdit step={step} updateStep={updateStep} />
    },
    {
      tabName: "Info",
      tabIcon: Info,
      tabContent: <StepInfoEdit step={step} updateStep={updateStep} />
    }
  ];
};

/**
 * Controls open and close of step configuration
 * Most config should be inline if able, but this is for extra items
 * such as required, toggle description, expirations / alerts, etc.
 */
export const EditRow = ({ classes, open, children }) => {
  // if (!open) return null;
  return (
    <TableRow className={classes.tableRow}>
      <TableCell className={classes.tableEdit} colSpan="2">
        <Collapse in={open}>{children}</Collapse>
      </TableCell>
    </TableRow>
  );
};

export const getIconClass = (step, classes) => {
  const SuccessClassName = "icon-success";
  const isValue = propertyExists(step.value);
  const iconClass = isValue
    ? classes.iconType + " " + SuccessClassName
    : classes.iconType;
  return iconClass;
};
