import React from "react";

function terms() {
  return (
    <span>
      <h1>Welcome to our Privacy Policy</h1>
      <h3>Your privacy is critically important to us.</h3>
      <br />
      Airsync is located in the United States.
      <br />
      <br />
      <p>
        It is Airsync’s policy to respect your privacy regarding any information
        we may collect while operating our website. This Privacy Policy applies
        to <a href="https://www.airsync.ai">https://www.airsync.ai</a>{" "}
        (hereinafter, &quot;us&quot;, &quot;we&quot;, or
        &quot;https://www.airsynsc.ai&quot;). We respect your privacy and are
        committed to protecting personally identifiable information you may
        provide us through the Website. We have adopted this privacy policy
        (&quot;Privacy Policy&quot;) to explain what information may be
        collected on our Website, how we use this information, and under what
        circumstances we may disclose the information to third parties. This
        Privacy Policy applies only to information we collect through the
        Website and does not apply to our collection of information from other
        sources.
      </p>
      <p>
        This Privacy Policy, together with the Terms and conditions posted on
        our Website, set forth the general rules and policies governing your use
        of our Website. Depending on your activities when visiting our Website,
        you may be required to agree to additional terms and conditions.
      </p>
      <h2>Website Visitors</h2>
      <p>
        Like most website operators, Airsync collects non-personally-identifying
        information of the sort that web browsers and servers typically make
        available, such as the browser type, language preference, referring
        site, and the date and time of each visitor request. Airsync’s purpose
        in collecting non-personally identifying information is to better
        understand how Airsync’s visitors use its website. From time to time,
        Airsync may release non-personally-identifying information in the
        aggregate, e.g., by publishing a report on trends in the usage of its
        website.
      </p>
      <p>
        Airsync also collects potentially personally-identifying information
        like Internet Protocol (IP) addresses for logged in users and for users
        leaving comments on https://www.airsync.ai. Airsync only discloses
        logged in user and commenter IP addresses under the same circumstances
        that it uses and discloses personally-identifying information as
        described below.
      </p>
      <h2>Gathering of Personally-Identifying Information</h2>
      <p>
        Certain visitors to Airsync’s websites choose to interact with Airsync
        in ways that require Airsync to gather personally-identifying
        information. The amount and type of information that Airsync gathers
        depends on the nature of the interaction. For example, we ask visitors
        who sign up for a blog at https://www.airsync.ai to provide a username
        and email address.
      </p>
      <h2>Security</h2>
      <p>
        The security of your Personal Information is important to us, but
        remember that no method of transmission over the Internet, or method of
        electronic storage is 100% secure. While we strive to use commercially
        acceptable means to protect your Personal Information, we cannot
        guarantee its absolute security.
      </p>
      <h2>Links To External Sites</h2>
      <p>
        Our Service may contain links to external sites that are not operated by
        us. If you click on a third party link, you will be directed to that
        third party&apos;s site. We strongly advise you to review the Privacy
        Policy and terms and conditions of every site you visit.
      </p>
      <p>
        We have no control over, and assume no responsibility for the content,
        privacy policies or practices of any third party sites, products or
        services.
      </p>
      <h2>Protection of Certain Personally-Identifying Information</h2>
      <p>
        Airsync discloses potentially personally-identifying and
        personally-identifying information only to those of its employees,
        contractors and affiliated organizations that (i) need to know that
        information in order to process it on Airsync’s behalf or to provide
        services available at Airsync’s website, and (ii) that have agreed not
        to disclose it to others. Some of those employees, contractors and
        affiliated organizations may be located outside of your home country; by
        using Airsync’s website, you consent to the transfer of such information
        to them. Airsync will not rent or sell potentially
        personally-identifying and personally-identifying information to anyone.
        Other than to its employees, contractors and affiliated organizations,
        as described above, Airsync discloses potentially personally-identifying
        and personally-identifying information only in response to a subpoena,
        court order or other governmental request, or when Airsync believes in
        good faith that disclosure is reasonably necessary to protect the
        property or rights of Airsync, third parties or the public at large.
      </p>
      <p>
        If you are a registered user of https://www.airsync.ai and have supplied
        your email address, Airsync may occasionally send you an email to tell
        you about new features, solicit your feedback, or just keep you up to
        date with what’s going on with Airsync and our products. We primarily
        use our blog to communicate this type of information, so we expect to
        keep this type of email to a minimum. If you send us a request (for
        example via a support email or via one of our feedback mechanisms), we
        reserve the right to publish it in order to help us clarify or respond
        to your request or to help us support other users. Airsync takes all
        measures reasonably necessary to protect against the unauthorized
        access, use, alteration or destruction of potentially
        personally-identifying and personally-identifying information.
      </p>
      <h2>Aggregated Statistics</h2>
      <p>
        Airsync may collect statistics about the behavior of visitors to its
        website. Airsync may display this information publicly or provide it to
        others. However, Airsync does not disclose your personally-identifying
        information.
      </p>
      <h2>E-commerce</h2>
      <p>
        Those who engage in transactions with Airsync – by purchasing
        Airsync&apos;s services or products, are asked to provide additional
        information, including as necessary the personal and financial
        information required to process those transactions. In each case,
        Airsync collects such information only insofar as is necessary or
        appropriate to fulfill the purpose of the visitor’s interaction with
        Airsync. Airsync does not disclose personally-identifying information
        other than as described below. And visitors can always refuse to supply
        personally-identifying information, with the caveat that it may prevent
        them from engaging in certain website-related activities.
      </p>
      <h2>Privacy Policy Changes</h2>
      <p>
        Although most changes are likely to be minor, Airsync may change its
        Privacy Policy from time to time, and in Airsync’s sole discretion.
        Airsync encourages visitors to frequently check this page for any
        changes to its Privacy Policy. Your continued use of this site after any
        change in this Privacy Policy will constitute your acceptance of such
        change.
      </p>
      <h2>Credit & Contact Information</h2>
      <p>
        This privacy policy was created at{" "}
        <a
          style={{ color: "inherit", textDecoration: "none" }}
          href="https://termsandconditionstemplate.com/privacy-policy-generator/"
          title="Privacy policy template generator"
          rel="noopener noreferrer"
          target="_blank"
        >
          termsandconditionstemplate.com
        </a>
        . If you have any questions about this Privacy Policy, please contact us
        via <a href="mailto:team@airsync.ai">email</a>.
      </p>
    </span>
  );
}
export default terms;
