import React from "react";

// @material-ui/core components
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// @material-ui/icons

// Services
import { ExpirationType } from "services/ChecklistService";

import { capitalizeFirstLetter } from "services/utility";

const ExpirationTypeMenuItems = Object.keys(ExpirationType).map(key => {
  return {
    name: key
      .split("_")
      .map(_ => _.toLowerCase())
      .map(capitalizeFirstLetter)
      .join(" "),
    value: key
  };
});

class ConfigureExpiration extends React.Component {
  updateExpirationType = (expiration, event) => {
    const newExpiration = Object.assign({}, expiration);
    newExpiration.type = event.target.value || ExpirationType.NEVER;
    if (newExpiration.type === ExpirationType.NEVER) {
      newExpiration.start = null;
    }
    return newExpiration;
  };

  // TODO:
  // type never -> nothing
  // type date -> date picker [expire on January 3rd]
  // type time -> text box number, text box metric, optional checklist [expire after 30 days after last time completed]
  // type count -> select checklist template (add step later?) [expire after 20 flights]
  // type sum -> select checklist template, select step [expire after 200 flight hours]
  // User is a checklist (quals -> like maintenance schedule)
  render() {
    const { classes, expiration, updateExpiration } = this.props;

    return (
      <FormControl
        fullWidth
        className={classes.selectFormControl + " " + classes.expirationWidget}
      >
        <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
          Expiration Type
        </InputLabel>
        <Select
          MenuProps={{
            className: classes.selectMenu
          }}
          classes={{
            select: classes.select
          }}
          value={expiration.type}
          onChange={event =>
            updateExpiration(this.updateExpirationType(expiration, event))
          }
          inputProps={{
            name: "simpleSelect",
            id: "simple-select"
          }}
        >
          <MenuItem
            disabled
            classes={{
              root: classes.selectMenuItem
            }}
            value="1"
          >
            Expiration Type
          </MenuItem>
          {ExpirationTypeMenuItems.map(item => (
            <MenuItem
              key={item.value}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value={item.value}
            >
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

export default ConfigureExpiration;
