import React from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import { Link } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Add from "@material-ui/icons/Add";
import { GrFormNextLink } from "react-icons/gr";
import { IconContext } from "react-icons";

// Components
import Autocomplete from "components/Autocomplete/Autocomplete.jsx";
import Button from "components/CustomButtons/Button.jsx";

// Subcomponents
// Services
import { StepType } from "services/StepService";

// Queries
import { ChecklistTemplatesQuery } from "api/queries/index";

// Assets
import stepsStyle from "assets/jss/material-dashboard-pro-react/components/stepsStyle.jsx";

// Variables
// Functions

const ChecklistTemplateSelector = ({
  classes,
  step,
  updateStep,
  isDisabled,
  name,
  data,
  createNew,
  editing
}) => {
  const onChange = results => {
    if (!results) {
      return;
    }

    let newStep = Object.assign({}, step);
    const checklistConfig = results
      ? {
          checklist: {
            name: results.label,
            checklistId: results.value,
            version: results.version
          }
        }
      : {
          checklist: {}
        };

    newStep.configuration = Object.assign(
      {},
      step.configuration,
      checklistConfig
    );
    updateStep(newStep);
  };

  const { getChecklistTemplates } = data
    ? data
    : { getChecklistTemplates: { items: [] } };
  const availableChecklists = getChecklistTemplates
    ? getChecklistTemplates.items.map(checklist => {
        return {
          label: checklist.metadata.name,
          value: checklist.id,
          version: checklist.metadata.version
        };
      })
    : [];

  const { configuration } = step;
  let link;
  if (
    configuration &&
    configuration.checklist &&
    configuration.checklist.checklistId
  ) {
    const { checklistId, name } = configuration.checklist;
    link = `/app/checklist/${name}?id=${checklistId}&edit=true`;
  }

  const linkStyle = editing
    ? { float: "right", marginRight: "96px", marginTop: "-106px" }
    : { float: "right", marginTop: "-35px" };

  return (
    <span>
      {name ? <legend>{name}</legend> : null}
      {editing ? (
        <Autocomplete
          placeholder={"Type to find an existing checklist"}
          value={step.configuration.checklist}
          onChange={onChange}
          choices={availableChecklists}
          disabled={isDisabled}
        />
      ) : null}

      {createNew && editing ? (
        <Button
          onClick={createNew}
          color="info"
          size="sm"
          style={{ float: "right", marginTop: "-106px" }}
        >
          <Add /> New
        </Button>
      ) : null}
      {link ? (
        <Link to={link}>
          <Button
            onClick={e => e.stopPropagation()}
            color="info"
            size="sm"
            style={linkStyle}
          >
            <IconContext.Provider
              value={{
                className: classes.subLink
              }}
            >
              <GrFormNextLink style={{ color: "white" }} />
            </IconContext.Provider>
          </Button>
        </Link>
      ) : null}
    </span>
  );
};

const ChecklistTemplates = graphql(ChecklistTemplatesQuery, {
  options: props => ({
    fetchPolicy: "network-only",
    variables: {
      orgId: props.user.currentOrganization.id
    }
  }),
  skip: props => {
    const { step } = props;
    return (
      step.type !== StepType.SUB_CHECKLIST &&
      step.type !== StepType.COMPLETED_CHECKLIST
    );
  }
});

export default compose(
  ChecklistTemplates,
  withStyles(stepsStyle)
)(ChecklistTemplateSelector);
