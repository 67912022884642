import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import withWidth from "@material-ui/core/withWidth";

// @material-ui/icons
import Notifications from "@material-ui/icons/Notifications";
import Search from "@material-ui/icons/Search";

// core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-dashboard-pro-react/components/headerLinksStyle";

class HeaderLinks extends React.Component {
  state = {
    open: false,
    alerts: [
      { id: "TOUR", message: "Take a Tour" },
      { id: "ONBOARD", message: "Expedited Onboarding" },
      { id: "AIRCRAFT", message: "Add an Aircraft" }
    ],
    searchWord: ""
  };

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleAlertClick = alert => {
    const { runTour } = this.props;
    if (alert.id === "TOUR") {
      this.setState({ open: false }, runTour());
    }
    if (alert.id === "ONBOARD") {
      this.props.history.push(
        "/app/checklist/pilot?id=DRONE000-0000-0000-0000-0000000PILOT"
      );
    }
    if (alert.id === "AIRCRAFT") {
      this.props.history.push(
        "/app/checklist/aircraft?id=DRONE000-0000-0000-0000-0000AIRCRAFT"
      );
    }
  };

  handleSearch = evt => {
    const { searchWord } = this.state;
    evt.preventDefault();
    this.props.history.push(`/app/search?term=${searchWord}`);
  };

  renderAlerts(classes, alerts) {
    const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover);
    return (
      <MenuList role="menu">
        {alerts.map(alert => {
          return (
            <MenuItem
              key={alert.id}
              onClick={() => this.handleAlertClick(alert)}
              className={dropdownItem}
            >
              {alert.message}
            </MenuItem>
          );
        })}
      </MenuList>
    );
  }

  renderSearch = () => {
    const { classes } = this.props;
    const searchButton = classes.top + " " + classes.searchButton;
    return (
      <span id="search">
        <form
          onSubmit={evt => this.handleSearch(evt)}
          className={classes.searchForm}
        >
          <CustomInput
            formControlProps={{
              className: classes.top + " " + classes.search
            }}
            inputProps={{
              placeholder: "Search",
              inputProps: {
                "aria-label": "Search",
                className: classes.searchInput,
                onChange: evt => this.setState({ searchWord: evt.target.value })
              }
            }}
          />
        </form>

        <Button
          color="white"
          aria-label="edit"
          justIcon
          round
          className={searchButton}
          onClick={evt => this.handleSearch(evt)}
        >
          <Search
            className={classes.headerLinksSvg + " " + classes.searchIcon}
          />
        </Button>
      </span>
    );
  };

  renderNotification = () => {
    const { classes, width } = this.props;
    const { open, alerts } = this.state;

    const displayAlerts =
      width === "sm" || width === "xs"
        ? alerts.filter(alert => alert.id !== "TOUR")
        : alerts;

    const managerClasses = classNames({
      [classes.managerClasses]: true
    });
    return (
      <div id="notifications" className={managerClasses}>
        <Button
          color="transparent"
          justIcon
          aria-label="Notifications"
          aria-owns={open ? "menu-list" : null}
          aria-haspopup="true"
          onClick={this.handleClick}
          className={classes.buttonLink}
          muiClasses={{
            label: ""
          }}
          ref={node => {
            this.anchorEl = node;
          }}
        >
          <Notifications
            className={classes.headerLinksSvg + " " + classes.links}
          />
          <span className={classes.notifications}>{displayAlerts.length}</span>
          <Hidden mdUp implementation="css">
            <span onClick={this.handleClick} className={classes.linkText}>
              Notification
            </span>
          </Hidden>
        </Button>
        <Popper
          open={open}
          anchorEl={this.anchorEl}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !open,
            [classes.pooperResponsive]: true,
            [classes.pooperNav]: true
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={this.handleClose}>
                  {this.renderAlerts(classes, displayAlerts)}
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderSearch()}
        {this.renderNotification()}
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  runTour: PropTypes.func
};

export default withRouter(
  withWidth()(withStyles(headerLinksStyle)(HeaderLinks))
);
