import React from "react";

// @material-ui/icons

// Components

// Subcomponents
import Steps from "../Steps/Steps.jsx";

// Services

const ChecklistSteps = ({
  checklist,
  updateStep,
  updateSteps,
  mode,
  parents,
  saveButtons,
  user,
  handleKeyDown,
  focus,
  setFocus,
  sendEvent
}) => {
  return (
    <div>
      <Steps
        checklist={checklist}
        updateStep={updateStep}
        updateSteps={updateSteps}
        mode={mode}
        parents={parents}
        user={user}
        focus={focus}
        setFocus={setFocus}
        handleKeyDown={handleKeyDown}
        sendEvent={sendEvent}
      />
      <br />
      <br />
      <br />
      <br />
      {saveButtons}
    </div>
  );
};

export default ChecklistSteps;
