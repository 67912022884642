import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Add from "@material-ui/icons/Add";

// Components
import Button from "components/CustomButtons/Button.jsx";
import Confirm from "components/Modal/Confirm";

// Subomponents
import Widgets from "views/Dashboards/Widgets/Widgets";
import WidgetModal from "views/Dashboards/Widgets/WidgetComponents/WidgetModal";

// Services
import { LogbookMode } from "services/LogbookService";
import { guid } from "services/utility";
import { calculateInitialLayoutPosition } from "services/WidgetService";

// Assets
import logbookWidgetsStyle from "assets/jss/material-dashboard-pro-react/views/logbookWidgetsStyle";

// Variables

// Functions
const AddWidgetButton = ({ widgets, updateWidgets, classes, addWidget }) => (
  <Button
    onClick={() => addWidget(widgets, updateWidgets)}
    color="rose"
    round
    className={classes.marginRight}
  >
    <Add className={classes.icons} />
    Add Widget
  </Button>
);

// TODO: finish add/edit widget modal
class LogbookWidgets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      widgetModalIsOpen: false,
      confirmModalIsOpen: false,
      widgetToEdit: null
    };
  }

  handleClose = () => {
    this.setState({
      widgetModalIsOpen: false,
      confirmModalIsOpen: false,
      widgetToEdit: null
    });
  };

  handleSave = widget => {
    const { widgets, updateWidgets } = this.props;
    const newWidget = JSON.parse(JSON.stringify(widget)); // Clone
    let newWidgets;
    if (!newWidget.widgetId) {
      // New
      newWidget.widgetId = guid();
      newWidget.layout = calculateInitialLayoutPosition(newWidget, widgets);
      newWidgets = widgets.slice(0); // Clone
    } else {
      // Edit
      newWidgets = widgets.filter(
        widget => widget.widgetId !== newWidget.widgetId
      );
    }
    newWidgets.push(newWidget);
    updateWidgets(newWidgets);
    this.setState({ widgetModalIsOpen: false });
  };

  handleConfirm = () => {
    const { widgets, updateWidgets } = this.props;
    const { widgetToDelete } = this.state;
    const newWidgets = widgets.filter(
      widget => widget.widgetId !== widgetToDelete.widgetId
    );
    updateWidgets(newWidgets);
    this.setState({ confirmModalIsOpen: false });
  };

  addWidget = () => {
    this.setState({ widgetModalIsOpen: true });
  };

  deleteWidget = widget => {
    this.setState({
      widgetToDelete: widget,
      confirmModalIsOpen: true
    });
  };

  editWidget = widget => {
    this.setState({ widgetModalIsOpen: true, widgetToEdit: widget });
  };

  render() {
    const {
      widgets,
      mode,
      updateWidgets,
      globalSettings,
      globalData,
      classes,
      user,
      showMedia
    } = this.props;
    const { widgetToEdit, widgetModalIsOpen, confirmModalIsOpen } = this.state;

    return (
      <div>
        <Widgets
          widgets={widgets}
          updateWidget={this.handleSave}
          updateWidgets={updateWidgets}
          editWidget={this.editWidget}
          deleteWidget={this.deleteWidget}
          mode={mode}
          globalSettings={globalSettings}
          globalData={globalData}
          user={user}
          showMedia={showMedia}
        />
        {mode === LogbookMode.EDIT ? (
          <AddWidgetButton
            widgets={widgets}
            updateWidgets={updateWidgets}
            classes={classes}
            addWidget={this.addWidget}
          />
        ) : null}
        <WidgetModal
          isOpen={widgetModalIsOpen}
          widgetToEdit={widgetToEdit}
          handleSave={this.handleSave}
          handleClose={this.handleClose}
          user={user}
        />
        <Confirm
          isOpen={confirmModalIsOpen}
          handleConfirm={this.handleConfirm}
          handleClose={this.handleClose}
          title="Are you sure you want to delete this widget?"
        />
      </div>
    );
  }
}

export default withStyles(logbookWidgetsStyle)(LogbookWidgets);
