import React from "react";

import InputBase from "@material-ui/core/InputBase";

import { NEW_DASHBOARD_DESCRIPTION_MARKER } from "services/LogbookService";

function update(property, value, metadata, updateMetadata) {
  const newMetadata = Object.assign({}, metadata);
  newMetadata[property] = value;
  updateMetadata(newMetadata);
}

const DescriptionEdit = ({
  metadata,
  updateMetadata,
  handleKeyDown,
  classes
}) => {
  const isNew =
    metadata.description === NEW_DASHBOARD_DESCRIPTION_MARKER ||
    metadata.description === "";
  const currentValue = isNew ? "" : metadata.description;
  const placeholder = isNew ? NEW_DASHBOARD_DESCRIPTION_MARKER : "";

  return (
    <div
      className={classes.cardDescription}
      onKeyDown={e => handleKeyDown(e, metadata)}
    >
      <InputBase
        id="description"
        autoFocus={isNew}
        autoComplete="off"
        value={currentValue}
        placeholder={placeholder}
        multiline={true}
        onChange={event =>
          update("description", event.target.value, metadata, updateMetadata)
        }
        type="text"
        classes={{
          root: classes.inputDescriptionRoot,
          input: classes.inputDescription
        }}
      />
    </div>
  );
};

const DescriptionDisplay = ({ metadata, classes }) => {
  return <div className={classes.cardDescription}>{metadata.description}</div>;
};

const DashboardDescription = ({
  isEditMode,
  metadata,
  updateMetadata,
  classes,
  handleKeyDown
}) => {
  return isEditMode ? (
    <DescriptionEdit
      metadata={metadata}
      updateMetadata={updateMetadata}
      classes={classes}
      handleKeyDown={handleKeyDown}
    />
  ) : (
    <DescriptionDisplay metadata={metadata} classes={classes} />
  );
};

export default DashboardDescription;
