import gql from "graphql-tag";
import { reactGridLayoutFields } from "./common";

////////////////////////////////////////////////
// LOGBOOK & WIDGET FRAGMENTS
////////////////////////////////////////////////

const logbookDetails = gql`
  fragment LogbookDetails on LogbookMetadata {
    name
    version
    dashboardNav
    tags
  }
`;

const logbookOwner = gql`
  fragment LogbookOwner on LogbookMetadata {
    owner {
      ownerId
      type
    }
  }
`;

const logbookOrganization = gql`
  fragment LogbookOrganization on LogbookMetadata {
    organization {
      orgId
      type
    }
  }
`;

const logbookSharing = gql`
  fragment LogbookSharing on LogbookMetadata {
    sharing {
      type
      list
    }
  }
`;

const logbookCreated = gql`
  fragment LogbookCreated on LogbookMetadata {
    created {
      by
      time
    }
  }
`;

const logbookModified = gql`
  fragment LogbookModified on LogbookMetadata {
    modified {
      by
      time
    }
  }
`;

const logbookGlobal = gql`
  fragment LogbookGlobal on LogbookMetadata {
    global {
      dateRange {
        type
        ranges {
          label
          range
        }
      }
      reference {
        type
        checklist {
          name
          checklistId
        }
      }
    }
  }
`;

const widgetHelpInfo = gql`
  fragment WidgetHelpInfo on LogbookWidget {
    info {
      text
      link
    }
  }
`;

const widgetConfiguration = gql`
  fragment WidgetConfiguration on LogbookWidget {
    configuration {
      title
      icon
      label
      checklistFormula
      checklist {
        name
        checklistId
        version
      }
      step {
        stepId
        name
        type
      }
      checklists {
        name
        checklistId
        version
      }
      strings
      integers
      floats
      booleans
      json
    }
  }
`;

const widgetValues = gql`
  fragment WidgetValues on LogbookWidget {
    values {
      color
      stats
      status
      step {
        stepId
      }
    }
  }
`;

const widgetLayout = gql`
  fragment WidgetLayout on LogbookWidget {
    layout {
      lg {
        ...ReactGridLayoutFields
      }
      md {
        ...ReactGridLayoutFields
      }
      sm {
        ...ReactGridLayoutFields
      }
    }
  }
  ${reactGridLayoutFields}
`;

const dashboardBanner = gql`
  fragment DashboardBanner on LogbookMetadata {
    banner {
      icon {
        type
        link
      }
      image {
        type
        link
      }
      color
    }
  }
`;

export const Logbook = {
  logbookMetadataInfo: gql`
    fragment LogbookMetadataInfo on LogbookMetadata {
      description
      ...LogbookDetails
      ...LogbookOwner
      ...LogbookOrganization
      ...LogbookSharing
      ...LogbookCreated
      ...LogbookModified
      ...LogbookGlobal
      ...DashboardBanner
    }
    ${logbookDetails}
    ${logbookOwner}
    ${logbookOrganization}
    ${logbookSharing}
    ${logbookCreated}
    ${logbookModified}
    ${logbookGlobal}
    ${dashboardBanner}
  `,
  widgetInfo: gql`
    fragment WidgetInfo on LogbookWidget {
      widgetId
      type
      ...WidgetHelpInfo
      ...WidgetConfiguration
      ...WidgetValues
      ...WidgetLayout
    }
    ${widgetHelpInfo}
    ${widgetConfiguration}
    ${widgetValues}
    ${widgetLayout}
  `
};
