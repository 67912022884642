/** Import React and 3rd party libs */
import React from "react";
import { NavLink } from "react-router-dom";

// @material-ui/core components
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

// @material-ui/icons
/** Import material ui icons */

// Components
/** Import generic components - internal and creative time */

// Subcomponents
/** Import components used only by this component - relative path imports */

// Services
import {
  getNavLinkColorClass,
  getItemTextClass,
  getItemIcon
} from "./LinksUtility";

// Queries
/** Import all graphql queries */

// Assets
/** Import assets: jss styles & images */

// Variables
/** Declare all constant variables */

// Functions

/**
 * TopLevelLink
 */
const TopLevelLink = ({ classes, prop, color, activeRoute, miniActive }) => {
  const navLinkColorClasses = getNavLinkColorClass(
    classes,
    color,
    activeRoute,
    prop
  );
  const itemText = getItemTextClass(classes, miniActive);
  return (
    <ListItem id={prop.id} className={classes.item}>
      <NavLink to={prop.path} className={navLinkColorClasses}>
        <ListItemIcon
          className={classes.itemIcon}
          style={miniActive ? { marginLeft: "3px" } : {}}
        >
          {getItemIcon(prop)}
        </ListItemIcon>
        <ListItemText
          primary={prop.name}
          disableTypography={true}
          className={itemText}
        />
      </NavLink>
    </ListItem>
  );
};

// Data Connectors

export default TopLevelLink;
