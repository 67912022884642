import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button.jsx";
import Divider from "@material-ui/core/Divider";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Add from "@material-ui/icons/Add";

// Components

// Subcomponents
import ConfigureExpiration from "./ConfigureExpiration";

// Services
import { ExpirationType } from "services/ChecklistService";

// Queries

// Assets
import stepsStyle from "assets/jss/material-dashboard-pro-react/components/stepsStyle.jsx";

// Variables

// Functions
const updateExpiration = (i, expiration, step, updateStep) => {
  const newExpiration = Object.assign({}, expiration);
  step.expiration[i] = newExpiration;
  updateStep(step);
};

const addExpiration = (step, updateStep) => {
  step.expiration.push({ type: ExpirationType.NEVER });
  updateStep(step);
};

const removeExpiration = (i, step, updateStep) => {
  step.expiration.splice(i, 1);
  updateStep(step);
};

const StepExpirationEdit = ({ classes, step, updateStep }) => {
  return (
    <div>
      {step.expiration.map((exp, i) => {
        return (
          <div key={i}>
            <ConfigureExpiration
              classes={classes}
              expiration={exp}
              updateExpiration={expiration =>
                updateExpiration(i, expiration, step, updateStep)
              }
            />
            {i > 0 ? (
              <div className={classes.expirationRemove}>
                <Button
                  onClick={() => removeExpiration(i, step, updateStep)}
                  size="sm"
                  color="primary"
                  round
                >
                  <Close className={classes.icons} />
                  Remove Expiration
                </Button>
              </div>
            ) : null}
            <Divider className={classes.expirationDivider} variant="middle" />
          </div>
        );
      })}
      <Button
        onClick={() => addExpiration(step, updateStep)}
        size="sm"
        color="primary"
        round
      >
        <Add className={classes.icons} />
        Add Expiration
      </Button>
    </div>
  );
};

export default withStyles(stepsStyle)(StepExpirationEdit);
