import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// Components
import Confirm from "components/Modal/Confirm";

// Sub Components
import Step from "./Step.jsx";

// Services

// Assetss
import notificationsStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";

class Steps extends React.Component {
  state = {
    checked: this.props.checkedIndexes,
    confirmModal: false,
    steps: [],
    updateSteps: () => {},
    stepToDelete: null
  };

  deleteStep = (step, steps, updateSteps) => {
    this.setState({
      stepToDelete: step,
      steps: steps,
      updateSteps: updateSteps,
      confirmModal: true
    });
  };

  onDragEnd = (result, steps, updateSteps, setFocus) => {
    const { destination, source } = result;
    if (!destination) {
      // If no destination nothing to do
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      // dropped in same place
      return;
    }

    // Bump steps between destination and source, up or down
    // depending upon the direction the step was dragged

    const newSteps = steps.slice();
    const sourcePos = source.index + 1;
    const destPos = destination.index + 1;

    newSteps.forEach(step => {
      const currPos = step.position;
      if (currPos === sourcePos) {
        step.position = destPos;
      } else if (sourcePos < destPos) {
        // Drag source up
        if (currPos < sourcePos || currPos > destPos) return; // skip steps not inbetween
        step.position = step.position - 1; // Bump inbetween cells down since dragged source up
      } else if (destPos < sourcePos) {
        // Drag source down
        if (currPos < destPos || currPos > sourcePos) return; // skip steps not inbetween
        step.position = step.position + 1; // Bump inbetween cells up since dragged source down
      }
    });

    setFocus(destPos);
    updateSteps(newSteps);
  };

  handleClose = () => {
    this.setState({ confirmModal: false });
  };

  handleConfirm = () => {
    const { stepToDelete, steps, updateSteps } = this.state;
    const newSteps = steps.filter(
      _step => _step.stepId !== stepToDelete.stepId
    );
    updateSteps(newSteps);
    this.setState({ confirmModal: false });
  };

  render() {
    const {
      checklist,
      updateStep,
      updateSteps,
      mode,
      parents,
      user,
      handleKeyDown,
      focus,
      setFocus,
      sendEvent
    } = this.props;
    const { confirmModal } = this.state;
    const { metadata, steps } = checklist;
    const sortedSteps = steps.sort((a, b) => a.position - b.position);
    // Remove holes in position (in case delete or bad position data)
    const orderedSteps = sortedSteps.map((step, i) => {
      step.position = i + 1;
      return step;
    });
    return (
      <div
        onMouseEnter={() => {
          // Need to set focus when enter steps to force an update.
          // So if update checklist name then click on add step button, the checklist name isn't
          // overwritten with old data from the steps.
          if (focus !== -2) {
            setFocus(-2);
          }
        }}
      >
        <DragDropContext
          onDragEnd={result =>
            this.onDragEnd(result, steps, updateSteps, setFocus)
          }
        >
          <Droppable droppableId="tasklist">
            {provided => {
              let number = 0; // Required position for number label
              return (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {orderedSteps.map((_step, index) => {
                    if (_step.isRequired) {
                      number = number + 1;
                    }
                    return (
                      <Step
                        user={user}
                        key={_step.stepId}
                        metadata={metadata}
                        parents={parents}
                        number={number}
                        index={index}
                        step={_step}
                        steps={steps}
                        mode={mode}
                        handleKeyDown={handleKeyDown}
                        focus={focus}
                        setFocus={setFocus}
                        updateSteps={updateSteps}
                        updateStep={updateStep}
                        deleteStep={step =>
                          this.deleteStep(step, steps, updateSteps)
                        }
                        sendEvent={sendEvent}
                      />
                    );
                  })}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
        <Confirm
          isOpen={confirmModal}
          handleConfirm={this.handleConfirm}
          handleClose={this.handleClose}
          title="Are you sure you want to delete this step?"
        />
      </div>
    );
  }
}

export default withStyles(notificationsStyle)(Steps);
