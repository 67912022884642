import gql from "graphql-tag";

////////////////////////////////////////////////
// COMMON FRAGMENTS
////////////////////////////////////////////////

export const checklistIdFields = gql`
  fragment ChecklistIdFields on ChecklistId {
    name
    checklistId
    version
    steps {
      name
      stepId
      type
    }
  }
`;

export const reactGridLayoutFields = gql`
  fragment ReactGridLayoutFields on ReactGridLayout {
    x
    y
    w
    h
    minW
  }
`;
