import React from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";

// @material-ui/icons

import colorSelectorStyle from "assets/jss/material-dashboard-pro-react/components/colorSelectorStyle.jsx";

const ColorSelector = ({ classes, onSelect }) => {
  /**
   * Hardcoded available colors
   * - due to the setup of CardHeader
   * - but also constrains the visuals
   * - maybe later arbitrary color
   */
  const COLORS = [
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "rose",
    "gray"
  ];

  return (
    <div className={classes.container}>
      {COLORS.map((color, i) => {
        const cardHeaderClasses = classNames({
          [classes.selector]: true,
          [classes.cardHeader]: true,
          [classes[color + "CardHeader"]]: color
        });
        return (
          <IconButton
            key={i}
            aria-label={`Choose ${color}`}
            className={classes.colorButton}
            classes={{ root: classes.colorButtonRoot }}
            onClick={() => onSelect(color)}
          >
            <div className={cardHeaderClasses}>{color}</div>
          </IconButton>
        );
      })}
    </div>
  );
};

export default withStyles(colorSelectorStyle)(ColorSelector);
