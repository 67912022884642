import gql from "graphql-tag";
import { Logbook } from "../fragments";

export const CreateLogbookMutation = gql`
  mutation CreateLogbookMutation(
    $orgId: String!
    $metadata: LogbookMetadataInput
    $widgets: [LogbookWidgetInput]
    $id: String
  ) {
    createLogbook(
      orgId: $orgId
      metadata: $metadata
      widgets: $widgets
      id: $id
    ) {
      id
      metadata {
        ...LogbookMetadataInfo
      }
      widgets {
        ...WidgetInfo
      }
    }
  }
  ${Logbook.logbookMetadataInfo}
  ${Logbook.widgetInfo}
`;

export const DeleteLogbookMutation = gql`
  mutation DeleteLogbookMutation($orgId: String!, $id: String, $version: Int) {
    deleteLogbook(orgId: $orgId, id: $id, version: $version) {
      id
    }
  }
`;
