// ##############################
// // // LoginPage view styles
// #############################

import {
  container,
  cardTitle,
  dangerColor,
  successColor
} from "assets/jss/material-dashboard-pro-react.jsx";

const loginPageStyle = () => ({
  container: {
    ...container,
    zIndex: "4"
  },
  cardTitle: {
    ...cardTitle,
    color: "#FFFFFF"
  },
  textCenter: {
    textAlign: "center"
  },
  justifyContentCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline"
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: "#FFFFFF"
    },
    marginLeft: "5px",
    marginRight: "5px"
  },
  inputAdornment: {
    marginRight: "18px"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)"
  },
  cardHeader: {
    marginBottom: "20px"
  },
  socialLine: {
    padding: "0.9375rem 0"
  },
  loginCard: {
    zIndex: 10,
    marginTop: "25vh",
    marginBottom: "10vh"
  },
  resendContainer: {
    width: "100%",
    textAlign: "center"
  },
  dangerColor: {
    color: dangerColor
  },
  successColor: {
    color: successColor
  },
  scrollContainer: {
    position: "relative",
    zIndex: 10
  },
  contact: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "500"
  },
  amazonIcon: {
    fontSize: "22px !important",
    marginTop: "2px !important"
  },
  progress: {
    margin: "0.85em"
  }
});

export default loginPageStyle;
