/* eslint no-unused-vars: "off" */

/**
 * SITE CONFIG
 *
 * Default Org
 *
 */

// Airspace logo is in assets
import logo from "assets/img/logo-white.png";

import Checklists from "views/Checklists/Checklists.jsx";
import Dashboards from "views/Dashboards/Dashboards.jsx";

// @material-ui/icons
import GridOn from "@material-ui/icons/GridOnOutlined";

const ONBOARDING_PATH =
  "/app/checklist/onboarding_checklist?id=AIRSYNC0-0000-0000-ADMN-00ONBOARDING";

/////////////////////////////
// Navigation Links
/////////////////////////////

const ChecklistsRoute = {
  path: "/app/checklists",
  name: "Checklists",
  id: "checklists",
  icon: "content_paste",
  component: Checklists,
  leftNav: true
};

const DashboardsRoute = {
  path: "/app/dashboards",
  name: "Dashboards",
  id: "dashboards",
  icon: GridOn,
  component: Dashboards,
  leftNav: true
};

const AppRedirectRoute = {
  redirect: true,
  path: "/app",
  pathTo: ONBOARDING_PATH
};

/////////////////////////////
// SITE CONFIGURATIONS
////////////////////////////

const AIRSPACE = {
  name: "Airspace",
  logo,
  leftNav: [ChecklistsRoute, DashboardsRoute, AppRedirectRoute],
  onboardPath: ONBOARDING_PATH
};

export default AIRSPACE;
