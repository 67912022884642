import queryString from "query-string";

// Services
import { omitDeep } from "services/utility";
import { LogbookMode } from "services/LogbookService";

class LogbookFunctions {
  constructor(state, setState, props, showNotification) {
    this.state = state;
    this.setState = setState;
    this.props = props;
    this.showNotification = showNotification;
  }

  updateMetadata = metadata => {
    const newLogbook = Object.assign({}, this.state.logbook);
    newLogbook.metadata = metadata;
    this.setState({ logbook: newLogbook });
  };

  updateWidgets = widgets => {
    const newLogbook = Object.assign({}, this.state.logbook);
    newLogbook.widgets = widgets;
    this.setState({ logbook: newLogbook });
  };

  updateLogbook = () => {
    const { createLogbook, location, history, user } = this.props;
    const orgId = user.currentOrganization.id;
    const { logbook } = this.state;
    const cleanLogbook = omitDeep(logbook, "__typename");
    const { metadata, widgets, id } = cleanLogbook;

    const serializedWidgets = widgets.map(widget => {
      const json = widget.configuration.json;
      // Check json exists and isn't already serialized
      if ((json !== null) & (typeof json === "object")) {
        widget.configuration.json = JSON.stringify(json);
      }
      return widget;
    });

    this.setState({
      confirmModal: true,
      confirmTitle: "Save the Logbook?",
      handleConfirm: () => {
        this.setState(
          {
            confirmModal: false
          },
          () => {
            createLogbook(orgId, metadata, serializedWidgets, id).then(
              results => {
                if (!results.data.createLogbook) {
                  return this.showNotification("error");
                }
                const { id } = results.data.createLogbook;
                const { search } = location;
                const value = queryString.parse(search);
                if (value.id) {
                  // If update, no need to update url, so just update state
                  // Increment version manually (may need to do the same with other server adjusted values)
                  logbook.metadata.version = logbook.metadata.version + 1;
                  this.setState({ logbook }, this.showNotification());
                } else {
                  // If create - add id to URL which will refetch updated version
                  const newSearch = search ? search + `&id=${id}` : `?id=${id}`;
                  const newLocation = Object.assign({}, location, {
                    search: newSearch
                  });
                  history.push(newLocation);
                }
              }
            );
          }
        );
      }
    });
  };

  copyLogbook = () => {
    this.setState({
      confirmModal: true,
      confirmTitle: "Copy to a new Logbook?",
      handleConfirm: () =>
        this.setState({
          confirmModal: false
        })
    });
  };

  editLogbook = () => {
    this.setState({ mode: LogbookMode.EDIT });
  };

  cancelEditLogbook = () => {
    const logbook = this.props.data.getLogbook;
    this.setState({
      confirmModal: true,
      confirmTitle: "Cancel Changes?",
      handleConfirm: () =>
        this.setState({
          mode: LogbookMode.VIEW,
          logbook,
          confirmModal: false
        })
    });
  };

  getLogbookFunctions = () => {
    return {
      editLogbook: this.editLogbook,
      cancelEditLogbook: this.cancelEditLogbook,
      copyLogbook: this.copyLogbook,
      updateLogbook: this.updateLogbook,
      updateMetadata: this.updateMetadata,
      updateWidgets: this.updateWidgets
    };
  };
}

export default LogbookFunctions;
