import React from "react";

// sub-components
import Stars from "./Stars";
import UserCell from "./UserCell";

// services
import { getStatsObject } from "../leaderboardService";

// style
import { LeaderboardStyle } from "../leaderboardStyle";

const Rows = ({
  users,
  updateChecklist,
  globalDateRange,
  openPicker,
  history
}) => {
  const userRows = users.map(user => {
    const statsObj = getStatsObject(user, globalDateRange);

    // Use template to map out all steps
    const stars = user.template.steps.map(step => {
      return (
        <Stars
          key={`${user.id}::${step.stepId}`}
          user={user}
          step={step}
          updateChecklist={updateChecklist}
          globalDateRange={globalDateRange}
          statsObj={statsObj}
          openPicker={openPicker}
        />
      );
    });

    return (
      <tr key={user.name} style={LeaderboardStyle.bodyRow}>
        <UserCell
          user={user}
          globalDateRange={globalDateRange}
          history={history}
        />
        {stars}
      </tr>
    );
  });
  return <tbody>{userRows}</tbody>;
};

export default Rows;
