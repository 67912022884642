import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { flowRight as compose } from "lodash";
import { S3Image } from "aws-amplify-react"; // Amplify S3 Image Viewer
import useSound from "use-sound";

// @material-ui/core components

// @material-ui/icons

// core components
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CardBody from "components/Card/CardBody";
import Picker from "components/Modal/Picker";

// subcomponents
import WidgetEditButtons from "../../WidgetComponents/WidgetEditButtons";
import Rows from "./LeaderboardComponents/Rows";
import Header from "./LeaderboardComponents/Header";

// services
import {
  FetchCompletedChecklist,
  CreateChecklistInstance
} from "services/LogbookService";
import { cloneChecklistForExecute } from "services/ChecklistService";
import {
  processData,
  cloneDayInstance,
  updateStepValue,
  ChecklistTemplates
} from "./leaderboardService";
import {
  LeaderboardStyle,
  getTableStyle,
  cardIconTheme,
  ANIMATION_SOUND
} from "./leaderboardStyle";

const ONE_MINUTE = 60 * 1000;

/**
 * Leaderboard
 */
const Leaderboard = ({
  classes,
  mode,
  widget,
  editWidget,
  deleteWidget,
  globalSettings,
  completedChecklists,
  templates,
  createChecklistInstance,
  history,
  user
}) => {
  const [users, setUsers] = useState([]);
  const [creatingOnload, setCreatingOnload] = useState(false);
  const [creatingAsync, setCreatingAsync] = useState(false);
  const [picker, setPicker] = useState({
    isOpen: false,
    currUser: null,
    currStep: null
  });
  const [animation, setAnimation] = useState({ fn: null });
  const [timeout, setTimout] = useState(null);
  const [playAnimationSound] = useSound(ANIMATION_SOUND);
  const { values, configuration } = widget;
  const config = configuration.json;
  const { globalDateRange } = globalSettings;
  const aUser = users[0];
  const aTemplate = aUser ? aUser.template : null;
  const CARD_ICON_URL =
    "checklist-zen/lemonadepixel/kidschores/kidschores-color/PNGs/lemonadepixel_kidschores-53.png";

  useEffect(() => {
    // Set interval to check if it's the next day, so can create new checklist
    if (users.length > 0 && !timeout) {
      const timeout = setInterval(() => {
        const orgId = user.currentOrganization.id;
        confirmChecklistIsCurrent(users, orgId);
      }, ONE_MINUTE);
      setTimout(timeout);
    }
    return () => {
      if (timeout) {
        clearInterval(timeout);
      }
    };
  }, [users]);

  useEffect(() => {
    if (
      users &&
      completedChecklists.aggregateInstances &&
      templates &&
      templates.getChecklistTemplates
    ) {
      // Need to handle null checklists getStatsObjectso don't block loading as checklists are created
      const completed = completedChecklists.aggregateInstances.items
        ? completedChecklists
        : { aggregateInstances: { items: [] } };
      const newUsersData = processData(completed, templates, config.users);
      const orgId = user.currentOrganization.id;
      // Create checklists for today if it doesn't already exist
      // Only create once to prevent duplicates & loops
      let isCreating = false;
      if (!creatingOnload) {
        for (let i = 0; i < newUsersData.length; i++) {
          const { items } = newUsersData[i].day;
          if (items.length === 0) {
            isCreating = true;
            const newChecklist = cloneChecklistForExecute(
              newUsersData[i].template
            );
            createChecklistInstance(
              orgId,
              newChecklist.metadata,
              newChecklist.steps,
              newChecklist.id
            );
          }
        }
      }

      if (isCreating) {
        setCreatingOnload(true);
      }
      setUsers(newUsersData);
    }
  }, [completedChecklists, templates]);

  // TODO: notify user / show day in Leaderboard
  const confirmChecklistIsCurrent = (_users, orgId) => {
    let day = null;
    for (let i = 0; i < _users.length; i++) {
      if (_users[i].day.items[0]) {
        day = _users[i].day.items[0];
        break;
      }
    }
    const currentChecklistDay = new Date(day.metadata.created.time).getDate();
    const currentDay = new Date().getDate();

    // If not current, create new checklists for all users
    if (currentChecklistDay !== currentDay) {
      if (!creatingAsync) {
        console.log("create async");
        for (let i = 0; i < _users.length; i++) {
          const newChecklist = cloneChecklistForExecute(_users[i].template);
          createChecklistInstance(
            orgId,
            newChecklist.metadata,
            newChecklist.steps,
            newChecklist.id
          );
        }
        setCreatingAsync(true);
      }
    } else {
      setCreatingAsync(false);
    }
  };

  const handleOpen = (currUser, currStep, animateStar) => {
    setPicker(
      Object.assign({}, picker, {
        isOpen: true,
        currUser,
        currStep
      })
    );
    setAnimation(Object.assign({}, animation, { fn: animateStar }));
  };

  const handleClose = () => {
    setPicker(
      Object.assign({}, picker, {
        isOpen: false,
        currUser: null,
        currStep: null
      })
    );
  };

  const handleIconSelect = icon => {
    const { currUser, currStep } = picker;
    const orgId = user.currentOrganization.id;
    const clonedInstance = cloneDayInstance(currUser);
    const updatedInstance = updateStepValue(
      currStep,
      clonedInstance,
      icon,
      true
    );
    setPicker(
      Object.assign({}, picker, {
        isOpen: false,
        currUser: null,
        currStep: null
      })
    );
    animation.fn();
    playAnimationSound();
    updateChecklist(updatedInstance, orgId);
  };

  const updateChecklist = async (newInstance, orgId) => {
    const { id, metadata, steps } = newInstance;
    // Update Instance
    // TODO: Add error to notify the user if response is not 200 okay
    createChecklistInstance(orgId, metadata, steps, id);
  };

  return (
    <Card>
      <CardHeader color={values.color} icon>
        <WidgetEditButtons
          mode={mode}
          classes={classes}
          editWidget={editWidget}
          deleteWidget={deleteWidget}
        />
        <CardIcon style={LeaderboardStyle.cardIcon} color={widget.values.color}>
          <S3Image imgKey={CARD_ICON_URL} theme={cardIconTheme} />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>{configuration.title}</h4>
      </CardHeader>
      <CardBody>
        <div style={LeaderboardStyle.tableContainerDiv}>
          <table style={getTableStyle(aTemplate)}>
            <Header aTemplate={aTemplate} />
            <Rows
              users={users}
              updateChecklist={updateChecklist}
              globalDateRange={globalDateRange}
              history={history}
              openPicker={handleOpen}
            />
          </table>
        </div>
      </CardBody>
      <Picker
        isOpen={picker.isOpen}
        handleClose={handleClose}
        handleIconSelect={handleIconSelect}
      />
    </Card>
  );
};

export default compose(
  FetchCompletedChecklist,
  ChecklistTemplates,
  CreateChecklistInstance,
  withRouter
)(Leaderboard);
