/** Import React and 3rd party libs */
import React, { useState, useRef } from "react";
import { S3Image } from "aws-amplify-react"; // Amplify S3 Image Viewer
import { Storage } from "aws-amplify"; // Amplify S3 Storage

// @material-ui/core components
import Icon from "@material-ui/core/Icon";
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";

// Components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

// Subcomponents
/** Import components used only by this component - relative path imports */

// Services
import { WidgetType } from "services/LogbookService";
import {
  getDefaultConfiguration,
  getPicInfo,
  PicTypes,
  constructUploadIconUrl
} from "services/WidgetService";
import { capitalizeFirstLetter } from "services/utility";

// Queries
/** Import all graphql queries */

// Assets
import widgetModalStyle from "assets/jss/material-dashboard-pro-react/components/widgetModalStyle.jsx";

// Variables
const MAX_PHOTO_SIZE = 1024 * 1024; // 1MB

// Functions
const WidgetTypeMenuItems = Object.keys(WidgetType).map(widgetType => {
  const name = widgetType
    .split("_")
    .map(parts => capitalizeFirstLetter(parts.toLowerCase()))
    .join(" ");
  return {
    name: name,
    value: widgetType
  };
});

const updateWidgetType = (widget, event) => {
  if (widget.type === event.target.value) {
    return null;
  }
  widget.type = event.target.value;
  widget.configuration = getDefaultConfiguration(widget);
  return widget;
};

const updateWidgetTitle = (widget, event) => {
  widget.configuration.title = event.target.value;
  return widget;
};

const WidgetTypeSelect = ({ classes, widget, updateWidget }) => {
  return (
    <FormControl
      fullWidth
      className={classes.selectFormControl + " " + classes.left}
    >
      <InputLabel htmlFor="step-type-select" className={classes.iconLabel}>
        Widget Type
      </InputLabel>
      <Select
        MenuProps={{
          className: classes.selectMenu
        }}
        classes={{
          select: classes.select
        }}
        value={widget.type}
        onChange={event => updateWidget(updateWidgetType(widget, event))}
        inputProps={{
          name: "widgetTypeSelect",
          id: "widget-type-select-edit"
        }}
      >
        <MenuItem
          disabled
          classes={{
            root: classes.selectMenuItem
          }}
          value="1"
        >
          Widget Type
        </MenuItem>
        {WidgetTypeMenuItems.map(item => (
          <MenuItem
            key={item.value}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected
            }}
            value={item.value}
          >
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const WidgetTitle = ({ widget, updateWidget }) => {
  return (
    <CustomInput
      labelText="Title"
      id="title"
      formControlProps={{
        fullWidth: true
      }}
      inputProps={{
        value: widget.configuration.title || "",
        onChange: event => updateWidget(updateWidgetTitle(widget, event)),
        type: "text"
        // disabled: mode !== LogbookMode.EDIT
      }}
    />
  );
};

const WidgetIcon = ({ classes, widget, updateWidget }) => {
  const uploadButton = useRef(null);
  const [message, setMessage] = useState("");
  const [notification, setNotification] = useState(false);
  const [uploading, setUploading] = useState(false);
  const picInfo = getPicInfo(widget.configuration.icon);

  const uploadPicture = () => {
    uploadButton.current.click();
  };

  const updateIcon = key => {
    const newConfiguration = Object.assign({}, widget.configuration);
    const newWidget = Object.assign({}, widget);
    newConfiguration.icon = constructUploadIconUrl(key);
    newWidget.configuration = newConfiguration;
    updateWidget(newWidget);
  };

  const onChange = e => {
    const image = e.target.files[0];
    const { type, size } = image;

    if (size > MAX_PHOTO_SIZE) {
      const message = `Your photo size was ${size /
        MAX_PHOTO_SIZE} MB which is greater than the max size of 1MB.  Please upload a smaller photo.`;
      setMessage(message);
      return setNotification(true);
    }

    setUploading(true);
    Storage.put(`widget-pic-${widget.widgetId}`, image, {
      level: "protected",
      contentType: type
    })
      .then(result => {
        setUploading(false);
        updateIcon(result.key);
      })
      .catch(err => {
        setUploading(false);
        setMessage(`Error uploading file: ${err}`);
        setNotification(true);
      });
  };

  return (
    <div className={classes.left}>
      <p htmlFor="step-type-select" className={classes.iconLabel}>
        Widget Icon
      </p>
      <br />
      {picInfo.picType === PicTypes.UPLOAD ? (
        <CardIcon
          style={{
            marginLeft: "64px",
            height: "69px",
            width: "63px",
            borderRadius: "3px",
            boxShadow:
              "0 12px 20px -10px rgba(0, 0, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 0, 0, 0.2)"
          }}
        >
          <S3Image
            theme={{
              photoImg: {
                height: "69px",
                width: "63px"
              }
            }}
            imgKey={picInfo.iconUrl}
            level="protected"
          />
        </CardIcon>
      ) : (
        <CardIcon style={{ marginLeft: "64px" }} color={widget.values.color}>
          <Icon style={{ color: "white" }}>{widget.configuration.icon}</Icon>
        </CardIcon>
      )}

      <br />
      <br />
      {uploading ? (
        <div>
          <CircularProgress className={classes.progress} color="secondary" />
        </div>
      ) : (
        <Button
          color="rose"
          className={classes.uploadButton}
          onClick={uploadPicture}
          round
        >
          Upload Picture
          <input
            type="file"
            accept="image/*"
            className={classes.fileUploadInput}
            onChange={e => onChange(e)}
            ref={uploadButton}
          />
        </Button>
      )}
      <Snackbar
        place="bc"
        color="danger"
        icon={AddAlert}
        message={message}
        open={notification}
        closeNotification={() => setNotification(false)}
        close
      />
    </div>
  );
};

/**
 * Declare JSX Component - class or
 */
const WidgetTypeEdit = ({ classes, widget, updateWidget }) => {
  return (
    <div>
      <WidgetTypeSelect
        classes={classes}
        widget={widget}
        updateWidget={updateWidget}
      />
      <WidgetTitle widget={widget} updateWidget={updateWidget} />
      <WidgetIcon
        classes={classes}
        widget={widget}
        updateWidget={updateWidget}
      />
    </div>
  );
};

// Data Connectors

export default withStyles(widgetModalStyle)(WidgetTypeEdit);
