import React from "react";
import { S3Image } from "aws-amplify-react"; // Amplify S3 Image Viewer

// sub components

// services
import { getPicInfo } from "services/WidgetService";

import { AwardboardStyle, countStarTheme } from "../awardboardStyle";

const UserCell = ({ user }) => {
  const picInfo = getPicInfo(user.icon);
  const starIcon =
    "checklist-zen/lemonadepixel/weddingicons/PNGs/lemonadepixel_weddingicons-38.png";

  return (
    <td style={AwardboardStyle.user.cell}>
      <div style={AwardboardStyle.user.imgContainer} key="key">
        <S3Image
          theme={{
            photoImg: {
              height: "69px",
              width: "63px",
              borderRadius: "3px"
            }
          }}
          imgKey={picInfo.iconUrl}
          level="protected"
        />
      </div>
      <div
        style={AwardboardStyle.user.detailsContainer}
        onClick={() => {
          console.log("click");
        }}
      >
        <span style={AwardboardStyle.user.name}>{user.name}</span>
        <br />
        <div style={AwardboardStyle.user.countContainer}>
          <S3Image imgKey={starIcon} theme={countStarTheme} />
          <small style={AwardboardStyle.user.countText}>
            {user.stats.count - user.stats.used}
          </small>
        </div>
      </div>
    </td>
  );
};

export default UserCell;
