import gql from "graphql-tag";
import { Organization } from "../fragments";

export const CreateOrgMutation = gql`
  mutation CreateOrgMutation($metadata: OrganizationMetadataInput) {
    createOrg(metadata: $metadata) {
      id
      metadata {
        ...OrgMetadataCurrent
      }
    }
  }
  ${Organization.orgMetadataCurrent}
`;

export const SwitchOrgMutation = gql`
  mutation SwitchOrgMutation($id: String) {
    switchOrg(id: $id) {
      id
      metadata {
        ...OrgMetadataCurrent
      }
    }
  }
  ${Organization.orgMetadataCurrent}
`;
