import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// Subcomponents
// WidgetTypes
import WidgetStatus from "./WidgetTypes/Status/WidgetStatus";
import WidgetExpiring from "./WidgetTypes/Expiring/WidgetExpiring";
import WidgetChecklistStep from "./WidgetTypes/ChecklistStep/WidgetChecklistStep";
import WidgetTable from "./WidgetTypes/Table/WidgetTable";
import WidgetTemplateTable from "./WidgetTypes/TemplateTable/WidgetTemplateTable";
import WidgetCount from "./WidgetTypes/Count/WidgetCount";
import Leaderboard from "./WidgetTypes/Leaderboard/Leaderboard";
import Awardboard from "./WidgetTypes/Awardboard/Awardboard";
import WidgetChart from "./WidgetTypes/Chart/WidgetChart";
import WidgetChecklist from "./WidgetTypes/Checklist/WidgetChecklist";
import ActivityTimeline from "./WidgetTypes/ActivityTimeline/ActivityTimeline";

// Services
import { WidgetType } from "services/LogbookService";

// Assets
import widgetStyle from "assets/jss/material-dashboard-pro-react/components/widgetStyle";

const Widget = ({
  classes,
  mode,
  widget,
  editWidget,
  deleteWidget,
  updateWidget,
  globalSettings,
  user,
  showMedia
}) => {
  // TODO: just pass through props
  switch (widget.type) {
    case WidgetType.STATUS:
      return (
        <WidgetStatus
          mode={mode}
          classes={classes}
          widget={widget}
          editWidget={editWidget}
          deleteWidget={deleteWidget}
          updateWidget={updateWidget}
          showMedia={showMedia}
        />
      );
    case WidgetType.EXPIRING:
      return (
        <WidgetExpiring
          mode={mode}
          classes={classes}
          widget={widget}
          editWidget={editWidget}
          deleteWidget={deleteWidget}
          updateWidget={updateWidget}
          showMedia={showMedia}
        />
      );
    case WidgetType.CHECKLIST_STEP:
      return (
        <WidgetChecklistStep
          mode={mode}
          classes={classes}
          widget={widget}
          editWidget={editWidget}
          deleteWidget={deleteWidget}
          globalSettings={globalSettings}
          updateWidget={updateWidget}
          user={user}
          showMedia={showMedia}
        />
      );
    case WidgetType.TABLE:
      return (
        <WidgetTable
          mode={mode}
          classes={classes}
          widget={widget}
          editWidget={editWidget}
          deleteWidget={deleteWidget}
          updateWidget={updateWidget}
          user={user}
          showMedia={showMedia}
        />
      );
    case WidgetType.TEMPLATE_TABLE:
      return (
        <WidgetTemplateTable
          mode={mode}
          classes={classes}
          widget={widget}
          editWidget={editWidget}
          deleteWidget={deleteWidget}
          updateWidget={updateWidget}
          user={user}
          showMedia={showMedia}
        />
      );
    case WidgetType.COUNT:
      return (
        <WidgetCount
          mode={mode}
          classes={classes}
          widget={widget}
          editWidget={editWidget}
          deleteWidget={deleteWidget}
          updateWidget={updateWidget}
          globalSettings={globalSettings}
          showMedia={showMedia}
        />
      );
    case WidgetType.LEADERBOARD:
      return (
        <Leaderboard
          mode={mode}
          classes={classes}
          widget={widget}
          editWidget={editWidget}
          deleteWidget={deleteWidget}
          updateWidget={updateWidget}
          globalSettings={globalSettings}
          user={user}
          showMedia={showMedia}
        />
      );
    case WidgetType.AWARDBOARD:
      return (
        <Awardboard
          mode={mode}
          classes={classes}
          widget={widget}
          editWidget={editWidget}
          deleteWidget={deleteWidget}
          updateWidget={updateWidget}
          globalSettings={globalSettings}
          user={user}
          showMedia={showMedia}
        />
      );
    case WidgetType.CHART:
      return (
        <WidgetChart
          mode={mode}
          classes={classes}
          widget={widget}
          editWidget={editWidget}
          updateWidget={updateWidget}
          deleteWidget={deleteWidget}
          showMedia={showMedia}
        />
      );
    case WidgetType.CHECKLIST:
      return (
        <WidgetChecklist
          mode={mode}
          classes={classes}
          widget={widget}
          editWidget={editWidget}
          deleteWidget={deleteWidget}
          updateWidget={updateWidget}
          globalSettings={globalSettings}
          user={user}
          showMedia={showMedia}
        />
      );
    case WidgetType.ACTIVITY_TIMELINE:
      return (
        <ActivityTimeline
          mode={mode}
          classes={classes}
          widget={widget}
          editWidget={editWidget}
          deleteWidget={deleteWidget}
          updateWidget={updateWidget}
          globalSettings={globalSettings}
          user={user}
          showMedia={showMedia}
        />
      );
    default:
      return <div>Error: Invalid Widget</div>;
  }
};

export default withStyles(widgetStyle)(Widget);
