import React from "react";
import { Storage } from "aws-amplify"; // Amplify S3 Storage
import queryString from "query-string"; // Handle query string parsing
import { withRouter } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fade from "@material-ui/core/Fade";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import AddAlert from "@material-ui/icons/AddAlert";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ProfilePic from "components/ProfilePic/ProfilePic.jsx";

// Services
import { copyObjectWithoutNull } from "services/utility.js";

// Assets
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

const MAX_PHOTO_SIZE = 1024 * 1024; // 1MB

const UploadButton = ({
  isEditMode,
  uploading,
  classes,
  uploadButton,
  onChange
}) => {
  function uploadPicture() {
    uploadButton.current.click();
  }

  if (!isEditMode) return null;

  if (uploading) {
    return (
      <div>
        <CircularProgress className={classes.progress} color="secondary" />
      </div>
    );
  }
  return (
    <Button
      color="rose"
      className={classes.uploadButton}
      onClick={uploadPicture}
      round
    >
      Upload Picture
      <input
        type="file"
        accept="image/*"
        className={classes.fileUploadInput}
        onChange={e => onChange(e)}
        ref={uploadButton}
      />
    </Button>
  );
};

// TODO: Refactor common upload functionality to own component

class OrgProfile extends React.Component {
  constructor(props) {
    super(props);
    // TODO: switch to ORG PROFILE
    const { user } = props;
    const profile = copyObjectWithoutNull(user.profile);
    Object.keys(user.profile || {}).forEach(key => {
      if (!user.profile[key]) {
        profile[key] = "";
      } else {
        profile[key] = user.profile[key];
      }
    });
    this.state = {
      loading: user.loading,
      profile,
      notification: false,
      message: "",
      organizationNameInputState: "success",
      uploading: false
    };
    this.uploadButton = React.createRef();
  }

  editProfile = () => {
    this.props.history.push({
      search: "?edit=true"
    });
  };

  resetChanges = () => {
    const profile = copyObjectWithoutNull(this.props.user.profile);
    this.setState(
      {
        profile,
        firstNameInputState: "success"
      },
      () => {
        this.props.history.push({
          search: ""
        });
      }
    );
  };

  updateProfile = () => {
    const { profile } = this.state;
    if (!profile.callsign || !profile.firstName) {
      const message = "Organization name is a required fields.";
      return this.setState({
        notification: true,
        message,
        organizationNameInputState: "error"
      });
    }
    this.props.user.updateProfile(profile);
  };

  updateInput = (key, value) => {
    const { profile } = this.state;
    profile[key] = value;
    this.setState({
      profile,
      organizationNameInputState: "success"
    });
  };

  uploadPicture = () => {
    this.uploadButton.current.click();
  };

  onChange = e => {
    const image = e.target.files[0];
    const { type, size } = image;

    if (size > MAX_PHOTO_SIZE) {
      const message = `Your photo size was ${size /
        MAX_PHOTO_SIZE} MB which is greater than the max size of 1MB.  Please upload a smaller photo.`;
      return this.setState({ notification: true, message });
    }

    // TODO: Implement org photo
    this.setState({ uploading: true }, () => {
      Storage.put("profile-pic", image, {
        level: "protected",
        contentType: type
        // progressCallback(progress) {
        //   const progressPercent = (progress.loaded / progress.total) * 100;
        //   this.setState({ uploadProgress: progressPercent });
        // }
      })
        .then(result => {
          const { profile } = this.state;
          profile.profilePicUrl = result.key;
          this.setState({ uploading: false }, () =>
            this.props.user.updateProfile(profile)
          );
        })
        .catch(err => {
          this.setState({
            uploading: false,
            notification: true,
            message: `Error uploading file: ${err}`
          });
        });
    });
  };

  render() {
    const { classes, location, user } = this.props;
    const {
      profile,
      loading,
      notification,
      message,
      organizationNameInputState,
      uploading
    } = this.state;
    const { search } = location;
    const value = queryString.parse(search);
    const isEditMode = value.edit;
    const { profilePicUrl } = profile;
    const org = user.currentOrganization;
    const orgUsers =
      org && org.access && org.access.users ? org.access.users : [];
    // const cardTitle =
    const cardName = org.metadata.name || "Title";
    const cardAbout = org.metadata.description || "Description...";
    let profileLink;

    if (loading || !profilePicUrl) {
      return (
        <div className={classes.progressContainer}>
          <CircularProgress className={classes.progress} color="secondary" />
        </div>
      );
    }
    console.log("org", org);

    // if (profilePicUrl === "profile-pic" || profilePicUrl.indexOf("http") > -1) {
    //   profileLink = "#noop";
    // }
    // const profileString =
    //   profilePicUrl === "profile-pic" || profilePicUrl.indexOf("http") > -1
    //     ? getRandomProfilePic()
    //     : profilePicUrl;
    // const profileName = profileString.split(".");
    // const profileParts = profileName[0].split("-");
    // profileLink = `https://www.google.com/search?q=${profileParts[0]}+${profileParts[1]}`;

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <PermIdentity />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  {isEditMode ? (
                    <span>
                      Edit Profile -{" "}
                      <small>Update your organizations profile</small>
                    </span>
                  ) : (
                    <span>Orgnaization Profile</span>
                  )}
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="Organization Name"
                      id="organization-name"
                      success={organizationNameInputState === "success"}
                      error={organizationNameInputState === "error"}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: !isEditMode,
                        value: org.metadata.name,
                        // TODO: upsdate value and onchange
                        onChange: evt =>
                          this.updateInput("name", evt.target.value)
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="Root User Email address"
                      id="email-address"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: org.metadata.rootEemail
                        // onChange: evt =>
                        //   this.updateInput("email", evt.target.value)
                      }}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <InputLabel style={{ color: "#AAAAAA" }}>
                      Description
                    </InputLabel>
                    <CustomInput
                      id="about-me"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 5,
                        disabled: !isEditMode,
                        value: cardAbout,
                        onChange: evt =>
                          this.updateInput("description", evt.target.value)
                      }}
                    />
                  </GridItem>
                </GridContainer>
                {isEditMode ? (
                  <span>
                    <Button
                      color="rose"
                      className={classes.updateProfileButton}
                      onClick={this.updateProfile}
                    >
                      Update Profile
                    </Button>
                    <Button
                      color="rose"
                      className={classes.resetProfileButton}
                      onClick={this.resetChanges}
                    >
                      Reset Changes
                    </Button>
                  </span>
                ) : (
                  <Button
                    color="rose"
                    className={classes.editProfileButton}
                    onClick={this.editProfile}
                  >
                    Edit Org Details
                  </Button>
                )}
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card profile>
              <CardAvatar profile>
                <a href={profileLink} rel="noopener noreferrer" target="_blank">
                  <Fade in={true} timeout={3000}>
                    <ProfilePic
                      profilePicUrl={profilePicUrl}
                      theme={{
                        photoImg: {
                          minHeight: "130px"
                        }
                      }}
                    />
                  </Fade>
                </a>
              </CardAvatar>
              <CardBody profile>
                {/* <h6 className={classes.cardCategory}>{cardTitle}</h6> */}
                <h4 className={classes.cardTitle}>{cardName}</h4>
                <p className={classes.description}>{cardAbout}</p>
                <UploadButton
                  isEditMode={isEditMode}
                  uploading={uploading}
                  classes={classes}
                  uploadButton={this.uploadButton}
                  onChange={this.onChange}
                />
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <PermIdentity />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  <span>Users</span>
                </h4>
              </CardHeader>
              <CardBody>
                <List>
                  {orgUsers.map(orgUser => {
                    return (
                      <ListItem key={orgUser.id}>
                        <ListItemText
                          primary={orgUser.id}
                          secondary={`Status: ${orgUser.status} - Email: ${orgUser.email} - Phone: ${orgUser.phone}`}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <Snackbar
          place="bc"
          color="danger"
          icon={AddAlert}
          message={message}
          open={notification}
          closeNotification={() => this.setState({ notification: false })}
          close
        />
      </div>
    );
  }
}

export default withRouter(withStyles(userProfileStyles)(OrgProfile));
