import React from "react";
import TagsInput from "react-tagsinput"; // react component plugin for creating beatiful tags on an input

// Services
import { LogbookMode } from "services/LogbookService";

const Tags = ({ metadata, updateMetadata, mode, update }) => {
  return (
    <span style={{ margin: "8px 0 8px -10px" }}>
      <TagsInput
        value={metadata.tags}
        disabled={mode !== LogbookMode.EDIT}
        onChange={tags => update("tags", tags, metadata, updateMetadata)}
        tagProps={{ className: "react-tagsinput-tag info" }}
        inputProps={
          mode !== LogbookMode.EDIT
            ? {
                style: { visibility: "hidden" }
              }
            : {}
        }
      />
    </span>
  );
};

export default Tags;
