import React from "react";
import PropTypes from "prop-types";

// Lib for handling headers
import { Helmet } from "react-helmet";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Face from "@material-ui/icons/Face";
import Subject from "@material-ui/icons/Subject";
import Message from "@material-ui/icons/Message";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

// components & services
import Notice from "components/Modal/Notice";
import { encode, verifyEmail } from "services/utility";

// assets
import logoWhite from "assets/img/logo-white.png";
import contactPageStyle from "assets/jss/material-dashboard-pro-react/views/contactPageStyle";

class ContactPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
      honey: "",
      nameState: "",
      emailState: "",
      subjectState: "",
      messageState: "",
      noticeModal: false,
      noticeTitle: ""
    };
  }

  handleSubmit = e => {
    const { classes } = this.props;
    const { name, email, subject, message, honey } = this.state;
    const data = {
      "form-name": "contact-form",
      email: email,
      name: name,
      subject: subject,
      message: message
    };

    e.preventDefault();

    if (honey) {
      console.log("error - honey pot full", honey);
      return;
    }

    if (!name) {
      return this.setState({ nameState: "error" });
    }

    if (!verifyEmail(email)) {
      console.log("error - bad email", email);
      return this.setState({ emailState: "error" });
    }

    if (!subject) {
      return this.setState({ subjectState: "error" });
    }

    if (!message) {
      return this.setState({ messageState: "error" });
    }

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode(data)
    })
      .then(() => {
        this.setState({
          name: "",
          email: "",
          subject: "",
          message: "",
          noticeModal: true,
          noticeTitle: (
            <span>
              <span>Your message has been sent!</span>
              <br />
              <br />
              <span>
                You can also reach us at{" "}
                <span className={classes.logoColor}>
                  <strong>team@airsync.ai</strong>
                </span>
                .
              </span>
            </span>
          )
        });
      })
      .catch(error => {
        this.setState({
          noticeModal: true,
          noticeTitle: (
            <span>
              <span>
                There was an error saving your spot for our beta release.
              </span>
              <br />
              <br />
              <span>Error: {error}</span>
              <br />
              <br />
              <span>
                Please reach out to{" "}
                <span className={classes.logoColor}>
                  <strong>team@airsync.ai</strong>
                </span>{" "}
                for help.
              </span>
            </span>
          )
        });
      });
  };

  handleEmailChange = e => {
    if (verifyEmail(e.target.value)) {
      this.setState({
        emailState: "success",
        email: e.target.value
      });
    } else {
      this.setState({ emailState: "error", email: e.target.value });
    }
  };

  handleNameChange = e => this.setState({ name: e.target.value });

  handleSubjectChange = e => this.setState({ subject: e.target.value });

  handleMessageChange = e => this.setState({ message: e.target.value });

  handleHoneyChange = e => this.setState({ honey: e.target.value });

  handleNoticeClose = () => {
    this.setState({ noticeModal: false, email: "", emailState: "" });
  };

  render() {
    const { classes } = this.props;
    const {
      name,
      email,
      subject,
      message,
      honey,
      nameState,
      emailState,
      subjectState,
      messageState,
      noticeModal,
      noticeTitle
    } = this.state;

    return (
      <div className={classes.container}>
        <Helmet>
          <title>Airsync | Contact</title>
          <meta
            name="description"
            content="Airsync powers modern operations.  Define programable workflows and customized reporting to match your organization's unique needs."
          />
        </Helmet>

        <GridContainer justifyContent="center" className={classes.section}>
          <GridItem xs={12} sm={6} md={6}>
            <form
              name="contact-form"
              onSubmit={this.handleSubmit}
              data-netlify="true"
              netlify-honeypot="honey2"
            >
              <Card login className={classes.signupCard}>
                <CardHeader
                  className={`${classes.signupCardHeader} ${classes.textCenter}`}
                  color="rose"
                >
                  <h4 className={classes.waitlistTitle}>Contact Us</h4>
                  <img
                    src={logoWhite}
                    alt="airsynclogo"
                    className={classes.logoImage}
                  />
                </CardHeader>
                <CardBody className={classes.signupCardBody}>
                  <CustomInput
                    success={nameState === "success"}
                    error={nameState === "error"}
                    labelText="Name..."
                    id="text"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      name: "name",
                      value: name,
                      onChange: this.handleNameChange,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Face className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      )
                    }}
                  />
                  <CustomInput
                    success={emailState === "success"}
                    error={emailState === "error"}
                    labelText="Email..."
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "email",
                      name: "email",
                      value: email,
                      onChange: this.handleEmailChange,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      )
                    }}
                  />
                  <CustomInput
                    success={subjectState === "success"}
                    error={subjectState === "error"}
                    labelText="Subject..."
                    id="subject"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      name: "subject",
                      value: subject,
                      onChange: this.handleSubjectChange,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Subject className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      )
                    }}
                  />
                  <CustomInput
                    success={messageState === "success"}
                    error={messageState === "error"}
                    labelText="Message..."
                    id="message"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      name: "message",
                      value: message,
                      onChange: this.handleMessageChange,
                      multiline: true,
                      rows: "6",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Message className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      )
                    }}
                  />
                  <span className={classes.honey}>
                    <CustomInput
                      labelText="Honey..."
                      id="honey"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        name: "honey",
                        value: honey,
                        onChange: this.handleHoneyChange
                      }}
                    />
                  </span>
                </CardBody>
                <CardFooter className={classes.signupCardFooter}>
                  <Button type="submit" color="rose" simple size="lg" block>
                    Send
                  </Button>
                </CardFooter>
              </Card>
            </form>
            <div className={classes.contact}>
              Or email us at{" "}
              <a href="mailto:team@airsync.ai">
                <span className={classes.logoColor}>
                  <strong>team@airsync.ai</strong>
                </span>
              </a>
              .
            </div>
          </GridItem>
        </GridContainer>
        <Notice
          isOpen={noticeModal}
          title={noticeTitle}
          handleClose={this.handleNoticeClose}
        />
      </div>
    );
  }
}

ContactPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(contactPageStyle)(ContactPage);
