import React from "react";

// Material Core
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";

// Services
import { DashboardNavType } from "services/LogbookService";

// Functions
const BlueCheckbox = withStyles({
  root: {
    "&$checked": {
      color: "#5bc0de"
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

function updatePinnedNav(event, metadata, updateMetadata) {
  const { name, checked } = event.target;
  const newMetadata = Object.assign({}, metadata);
  if (!newMetadata.dashboardNav) {
    newMetadata.dashboardNav = [];
  }
  if (name === "dashboard") {
    if (checked) {
      newMetadata.dashboardNav.push(DashboardNavType.PRIVATE);
    } else {
      newMetadata.dashboardNav = newMetadata.dashboardNav.filter(
        item => item !== DashboardNavType.PRIVATE
      );
    }
  } else {
    console.log("Error: unsupported name");
  }

  updateMetadata(newMetadata);
}

const PinToNav = ({ metadata, updateMetadata }) => {
  return (
    <FormGroup row style={{ padding: "0", alignItems: "center" }}>
      <span
        style={{
          opacity: 0.76,
          fontWeight: 500,
          paddingRight: "16px",
          fontSize: "14px"
        }}
      >
        Pin to Left Nav:
      </span>
      <FormControlLabel
        control={
          <BlueCheckbox
            checked={
              metadata.dashboardNav
                ? metadata.dashboardNav.includes(DashboardNavType.PRIVATE)
                : false
            }
            onChange={event => updatePinnedNav(event, metadata, updateMetadata)}
            name="dashboard"
          />
        }
        label="Dashboard"
      />
    </FormGroup>
  );
};

export default PinToNav;
