import React from "react";

// State Management
import { Rehydrated } from "aws-appsync-react";

/**
 * AppSync isn’t really compatible with apollo client 3.  You can upgrade but lose offline or they suggest using amplify datastore. Need to figure out a path forward, punting down the road for now. Apollo 3 also deprecates react-apollo.
 * react-apollo upgrade to 3: https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/448
 * apollo 3: https://www.apollographql.com/docs/react/migrating/apollo-client-3-migration/
 * apollo 3 & app sync: https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/448
 * and https://dev.to/danielbayerlein/migrate-apollo-from-v2-to-v3-in-conjunction-with-aws-appsync-16c0
 */
import { ApolloProvider } from "react-apollo";

// Route Management
import { createBrowserHistory } from "history";

// Top Level Routes
import AppRoutes from "routes/IndexRouter";

// User Authentication
import AuthProvider, { client, dummyUser } from "components/Login/AuthProvider";

const hist = createBrowserHistory();

function HydratedApp() {
  // Pass in the non-hydrated version for the loading component to avoid flash
  return (
    <ApolloProvider client={client}>
      <Rehydrated loading={<AppRoutes user={dummyUser} hist={hist} />}>
        <AuthProvider hist={hist}>
          <AppRoutes hist={hist} />
        </AuthProvider>
      </Rehydrated>
    </ApolloProvider>
  );
}

class App extends React.PureComponent {
  render() {
    // If in Node, return the App without Apollo and AppSync (Hydrations) due to issue
    // with react-snapshot prerendering and getting stuck in loading...
    // TODO: Fix this so can prerender public checklists with data in Dynamo
    // AuthProvider provides the user prop to the children components
    return window.name === "nodejs" ? (
      <AppRoutes user={dummyUser} hist={hist} />
    ) : (
      <HydratedApp />
    );
  }
}

export default App;
