import React from "react";

// Apollo Data Layer
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";

// Routing
import { Link } from "react-router-dom";

// Amplify S3 Storage
import { Storage } from "aws-amplify";

// @material-ui/components
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Update from "@material-ui/icons/Update";

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

// sub components
import WidgetEditButtons from "../../WidgetComponents/WidgetEditButtons";

import {
  FindInstancesByTemplateIdFullQuery,
  ChecklistInstanceQuery
} from "api/queries";
import { urlName } from "services/ChecklistService";
import { StepType } from "services/StepService";

// Hardcode for now reference,
// Need to figure out how to just query most recent for user and make sure it has good data
const CHECKLIST_NUMBER = 10;

class WidgetChecklistStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imagePreviewUrls: {}
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Shows steps for a checklist
   *
   * Can show for a configured id or pull from a reference
   * With references, either pull data directly from the reference
   * or searches the step to find a completed checklist that matches
   * the configured template type and queries that for the data
   */
  render() {
    const {
      classes,
      mode,
      widget,
      editWidget,
      deleteWidget,
      data,
      globalSettings
    } = this.props;

    const { imagePreviewUrls } = this.state;
    const { values, configuration } = widget;
    const { findInstancesByTemplateId } = data
      ? data
      : { findInstancesByTemplateId: { items: [] } };
    let { items } = findInstancesByTemplateId
      ? findInstancesByTemplateId
      : { items: [] };
    const emptyStyle = { margin: "24px auto" };
    const { getChecklistInstance } = data
      ? data
      : { getChecklistInstance: null };
    items = getChecklistInstance ? [getChecklistInstance] : items;

    if (!items) {
      items = [];
    }

    // Could filterd for completed but for now using all items (i.e. for active flight);
    const completedItems = items;
    // Use most recent of completed checklists or single instance returned
    let currentInstance = completedItems[0];
    // for (let i = 1; i < completedItems.length; i++) {
    //   if (
    //     completedItems[i].metadata.created.time >
    //     currentInstance.metadata.created.time
    //   ) {
    //     currentInstance = completedItems[i];
    //   }
    // }

    if (!currentInstance) {
      if (
        widget.configuration.json.reference &&
        globalSettings.referenceInstance
      ) {
        const tempInstance =
          globalSettings.referenceInstance.items[CHECKLIST_NUMBER];
        if (
          tempInstance.metadata.template.checklistId ===
          widget.configuration.json.checklist.checklistId
        ) {
          currentInstance = tempInstance;
        }
      }
    }

    // Find steps in current instance
    let currentSteps = [];
    if (currentInstance) {
      const { json } = configuration;
      const { steps } = json ? json : { steps: [] };
      if (steps) {
        steps.forEach(step => {
          for (let i = 0; i < currentInstance.steps.length; i++) {
            if (currentInstance.steps[i].stepId === step.stepId) {
              currentSteps.push(currentInstance.steps[i]);
            }
          }
        });
      }
    }

    let wholeBody;
    if (!currentInstance) {
      wholeBody = <h4 style={emptyStyle}>No Completed Checklist</h4>;
    }

    let checklist, urlSafe, imagePreviewUrl;
    if (!currentSteps || currentSteps.length === 0) {
      wholeBody = <h4 style={emptyStyle}>Step Not Completed</h4>;
    } else {
      wholeBody = currentSteps.map(currentStep => {
        let item = <div>Nothing</div>;
        switch (currentStep.type) {
          case StepType.TEXT_INPUT:
            item = (
              <h4 style={emptyStyle}>
                {currentStep.value ? currentStep.value.text : ""}
              </h4>
            );
            break;
          case StepType.NUMBER_INPUT:
            item = (
              <h4 style={emptyStyle}>
                {currentStep.value ? currentStep.value.number : 0}
              </h4>
            );
            break;
          case StepType.LINK_INPUT:
            item = (
              <a
                href={currentStep.value ? currentStep.value.link : ""}
                style={emptyStyle}
              >
                <h4>{currentStep.value ? currentStep.value.link : ""}</h4>
              </a>
            );
            break;
          case StepType.IMAGE_INPUT:
          case StepType.IMAGE_DISPLAY:
            imagePreviewUrl = imagePreviewUrls[currentStep.stepId];
            if (!imagePreviewUrl && currentStep.value) {
              const image_link = currentStep.value.image_link;
              if (image_link.startsWith("http")) {
                imagePreviewUrls[currentStep.stepId] = image_link;
              } else {
                Storage.get(currentStep.value.image_link, {
                  level: "protected"
                })
                  .then(result => {
                    if (this._isMounted) {
                      imagePreviewUrls[currentStep.stepId] = result;
                      this.setState({ imagePreviewUrls });
                    }
                  })
                  .catch(err => console.log(err));
              }
            } else {
              item = (
                <img
                  style={{
                    maxHeight: "300px",
                    maxWidth: "400px",
                    margin: "24px auto"
                  }}
                  src={imagePreviewUrl}
                  alt="..."
                />
              );
            }
            break;
          case StepType.DATE_TIME_INPUT:
            item = (
              <h4 style={emptyStyle}>
                {currentStep.value ? currentStep.value.data_time_tz : ""}
              </h4>
            );
            break;
          case StepType.LIST_INPUT:
            // TODO: format better
            item = (
              <h4 style={emptyStyle}>
                {currentStep.value ? currentStep.value.list : ""}
              </h4>
            );
            break;
          case StepType.SUB_CHECKLIST:
            checklist = currentStep.value ? currentStep.value.checklist : {};
            urlSafe = `/app/checklist/${urlName(checklist.name)}?id=${
              checklist.checklistId
            }&instance=true&status=complete`;
            item = (
              <h4 style={emptyStyle}>
                <Link to={urlSafe}>
                  <span>{checklist.name}</span>
                </Link>
              </h4>
            );
            break;
          case StepType.COMPLETED_CHECKLIST:
            checklist = currentStep.value ? currentStep.value.checklist : {};
            urlSafe = `/app/checklist/${urlName(checklist.name)}?id=${
              checklist.checklistId
            }&instance=true&status=complete`;
            item = (
              <h4 style={emptyStyle}>
                <Link to={urlSafe}>
                  <span>{checklist.name}</span>
                </Link>
              </h4>
            );
            break;
          case StepType.CHECKBOX:
          case StepType.NOTE:
          case StepType.CAUTION:
          case StepType.WARNING:
          case StepType.CALCULATED:
          case StepType.PLUGIN:
            item = <h4 style={emptyStyle}>Unsupported Step Type</h4>;
            break;
          default:
            item = <h4 style={emptyStyle}>Unkown Step Type</h4>;
        }

        return (
          <div
            key={currentStep.stepId}
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "24px"
            }}
          >
            <h6>{currentStep.name}</h6>
            <div style={{ marginLeft: "24px" }}>{item}</div>
          </div>
        );
      });
    }

    let icon = <Update />;
    if (configuration.icon) {
      icon = <Icon>{configuration.icon}</Icon>;
    }

    return (
      <Card>
        <CardHeader color={values.color} stats icon>
          <WidgetEditButtons
            mode={mode}
            classes={classes}
            editWidget={editWidget}
            deleteWidget={deleteWidget}
          />
          <CardIcon color={values.color}>{icon}</CardIcon>
          <br />
          <h3 className={classes.cardTitle}>{configuration.title}</h3>
        </CardHeader>
        <CardFooter
          stats
          style={{ flexDirection: "column", alignItems: "flex-start" }}
        >
          {wholeBody}
        </CardFooter>
      </Card>
    );
  }
}

const isReference = props => {
  return (
    props.widget &&
    props.widget.configuration &&
    props.widget.configuration.json &&
    props.widget.configuration.json.reference
  );
};

const isReferenceChecklist = props => {
  if (
    !isReference(props) ||
    !isChecklist(props) ||
    !props.globalSettings ||
    !props.globalSettings.referenceInstance ||
    !props.globalSettings.referenceInstance.items
  ) {
    return false;
  }
  return true;
};

const isReferenceChecklistMatch = props => {
  if (
    !isReference(props) ||
    !isChecklist(props) ||
    !isReferenceChecklist(props) ||
    !props.globalSettings.referenceInstance.items
  ) {
    return false;
  }
  const refChecklist =
    props.globalSettings.referenceInstance.items[CHECKLIST_NUMBER];
  const refTemplateId = refChecklist.metadata.template.checklistId;
  const id = props.widget.configuration.json.checklist.checklistId;
  return refTemplateId === id;
};

const isChecklist = props => {
  return !!(
    props.widget &&
    props.widget.configuration &&
    props.widget.configuration.json &&
    props.widget.configuration.json.checklist &&
    props.widget.configuration.json.checklist.checklistId
  );
};

const GetChecklistInstanceQuery = graphql(ChecklistInstanceQuery, {
  options: props => {
    // Know here that it is a reference but not the actual reference
    // Need to iterate through steps and find one that matches this
    // Then set that instance id as the id (not the configured one);
    // If no value is set, query returns nothing (should probably skip);
    const { user, globalSettings } = props;
    const refChecklist =
      globalSettings.referenceInstance.items[CHECKLIST_NUMBER];
    let checklistId = null;
    for (let i = 0; i < refChecklist.steps.length; i++) {
      const step = refChecklist.steps[i];
      if (step.type === StepType.COMPLETED_CHECKLIST) {
        if (
          step.configuration.checklist.checklistId ===
          props.widget.configuration.json.checklist.checklistId
        ) {
          checklistId = step.value && step.value.checklist.checklistId;
        }
      }
    }

    // const id = widget.configuration.json.checklist.checklistId;
    const orgId = user.currentOrganization.id;
    return {
      fetchPolicy: "network-only",
      variables: {
        orgId,
        id: checklistId
      }
    };
  },
  skip: props => {
    return (
      !isReference(props) ||
      !isChecklist(props) ||
      !isReferenceChecklist(props) ||
      isReferenceChecklistMatch(props)
    );
  }
});

const FindInstancesByTemplateIdFull = graphql(
  FindInstancesByTemplateIdFullQuery,
  {
    options: props => {
      const { widget } = props;
      const id = widget.configuration.json.checklist.checklistId;
      return {
        fetchPolicy: "network-only",
        variables: {
          templateId: id
        }
      };
    },
    skip: props => {
      return isReference(props) || !isChecklist(props);
    }
  }
);

export default compose(
  FindInstancesByTemplateIdFull,
  GetChecklistInstanceQuery
)(WidgetChecklistStep);
