import React, { useState } from "react";
import _ from "lodash";

import InputBase from "@material-ui/core/InputBase";

// Keeping debounce pattern for inputs due performance issues with checklist rerender.
// Pass in the time of update and only update if a more recent one hasn't been processed.
// May not be as applicable for logbooks but better safe than soggy.
const debounceUpdateWidget = _.debounce((newWidget, updateWidget, time) => {
  updateWidget(newWidget, time);
}, 1000);

function update(value, widget, updateWidget, setWidgetState) {
  const newWidget = Object.assign({}, widget);
  newWidget.configuration.title = value;
  setWidgetState(newWidget);
  debounceUpdateWidget(newWidget, updateWidget);
}

const NameEdit = ({ widget, updateWidget, classes }) => {
  const [widgetState, setWidgetState] = useState(null);
  const currentWidget = widgetState || widget;
  const currentValue = currentWidget.configuration.title;
  return (
    <div className={classes.cardEditTitle}>
      <InputBase
        id="name"
        autoFocus={true}
        autoComplete="off"
        value={currentValue}
        onChange={event =>
          update(
            event.target.value,
            currentWidget,
            updateWidget,
            setWidgetState
          )
        }
        type="text"
        classes={{ root: classes.widgetEditTitle }}
      />
    </div>
  );
};

const NameDisplay = ({ widget, classes }) => {
  return (
    <div className={classes.widgetDisplayTitle}>
      {widget.configuration.title}
    </div>
  );
};

const WidgetTitle = ({ isEditMode, widget, updateWidget, classes }) => {
  return isEditMode ? (
    <NameEdit widget={widget} updateWidget={updateWidget} classes={classes} />
  ) : (
    <NameDisplay widget={widget} classes={classes} />
  );
};

export default WidgetTitle;
