import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// @material-ui/icons

// Components
import CustomInput from "components/CustomInput/CustomInput.jsx";

// Subcomponents

// Services
import { RoundingType } from "services/ChecklistService";

// Queries

// Assets
import stepsStyle from "assets/jss/material-dashboard-pro-react/components/stepsStyle.jsx";

// Variables

// Functions
const updateUnits = (step, event) => {
  let newStep = Object.assign({}, step);
  if (!newStep.configuration) {
    newStep.configuration = {};
  }
  newStep.configuration.unit = event.target.value || null;
  return newStep;
};

const updateRoundingConfig = (step, event) => {
  let newStep = Object.assign({}, step);
  newStep.configuration.rounding = event.target.value || null;
  return newStep;
};

const getRoundingMenuItems = () => {
  const types = Object.keys(RoundingType);
  return types.map(type => {
    return {
      name: type.toLowerCase(),
      value: type
    };
  });
};

const ConfigureUnits = ({ classes, step, updateStep }) => {
  const { configuration } = step;
  return (
    <span>
      <CustomInput
        labelText="Units"
        id="units"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          onChange: event => updateStep(updateUnits(step, event)),
          type: "text",
          value: configuration.unit || ""
        }}
      />
      <br />
      <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel
          htmlFor="rounding-type-select"
          className={classes.selectLabel}
        >
          Rounding Type
        </InputLabel>
        <Select
          MenuProps={{
            className: classes.selectMenu
          }}
          classes={{
            select: classes.select
          }}
          value={configuration.rounding || ""}
          onChange={event => updateStep(updateRoundingConfig(step, event))}
          inputProps={{
            name: "formulaStepSelect",
            id: "rounding-type-select"
          }}
        >
          <MenuItem
            disabled
            classes={{
              root: classes.selectMenuItem
            }}
            value="1"
          >
            Place
          </MenuItem>
          {getRoundingMenuItems().map(type => (
            <MenuItem
              key={type.value}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value={type.value}
            >
              {type.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </span>
  );
};

export default withStyles(stepsStyle)(ConfigureUnits);
