import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// Components

// Sub Component

// Services

// Asset
import countWidgetStyle from "assets/jss/material-dashboard-pro-react/components/countWidgetStyle.jsx";

const ActivityTimelineConfig = () => {
  return (
    <div style={{ padding: "10px", textAlign: "left" }}>
      <h4 style={{ textAlign: "left", marginTop: "30px", marginBottom: "0" }}>
        Activity Timeline
      </h4>
    </div>
  );
};

export default withStyles(countWidgetStyle)(ActivityTimelineConfig);
