import React, { useState, useEffect } from "react";
import { S3Image } from "aws-amplify-react"; // Amplify S3 Image Viewe

import useSound from "use-sound"; // Lib to play sounds

import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Star from "@material-ui/icons/Star";
import StarBorder from "@material-ui/icons/StarBorder";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";

// services
import {
  cloneDayInstance,
  updateStepValue,
  MAX_STEPS
} from "../leaderboardService";

import {
  LeaderboardStyle,
  imageTheme,
  ANIMATION_TIME,
  REMOVE_STAR_SOUND
} from "../leaderboardStyle";

import starIcon from "assets/img/lemonadepixel/lemonadepixel_weddingicons-38.png";

const secondsTime = ANIMATION_TIME / 1000;

const starsStyle = {
  starStyle: {
    height: "28px",
    width: "28px",
    marginTop: "-70px",
    verticalAlign: "middle",
    border: "0",
    visibility: "hidden"
  }
};

// Construct 50 animations (so max steps is 50)
for (let i = 1; i <= MAX_STEPS; i++) {
  let distance = 110 + 245 * i;
  starsStyle[`@keyframes slideRight-${i}`] = {
    from: { marginLeft: "0px", marginTop: "-85px", visibility: "visible" },
    to: {
      marginLeft: `-${distance}px`,
      marginTop: "-70px",
      visibility: "visible",
      transform: "rotate(-360deg)"
    }
  };
  starsStyle[`shootingStar-${i}`] = {
    animationName: `slideRight-${i}`,
    animationDuration: `${secondsTime}s`,
    animationTimingFunction: "ease-out"
  };
}

// Add a star for the current day
const addStar = (user, step, openPicker, animateStar) => {
  openPicker(user, step, animateStar);

  // TOOD: can allow a default icon and just add without picker
  // const clonedInstance = cloneDayInstance(user);
  // return updateStepValue(step, clonedInstance, true);
};

// Remove a star for the current day
const removeStar = (user, step) => {
  const clonedInstance = cloneDayInstance(user);
  return updateStepValue(step, clonedInstance, null, false);
};

const Stars = ({
  user,
  step,
  updateChecklist,
  statsObj,
  openPicker,
  classes
}) => {
  const [animate, setAnimate] = useState(false);
  const [playRemoveSound] = useSound(REMOVE_STAR_SOUND);
  const stats = statsObj[step.stepId];
  const count = stats ? stats.count : 0;
  const icons = stats ? stats.icons : [];
  const starClasses = animate
    ? classes.starStyle + " " + classes[`shootingStar-${step.position}`]
    : classes.starStyle;
  let stars = [];

  const animateStar = () => {
    setAnimate(true);
  };

  useEffect(() => {
    let timeout = null;
    if (animate) {
      timeout = setTimeout(() => {
        setAnimate(false);
      }, ANIMATION_TIME);
    }

    return () => {
      if (timeout) {
        clearInterval(timeout);
      }
    };
  }, [animate]);

  for (let i = 0; i < count; i++) {
    const icon = icons[i];
    if (icon) {
      stars.push(<S3Image key={i} imgKey={icon} theme={imageTheme} />);
    } else {
      stars.push(<Star key={i} style={LeaderboardStyle.body.star} />);
    }
  }
  if (stars.length === 0) {
    stars.push(<StarBorder key="outline" style={LeaderboardStyle.body.star} />);
  }

  return (
    <td style={LeaderboardStyle.body.cell}>
      <div style={LeaderboardStyle.body.cellContainer}>
        <div style={LeaderboardStyle.body.addDeleteContainer}>
          <div>
            <Add
              style={LeaderboardStyle.body.addDeleteButton}
              onClick={async () => {
                addStar(user, step, openPicker, animateStar);
              }}
            />
          </div>
        </div>
        <div style={LeaderboardStyle.body.starOuterContainer}>
          <div style={LeaderboardStyle.body.starContainer}>{stars}</div>
          <img src={starIcon} alt="star icon" className={starClasses} />
        </div>
        <div style={LeaderboardStyle.body.addDeleteContainer}>
          <div>
            <Remove
              style={LeaderboardStyle.body.addDeleteButton}
              onClick={async () => {
                const updatedChecklist = removeStar(user, step);
                playRemoveSound();
                updateChecklist(updatedChecklist);
              }}
            />
          </div>
        </div>
      </div>
    </td>
  );
};

export default withStyles(starsStyle)(Stars);
