import React from "react";
import PropTypes from "prop-types";
import { flowRight as compose } from "lodash";
import { withRouter } from "react-router-dom";

// Lib for AWS
import { Auth } from "aws-amplify";

// Lib for handling headers
import { Helmet } from "react-helmet";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

// components and services
import { verifyEmail, verifyPhone, verifyPassword } from "services/utility";
import Notice from "components/Modal/Notice";
import SignIn from "components/Login/SignIn";
import NewPassword from "components/Login/NewPassword";
import ForgotPassword from "components/Login/ForgotPassword";
import LoginWithGoogle from "components/Login/LoginWithGoogle";
import LoginWithFacebook from "components/Login/LoginWithFacebook";
import LoginWithAmazon from "components/Login/LoginWithAmazon";
import {
  getNoticeTitle,
  NOTICE_TYPE,
  FORM_STATE,
  INPUT_TYPE,
  ERROR_MESSAGE
} from "components/Login/loginVariables";
import { SignupConfig } from "services/ConfigService";
import { getInputConfig } from "components/Login/loginVariables";
import { formattedPhone } from "services/utility";

// assets
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

/**
 * Login Page - Handles Login flows: Sign Up, Forgot Password, Reset Password.
 * Doesn't handle Register which is in RegisterPage
 *
 * Notes: formInputState is old and should be removed.  Don't need success state, just show
 * user error or normal.  So can check for error state by presence of an error message.
 */
class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      error: "", // Old style for reset password / new password - need to refactor to be like signin / register
      errorMessage: {
        [INPUT_TYPE.USERNAME]: "",
        [INPUT_TYPE.PASSWORD]: "",
        [INPUT_TYPE.NEW_PASSWORD]: "",
        [INPUT_TYPE.CODE]: "",
        general: ""
      },
      showConfirmationError: false,
      formInputValue: {
        [INPUT_TYPE.EMAIL]: "",
        [INPUT_TYPE.PHONE]: "",
        [INPUT_TYPE.PHONE_EMAIL]: "",
        [INPUT_TYPE.USERNAME]: "",
        [INPUT_TYPE.PASSWORD]: "",
        [INPUT_TYPE.NEW_PASSWORD]: "",
        [INPUT_TYPE.CODE]: ""
      },
      formInputState: {
        [INPUT_TYPE.EMAIL]: "",
        [INPUT_TYPE.PHONE]: "",
        [INPUT_TYPE.PHONE_EMAIL]: "",
        [INPUT_TYPE.USERNAME]: "",
        [INPUT_TYPE.PASSWORD]: "",
        [INPUT_TYPE.NEW_PASSWORD]: "",
        [INPUT_TYPE.CODE]: ""
      },
      noticeModal: false,
      noticeTitle: "",
      noticeSize: "medium",
      onClose: null,
      formState: FORM_STATE.SIGN_IN,
      signingIn: false
    };
  }

  componentDidMount() {
    // If user is logged in (landed here from invite link)
    // Need to log out so they can login and join new org
    const { location } = this.props;
    const search = location.search;
    const params = new URLSearchParams(search);
    const orgInvite = params.get("invite");
    // const clientMetadata = orgInvite ? { org_invite: orgInvite } : null;
    if (orgInvite) {
      Auth.signOut()
        .then(() => {
          console.log("signed out to handle new org invite");
        })
        .catch(err => console.log(err));
    }
    // we add a hidden class to the card and after 400 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      400
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  updateInput = (evt, inputType) => {
    const formInputValue = Object.assign({}, this.state.formInputValue);
    const formInputState = Object.assign({}, this.state.formInputState);
    const error = "";
    formInputValue[inputType] = evt.target.value;
    formInputState[inputType] = "";
    const errorMessage = Object.assign({}, this.state.errorMessage);
    errorMessage[inputType] = "";
    errorMessage.general = "";
    this.setState({ formInputValue, formInputState, errorMessage, error });
  };

  updateFormState = formState => {
    this.setState({ formState });
  };

  updateErrorState = (error, inputType) => {
    const formInputState = Object.assign({}, this.state.formInputState);
    formInputState[inputType] = "error";
    const errorMessage = Object.assign({}, this.state.errorMessage);
    errorMessage[inputType] = error;
    this.setState({
      error,
      formInputState,
      errorMessage,
      signingIn: false
    });
  };

  handleSubmit = () => {
    this.setState({ signingIn: true }, this.validateSignIn);
  };

  validateSignIn = () => {
    const { user } = this.props;
    const { formInputValue } = this.state;
    let username = formInputValue[INPUT_TYPE.USERNAME];
    const password = formInputValue[INPUT_TYPE.PASSWORD];
    let error = false;
    const errorMessage = Object.assign({}, this.state.errorMessage);
    const inputConfig = getInputConfig();

    if (SignupConfig.username) {
      // If username
      // TODO: Handle username only, or with email and phone, or email or phone
    } else if (SignupConfig.email) {
      if (SignupConfig.phone) {
        // If Email and Phone only
        const isEmail = true; //verifyEmail(username);
        const isPhone = verifyPhone(username);
        if (!isEmail && !isPhone) {
          error = true;
        } else if (isPhone) {
          username = formattedPhone(username);
        }
      } else {
        // If Email Only
        if (!verifyEmail(username)) {
          error = true;
        }
      }
    } else {
      // If Phone Only
      if (!verifyPhone(username)) {
        error = true;
      } else {
        username = formattedPhone(username);
      }
    }

    if (error) {
      errorMessage[INPUT_TYPE.USERNAME] = inputConfig.error;
    }

    if (!verifyPassword(password)) {
      errorMessage[INPUT_TYPE.PASSWORD] = ERROR_MESSAGE.PASSWORD;
      error = true;
    }

    if (error) {
      this.setState({
        errorMessage,
        signingIn: false
      });
      return;
    }

    this.signIn(user, username, password);
  };

  setNewPassword = () => {
    const { formInputValue } = this.state;
    const email = formInputValue[INPUT_TYPE.EMAIL];
    const code = formInputValue[INPUT_TYPE.CODE];
    const newPassword = formInputValue[INPUT_TYPE.NEW_PASSWORD];

    if (!verifyEmail(email)) {
      this.updateErrorState(ERROR_MESSAGE.EMAIL, INPUT_TYPE.EMAIL);
      return;
    }

    if (!verifyPassword(newPassword)) {
      this.updateErrorState(ERROR_MESSAGE.PASSWORD, INPUT_TYPE.NEW_PASSWORD);
      return;
    }

    if (!code) {
      this.updateErrorState(ERROR_MESSAGE.CODE, INPUT_TYPE.CODE);
      return;
    }

    Auth.forgotPasswordSubmit(email, code, newPassword)
      .then(() => {
        const { user } = this.props;
        const onCloseFn = () => {
          return this.signIn(user, email, newPassword);
        };
        this.openNoticeModal(NOTICE_TYPE.RESET_SUCCESS, "", email, onCloseFn);
      })
      .catch(err => this.openNoticeModal(NOTICE_TYPE.RESET_ERROR, err));
  };

  signIn = (user, username, password) => {
    const { location } = this.props;
    const search = location.search;
    const params = new URLSearchParams(search);
    const orgInvite = params.get("invite"); // TODO: also check for email or phone and pass to backend for appropriate handling
    const clientMetadata = orgInvite ? { org_invite: orgInvite } : null;
    Auth.signIn(username, password, clientMetadata)
      .then(returnedUser => {
        // NOTE: not worrying about any user challenges since no MFA
        // but should later add new password in case need to reset for all users
        // https://aws-amplify.github.io/docs/js/authentication#using-components-in-react
        user.login(returnedUser, this.props.location);
      })
      .catch(err => {
        const errorMessage = Object.assign({}, this.state.errorMessage);
        if (err.code === "UserNotConfirmedException") {
          // The error happens if the user didn't finish the confirmation step when signing up
          // In this case you need to resend the code and confirm the user
          // About how to resend the code and confirm the user
          this.setState({ showConfirmationError: true, signingIn: false });
        } else if (err.code === "PasswordResetRequiredException") {
          // The error happens when the password is reset in the Cognito console
          // In this case you need to call forgotPassword to reset the password
          // NOTE: don't worry about for know, user should reset own password.
          errorMessage.general = err;
          this.setState({
            error: err,
            errorMessage,
            signingIn: false
          });
        } else {
          errorMessage.general = err;
          this.setState({
            error: err,
            errorMessage,
            signingIn: false
          });
        }
      });
  };

  openNoticeModal = (noticeType, error, email, fn) => {
    const { classes } = this.props;
    this.setState({
      noticeModal: true,
      noticeTitle: getNoticeTitle(noticeType, classes, error, email),
      noticeSize: "medium",
      onClose: fn,
      signingIn: false
    });
  };

  openError = error => {
    this.openNoticeModal(NOTICE_TYPE.SOCIAL_SIGNIN_ERROR, error);
  };

  handleClose = () => {
    const { onClose } = this.state;
    const fn = onClose ? onClose : () => {};
    this.setState(
      {
        noticeModal: false,
        noticeTitle: "",
        onClose: null
      },
      fn()
    );
  };

  // Resend the verification link for a new user who isn't confirmed
  resendSignUp = () => {
    const { formInputValue } = this.state;
    const email = formInputValue[INPUT_TYPE.EMAIL];
    Auth.resendSignUp(email)
      .then(() => {
        this.openNoticeModal(NOTICE_TYPE.RESEND_SUCCESS);
      })
      .catch(err => {
        this.openNoticeModal(NOTICE_TYPE.RESEND_ERROR, err);
      });
  };

  // Send a code to reset your password from the Forgot Password page
  resetPassword = () => {
    const { formInputValue } = this.state;
    const email = formInputValue[INPUT_TYPE.EMAIL];

    Auth.forgotPassword(email)
      .then(() => {
        this.setState(
          { formState: FORM_STATE.NEW_PASSWORD },
          this.openNoticeModal(NOTICE_TYPE.CODE_SENT, "", email)
        );
      })
      .catch(err => this.openNoticeModal(NOTICE_TYPE.RESET_ERROR, err));
  };

  renderFormBody(
    formState,
    formInputState,
    error,
    errorMessage,
    email,
    showConfirmationError,
    signingIn
  ) {
    switch (formState) {
      case FORM_STATE.SIGN_IN:
        return (
          <SignIn
            errorMessage={errorMessage}
            updateInput={this.updateInput}
            updateFormState={this.updateFormState}
            handleSubmit={this.handleSubmit}
            showConfirmationError={showConfirmationError}
            resendSignUp={this.resendSignUp}
            signingIn={signingIn}
            email={email}
          />
        );
      case FORM_STATE.RESET_PASSWORD:
        return (
          <ForgotPassword
            formInputState={formInputState}
            updateInput={this.updateInput}
            updateFormState={this.updateFormState}
            resetPassword={this.resetPassword}
            error={error}
          />
        );
      case FORM_STATE.NEW_PASSWORD:
        return (
          <NewPassword
            formInputState={formInputState}
            updateInput={this.updateInput}
            updateFormState={this.updateFormState}
            setNewPassword={this.setNewPassword}
            error={error}
          />
        );
      default:
        return this.renderSignIn();
    }
  }

  render() {
    const { classes, user, location } = this.props;
    const {
      noticeModal,
      noticeTitle,
      noticeSize,
      formState,
      formInputState,
      formInputValue,
      showConfirmationError,
      error,
      errorMessage,
      signingIn
    } = this.state;
    const email = formInputValue[INPUT_TYPE.EMAIL];
    return (
      <div className={classes.container}>
        <Helmet>
          <title>Airsync | Login</title>
          <meta
            name="description"
            content="Airsync powers modern operations.  Define programable workflows and customized reporting to match your organization's unique needs."
          />
        </Helmet>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={12} md={12}>
            <div className={classes.scrollContainer}>
              <GridContainer justifyContent="center">
                <GridItem xs={12} sm={6} md={4}>
                  <form>
                    <Card
                      login
                      className={
                        classes[this.state.cardAnimaton] +
                        " " +
                        classes.loginCard
                      }
                    >
                      <CardHeader
                        className={`${classes.cardHeader} ${classes.textCenter}`}
                        color="rose"
                      >
                        <h4 className={classes.cardTitle}>Log in</h4>
                        <div className={classes.socialLine}>
                          <LoginWithGoogle
                            user={user}
                            openErrorNotice={this.openError}
                            location={location}
                          >
                            <Button
                              color="transparent"
                              justIcon
                              className={classes.customButtonClass}
                            >
                              <i className="fab fa-google-plus" />
                            </Button>
                          </LoginWithGoogle>
                          <LoginWithFacebook
                            user={user}
                            openErrorNotice={this.openError}
                            location={location}
                          >
                            <Button
                              color="transparent"
                              justIcon
                              className={classes.customButtonClass}
                            >
                              <i className="fab fa-facebook-square" />
                            </Button>
                          </LoginWithFacebook>
                          <LoginWithAmazon
                            id="amazon-root"
                            user={user}
                            openErrorNotice={this.openError}
                          >
                            <Button
                              color="transparent"
                              justIcon
                              className={classes.customButtonClass}
                            >
                              <i
                                className={`fab fa-amazon ${classes.amazonIcon}`}
                              />
                            </Button>
                          </LoginWithAmazon>
                        </div>
                      </CardHeader>
                      {this.renderFormBody(
                        formState,
                        formInputState,
                        error,
                        errorMessage,
                        email,
                        showConfirmationError,
                        signingIn
                      )}
                    </Card>
                  </form>
                </GridItem>
              </GridContainer>
            </div>
          </GridItem>
        </GridContainer>
        <Notice
          isOpen={noticeModal}
          title={noticeTitle}
          size={noticeSize}
          handleClose={this.handleClose}
        />
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(withRouter, withStyles(loginPageStyle))(LoginPage);
