import React from "react";

// Apollo Data Layer
import { graphql } from "react-apollo";

import { flowRight as compose } from "lodash";

import Autocomplete from "components/Autocomplete/Autocomplete.jsx";

import { ChecklistTemplatesQuery } from "api/queries/index";

class ChecklistSelector extends React.Component {
  render() {
    const { data, checklist, onChange, hideLegend } = this.props;
    const { getChecklistTemplates } = data
      ? data
      : { getChecklistTemplates: { items: [] } };

    const availableChecklists = getChecklistTemplates
      ? getChecklistTemplates.items.map(checklist => {
          return {
            label: checklist.metadata.name,
            value: checklist.id,
            version: checklist.metadata.version
          };
        })
      : [];

    const _onChange = results => {
      const checklist = results
        ? {
            name: results.label,
            checklistId: results.value,
            version: results.version
          }
        : null;
      onChange(checklist);
    };

    return (
      <div>
        <span>
          {hideLegend ? null : <legend>Checklist Template</legend>}
          <Autocomplete
            placeholder={"Type to find a checklist"}
            value={checklist}
            onChange={_onChange}
            choices={availableChecklists}
          />
        </span>
      </div>
    );
  }
}

const ChecklistTemplates = graphql(ChecklistTemplatesQuery, {
  options: props => ({
    fetchPolicy: "network-only",
    variables: {
      orgId: props.user.currentOrganization.id
    }
  })
});

export default compose(ChecklistTemplates)(ChecklistSelector);
