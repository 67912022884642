import {
  warningColor,
  successColor,
  infoColor
} from "assets/jss/material-dashboard-pro-react.jsx";

const checklistCardStyle = {
  cardTitle: {
    padding: "24px 0px 24px 0px",
    fontSize: "2.4em",
    fontWeight: 700,
    lineHeight: "24px"
    // textTransform: "uppercase"
  },
  cardEditTitle: {
    padding: "24px 0px 0px 0px",
    flexGrow: 1
  },
  inputTitle: {
    width: "100%",
    fontSize: "2.4em",
    fontWeight: 700,
    lineHeight: "24px"
  },
  body: {
    overflowY: "auto !important"
  },
  cardBodyWrapper: {
    // width: "900px",
    // paddingLeft: "calc(86px + env(safe-area-inset-left))",
    // paddingRight: "calc(86px + env(safe-area-inset-right))",
    margin: "0 auto",
    zIndex: 1000,

    "@media (min-width: 768px)": {
      // Sidebar closes
      width: "750px",
      paddingLeft: "calc(24px + env(safe-area-inset-left))",
      paddingRight: "calc(24px + env(safe-area-inset-right))"
    },
    "@media (min-width: 960px)": {
      width: "780px",
      paddingLeft: "calc(64px + env(safe-area-inset-left))",
      paddingRight: "calc(24px + env(safe-area-inset-right))"
    },
    "@media (min-width: 1200px)": {
      width: "900px",
      paddingLeft: "calc(86px + env(safe-area-inset-left))",
      paddingRight: "calc(86px + env(safe-area-inset-right))"
    },
    "@media print": {
      width: "750px",
      paddingLeft: "calc(48px + env(safe-area-inset-left))",
      paddingRight: "calc(48px + env(safe-area-inset-right))"
    }
  },
  cardHeaderWrapper: {
    width: "900px",
    height: "110px",
    margin: "0 auto",
    paddingLeft: "calc(86px + env(safe-area-inset-left))",
    paddingRight: "calc(86px + env(safe-area-inset-right))",
    position: "relative"
  },
  displayNone: {
    display: "none !important"
  },
  tabsRoot: {
    minHeight: "unset !important",
    "& $tabRootButton": {
      fontSize: "0.875rem"
    }
  },
  tabRootButton: {
    minHeight: "unset !important",
    minWidth: "unset !important",
    width: "unset !important",
    height: "unset !important",
    maxWidth: "unset !important",
    maxHeight: "unset !important",
    padding: "10px 15px",
    borderRadius: "3px",
    lineHeight: "24px",
    border: "0 !important",
    color: "#fff !important",
    marginLeft: "4px",
    "&:last-child": {
      marginLeft: "0px"
    }
  },
  tabLabelContainer: {
    padding: "0px"
  },
  tabLabel: {
    fontWeight: "500",
    fontSize: "12px"
  },
  tabSelected: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    transition: "0.2s background-color 0.1s"
  },
  tabWrapper: {
    display: "inline-block",
    minHeight: "unset !important",
    minWidth: "unset !important",
    width: "unset !important",
    height: "unset !important",
    maxWidth: "unset !important",
    maxHeight: "unset !important",
    "& > svg,& > .fab,& > .fas,& > .far,& > .fal,& > .material-icons": {
      verticalAlign: "middle",
      margin: "-1px 5px 0 0"
    }
  },
  cardMenu: {
    float: "right"
  },
  editHeader: {
    // border: "2px solid #ffffff",
    padding: "5px 18px !important",
    height: "120px"
  },
  normalHeader: {
    padding: "5px 18px !important",
    height: "120px"
  },
  checklistMenuItem: {
    paddingTop: "8px",
    paddingBottom: "8px"
  },
  titleEditButton: {
    width: "27px",
    height: "27px",
    padding: "0"
  },
  titleButtonRoot: {
    padding: "8px",
    margin: "-10px 0 -8px 10px"
  },
  titleButtonIcon: {
    width: "17px",
    height: "17px",
    color: "white",
    opacity: 0.8
  },
  iconEditButton: {},
  iconButtonRoot: {
    padding: 0
  },
  iconEdit: {
    // color: "white"
    height: "48px",
    width: "48px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgba(0, 0, 0, 0.87)"
  },
  iconNoEdit: {
    // color: "white",
    height: "48px",
    width: "48px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgba(0, 0, 0, 0.87)"
  },
  iconBackground: {
    backgroundColor: "white",
    height: "50px",
    width: "50px",
    borderRadius: "4px",
    border: "1px solid #eee",
    marginTop: "-44px"
  },
  formControl: {
    display: "inline",
    backgroundColor: "#00acc1",
    padding: "6px 6px 8px 4px"
  },
  editButton: {
    visibility: "visible",
    opacity: ".86",
    position: "absolute",
    bottom: "8px",
    right: "105px",
    padding: "0 8px",
    fontSize: "12px",
    color: "#555"
  },
  editButtonHide: {
    visibility: "hidden",
    opacity: 0,
    position: "absolute",
    bottom: "8px",
    right: "105px",
    padding: "0 8px",
    fontSize: "12px",
    color: "#555",
    transition: "visibility 0.1s, opacity 0.5s linear"
  },
  menuButton: {
    visibility: "visible",
    opacity: ".86",
    color: "#555"
  },
  menuButtonHide: {
    visibility: "hidden",
    opacity: 0,
    color: "#555",
    transition: "visibility 0.1s, opacity 0.5s linear"
  },
  suggestionWrapper: {
    opacity: 0.54
  },
  tableRow: {
    height: "36px"
  },
  tableRowHover: {
    cursor: "pointer"
  },
  tableCell: {
    borderBottom: "none"
  },
  suggestedTemplateItem: {
    display: "flex",
    alignItems: "center",
    fontSize: "18px"
  },
  suggestedTemplateIcon: {
    marginRight: "8px"
  },
  checklistStatus: {
    float: "right",
    marginTop: "120px",
    marginRight: "-197px",
    fontSize: "16px",
    fontWeight: "500",
    opacity: "0.76"
  },
  checklistStatusTemplate: {
    color: infoColor
  },
  checklistStatusCompleted: {
    color: successColor
  },
  checklistStatusActive: {
    color: warningColor
  }
};

export default checklistCardStyle;
