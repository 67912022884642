import React from "react";
import { Auth } from "aws-amplify";

// Federated sign in from Facebook
class LoginWithFacebook extends React.Component {
  constructor(props) {
    super(props);
    this.signIn = this.signIn.bind(this);
  }

  signIn() {
    Auth.federatedSignIn({ provider: "Facebook" });
  }

  // componentDidMount() {
  //   if (!window.FB) this.createScript();
  // }

  // signIn() {
  //   const fb = window.FB;
  //   const { user, openErrorNotice, location } = this.props;
  //   fb.getLoginStatus(response => {
  //     if (response.status === "connected") {
  //       this.getAWSCredentials(
  //         response.authResponse,
  //         user,
  //         openErrorNotice,
  //         location
  //       );
  //     } else {
  //       fb.login(
  //         response => {
  //           if (!response || !response.authResponse) {
  //             return openErrorNotice("No response");
  //           }
  //           this.getAWSCredentials(
  //             response.authResponse,
  //             user,
  //             openErrorNotice,
  //             location
  //           );
  //         },
  //         {
  //           // the authorized scopes
  //           scope: "public_profile,email"
  //         }
  //       );
  //     }
  //   });
  // }

  // async getAWSCredentials(response, user, openErrorNotice, location) {
  //   const { accessToken, expiresIn } = response;
  //   const date = new Date();
  //   const expires_at = expiresIn * 1000 + date.getTime();
  //   if (!accessToken) {
  //     return openErrorNotice("No access Token");
  //   }

  //   const fb = window.FB;
  //   fb.api(
  //     "/me",
  //     {
  //       fields: "name,email,first_name,last_name,picture.width(360).height(360)"
  //     },
  //     response => {
  //       const newUser = {
  //         name: response.first_name,
  //         email: response.email
  //       };

  //       // Used for Airspace profile creation
  //       const federatedUser = {
  //         email: response.email,
  //         given_name: response.first_name,
  //         family_name: response.last_name,
  //         profilePicUrl: response.picture.data.url
  //       };

  //       Auth.federatedSignIn(
  //         "facebook",
  //         { token: accessToken, expires_at },
  //         newUser
  //       ).then(credentials => {
  //         user.loginFederated(
  //           LOGIN_TYPE.FACEBOOK,
  //           federatedUser,
  //           credentials,
  //           location
  //         );
  //       });
  //     }
  //   );
  // }

  // createScript() {
  //   // load the sdk
  //   window.fbAsyncInit = this.fbAsyncInit;
  //   const script = document.createElement("script");
  //   script.src = "https://connect.facebook.net/en_US/sdk.js";
  //   script.async = true;
  //   script.onload = this.initFB;
  //   document.body.appendChild(script);
  // }

  // initFB() {
  //   const fb = window.FB;
  //   if (!fb) console.log("FB SDK not inited");
  // }

  // fbAsyncInit() {
  //   // init the fb sdk client
  //   const fb = window.FB;
  //   fb.init({
  //     appId: process.env.REACT_APP_FACEBOOK_APP_ID,
  //     cookie: true,
  //     xfbml: true,
  //     version: "v2.11"
  //   });
  // }

  render() {
    return <span onClick={this.signIn}>{this.props.children}</span>;
  }
}

export default LoginWithFacebook;
