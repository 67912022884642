import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// import Update from "@material-ui/icons/Update";
import Timeline from "@material-ui/icons/Timeline";

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";

// subcomponents
import WidgetEditButtons from "../../WidgetComponents/WidgetEditButtons";

// ##############################
// // // Multiple Bars Chart
// #############################
var delays2 = 80,
  durations2 = 500;

const multipleBarsChart = {
  data: {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Mai",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    series: [
      [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
      [412, 243, 280, 580, 453, 353, 300, 364, 368, 410, 636, 695]
    ]
  },
  options: {
    seriesBarDistance: 10,
    axisX: {
      showGrid: false
    },
    height: "300px"
  },
  responsiveOptions: [
    [
      "screen and (max-width: 640px)",
      {
        seriesBarDistance: 10,
        axisX: {
          labelInterpolationFnc: function(value) {
            return value[0];
          }
        }
      }
    ]
  ],
  animation: {
    draw: function(data) {
      if (data.type === "bar") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays2,
            dur: durations2,
            from: 0,
            to: 1,
            easing: "ease"
          }
        });
      }
    }
  }
};

const WidgetChart = ({ classes, mode, widget, editWidget, deleteWidget }) => {
  const { values, configuration } = widget;
  return (
    <Card>
      <CardHeader color={values.color} stats icon>
        <WidgetEditButtons
          mode={mode}
          classes={classes}
          editWidget={editWidget}
          deleteWidget={deleteWidget}
        />
        <CardIcon color={values.color}>
          {/* <Icon>{configuration.icon}</Icon> */}
          <Timeline />
        </CardIcon>
        <p className={classes.cardCategory}>{configuration.title}</p>
        <h3 className={classes.cardTitle}>
          {values.stats} <small>{configuration.label}</small>
        </h3>
      </CardHeader>

      <CardBody>
        <ChartistGraph
          data={multipleBarsChart.data}
          type="Bar"
          options={multipleBarsChart.options}
          listener={multipleBarsChart.animation}
        />
      </CardBody>
    </Card>
  );
};

export default WidgetChart;
