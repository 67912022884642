import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import indexRoutes from "routes/index_routes.jsx";

class AppRoutes extends React.Component {
  render() {
    const { user, hist } = this.props;
    return (
      <Router history={hist}>
        <Switch>
          {indexRoutes.map((prop, key) => {
            return (
              <Route
                path={prop.path}
                component={props => <prop.component {...props} user={user} />}
                key={key}
              />
            );
          })}
        </Switch>
      </Router>
    );
  }
}

export default AppRoutes;
