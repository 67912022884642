import React from "react";
import { withRouter } from "react-router-dom";
import { flowRight as compose } from "lodash";

// // @material-ui/core components
// import Icon from "@material-ui/core/Icon";

// // @material-ui/icons

// // core components
// import Card from "components/Card/Card.jsx";
// import CardHeader from "components/Card/CardHeader.jsx";
// import CardIcon from "components/Card/CardIcon.jsx";
// import CardBody from "components/Card/CardBody.jsx";
// import CardFooter from "components/Card/CardFooter.jsx";

// // sub components
// import WidgetEditButtons from "../../WidgetComponents/WidgetEditButtons";

// services

/**
 * Activity Timeline Widget
 *
 * Displays a list of activity data for a data type defined by a checklist template.
 * Queries for past data and streams real-time updates through subscriptions.
 * All data is of type Event.
 */
const ActivityTimeline = () =>
  // {
  //   // classes,
  //   // mode,
  //   // widget,
  //   // editWidget,
  //   // deleteWidget
  // }
  {
    // const { values, configuration } = widget;
    // const title = configuration.title || "Activity Timeline";
    // const icon = configuration.icon || "event_note";
    // const color = configuration.color || "blue";

    return (
      <div>Timeline</div>
      // <Card>
      //   <CardHeader color={values.color} icon>
      //     <WidgetEditButtons
      //       mode={mode}
      //       classes={classes}
      //       editWidget={editWidget}
      //       deleteWidget={deleteWidget}
      //     />
      //     <CardIcon style={{ marginLeft: "64px" }} color={color}>
      //       <Icon style={{ color: "white" }}>{icon}</Icon>
      //     </CardIcon>
      //     <h4 className={classes.cardIconTitle}>{title}</h4>
      //   </CardHeader>
      //   <CardBody>
      //     <div>body</div>
      //   </CardBody>
      //   <CardFooter stats>
      //     <div className={classes.stats}>Footer</div>
      //   </CardFooter>
      // </Card>
    );
  };

export default compose(withRouter)(ActivityTimeline);
