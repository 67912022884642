/** Import React and 3rd party libs */
import React from "react";
import { ResizableBox } from "react-resizable";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-graphql";
import "prismjs/components/prism-yaml";
import "prismjs/components/prism-json";
// import "prismjs/components/prism-jsx";
import "prismjs/components/prism-java";
import "prismjs/components/prism-kotlin";
import "prismjs/themes/prism.css"; //Example style, you can use another

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Icon from "@material-ui/core/Icon";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
// import TextField from "@material-ui/core/TextField";

// @material-ui/icons
import Settings from "@material-ui/icons/Settings";

// Components

// Subcomponents
import ConfigureUnits from "../../StepComponents/StepEdit/ConfigureUnits";
import StepNote from "../../StepComponents/StepNote";
import ViewTitle from "../../StepComponents/ViewTitle";

// Services
import {
  getCommonTabs,
  EditRow,
  isInputDisabled,
  getIconClass
} from "../StepTypesUtility";
import { StepType, getStepRowClass } from "services/StepService";

// Queries
/** Import all graphql queries */

// Assets
import stepsStyle from "assets/jss/material-dashboard-pro-react/components/stepsStyle.jsx";

// Variables
/** Declare all constant variables */

// Functions
const StepIcon = ({ classes, step }) => {
  let iconType = <Icon>code</Icon>;

  return (
    <TableCell className={classes.tableCheck}>
      <div className={getIconClass(step, classes)}>{iconType}</div>
    </TableCell>
  );
};

const StepContent = props => {
  const { classes, mode } = props;
  const isDisabled = isInputDisabled(mode);
  return (
    <TableCell className={classes.tableContent}>
      <ViewTitle {...props} />
      <StepInput isDisabled={isDisabled} {...props} />
    </TableCell>
  );
};

const LanguagesEnum = {
  JS: "js",
  GRAPHQL: "graphql",
  YAML: "yaml",
  JSON: "json",
  // JSX: "jsx"
  JAVA: "java",
  CLIKE: "clike",
  KOTLIN: "kotlin"
};

const updateConfig = (step, code, language, height) => {
  const newStep = Object.assign({}, step);
  const newConfiguration = Object.assign({}, step.configuration);
  newConfiguration.json = JSON.stringify({ code, language, height });
  newStep.configuration = newConfiguration;
  return newStep;
};

const StepInput = props => {
  const { step, updateStep } = props;
  const jsonConfig =
    step.configuration && step.configuration.json
      ? step.configuration.json
      : "{}";
  const { code, language, height } = JSON.parse(jsonConfig);
  const _code = code || `function add(a, b) {\n  return a + b;\n}`;
  const _language = language || LanguagesEnum.JS;
  const _height = height || 120;
  return (
    <>
      <FormControl
        variant="outlined"
        style={{
          float: "right",
          marginTop: "-38px",
          marginRight: "6px",
          width: "120px"
        }}
      >
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={_language}
          onChange={event =>
            updateStep(updateConfig(step, _code, event.target.value, _height))
          }
          displayEmpty
          label="Language"
          autoWidth={true}
          variant="standard"
        >
          {Object.values(LanguagesEnum).map(lang => (
            <MenuItem key={lang} value={lang}>
              {lang}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ResizableBox
        width={580}
        height={_height}
        draggableOpts={{}}
        minConstraints={[580, 120]}
        maxConstraints={[580, 2000]}
        style={{ backgroundColor: "#f5f2f0", marginTop: "6pxgs" }}
        onResize={(event, { size }) =>
          updateStep(updateConfig(step, _code, _language, size.height))
        }
      >
        <div style={{ height: "100%", overflow: "scroll" }}>
          <Editor
            value={_code}
            onValueChange={code =>
              updateStep(updateConfig(step, code, _language, _height))
            }
            highlight={code => highlight(code, languages[_language])}
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 14,
              height: "100%",
              overflow: "scroll"
            }}
          />
        </div>
      </ResizableBox>
    </>
  );
};

/**
 * Code Step
 * Syntax highlighting for
 * TODO: allow resizing container
 */
const CodeEmbedStep = props => {
  const {
    classes,
    step,
    updateStep,
    editing,
    setEditState,
    noteOpen,
    setNoteState
  } = props;
  const editProps = { editing, setEditState, noteOpen, setNoteState };
  const tabs = getCommonTabs(step, updateStep);
  const showRowDividers = true;
  const primaryTableRowClass = getStepRowClass(
    classes,
    showRowDividers,
    editing || noteOpen
  );

  if (step.type === StepType.NUMBER_INPUT) {
    tabs.push({
      tabName: "Configuration",
      tabIcon: Settings,
      tabContent: (
        <FormControl fullWidth className={classes.selectFormControl}>
          {<ConfigureUnits step={step} updateStep={updateStep} />}
        </FormControl>
      )
    });
  }

  return (
    <>
      <TableRow key={step.stepId} className={primaryTableRowClass}>
        <StepIcon {...props} step={step} />
        <StepContent {...props} {...editProps} step={step} />
      </TableRow>
      <EditRow classes={classes} open={editing}>
        <CustomTabs title="Configure" headerColor="gray" tabs={tabs} />
      </EditRow>
      <EditRow classes={classes} open={noteOpen}>
        <StepNote step={step} updateStep={updateStep} />
      </EditRow>
    </>
  );
};

export default withStyles(stepsStyle)(CodeEmbedStep);
