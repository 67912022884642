/** Import React and 3rd party libs */
import React from "react";

// @material-ui/core components
import Drawer from "@material-ui/core/Drawer";

// @material-ui/icons
/** Import material ui icons */

// Components
import HeaderLinks from "components/Header/HeaderLinks.jsx";

// Subcomponents
import SidebarWrapper from "./SidebarWrapper";

// Services
import { getDrawerPaperClass, getSidebarWrapperClass } from "./SidebarUtility";

// Queries
/** Import all graphql queries */

// Assets
/** Import assets: jss styles & images */

// Variables
/** Declare all constant variables */

// Functions
/** Declare all functions - utility followed by JSX*/

/**
 * Small Sidebard - Visible small and down
 * Shows a menu button that shows the sidebard on click on the right.
 */
const SmallSidebar = ({
  classes,
  image,
  bgColor,
  runTour,
  miniActive,
  open,
  handleDrawerToggle,
  userComponent,
  organizationComponent,
  links,
  pinnedChecklistsActive,
  pinnedChecklistsTemplate,
  newChecklistButton
}) => {
  const drawerPaper = getDrawerPaperClass(classes, miniActive);
  const sidebarWrapper = getSidebarWrapperClass(classes, miniActive);
  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={open}
      classes={{
        paper: drawerPaper + " " + classes[bgColor + "Background"]
      }}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true // Better open performance on mobile.
      }}
    >
      {organizationComponent}
      <SidebarWrapper
        className={sidebarWrapper}
        userComponent={userComponent}
        headerLinks={<HeaderLinks runTour={runTour} />}
        links={links}
        pinnedChecklistsTemplate={pinnedChecklistsTemplate}
        pinnedChecklistsActive={pinnedChecklistsActive}
        newChecklistButton={newChecklistButton}
      />
      {image !== undefined ? (
        <div
          className={classes.background}
          style={{ backgroundImage: "url(" + image + ")" }}
        />
      ) : null}
    </Drawer>
  );
};

// Data Connectors

export default SmallSidebar;
