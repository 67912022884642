/** Import React and 3rd party libs */
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { flowRight as compose } from "lodash";

// @material-ui/core components
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

// @material-ui/icons
import Add from "@material-ui/icons/Add";
import ArrowRight from "@material-ui/icons/ArrowRight";

// Components
import Links from "./Links";

// Subcomponents
/** Import components used only by this component - relative path imports */

// Services
import {
  getNavLinkClass,
  getItemTextClass,
  getCollapseItemTextClass,
  getLeftCarotClass,
  getItemIcon,
  createdPinnedTemplateLinks
} from "./LinksUtility";
import { newChecklist, goToTemplate } from "services/ChecklistService";
import { StepType } from "services/StepService";

// Queries
import {
  CreateChecklistInstance,
  AddEvent
} from "views/Checklists/ChecklistComponents/ChecklistQueries";

// Assets
/** Import assets: jss styles & images */

// Variables
/** Declare all constant variables */

// Functions
const _openCollapse = (e, id, openCollapse) => {
  e.stopPropagation();
  openCollapse(`${id}-template`);
};

/**
 * PinnedLinkTemplate
 */
const PinnedLinkTemplate = ({
  classes,
  prop,
  color,
  activeRoute,
  miniActive,
  state,
  openCollapse,
  createChecklistInstance,
  location,
  history,
  user,
  nesting
}) => {
  const [showButtons, setShowButtons] = useState(false);
  const navLinkClasses = getNavLinkClass(classes, activeRoute, prop);
  const pinnedClass = miniActive
    ? classes.pinnedItemLinkSmall
    : classes.pinnedItemLink;
  const pinnedLinkClassess = classNames(navLinkClasses, pinnedClass);
  const itemText = getItemTextClass(classes, miniActive);
  const collapseItemText = getCollapseItemTextClass(classes, miniActive);
  const templatesMap = {};

  prop.templates.forEach(template => {
    templatesMap[template.id] = template;
  });

  const currentTemplate = templatesMap[prop.id];
  const subTemplates = currentTemplate.steps.reduce((subs, step) => {
    if (step.type === StepType.SUB_CHECKLIST) {
      if (
        step.configuration &&
        step.configuration.checklist &&
        step.configuration.checklist.checklistId
      ) {
        const sub = templatesMap[step.configuration.checklist.checklistId];
        if (sub) {
          // Only add a sub to the list if it exists and not a dangling checklist reference
          // TODO: should figure out how to delete dangling references
          subs.push(sub);
        }
      }
    }
    return subs;
  }, []);

  const areSubTemplates = subTemplates.length > 0;

  let dynamicLinks = null;
  if (areSubTemplates) {
    const subLinks = createdPinnedTemplateLinks(
      subTemplates,
      false, // TODO: should be sorted by step order
      prop.templates,
      nesting ? prop.nesting + 1 : 1
    );
    dynamicLinks = (
      <Links
        routes={subLinks}
        color={color}
        miniActive={miniActive}
        activeRoute={activeRoute}
        openCollapse={openCollapse}
        state={state}
        user={user}
      />
    );
  }

  const collapseClass = classNames(
    classes.collapseItem,
    classes.pinnedCollapseItem
  );

  const startNewChecklist = e => {
    e.stopPropagation();
    const props = { createChecklistInstance, history, location, user };
    console.log("user", props.user);
    newChecklist(prop.template, props);
  };

  // Use text check to shorten name since CSS max-width dosn't work well.
  const MAX_NAME_LENGTH = showButtons ? 15 : 20;
  const shortenedName =
    prop.name.length > MAX_NAME_LENGTH
      ? `${prop.name.slice(0, MAX_NAME_LENGTH)}...`
      : prop.name;
  let marginLeft = 15;
  if (!areSubTemplates && prop.nested) {
    marginLeft = marginLeft + marginLeft * prop.nested;
  }

  return (
    <ListItem className={classes.item}>
      <div
        className={pinnedLinkClassess}
        onClick={() => goToTemplate(prop.id, prop.name, { history, location })}
        onMouseEnter={() => setShowButtons(true)}
        onMouseLeave={() => setShowButtons(false)}
      >
        {miniActive ? null : (
          <div
            className={classes.itemIcon}
            style={{ position: "absolute", left: 3, top: 10, zIndex: 1000 }}
          >
            <IconButton
              className={classes.caretIconButton}
              key="caret"
              aria-label="Icon"
              title={`Show active ${prop.name} checklists`}
              onClick={e => _openCollapse(e, prop.id, openCollapse)}
            >
              <ArrowRight
                className={getLeftCarotClass(classes, state, prop, true)}
              />
            </IconButton>
          </div>
        )}
        <ListItemIcon
          className={classes.itemIcon}
          style={{ marginLeft: miniActive ? "13px" : "36px" }}
        >
          {getItemIcon(prop)}
        </ListItemIcon>
        <ListItemText
          primary={shortenedName}
          disableTypography={true}
          className={itemText}
          title={`Go to ${prop.name} template`}
          inset={false}
        />
        <ListItemSecondaryAction
          classes={{ root: classes.listItemSecondaryAction }}
          title={`Start new ${prop.name} checklist`}
          className={
            showButtons ? classes.navMenuButton : classes.navMenuButtonHide
          }
        >
          <Button
            variant="contained"
            className={classes.newButton}
            size="small"
            onClick={startNewChecklist}
          >
            <Add className={classes.newButtonIcon} />
          </Button>
        </ListItemSecondaryAction>
      </div>
      <Collapse in={state[`${prop.id}-template`]} unmountOnExit>
        <List
          className={classes.list + " " + classes.collapseList}
          style={{ marginLeft }}
        >
          {!areSubTemplates ? (
            <ListItem className={collapseClass}>
              <div className={classes.collapseItemNoLink}>
                <ListItemText
                  primary="No subchecklists"
                  disableTypography={true}
                  className={collapseItemText}
                  inset={false}
                />
              </div>
            </ListItem>
          ) : (
            dynamicLinks
          )}
        </List>
      </Collapse>
    </ListItem>
  );
};

export default compose(
  CreateChecklistInstance,
  AddEvent,
  withRouter
)(PinnedLinkTemplate);
