import { graphql } from "react-apollo"; // Apollo Data Layer
import { AggregateInstancesQuery } from "api/queries";
import { CreateChecklistInstanceMutation } from "api/mutations";
import queryString from "query-string";

// Services
import { omitDeep } from "services/utility";

export const NEW_DASHBOARD_DESCRIPTION_MARKER = "A new dashboard";
export const NEW_DASHBOARD_NAME_MARKER = "New Dashboard";

export const LogbookMode = {
  VIEW: "VIEW",
  EDIT: "EDIT"
};

export const WidgetType = {
  STATUS: "STATUS",
  EXPIRING: "EXPIRING",
  TABLE: "TABLE",
  TEMPLATE_TABLE: "TEMPLATE_TABLE",
  CHECKLIST: "CHECKLIST",
  CHECKLIST_STEP: "CHECKLIST_STEP",
  COUNT: "COUNT",
  LEADERBOARD: "LEADERBOARD",
  AWARDBOARD: "AWARDBOARD",
  CHART: "CHART",
  ACTIVITY_TIMELINE: "ACTIVITY_TIMELINE"
};

export const DateRangeTypes = {
  NONE: "NONE",
  DAY: "DAY",
  WEEK: "WEEK",
  MONTH: "MONTH",
  YEAR: "YEAR",
  CUSTOM: "CUSTOM",
  SELECTOR: "SELECTOR",
  CUSTOM_SELECTOR: "CUSTOM_SELECTOR"
};

export const ReferenceTypes = {
  NONE: "NONE",
  URL: "URL",
  USER_MOST_RECENT_ACTIVE: "USER_MOST_RECENT_ACTIVE",
  ROOT_CHECKLISTS: "ROOT_CHECKLISTS"
};

// Specifies the location a dashboard is pinned to in the nav
// Only private is currently supported
export const DashboardNavType = {
  PRIVATE: "PRIVATE",
  SHARED: "SHARED"
};

const parseQueryString = location => {
  const { search } = location;
  return queryString.parse(search);
};

export const getReference = props => {
  let reference;
  const { id } = parseQueryString(props.location);
  if (DefaultLogbooks[id]) {
    reference = DefaultLogbooks[id].metadata.global.reference;
  } else {
    const logbook = props.data ? props.data.getLogbook : null;
    const global = logbook ? logbook.metadata.global : null;
    reference = global ? global.reference : {};
  }
  return reference || {};
};

export const DefaultLogbooks = {
  ACTIVE_CHECKLISTS: {
    id: "ACTIVE_CHECKLISTS",
    metadata: {
      name: "Checklists",
      description: "Default logbook for active checklists",
      global: {
        reference: {
          type: ReferenceTypes.ROOT_CHECKLISTS
        }
      },
      dashboardNav: []
    },
    widgets: []
  }
};

// APIs - Stateless APIs to replace logbook functions
export const updateDashboard = (
  logbook,
  props,
  showNotification,
  updateLogbookState
) => {
  const { createLogbook, location, history, user } = props;
  const orgId = user.currentOrganization.id;
  const cleanLogbook = omitDeep(logbook, "__typename");
  const { metadata, widgets, id } = cleanLogbook;

  const serializedWidgets = widgets.map(widget => {
    const json = widget.configuration.json;
    // Check json exists and isn't already serialized
    if ((json !== null) & (typeof json === "object")) {
      widget.configuration.json = JSON.stringify(json);
    }
    return widget;
  });

  createLogbook(orgId, metadata, serializedWidgets, id).then(results => {
    if (!results.data.createLogbook) {
      return showNotification("error");
    }
    const { id } = results.data.createLogbook;
    const { search } = location;
    const value = queryString.parse(search);
    if (value.id) {
      // If update, no need to update url, so just update state
      // Increment version manually (may need to do the same with other server adjusted values)
      logbook.metadata.version = logbook.metadata.version + 1;
      showNotification();
      updateLogbookState(logbook);
    } else {
      // If create - add id to URL which will refetch updated version
      const newSearch = search ? search + `&id=${id}` : `?id=${id}`;
      const newLocation = Object.assign({}, location, {
        search: newSearch
      });
      history.push(newLocation);
    }
  });
};

//////////////////////////////////
// LEADERBOARD
//////////////////////////////////

// Used by Leaderboard & Awardboard
// WARNING: Don't use elsewhere to create instances
export const CreateChecklistInstance = graphql(
  CreateChecklistInstanceMutation,
  {
    props: ({ mutate, ownProps }) => ({
      createChecklistInstance: (orgId, metadata, steps, id) => {
        return mutate({
          variables: {
            orgId,
            metadata,
            steps,
            id
          },
          update: (proxy, { data }) => {
            const { widget } = ownProps;
            const { configuration } = widget;
            const config = configuration.json;
            const { createChecklistInstance: checklist } = data;

            // Don't update if checklist is null
            // Sometimes data is empty object?!? even though update calls always return normally
            // Seems to happen with lots of quick updates (i.e. typing)
            if (!checklist) return;

            // NOTE: doesn't update MyInstances Query
            // Currently feel these instances will be hidden in just Leaderboard Mode
            // But may not be correct if combine with ChecklistZen functionality
            const aggs = {};
            const filter = constructFilter(config);
            const filterString = JSON.stringify(filter);
            const aggsString = JSON.stringify(aggs);

            try {
              const cachedDataCompleted = proxy.readQuery({
                query: AggregateInstancesQuery,
                variables: {
                  filter: filterString,
                  aggs: aggsString
                }
              });

              // Need to filter because called twice
              // https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/282
              const items = cachedDataCompleted.aggregateInstances.items || [];
              cachedDataCompleted.aggregateInstances.items = [
                ...items.filter(e => {
                  return e.id !== checklist.id;
                }),
                checklist
              ];

              proxy.writeQuery({
                query: AggregateInstancesQuery,
                variables: {
                  filter: filterString,
                  aggs: aggsString
                },
                data: cachedDataCompleted
              });
            } catch (error) {
              // NOOP - Apollo has an error where a MissingFieldError is thrown if the query doesn't exist in the cachd
              // Fix with upgrade to 3.3. which is dependent on appsync
              // https://www.apollographql.com/docs/react/caching/cache-interaction/
            }
          }
        });
      }
    })
  }
);

// TODO: pass in > 50 for AggregateInstances: total count needs to be users * days since each user could have 1 instance per day
export const FetchCompletedChecklist = graphql(AggregateInstancesQuery, {
  name: "completedChecklists",
  options: props => {
    const { widget } = props;
    const { configuration } = widget;
    const config = configuration.json;

    let aggs = {};
    let filter = constructFilter(config);

    const filterString = JSON.stringify(filter);
    const aggsString = JSON.stringify(aggs);

    return {
      fetchPolicy: "network-only",
      variables: {
        filter: filterString,
        aggs: aggsString
      }
    };
  }
});

export const constructFilter = config => {
  let filter = [];

  if (config && config.users) {
    const { users } = config;
    filter = [
      {
        range: {
          "metadata.created.time": { gte: "now-90d/d" }
        }
      }
    ];
    if (users.length > 1) {
      filter.push({
        terms: {
          "metadata.template.checklistId": config.users.map(
            user => user.checklist_id
          )
        }
      });
    }

    if (users.length === 1) {
      filter.push({
        term: { "metadata.template.checklistId": users[0].checklist_id }
      });
    }
  }

  return filter;
};

export const aggregateChecklistsByTemplateId = (items, users) => {
  const usersInstancesMap = items
    .map(checklist => {
      return [checklist.metadata.template.checklistId, checklist];
    })
    .reduce((a, b) => {
      const [templateId, checklist] = b;
      if (!a[templateId]) a[templateId] = [];
      a[templateId].push(checklist);
      return a;
    }, {});

  // Fill in empty list if no data
  const usersInstancesMapNonEmtpy =
    Object.keys(usersInstancesMap).length > 0
      ? usersInstancesMap
      : users.reduce((dict, user) => {
          return (dict[user.checklist_id] = []);
        }, {});

  return usersInstancesMapNonEmtpy;
};
