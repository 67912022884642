import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import VerifiedUser from "@material-ui/icons/VerifiedUser";

// core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Danger from "components/Typography/Danger.jsx";

// Services and Subcomponents
import { FORM_STATE, INPUT_TYPE } from "./loginVariables";

// assets
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

class NewPassword extends React.PureComponent {
  render() {
    const {
      classes,
      formInputState,
      updateInput,
      updateFormState,
      setNewPassword,
      error
    } = this.props;

    return (
      <span>
        <CardBody>
          <CustomInput
            id="email"
            labelText="Email"
            success={formInputState[INPUT_TYPE.EMAIL] === "success"}
            error={formInputState[INPUT_TYPE.EMAIL] === "error"}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: "email",
              onChange: evt => updateInput(evt, INPUT_TYPE.EMAIL),
              endAdornment: (
                <InputAdornment position="end">
                  <Email className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
          <CustomInput
            id="code"
            labelText="Code"
            success={formInputState[INPUT_TYPE.CODE] === "success"}
            error={formInputState[INPUT_TYPE.CODE] === "error"}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: "text",
              onChange: evt => updateInput(evt, INPUT_TYPE.CODE),
              autoComplete: "new-password",
              endAdornment: (
                <InputAdornment position="end">
                  <VerifiedUser className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
          <CustomInput
            id="new_password"
            labelText="New Password"
            success={formInputState[INPUT_TYPE.NEW_PASSWORD] === "success"}
            error={formInputState[INPUT_TYPE.NEW_PASSWORD] === "error"}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: "password",
              onChange: evt => updateInput(evt, INPUT_TYPE.NEW_PASSWORD),
              autoComplete: "new-password",
              endAdornment: (
                <InputAdornment position="end">
                  <Icon className={classes.inputAdornmentIcon}>
                    lock_outline
                  </Icon>
                </InputAdornment>
              )
            }}
          />
          {error ? <Danger>{error.message || error}</Danger> : null}
          <div className={classes.justifyContentCenter}>
            <Button
              onClick={() => updateFormState(FORM_STATE.RESET_PASSWORD)}
              color="info"
              simple
              size="sm"
              block
            >
              Back to Forgot Password
            </Button>
          </div>
        </CardBody>
        <CardFooter className={classes.justifyContentCenter}>
          <Button onClick={setNewPassword} color="rose" simple size="lg" block>
            Set New Password
          </Button>
        </CardFooter>
      </span>
    );
  }
}

NewPassword.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(loginPageStyle)(NewPassword);
