/** Import React and 3rd party libs */
import React, { useState } from "react";
import { NavLink, withRouter } from "react-router-dom";

import { graphql } from "react-apollo";

import classNames from "classnames";

import { flowRight as compose } from "lodash";

// @material-ui/core components
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

// @material-ui/icons
import Add from "@material-ui/icons/Add";
import ArrowRight from "@material-ui/icons/ArrowRight";

// Components
import Checklist from "views/Checklists/Checklist";

// Subcomponents
/** Import components used only by this component - relative path imports */

// Services
import {
  getNavLinkClass,
  getItemTextClass,
  getCollapseItemTextClass,
  getNavLinkCollapseClass,
  getLeftCarotClass,
  getItemIcon
} from "./LinksUtility";
import {
  constructInstanceName,
  StatusType,
  newChecklist,
  goToTemplate
} from "services/ChecklistService";

// Queries
import { FindInstancesByTemplateIdFullQuery } from "api/queries";
import {
  CreateChecklistInstance,
  AddEvent
} from "views/Checklists/ChecklistComponents/ChecklistQueries";

// Assets
/** Import assets: jss styles & images */

// Variables
/** Declare all constant variables */

// Functions
const _openCollapse = (e, id, openCollapse) => {
  e.stopPropagation();
  openCollapse(`${id}-active`);
};

/**
 * PinnedLinkActive
 */
const PinnedLinkActive = ({
  classes,
  prop,
  color,
  activeRoute,
  miniActive,
  state,
  openCollapse,
  instances,
  createChecklistInstance,
  location,
  history,
  user
}) => {
  const [showButtons, setShowButtons] = useState(false);
  const navLinkClasses = getNavLinkClass(classes, activeRoute, prop);
  const pinnedClass = miniActive
    ? classes.pinnedItemLinkSmall
    : classes.pinnedItemLink;
  const pinnedLinkClassess = classNames(navLinkClasses, pinnedClass);
  const itemText = getItemTextClass(classes, miniActive);
  const collapseItemText = getCollapseItemTextClass(classes, miniActive);
  const instanceItems =
    instances.findInstancesByTemplateId &&
    instances.findInstancesByTemplateId.items
      ? instances.findInstancesByTemplateId.items
      : [];
  const MAX_SUB_NAME_LENGTH = 26;
  const views = instanceItems.slice(0, 4).map(instance => {
    const status =
      instance.metadata.status === StatusType.ACTIVE ? "active" : "completed";
    const subName = constructInstanceName(instance, prop.template);
    const shortenedSubName =
      subName.length > MAX_SUB_NAME_LENGTH
        ? `${subName.slice(0, MAX_SUB_NAME_LENGTH)}...`
        : subName;
    return {
      path: `/app/checklist/${instance.metadata.name}?id=${instance.id}&instance=true&status=${status}`,
      name: shortenedSubName,
      title: subName,
      component: Checklist
    };
  });
  const collapseClass = classNames(
    classes.collapseItem,
    classes.pinnedCollapseItem
  );
  const navLinkCollapseClasses = getNavLinkCollapseClass(
    classes,
    color,
    activeRoute,
    prop
  );
  const startNewChecklist = e => {
    e.stopPropagation();
    const props = { createChecklistInstance, history, location, user };
    console.log("user", props.user);
    newChecklist(prop.template, props);
  };

  // Use text check to shorten name since CSS max-width dosn't work well.
  const MAX_NAME_LENGTH = showButtons ? 15 : 20;
  const shortenedName =
    prop.name.length > MAX_NAME_LENGTH
      ? `${prop.name.slice(0, MAX_NAME_LENGTH)}...`
      : prop.name;

  return (
    <ListItem className={classes.item}>
      <div
        className={pinnedLinkClassess}
        onClick={() => goToTemplate(prop.id, prop.name, { history, location })}
        onMouseEnter={() => setShowButtons(true)}
        onMouseLeave={() => setShowButtons(false)}
      >
        {miniActive ? null : (
          <div
            className={classes.itemIcon}
            style={{ position: "absolute", left: 3, top: 10, zIndex: 1000 }}
          >
            <IconButton
              className={classes.caretIconButton}
              key="caret"
              aria-label="Icon"
              title={`Show active ${prop.name} checklists`}
              onClick={e => _openCollapse(e, prop.id, openCollapse)}
            >
              <ArrowRight
                className={getLeftCarotClass(classes, state, prop, true)}
              />
            </IconButton>
          </div>
        )}
        <ListItemIcon
          className={classes.itemIcon}
          style={{ marginLeft: miniActive ? "13px" : "36px" }}
        >
          {getItemIcon(prop)}
        </ListItemIcon>
        <ListItemText
          primary={shortenedName}
          disableTypography={true}
          className={itemText}
          title={`Go to ${prop.name} template`}
          inset={false}
        />
        <ListItemSecondaryAction
          classes={{ root: classes.listItemSecondaryAction }}
          title={`Start new ${prop.name} checklist`}
          className={
            showButtons ? classes.navMenuButton : classes.navMenuButtonHide
          }
        >
          <Button
            variant="contained"
            className={classes.newButton}
            size="small"
            onClick={startNewChecklist}
          >
            <Add className={classes.newButtonIcon} />
          </Button>
        </ListItemSecondaryAction>
      </div>
      <Collapse in={state[`${prop.id}-active`]} unmountOnExit>
        <List className={classes.list + " " + classes.collapseList}>
          {views.length === 0 ? (
            <ListItem className={collapseClass}>
              <div className={classes.collapseItemNoLink}>
                <ListItemText
                  primary="No active checklists"
                  disableTypography={true}
                  className={collapseItemText}
                  inset={false}
                />
              </div>
            </ListItem>
          ) : null}
          {views.map((prop, key) => {
            if (prop.redirect) {
              return null;
            }
            return (
              <ListItem key={key} className={collapseClass} title={prop.title}>
                <NavLink to={prop.path} className={navLinkCollapseClasses}>
                  <ListItemText
                    primary={prop.name}
                    disableTypography={true}
                    className={collapseItemText}
                    inset={false}
                  />
                </NavLink>
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    </ListItem>
  );
};

// Data Connectors
// TODO: figure out how to make sure it gets only the most recent active for the pilot / user
// Currently fetch 50 and not sure it is ordered, but works for demo
export const FindInstancesByTemplateIdFull = graphql(
  FindInstancesByTemplateIdFullQuery,
  {
    name: "instances",
    options: props => {
      const id = props.prop.id;
      if (!id) {
        return console.log(
          "Error fetching reference instance: no checklist id"
        );
      }
      return {
        fetchPolicy: "network-only",
        variables: {
          templateId: id
        }
      };
    }
  }
);

export default compose(
  CreateChecklistInstance,
  AddEvent,
  FindInstancesByTemplateIdFull,
  withRouter
)(PinnedLinkActive);
