import React, { useState, useRef } from "react";

// material-ui components
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";

// Components
import { MediaTabs } from "components/MediaMenu/MediaMenu";

const DashboardIcon = ({
  isEditMode,
  classes,
  showMedia,
  handleMediaSelect,
  metadata
}) => {
  let iconAnchorEl = useRef(null);
  const [editVisible, setEditVisible] = useState(false);
  const iconEditVisible = isEditMode && editVisible;
  const icon =
    metadata.banner && metadata.banner.icon
      ? metadata.banner.icon.link
      : "assignment";
  const _showMedia = () => {
    const mediaMenu = {
      open: true,
      config: {
        tabs: [MediaTabs.ICONS, MediaTabs.IMAGE_UPLOAD, MediaTabs.WEB_LINK]
      },
      handleSelect: handleMediaSelect,
      anchorEl: iconAnchorEl
    };
    showMedia(mediaMenu);
  };
  const iconWrapper = !iconEditVisible ? (
    <Icon onClick={showMedia} className={classes.iconNoEdit} fontSize="large">
      {icon}
    </Icon>
  ) : (
    <IconButton
      aria-label="Icon"
      className={classes.iconEditButton}
      classes={{ root: classes.iconButtonRoot }}
      ref={node => (iconAnchorEl = node)}
      onClick={_showMedia}
    >
      <Icon className={classes.iconEdit} fontSize="large">
        {icon}
      </Icon>
    </IconButton>
  );
  return (
    <div
      className={classes.iconBackground}
      onMouseEnter={() => setEditVisible(true)}
      onMouseLeave={() => setEditVisible(false)}
    >
      {iconWrapper}
    </div>
  );
};

export default DashboardIcon;
