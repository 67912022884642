import React from "react";

// nodejs library to set properties for components
import PropTypes from "prop-types";

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import customTabsStyle from "assets/jss/material-dashboard-pro-react/components/customTabsStyle.jsx";

const StyledButton = withStyles({
  root: {
    color: "rgba(255, 255, 255, 0.84)",
    "&:hover": {
      color: "rgba(255, 255, 255, 1)",
      backgroundColor: "rgba(255, 255, 255, 0.08)"
    }
  }
})(IconButton);

class CustomTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.defaultTab || 0,
      anchorEl: null
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { tabs } = props;
    const { value } = state;
    // Validate that the tab exists else set to 0
    if (value >= tabs.length) {
      state.value = 0;
    }
    return state;
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = fn => {
    const _fn = fn ? fn : () => {};
    this.setState({ anchorEl: null }, _fn());
  };

  renderMenu = menuItems => {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div>
        <StyledButton
          aria-label="More"
          aria-owns={open ? "long-menu" : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <MoreVertIcon />
        </StyledButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => this.handleClose()}
          // PaperProps={{
          //   style: {
          //     maxHeight: ITEM_HEIGHT * 4.5,
          //     width: 200
          //   }
          // }}
        >
          {menuItems.map(menuItem => (
            <MenuItem
              key={menuItem.title}
              onClick={() => this.handleClose(menuItem.onClick)}
            >
              <ListItemIcon>{menuItem.icon}</ListItemIcon>
              <ListItemText inset primary={menuItem.title} />
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  };

  render() {
    const {
      classes,
      headerColor,
      plainTabs,
      tabs,
      menuItems,
      title
    } = this.props;

    const isEdit = title === "Configure";
    const style = isEdit ? { margin: 0 } : {};

    return (
      <Card plain={plainTabs} style={style}>
        <CardHeader
          color={headerColor}
          plain={plainTabs}
          style={{ zIndex: "0 !important" }}
        >
          {menuItems ? (
            <div className={classes.cardMenu}>{this.renderMenu(menuItems)}</div>
          ) : null}
          <div className={classes.cardTitle}>{title}</div>
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            classes={{
              root: classes.tabsRoot,
              indicator: classes.displayNone
            }}
          >
            {tabs.map((prop, key) => {
              var icon = {};
              if (prop.tabIcon) {
                icon = {
                  icon: <prop.tabIcon />
                };
              }
              return (
                <Tab
                  classes={{
                    root: classes.tabRootButton,
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper
                  }}
                  key={key}
                  label={prop.tabName}
                  {...icon}
                />
              );
            })}
          </Tabs>
        </CardHeader>
        <CardBody>
          {tabs.map((prop, key) => {
            if (key === this.state.value) {
              return <div key={key}>{prop.tabContent}</div>;
            }
            return null;
          })}
        </CardBody>
      </Card>
    );
  }
}

CustomTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  headerColor: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "rose",
    "gray"
  ]),
  title: PropTypes.string,
  defaultTab: PropTypes.number,
  rtlActive: PropTypes.bool,
  plainTabs: PropTypes.bool,
  menuItems: PropTypes.arrayOf(PropTypes.object)
};

export default withStyles(customTabsStyle)(CustomTabs);
