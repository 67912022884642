import {
  defaultFont,
  primaryColor,
  dangerColor,
  infoColor,
  grayColor,
  successColor,
  tooltip
} from "assets/jss/material-dashboard-pro-react.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import typographyStyle from "assets/jss/material-dashboard-pro-react/components/typographyStyle.jsx";

const tasksStyle = {
  ...customCheckboxRadioSwitch,
  ...customSelectStyle,
  ...typographyStyle,
  table: {
    marginBottom: "0",
    backgroundColor: "white"
  },
  tableEditing: {
    marginBottom: "0",
    backgroundColor: "white",
    border: "1px solid rgba(0, 0, 0, 0.2)"
  },
  tableRowDividers: {
    position: "relative",
    borderBottom: "1px solid #dddddd"
  },
  tableRow: {
    position: "relative"
  },
  tableActions: {
    border: "none",
    padding: "12px 8px !important",
    verticalAlign: "middle",
    width: "8%"
  },
  stepButtons: {
    visibility: "visible",
    opacity: 0.54
  },
  stepButtonsHide: {
    visibility: "hidden",
    opacity: 0,
    transition: "visibility 0.1s, opacity 0.5s linear"
  },
  tableCell: {
    ...defaultFont,
    padding: "0",
    verticalAlign: "middle",
    border: "none",
    lineHeight: "1.42857143",
    fontSize: "14px",
    width: "84%"
  },
  tableContent: {
    ...defaultFont,
    padding: "16px 0",
    verticalAlign: "middle",
    border: "none",
    lineHeight: "1.42857143",
    fontSize: "14px",
    width: "84%"
  },
  tableEdit: {
    ...defaultFont,
    padding: "0",
    verticalAlign: "middle",
    border: "none",
    lineHeight: "1.42857143",
    fontSize: "14px",
    width: "84%"
  },
  stepNumber: {
    display: "inline-block",
    fontSize: "1.7em",
    paddingRight: "8px",
    opacity: "0.54"
  },
  stepName: {
    ...defaultFont,
    display: "inline-block",
    flexGrow: 1,
    margin: "0px",
    fontSize: "1.25em",
    color: "rgba(0, 0, 0, 0.76)",
    fontWeight: "500",
    paddingTop: "4px"
  },
  inputName: {
    width: "100%"
  },
  inputDescription: {
    width: "100%",
    padding: 0
  },
  stepDescription: {
    ...defaultFont,
    display: "inline-block",
    flexGrow: 1,
    margin: "0px",
    fontSize: "0.9em",
    color: "rgba(0, 0, 0, 0.54)",
    fontWeight: "300"
  },
  tableCheck: {
    ...defaultFont,
    padding: "0",
    verticalAlign: "middle",
    border: "none",
    lineHeight: "1.42857143",
    fontSize: "14px",
    width: "8%"
  },
  tableActionButton: {
    width: "27px",
    height: "27px",
    padding: "0"
  },
  tableInfoButton: {
    width: "27px",
    height: "27px",
    padding: "0",
    verticalAlign: "sub"
  },
  tableActionButtonIcon: {
    width: "17px",
    height: "17px"
  },
  textType: {
    paddingLeft: "14px"
  },
  iconType: {
    paddingLeft: "14px",
    opacity: "0.76"
  },
  edit: {
    backgroundColor: "transparent",
    color: primaryColor,
    boxShadow: "none"
  },
  subdued: {
    backgroundColor: "transparent",
    color: grayColor,
    boxShadow: "none"
  },
  success: {
    backgroundColor: "transparent",
    color: successColor,
    boxShadow: "none"
  },
  close: {
    backgroundColor: "transparent",
    color: dangerColor,
    boxShadow: "none"
  },
  info: {
    backgroundColor: "transparent",
    color: infoColor,
    boxShadow: "none"
  },
  checklistLink: {
    display: "inline-block",
    verticalAlign: "super",
    paddingLeft: "16px"
  },
  calcuatedValue: {
    display: "inline-block",
    paddingRight: "8px",
    color: primaryColor
  },
  units: {
    display: "inline-block",
    color: primaryColor
  },
  linkCell: {
    "&:hover": {
      cursor: "pointer"
    }
  },
  spinnerBox: {
    height: "84px",
    padding: "16px"
  },
  expirationDivider: {
    margin: "18px auto",
    maxWidth: "240px"
  },
  expirationRemove: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  stepMenuButton: {
    visibility: "visible",
    opacity: 0.44
  },
  stepMenuButtonHide: {
    visibility: "hidden",
    opacity: 0,
    transition: "visibility 0.1s, opacity 0.5s linear"
  },
  fullRow: {
    display: "flex",
    alignItems: "center",
    marginLeft: "-90px"
  },
  actionMenuModal: {
    zIndex: 1000
  },
  subLink: {
    "& path": {
      stroke: "#FFF"
    }
  },
  ...tooltip
};
export default tasksStyle;
