import React from "react";
import { withRouter } from "react-router-dom";
import { graphql } from "react-apollo"; // Apollo Data Layer
import { flowRight as compose } from "lodash";
import { S3Image } from "aws-amplify-react"; // Amplify S3 Image Viewer

// @material-ui/core components
import Icon from "@material-ui/core/Icon";
import Update from "@material-ui/icons/Update";

// @material-ui/icons
import Star from "@material-ui/icons/Star";

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

// sub components
import WidgetEditButtons from "../../WidgetComponents/WidgetEditButtons";

// services
import { AggregateInstancesQuery } from "api/queries";
import { getPicInfo, PicTypes } from "services/WidgetService";
import { DateRangeTypes } from "services/LogbookService";
import { StatusType } from "services/ChecklistService";

const getCount = (source, aggregations) => {
  if (source === "COMPLETED_STEPS") {
    return {
      value: aggregations.steps.steps_completed.value,
      label: "completed steps"
    };
  }

  if (aggregations.checklists_completed) {
    return aggregations.checklists_completed.value;
  }

  return 0;
};

const getLabel = () => {
  // if (duration === "LAST_SEVEN_DAYS") {
  //   return `${count.value || 0} ${count.label} in the last 7 days`;
  // }
  return "";
};

const CountBody = ({ type, count, starIconInfo }) => {
  if (type === "DIGIT") {
    return <h3>{count}</h3>;
  }

  if (type === "STAR") {
    const stars = [];
    if (starIconInfo.picType === PicTypes.UPLOAD) {
      for (let i = 0; i < count.value; i++) {
        stars.push(
          <div key={i} style={{ display: "inline", margin: "12px" }}>
            <S3Image
              theme={{
                photoImg: {
                  height: "48px"
                }
              }}
              imgKey={starIconInfo.iconUrl}
              level="protected"
            />
          </div>
        );
      }
      return <div style={{ display: "flex", flexWrap: "wrap" }}>{stars}</div>;
    } else {
      for (let i = 0; i < count.value; i++) {
        stars.push(<Star key={i} style={{ color: "#fff52b", fontSize: 64 }} />);
      }
      return <>{stars}</>;
    }
  }

  return <h3>Invalid Type</h3>;
};

/**
 * Count Widget - For general counts of checklists, steps, or a start chart
 */
const WidgetCount = ({
  classes,
  mode,
  widget,
  editWidget,
  deleteWidget,
  data
}) => {
  const { values, configuration } = widget;
  const { aggregateInstances } = data ? data : {};
  const aggregations =
    aggregateInstances && aggregateInstances.aggregations
      ? JSON.parse(aggregateInstances.aggregations)
      : { steps: { steps_completed: 0 } };
  const [type, source, duration] = configuration.strings;
  const count = getCount(source, aggregations);
  const label = getLabel(duration, count);
  const picInfo = getPicInfo(widget.configuration.icon);
  const starIconInfo = getPicInfo(widget.configuration.strings[3] || "");

  const { checklists } = configuration;
  if (checklists && checklists[0])
    return (
      <Card>
        <CardHeader color={values.color} icon>
          <WidgetEditButtons
            mode={mode}
            classes={classes}
            editWidget={editWidget}
            deleteWidget={deleteWidget}
          />
          {picInfo.picType === PicTypes.UPLOAD ? (
            <div
              style={{
                marginLeft: "64px",
                marginRight: "15px",
                height: "69px",
                width: "63px",
                float: "left",
                marginTop: "-20px",
                boxShadow:
                  "0 12px 20px -10px rgba(0, 0, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 0, 0, 0.2)"
              }}
            >
              <S3Image
                theme={{
                  photoImg: {
                    height: "69px",
                    width: "63px",
                    borderRadius: "3px"
                  }
                }}
                imgKey={picInfo.iconUrl}
                level="protected"
              />
            </div>
          ) : (
            <CardIcon
              style={{ marginLeft: "64px" }}
              color={widget.values.color}
            >
              <Icon style={{ color: "white" }}>
                {widget.configuration.icon}
              </Icon>
            </CardIcon>
          )}
          <h4 className={classes.cardIconTitle}>{configuration.title}</h4>
        </CardHeader>
        <CardBody>
          <CountBody type={type} count={count} starIconInfo={starIconInfo} />
        </CardBody>
        <CardFooter stats>
          <div className={classes.stats}>
            <Update />
            {label}
          </div>
        </CardFooter>
      </Card>
    );
};

const getCurrentDayQuery = () => {
  const queryDate = new Date(); // Current time
  const year = queryDate.getFullYear();
  const month = ("0" + (queryDate.getMonth() + 1)).slice(-2);
  const day = ("0" + queryDate.getDate()).slice(-2);
  const dateQueryString = `${year}-${month}-${day}||/d`;
  return dateQueryString;
};

const getCurrentWeekQuery = () => {
  const queryDate = new Date(); // Current time
  queryDate.setDate(queryDate.getDate() + ((7 - queryDate.getDay()) % 7)); // Next Sunday
  const year = queryDate.getFullYear();
  const month = ("0" + (queryDate.getMonth() + 1)).slice(-2);
  const day = ("0" + queryDate.getDate()).slice(-2);
  const dateQueryString = `${year}-${month}-${day}||-7d`;
  return dateQueryString;
};

const getCurrentMonthQuery = () => {
  const queryDate = new Date(); // Current time
  const year = queryDate.getFullYear();
  const month = ("0" + (queryDate.getMonth() + 1)).slice(-2);
  const day = ("0" + queryDate.getDate()).slice(-2);
  const dateQueryString = `${year}-${month}-${day}||/M`;
  return dateQueryString;
};

const getDateQuery = globalDateRange => {
  switch (globalDateRange) {
    case DateRangeTypes.DAY:
      return getCurrentDayQuery();
    case DateRangeTypes.WEEK:
      return getCurrentWeekQuery();
    case DateRangeTypes.MONTH:
      return getCurrentMonthQuery();
    default:
      return getCurrentDayQuery();
  }
};

const isPilot = checklists => {
  return checklists.length && checklists[0].name === "Pilot";
};

const isAircraft = checklists => {
  return checklists.length && checklists[0].name === "Aircraft";
};

const isFlight = checklists => {
  return checklists.length && checklists[0].name === "Flight";
};

// TODO: pass source false so no items returned
const getFilterStringAggs = (checklists, globalDateRange) => {
  let aggs = {};
  let filter = [];

  if (checklists) {
    if (isPilot(checklists) || isAircraft(checklists) || isFlight(checklists)) {
      filter.push({
        match: { "metadata.template.checklistId": checklists[0].checklistId }
      });
      aggs = JSON.stringify({
        checklists_completed: {
          value_count: {
            field: "metadata.version"
          }
        }
      });
    } else {
      filter = [
        { term: { "metadata.status": StatusType.COMPLETED } },
        {
          range: {
            "metadata.created.time": { gte: getDateQuery(globalDateRange) }
          }
        }
      ];

      if (checklists.length & (checklists.length > 1)) {
        filter.push({
          terms: {
            "metadata.template.checklistId": checklists.map(
              checklist => checklist.checklistId
            )
          }
        });
      }

      if (checklists.length & (checklists.length === 1)) {
        filter.push({
          term: { "metadata.template.checklistId": checklists[0].checklistId }
        });
      }

      aggs = JSON.stringify({
        checklists_completed: {
          value_count: {
            field: "metadata.status"
          }
        },
        steps: {
          nested: {
            path: "steps"
          },
          aggs: {
            steps_completed: {
              value_count: {
                field: "steps.value.checked"
              }
            }
          }
        }
      });
    }
  }

  const filterString = JSON.stringify(filter);
  return [filterString, aggs];
};

// TODO: update footer to show correct text "last x days"
// TODO: test out queries - seems day might be two days, a week more than sunday, etc.
// TODO: don't refetch if the query is the same
// TODO: should read data from ES -> just pass appropriate query (don't fetch all instances)
// Aggregate in the query, allow arbitrary ES query, just perm check on server for user & org and org perm/role
const AggregateInstances = graphql(AggregateInstancesQuery, {
  options: props => {
    const { globalSettings, widget } = props;
    const { globalDateRange } = globalSettings;
    const { checklists } = widget.configuration;
    const [filterString, aggs] = getFilterStringAggs(
      checklists,
      globalDateRange
    );
    return {
      fetchPolicy: "network-only",
      variables: {
        filter: filterString,
        aggs
      }
    };
  },
  skip: props => {
    return !(
      props.widget &&
      props.widget.configuration &&
      props.widget.configuration.checklists &&
      props.widget.configuration.checklists[0]
    );
  }
});

export default compose(AggregateInstances, withRouter)(WidgetCount);
