import gql from "graphql-tag";
import { Organization } from "../fragments";

export const OrganizationQuery = gql`
  query OrganizationQuery($id: String!, $version: Int) {
    getOrganization(id: $id, version: $version) {
      id
      metadata {
        ...OrgMetadataCurrent
      }
      access {
        ...OrgAccess
      }
    }
  }
  ${Organization.orgMetadataCurrent}
  ${Organization.orgAccess}
`;

export const OrganizationsQuery = gql`
  query OrganizationsQuery($ids: [String!]!) {
    getOrganizations(ids: $ids) {
      id
      metadata {
        ...OrgMetadataCurrent
      }
      access {
        ...OrgAccess
      }
    }
  }
  ${Organization.orgMetadataCurrent}
  ${Organization.orgAccess}
`;
