import React, { useState, useRef } from "react";

// material-ui components
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";

// Components
import { MediaTabs } from "components/MediaMenu/MediaMenu";

const handleMediaSelect = (media, tabType, widget, updateWidget, showMedia) => {
  const newWidget = Object.assign({}, widget);
  if (tabType === MediaTabs.ICONS) {
    // Only save to icon for now
    // Need to add banner type to widget to add other types of media
    newWidget.configuration.icon = media;
    updateWidget(newWidget);
    const mediaMenu = {
      open: false
    };
    showMedia(mediaMenu);
  }
};

const WidgetIcon = ({
  isEditMode,
  classes,
  showMedia,
  widget,
  updateWidget
}) => {
  let iconAnchorEl = useRef(null);
  const [editVisible, setEditVisible] = useState(false);
  const iconEditVisible = isEditMode && editVisible;
  const icon =
    widget.configuration && widget.configuration.icon
      ? widget.configuration.icon
      : "widgets";
  const _showMedia = () => {
    const mediaMenu = {
      open: true,
      config: {
        tabs: [MediaTabs.ICONS, MediaTabs.IMAGE_UPLOAD, MediaTabs.WEB_LINK]
      },
      handleSelect: (media, tabType) =>
        handleMediaSelect(media, tabType, widget, updateWidget, showMedia),
      anchorEl: iconAnchorEl
    };
    showMedia(mediaMenu);
  };
  const iconWrapper = !iconEditVisible ? (
    <Icon onClick={showMedia} className={classes.iconNoEdit} fontSize="large">
      {icon}
    </Icon>
  ) : (
    <IconButton
      aria-label="Icon"
      className={classes.iconEditButton}
      classes={{ root: classes.iconButtonRoot }}
      ref={node => (iconAnchorEl = node)}
      onClick={_showMedia}
    >
      <Icon className={classes.iconEdit} fontSize="large">
        {icon}
      </Icon>
    </IconButton>
  );
  return (
    <div
      className={classes.iconBackground}
      onMouseEnter={() => setEditVisible(true)}
      onMouseLeave={() => setEditVisible(false)}
    >
      {iconWrapper}
    </div>
  );
};

export default WidgetIcon;
