/** Import React and 3rd party libs */
import React from "react";
import { NavLink } from "react-router-dom";

// @material-ui/core components
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";

// @material-ui/icons
/** Import material ui icons */

// Components
/** Import generic components - internal and creative time */

// Subcomponents
/** Import components used only by this component - relative path imports */

// Services
import {
  getNavLinkClass,
  getItemTextClass,
  getCollapseItemTextClass,
  getItemIcon,
  getNavLinkCollapseClass,
  getCarotClass
} from "./LinksUtility";

// Queries
/** Import all graphql queries */

// Assets
/** Import assets: jss styles & images */

// Variables
/** Declare all constant variables */

// Functions

/**
 * CollapsableLink
 */
const CollapsableLink = ({
  classes,
  prop,
  color,
  activeRoute,
  miniActive,
  state,
  openCollapse
}) => {
  const navLinkClasses = getNavLinkClass(classes, activeRoute, prop);
  const itemText = getItemTextClass(classes, miniActive);
  const collapseItemText = getCollapseItemTextClass(classes, miniActive);
  return (
    <ListItem className={classes.item}>
      <div className={navLinkClasses} onClick={() => openCollapse(prop.state)}>
        <ListItemIcon className={classes.itemIcon}>
          {getItemIcon(prop)}
        </ListItemIcon>
        <ListItemText
          primary={prop.name}
          secondary={<b className={getCarotClass(classes, state, prop)} />}
          disableTypography={true}
          className={itemText}
        />
      </div>
      <Collapse in={state[prop.state]} unmountOnExit>
        <List className={classes.list + " " + classes.collapseList}>
          {prop.views.map((prop, key) => {
            if (prop.redirect) {
              return null;
            }
            const navLinkCollapseClasses = getNavLinkCollapseClass(
              classes,
              color,
              activeRoute,
              prop
            );
            return (
              <ListItem key={key} className={classes.collapseItem}>
                <NavLink to={prop.path} className={navLinkCollapseClasses}>
                  <span className={classes.collapseItemMini}>{prop.mini}</span>
                  <ListItemText
                    primary={prop.name}
                    disableTypography={true}
                    className={collapseItemText}
                  />
                </NavLink>
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    </ListItem>
  );
};

// Data Connectors

export default CollapsableLink;
