import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
const userProfileStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    "& small": {
      fontSize: "80%",
      fontWeight: "400"
    }
  },
  cardCategory: {
    marginTop: "10px",
    color: "#999999 !important",
    textAlign: "center"
  },
  description: {
    color: "#999999"
  },
  editProfileButton: {
    float: "right"
  },
  resetProfileButton: {
    float: "right"
  },
  updateProfileButton: {
    float: "right",
    marginLeft: "12px"
  },
  progressContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "calc(100vh - 123px)"
  },
  uploadButton: {
    margin: "1em"
  },
  fileUploadInput: {
    display: "none"
  },
  progress: {
    margin: "0.85em"
  }
};
export default userProfileStyles;
