import React from "react";

// @material-ui/core components
import Divider from "@material-ui/core/Divider";

// @material-ui/icons

// Components

// Subcomponents
import Version from "./MetadataComponents/Version";
import Tags from "./MetadataComponents/Tags";
import IsPublic from "./MetadataComponents/IsPublic";
import DateRanges from "./MetadataComponents/DateRanges";
import Reference from "./MetadataComponents/Reference";
import PinToNav from "./MetadataComponents/PinToNav";

// Services

function update(property, value, metadata, updateMetadata) {
  const newMetadata = Object.assign({}, metadata);
  if (value === null) value = !metadata[property]; // Boolean toggle
  newMetadata[property] = value;
  updateMetadata(newMetadata);
}

const MetaData = props => {
  const { hideLogbookMetadata, metadata, updateMetadata } = props;
  if (hideLogbookMetadata) {
    // Only return the date selector
    return <DateRanges {...props} update={update} />;
  }

  return (
    <div style={{ marginLeft: "16px", width: "300px" }}>
      <form>
        <Version {...props} />
      </form>
      <Tags {...props} update={update} />
      <div style={{ padding: "16px 0 0" }}>
        <Divider />
        <h6 style={{ opacity: "0.76" }}>Sharing</h6>
      </div>
      <IsPublic {...props} />
      <div style={{ padding: "16px 0 0" }}>
        <Divider />
        <h6 style={{ opacity: "0.76" }}>Configuration</h6>
      </div>
      <DateRanges {...props} update={update} />
      <br />
      <br />
      <Reference {...props} update={update} />
      <div style={{ padding: "32px 0 0" }}>
        <Divider />
        <h6 style={{ opacity: "0.76" }}>Navigation</h6>
      </div>
      <PinToNav metadata={metadata} updateMetadata={updateMetadata} />
    </div>
  );
};

export default MetaData;
