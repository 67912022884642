/** Import React and 3rd party libs */
import React from "react";
// import PerfectScrollbar from "perfect-scrollbar";

// @material-ui/core components

// @material-ui/icons
/** Import material ui icons */

// Components

// Subcomponents
/** Import components used only by this component - relative path imports */

// Services

// Queries
/** Import all graphql queries */

// Assets
/** Import assets: jss styles & images */

// Variables
// var ps; // Perfect Scrollbar

// Functions

/**
 * Declare JSX Component - class or
 */
// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
  sidebarWrapper = React.createRef();

  componentDidMount() {
    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps = new PerfectScrollbar(this.sidebarWrapper, {
    //     suppressScrollX: true,
    //     suppressScrollY: false
    //   });
    // }
  }
  componentWillUnmount() {
    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps.destroy();
    // }
  }
  render() {
    const {
      className,
      userComponent,
      headerLinks,
      links,
      pinnedChecklistsTemplate,
      pinnedChecklistsActive,
      newChecklistButton
    } = this.props;

    return (
      <div className={className} ref={this.sidebarWrapper}>
        {userComponent}
        {headerLinks}
        {links}
        {pinnedChecklistsTemplate}
        {pinnedChecklistsActive}
        {newChecklistButton}
      </div>
    );
  }
}

export default SidebarWrapper;
