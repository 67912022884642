import React from "react";

function terms() {
  return (
    <span>
      <h1>Data Deletion Instructions</h1>
      <h3>Your data is your data.</h3>
      <br />
      Please reach out to us directly through our &nbsp;
      <a href="https://www.airsync.ai//pages/contact">contact page</a> or email
      us at <a href="mailto:team@airsync.ai">team@airsync.ai</a>
      <br />
      We will quickly delete all your organization and user data upon
      verification.
      <br />
      Thank you.
    </span>
  );
}
export default terms;
