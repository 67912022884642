import React from "react";
import PropTypes from "prop-types";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
// import Store from "@material-ui/icons/Store";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import DateRange from "@material-ui/icons/DateRange";
import Update from "@material-ui/icons/Update";
import Language from "@material-ui/icons/Language";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

/***************************************** */
//////////////////////////////////////////
// TODO: DELETE and REPLACE with Dashboard
//////////////////////////////////////////
/***************************************** */

const PilotStatus = ({ classes }) => (
  <Card>
    <CardHeader color="success" stats icon>
      <CardIcon color="success">
        <Icon>group</Icon>
      </CardIcon>
      <p className={classes.cardCategory}>Pilots</p>
      <h3 className={classes.cardTitle}>
        17/18 <small>curr</small>
      </h3>
    </CardHeader>
    <CardFooter stats>
      <div className={classes.stats}>
        <Update />
        Just Updated
      </div>
    </CardFooter>
  </Card>
);

const AircraftStatus = ({ classes }) => (
  <Card>
    <CardHeader color="danger" stats icon>
      <CardIcon color="danger">
        <Icon>airplanemode_active</Icon>
      </CardIcon>
      <p className={classes.cardCategory}>Aircraft</p>
      <h3 className={classes.cardTitle}>
        8/12 <small>up</small>
      </h3>
    </CardHeader>
    <CardFooter stats>
      <div className={classes.stats}>
        <Update />
        Just Updated
      </div>
    </CardFooter>
  </Card>
);

const ExpiringQuals = ({ classes }) => (
  <Card>
    <CardHeader color="success" stats icon>
      <CardIcon color="success">
        <Icon>flight_land</Icon>
      </CardIcon>
      <p className={classes.cardCategory}>Expiring Quals</p>
      <h3 className={classes.cardTitle}>25</h3>
    </CardHeader>
    <CardFooter stats>
      <div className={classes.stats}>
        <DateRange />
        Next 30 Days
      </div>
    </CardFooter>
  </Card>
);

const RequiredMaintenance = ({ classes }) => (
  <Card>
    <CardHeader color="warning" stats icon>
      <CardIcon color="warning">
        <Icon>build</Icon>
      </CardIcon>
      <p className={classes.cardCategory}>Maintenance</p>
      <h3 className={classes.cardTitle}>32</h3>
    </CardHeader>
    <CardFooter stats>
      <div className={classes.stats}>
        <DateRange />
        Next 30 Days
      </div>
    </CardFooter>
  </Card>
);

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      data: []
    };
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };
  handleChangeIndex = index => {
    this.setState({ value: index });
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <PilotStatus classes={classes} />
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <AircraftStatus classes={classes} />
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <ExpiringQuals classes={classes} />
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <RequiredMaintenance classes={classes} />
          </GridItem>
        </GridContainer>

        <h3>Pilots</h3>
        <br />

        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <Language />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Pilots</h4>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={this.state.data}
                  filterable
                  columns={[
                    {
                      Header: "Name",
                      accessor: "name"
                    },
                    {
                      Header: "Position",
                      accessor: "position"
                    },
                    {
                      Header: "Office",
                      accessor: "office"
                    },
                    {
                      Header: "Age",
                      accessor: "age"
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false
                    }
                  ]}
                  defaultPageSize={10}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <h3>Aircraft</h3>
        <br />

        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <Language />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Pilots</h4>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={this.state.data}
                  filterable
                  columns={[
                    {
                      Header: "Name",
                      accessor: "name"
                    },
                    {
                      Header: "Position",
                      accessor: "position"
                    },
                    {
                      Header: "Office",
                      accessor: "office"
                    },
                    {
                      Header: "Age",
                      accessor: "age"
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false
                    }
                  ]}
                  defaultPageSize={10}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
