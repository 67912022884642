import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/core components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.jsx";
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// Components
import CustomInput from "components/CustomInput/CustomInput";

// Services
import notificationsStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(notificationsStyle);

/**
 * Bad design that shoud be refactored -> confirm title is used in the body.  But now want body content and title.
 * So if paragraph is not null, create a separate confirm modal.  Should refactor all confirm modals to this format
 * to look better.
 */
const Confirm = ({ isOpen, handleConfirm, handleClose }) => {
  const [orgName, setOrgName] = useState("");
  const _handleConfirm = () => {
    // Only create or if name, later add notification of error for now just don't fire
    if (orgName) {
      handleConfirm(orgName);
    }
  };
  const classes = useStyles();
  return (
    <Dialog
      classes={{
        root: classes.center + " " + classes.modalRoot,
        paper: classes.modal + " " + classes.modalSmall
      }}
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="small-modal-slide-title"
      aria-describedby="small-modal-slide-description"
      style={{ textAlign: "left" }}
    >
      <DialogTitle
        id="small-modal-slide-title"
        className={classes.modalHeader}
        style={{ opacity: "0.74" }}
      >
        Create New <span style={{ color: "#00acc1" }}>A</span>irsync Org
        <Button
          justIcon
          className={classes.modalCloseButtonParagraph}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={handleClose}
        >
          <Close className={classes.modalClose} />
        </Button>
      </DialogTitle>
      <DialogContent
        id="small-modal-slide-description"
        className={classes.modalBody + " " + classes.modalSmallBody}
      >
        <div>
          <CustomInput
            id="org_name"
            labelText="Org Name"
            error={false}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: "text",
              onChange: evt => setOrgName(evt.target.value),
              endAdornment: (
                <InputAdornment position="end">
                  <Icon className={classes.inputAdornmentIcon}>
                    language_outline
                  </Icon>
                </InputAdornment>
              )
            }}
          />
        </div>
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <Button
          onClick={handleClose}
          color="github"
          style={{ opacity: ".45" }}
          simple
          className={classes.modalSmallFooterFirstButton}
        >
          Never Mind
        </Button>
        <Button
          onClick={_handleConfirm}
          color="info"
          simple
          className={
            classes.modalSmallFooterFirstButton +
            " " +
            classes.modalSmallFooterSecondButton
          }
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Confirm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string
};

export default Confirm;
