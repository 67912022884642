/** Import React and 3rd party libs */
import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";

import classNames from "classnames";

// @material-ui/icons
/** Import material ui icons */

// Components
/** Import generic components - internal and creative time */

// Subcomponents
import TopLevelLink from "./TopLevelLink";
import CollapsableLink from "./CollapsableLink";
import PinnedLinkActive from "./PinnedLinkActive";
import PinnedLinkTemplate from "./PinnedLinkTemplate";

// Services
/** Import all services and variables */

// Queries
/** Import all graphql queries */

// Assets
import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx";

// Variables
/** Declare all constant variables */

// Functions

/**
 * Links
 */
const Links = props => {
  const { classes, routes } = props;
  // TODO: Here dynamically fetch templates (async) then add pinned checklists to routes
  // Just use templates => later switch to ES search for just "pinned"
  // These pinned templates will be org level.  Later need to figure out User Overrides.
  const isPinned =
    routes && routes[0] && (routes[0].pinnedActive || routes[0].pinnedTemplate);
  const isPinnedDashboard = routes && routes[0] && routes[0].pinnedDashboard;

  const listClasses = classNames({
    [classes.list]: true,
    [classes.pinnedList]: isPinned,
    [classes.pinnedDashboard]: isPinnedDashboard
  });

  return (
    <List className={listClasses}>
      {routes.map((prop, key) => {
        // Autmatic redirect
        if (prop.redirect) {
          return null;
        }

        // Collapsable link with submenu
        if (prop.collapse) {
          return <CollapsableLink key={key} prop={prop} {...props} />;
        }

        // Pinned link with new button and template checklists
        if (prop.pinnedTemplate) {
          return <PinnedLinkTemplate key={key} prop={prop} {...props} />;
        }

        // Pinned link with new button and active checklists
        if (prop.pinnedActive) {
          return <PinnedLinkActive key={key} prop={prop} {...props} />;
        }

        // Link with no submenu
        return <TopLevelLink key={key} prop={prop} {...props} />;
      })}
    </List>
  );
};

// Data Connectors

export default withStyles(sidebarStyle)(Links);
