import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import getDashboardRoutes from "./app_dashboard_routes";

class DashboardRoutes extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { pathname: currPathName, search: currSearch } = this.props.location;
    const { pathname: nextPathName, search: nextSearch } = nextProps.location;
    if (currPathName !== nextPathName) {
      return true;
    }
    if (currSearch !== nextSearch) {
      return true;
    }
    return false;
  }

  render() {
    const { user } = this.props;
    const dashboardRoutes = getDashboardRoutes(user.siteConfig.leftNav);
    return (
      <Switch>
        {dashboardRoutes.map((prop, key) => {
          if (prop.redirect)
            return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
          if (prop.collapse)
            return prop.views.map((prop, key) => {
              return (
                <Route
                  key={key}
                  path={prop.path}
                  component={props => <prop.component {...props} user={user} />}
                />
              );
            });
          return (
            <Route
              key={key}
              path={prop.path}
              component={props => <prop.component {...props} user={user} />}
            />
          );
        })}
      </Switch>
    );
  }
}

export default DashboardRoutes;
