/** Import React and 3rd party libs */
import React from "react";
import moment from "moment";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
/** Import material ui icons */

// Components
/** Import generic components - internal and creative tim */

// Subcomponents
/** Import components used only by this component - relative path imports */

// Services
import { OperationType } from "services/ChecklistService";
import { StepType } from "services/StepService";

// Queries
/** Import all graphql queries */

// Assets
import stepsStyle from "assets/jss/material-dashboard-pro-react/components/stepsStyle.jsx";

// Variables
/** Declare all constant variables */

// Functions
const calculateFormula = (step, steps) => {
  if (!step.configuration.step1 || !step.configuration.step2) {
    return "Missing Configuration";
  }

  const step1Id = step.configuration.step1.stepId;
  const step2Id = step.configuration.step2.stepId;
  const operation = step.configuration.operation;
  const step1 = steps.filter(step => step.stepId === step1Id)[0];
  const step2 = steps.filter(step => step.stepId === step2Id)[0];
  let value1, value2, results;

  if (!step1 || !step2) {
    return "ERROR: Missing Steps";
  }

  switch (step1.type) {
    case StepType.DATE_TIME_INPUT:
      value1 = step1.value.date_time_tz
        ? moment(step1.value.date_time_tz).valueOf()
        : null;
      break;
    case StepType.NUMBER_INPUT:
      value1 = step1.value.number;
      break;
    default:
    // noop
  }

  switch (step2.type) {
    case StepType.DATE_TIME_INPUT:
      value2 = step2.value.date_time_tz
        ? moment(step2.value.date_time_tz).valueOf()
        : null;
      break;
    case StepType.NUMBER_INPUT:
      value2 = step2.value.number;
      break;
    default:
    // noop
  }

  if (!value1 || !value2) {
    return;
  }

  switch (operation) {
    case OperationType.ADDITION:
      results = value1 + value2;
      break;
    case OperationType.SUBTRACTION:
      results = value1 - value2;
      break;
    default:
      // eslint-disable-next-line
      console.log("Unkown Operation Type");
  }

  // Assuming hours for now
  // TODO: update unit type to enum and branch on different types
  // TODO: also update label so 1 hour and not 1 hours
  const ms_per_hour = 1000 * 60 * 60;
  results = results / ms_per_hour;

  return results;
};

/**
 * Declare JSX Component - class or
 */
const CalculatedInput = ({ classes, step, steps }) => {
  const calculatedValue = calculateFormula(step, steps);
  return (
    <div>
      <h5 className={classes.calcuatedValue}>{calculatedValue}</h5>
      <h6 className={classes.units}>
        {calculatedValue ? step.configuration.units : ""}
      </h6>
    </div>
  );
};

// Data Connectors

export default withStyles(stepsStyle)(CalculatedInput);
