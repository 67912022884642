/** Import React and 3rd party libs */
import React from "react";

// @material-ui/core components
/** Import material ui components */

// @material-ui/icons
import Done from "@material-ui/icons/Done";

// Components
import Button from "components/CustomButtons/Button.jsx";

// Subcomponents
/** Import components used only by this component - relative path imports */

// Services
import { ChecklistMode, isActive } from "services/ChecklistService";

// Queries
/** Import all graphql queries */

// Assets
/** Import assets: jss styles & images */

// Variables
/** Declare all constant variables */

// Functions
const PublishChecklist = ({ classes, publishChecklist }) => {
  return (
    <Button
      onClick={publishChecklist}
      color="rose"
      className={classes.marginRight}
    >
      <Done className={classes.icons} />
      Publish Checklist
    </Button>
  );
};

const CancelEdit = ({ classes, cancelEdit }) => {
  return (
    <Button onClick={cancelEdit} className={classes.marginRight}>
      <Done className={classes.icons} />
      Cancel Changes
    </Button>
  );
};

const RevertChanges = ({ classes, revertChanges }) => {
  return (
    <Button onClick={revertChanges} className={classes.marginRight}>
      <Done className={classes.icons} />
      Revert Changes
    </Button>
  );
};

const CompleteChecklistButton = ({ classes, completeChecklist }) => {
  return (
    <Button
      onClick={completeChecklist}
      color="rose"
      className={classes.marginRight}
    >
      <Done className={classes.icons} />
      Complete Checklist
    </Button>
  );
};

const UpdateChecklistButton = ({ classes, updateCompletedChecklist }) => {
  return (
    <Button
      onClick={updateCompletedChecklist}
      color="rose"
      className={classes.marginRight}
    >
      <Done className={classes.icons} />
      Update Checklist
    </Button>
  );
};

// const IndexChecklist = ({ classes, indexChecklist }) => {
//   return (
//     <Button
//       onClick={indexChecklist}
//       color="rose"
//       round
//       className={classes.marginRight}
//     >
//       <Done className={classes.icons} />
//       Index Checklist
//     </Button>
//   );
// };

/**
 * Declare JSX Component - class or function
 */
const ChecklistSaveButtons = props => {
  const { classes, mode, checklist, checklistFn } = props;

  // const isAdmin =
  //   user.profile &&
  //   user.profile.id === "us-east-1:75246bba-3523-4699-b82b-6bf8901e98f3";

  const style = {
    position: "absolute",
    bottom: "30px"
  };

  // Don't show buttons on first load with no steps.
  // Also doesn't make sense to execute or edit checklists with no steps
  if (
    !props.checklist ||
    !props.checklist.steps ||
    props.checklist.steps.length === 0
  ) {
    return null;
  }

  if (mode === ChecklistMode.TEMPLATE_EDIT) {
    return (
      <div style={style}>
        <RevertChanges
          classes={classes}
          revertChanges={checklistFn.revertChanges}
        />
        <PublishChecklist
          classes={classes}
          publishChecklist={checklistFn.publishChecklist}
        />
      </div>
    );
  }

  if (isActive(checklist)) {
    return (
      <div style={style}>
        <CompleteChecklistButton
          classes={classes}
          completeChecklist={checklistFn.completeChecklist}
        />
      </div>
    );
  }

  if (mode === ChecklistMode.INSTANCE_EDIT) {
    return (
      <div style={style}>
        <CancelEdit
          classes={classes}
          cancelEdit={checklistFn.cancelEditChecklist}
        />
        <UpdateChecklistButton
          classes={classes}
          updateCompletedChecklist={checklistFn.updateCompletedChecklist}
        />
      </div>
    );
  }

  return null;
};

// Data Connectors

export default ChecklistSaveButtons;
