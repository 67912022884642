import React from "react";

import ChecklistSelector from "components/ChecklistSelector/ChecklistSelector";

class TableConfig extends React.Component {
  render() {
    const { widget, updateWidget, user } = this.props;

    const onChange = checklist => {
      let newWidget = Object.assign({}, widget);
      const newConfiguration = checklist
        ? {
            checklist
          }
        : {
            checklist: {}
          };
      newWidget.configuration = Object.assign(
        {},
        widget.configuration,
        newConfiguration
      );
      updateWidget(newWidget);
    };

    return (
      <ChecklistSelector
        checklist={widget.configuration.checklist}
        onChange={onChange}
        user={user}
      />
    );
  }
}

export default TableConfig;
