import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// Components

// Subcomponents

// Services
import { getDefaultWidget } from "services/WidgetService";
import { getWidgetEditTabs } from "./WidgetEdit/WidgetEditService";

// Assets
import widgetModalStyle from "assets/jss/material-dashboard-pro-react/components/widgetModalStyle.jsx";

// Variables

// Functions
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

// Update the widget if editing a new one or a different one
const shouldUpdateWidget = (widgetToEdit, prevState) => {
  const isEdit = !!widgetToEdit;
  const isNew = !prevState.widget.widgetId;
  // If editing a widget and previouly was adding a new widge
  const newWidgetEdit = isEdit && isNew;
  // or editing a different widget, then update state
  const differentWidgetEdit =
    isEdit && !isNew && prevState.widget.widgetId !== widgetToEdit.widgetId;
  return newWidgetEdit || differentWidgetEdit;
};

class WidgetModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      widget: getDefaultWidget()
    };
  }

  static getDerivedStateFromProps(props, state) {
    // If previously was editing a widget but now creating a new one,
    // reset to the default widget
    const wasEdit = state.widget.widgetId;
    const isNew = !props.widgetToEdit;
    if (isNew && wasEdit) {
      return {
        widget: getDefaultWidget()
      };
    }

    // No state update necessary
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const widgetToEdit = JSON.parse(JSON.stringify(this.props.widgetToEdit));
    if (shouldUpdateWidget(widgetToEdit, prevState)) {
      this.setState({ widget: widgetToEdit });
    }
  }

  updateWidget = widget => {
    if (!widget) return;
    this.setState({ widget });
  };

  render() {
    const {
      classes,
      isOpen,
      handleSave,
      handleClose,
      widgetToEdit,
      user
    } = this.props;
    const { widget } = this.state;
    const isEdit = !!widgetToEdit;
    const tabs = getWidgetEditTabs(widget, this.updateWidget, isOpen, user);

    return (
      <div>
        <Dialog
          classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modal + " " + classes.modalLarge
          }}
          open={isOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="small-modal-slide-title"
          aria-describedby="small-modal-slide-description"
        >
          <DialogTitle
            id="small-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            {isEdit ? "Update Widget" : "Create Widget"}
            <Button
              justIcon
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={handleClose}
            >
              <Close className={classes.modalClose} />
            </Button>
          </DialogTitle>
          <DialogContent
            id="small-modal-slide-description"
            className={classes.modalBody + " " + classes.modalSmallBody}
          >
            <CustomTabs title="Checklist:" headerColor="rose" tabs={tabs} />
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button
              onClick={handleClose}
              color="transparent"
              className={classes.modalSmallFooterFirstButton}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleSave(widget)}
              color="success"
              simple
              className={
                classes.modalSmallFooterFirstButton +
                " " +
                classes.modalSmallFooterSecondButton
              }
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(widgetModalStyle)(WidgetModal);
