import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Email from "@material-ui/icons/Email";

// core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Danger from "components/Typography/Danger.jsx";

// Services and Subcomponents
import { FORM_STATE, INPUT_TYPE } from "./loginVariables";

// assets
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

class ForgotPassword extends React.PureComponent {
  render() {
    const {
      classes,
      formInputState,
      updateInput,
      updateFormState,
      resetPassword,
      error
    } = this.props;

    return (
      <span>
        <CardBody>
          <CustomInput
            id="email"
            labelText="Email"
            success={formInputState[INPUT_TYPE.EMAIL] === "success"}
            error={formInputState[INPUT_TYPE.EMAIL] === "error"}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: "email",
              onChange: evt => updateInput(evt, INPUT_TYPE.EMAIL),
              endAdornment: (
                <InputAdornment position="end">
                  <Email className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
          {error ? <Danger>{error.message || error}</Danger> : null}
          <div className={classes.justifyContentCenter}>
            <div>
              <Button
                onClick={() => updateFormState(FORM_STATE.SIGN_IN)}
                color="info"
                simple
                size="sm"
              >
                Sign In
              </Button>
              |
              <Button
                onClick={() => updateFormState(FORM_STATE.NEW_PASSWORD)}
                color="info"
                simple
                size="sm"
              >
                Enter Code
              </Button>
            </div>
          </div>
        </CardBody>
        <CardFooter className={classes.justifyContentCenter}>
          <Button onClick={resetPassword} color="rose" simple size="lg" block>
            Send Reset Code
          </Button>
        </CardFooter>
      </span>
    );
  }
}

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(loginPageStyle)(ForgotPassword);
