import React from "react";

// @material-ui/core components
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// @material-ui/icons

// Components
import ChecklistSelector from "components/ChecklistSelector/ChecklistSelector";

// Services
import { LogbookMode, ReferenceTypes } from "services/LogbookService";

const ReferenceMenuItems = [
  {
    name: "None",
    value: ReferenceTypes.NONE
  },
  {
    name: "URL",
    value: ReferenceTypes.URL
  },
  {
    name: "Root Checklists",
    value: ReferenceTypes.ROOT_CHECKLISTS
  },
  {
    name: "User Most Recent Active",
    value: ReferenceTypes.USER_MOST_RECENT_ACTIVE
  }
];

const ReferenceType = ({ metadata, updateMetadata, classes, update, type }) => {
  return (
    <FormControl
      fullWidth
      className={classes.selectFormControl + " " + classes.left}
    >
      <InputLabel htmlFor="step-type-select" className={classes.iconLabel}>
        Global Checklist Reference
      </InputLabel>
      <Select
        MenuProps={{
          className: classes.selectMenu
        }}
        classes={{
          select: classes.select
        }}
        value={type}
        onChange={event => {
          const newGlobal = Object.assign({}, metadata.global, {
            reference: { type: event.target.value }
          });
          update("global", newGlobal, metadata, updateMetadata);
        }}
        inputProps={{
          name: "widgetTypeSelect",
          id: "widget-type-select-ref"
        }}
      >
        <MenuItem
          disabled
          classes={{
            root: classes.selectMenuItem
          }}
          value="1"
        >
          Reference Type
        </MenuItem>
        {ReferenceMenuItems.map(item => (
          <MenuItem
            key={item.value}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected
            }}
            value={item.value}
          >
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const Reference = props => {
  const { mode, metadata, updateMetadata, user, update } = props;
  const { reference } = metadata && metadata.global ? metadata.global : {};
  const type = reference ? reference.type : ReferenceTypes.NONE;
  if (mode === LogbookMode.VIEW) {
    return null;
  }

  const onChange = checklist => {
    const newReference = Object.assign({}, metadata.global.reference, {
      checklist: checklist ? checklist : {}
    });
    const newGlobal = Object.assign({}, metadata.global, {
      reference: newReference
    });
    update("global", newGlobal, metadata, updateMetadata);
  };

  return (
    <>
      <ReferenceType {...props} type={type} />
      {type === ReferenceTypes.NONE ? null : (
        <div>
          <br />
          <ChecklistSelector
            checklist={reference.checklist}
            onChange={onChange}
            user={user}
          />
        </div>
      )}
    </>
  );
};

export default Reference;
