import React, { useState, useRef, useEffect } from "react";

import InfiniteScroll from "react-infinite-scroll-component";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import ListSubheader from "@material-ui/core/ListSubheader";

// @material-ui/icons
import Search from "@material-ui/icons/Search";
import { BsHash } from "react-icons/bs";
import { GrLink } from "react-icons/gr";
import { TiWeatherPartlySunny } from "react-icons/ti";
import { BsClock } from "react-icons/bs";

import {
  NEW_STEP_MARKER,
  StepType,
  convertStep,
  addStepNoUpdate
} from "services/StepService";

const ActionInfo = {
  input: {
    name: "Basic Input Steps",
    type: "LIST_HEADER",
    divider: false
  },
  checkbox: {
    name: "Checkbox",
    icon: "assignment_turned_in",
    description: "Your standard todo checkbox",
    type: StepType.CHECKBOX
  },
  number_input: {
    name: "Number",
    icon: <BsHash />,
    description: "A numeric input",
    type: StepType.NUMBER_INPUT
  },
  single_line_text_input: {
    name: "Single Line Text",
    icon: "notes",
    description: "A single line text input",
    type: StepType.TEXT_INPUT
  },
  list_input: {
    name: "List",
    icon: "list",
    description: "A list of items",
    type: StepType.LIST_INPUT
  },
  url_input: {
    name: "URL",
    icon: "link",
    description: "A link input",
    type: StepType.LINK_INPUT
  },
  url_embed: {
    name: "Web Link",
    icon: "link",
    description: "Embed a link the internet",
    type: StepType.LINK_EMBED
  },
  code: {
    name: "Code",
    icon: "code",
    description: "Embed highlighted code",
    type: StepType.CODE_EMBED
  },
  date_time_range_input: {
    name: "Date Time",
    icon: "insert_invitation",
    description: "A date, time, or range input",
    type: StepType.DATE_TIME_INPUT
  },
  media: {
    name: "Media",
    type: "LIST_HEADER",
    divider: true
  },
  image_media_upload: {
    name: "Upload Image",
    icon: "photo_camera",
    description: "Take or upload an image",
    type: StepType.IMAGE_INPUT
  },
  image_media_display: {
    name: "Display Image",
    icon: "insert_photo",
    description: "Display an image",
    type: StepType.IMAGE_DISPLAY
  },
  advanced: {
    name: "Advanced",
    type: "LIST_HEADER",
    divider: true
  },
  embed: {
    name: "Embed",
    type: "LIST_HEADER",
    divider: true
  },
  linked_completed_checklist_advanced: {
    name: "Linked Checklist",
    icon: <GrLink />,
    description: "A completed checklist to link",
    type: StepType.COMPLETED_CHECKLIST
  },
  sub_child_checklist_advanced: {
    name: "Child Checklist",
    icon: "assignment",
    description: "A child checklist to run",
    type: StepType.SUB_CHECKLIST
  },
  timeclock: {
    name: "Time Clock",
    icon: <BsClock />,
    description: "A timer for your task or day",
    type: StepType.TIME_CLOCK
  },
  user: {
    name: "User",
    icon: "person_outline",
    description: "A user in your organization",
    type: StepType.USER
  },
  plugins: {
    name: "Plugins",
    type: "LIST_HEADER",
    divider: true
  }
};

const Actions = [
  "input",
  "checkbox",
  "single_line_text_input",
  "number_input",
  "date_time_range_input",
  "url_input",
  "list_input",
  "media",
  "image_media_upload",
  "image_media_display",
  "embed",
  "url_embed",
  "code",
  "advanced",
  "linked_completed_checklist_advanced",
  "sub_child_checklist_advanced",
  "user",
  "timeclock",
  "plugins"
];

// TODO: load this from db, with config and display from Plugin step type
const Plugins = [
  {
    name: "Weather",
    icon: <TiWeatherPartlySunny style={{ marginLeft: "2px" }} />,
    description: {
      short: "The current weather or a forcast"
    },
    id: "uuid_weather"
  }
];

// Load Plugins
const All_Actions = Plugins.reduce((_actions, plugin) => {
  const { id, name, icon, description } = plugin;
  ActionInfo[id] = {
    id,
    name: name,
    icon: icon,
    description: description.short,
    type: StepType.PLUGIN
  };
  _actions.push(plugin.id);
  return _actions;
}, Actions);

const styles = {
  listSubheader: {
    textTransform: "uppercase",
    fontSize: ".85em",
    padding: "8px 18px",
    lineHeight: "24px"
  },
  menuList: {
    padding: 0
  },
  menuItem: {
    minWidth: "300px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0)"
    }
  }
};

const listFilter = (action, filter) => {
  return (
    action.includes(filter.toLowerCase()) &&
    ActionInfo[action].type !== "LIST_HEADER"
  );
};

const getFilteredItems = (filter, onSelect, classes, selected, setSelected) => {
  const filteredItems = All_Actions.filter(action => {
    return listFilter(action, filter);
  }).map((action, i) => {
    const actionInfo = ActionInfo[action];
    const isSelected = selected.number === i ? true : false;
    return (
      <MenuItem
        key={actionInfo.name}
        className={classes.menuItem}
        selected={isSelected}
        onClick={() => onSelect(selected)}
        onMouseEnter={() => {
          setSelected({ number: i, action });
        }}
      >
        <ListItemIcon className={classes.icon}>
          {typeof actionInfo.icon === "string" ? (
            <Icon>{actionInfo.icon}</Icon>
          ) : (
            actionInfo.icon
          )}
        </ListItemIcon>
        <ListItemText
          classes={{ primary: classes.primary }}
          inset={false}
          primary={actionInfo.name}
          secondary={actionInfo.description}
        />
      </MenuItem>
    );
  });

  if (filteredItems.length === 0) {
    filteredItems.push(
      <MenuItem key={"no-results"} className={classes.menuItem}>
        <ListItemText
          classes={{ primary: classes.primary }}
          inset={false}
          primary={"No results match..."}
        />
      </MenuItem>
    );
  }
  return filteredItems;
};

const getItems = (range, onSelect, classes, selected, setSelected) => {
  const [start, stop] = range;
  return All_Actions.slice(start, stop).map((action, i) => {
    const actionInfo = ActionInfo[action];
    if (actionInfo.type === "LIST_HEADER") {
      return (
        <span key={actionInfo.name}>
          {actionInfo.divider ? (
            <Divider style={{ marginTop: "16px" }} />
          ) : null}
          <ListSubheader
            classes={{ root: classes.listSubheader }}
            disableSticky={true}
          >
            {actionInfo.name}
          </ListSubheader>
        </span>
      );
    }
    const isSelected = selected.number === i ? true : false;
    return (
      <MenuItem
        key={actionInfo.name}
        className={classes.menuItem}
        selected={isSelected}
        onClick={() => onSelect(selected)}
        onMouseEnter={() => {
          setSelected({ number: i, action });
        }}
      >
        <ListItemIcon className={classes.icon}>
          <Icon>{actionInfo.icon}</Icon>
        </ListItemIcon>
        <ListItemText
          classes={{ primary: classes.primary }}
          inset={false}
          primary={actionInfo.name}
          secondary={actionInfo.description}
        />
      </MenuItem>
    );
  });
};

const filteredItems = filter => {
  return filter && filter.length > 1
    ? All_Actions.filter(action => {
        return listFilter(action, filter);
      })
    : All_Actions;
};

const createStep = (
  selected,
  step,
  steps,
  updateSteps,
  setFocus,
  isTurnInto,
  closeStepMenu
) => {
  const stepInfo = ActionInfo[selected.action];
  if (step.name === NEW_STEP_MARKER || isTurnInto) {
    convertStep(step, stepInfo.type, steps, updateSteps);
    if (isTurnInto) {
      closeStepMenu();
    }
  } else {
    // Create a new step of the type
    const position = step.position + 1;
    const newSteps = addStepNoUpdate(steps, position, step, stepInfo.type);
    updateSteps(newSteps);
    setFocus(position); // Updates the focus to the new step
  }
};

// item is either step (if editing step name) or metadata (if editing checklist name)
const handleKeyDown = (
  e,
  selected,
  setSelected,
  filter,
  step,
  steps,
  updateSteps,
  setFocus,
  close,
  filterRef,
  isTurnInto,
  closeStepMenu
) => {
  let items;

  if (
    (e.keyCode >= 65 && e.keyCode <= 90) ||
    (e.keyCode >= 48 && e.keyCode <= 57)
  ) {
    filterRef.focus();
  }
  if (e.key === "Enter") {
    e.preventDefault();
    // Don't select if undefined (i.e. empty filter results);
    if (selected.action) {
      createStep(
        selected,
        step,
        steps,
        updateSteps,
        setFocus,
        isTurnInto,
        closeStepMenu
      );
      close();
    }
  }

  if (e.key === "ArrowUp") {
    // toggle menu items up
    e.preventDefault();
    items = filteredItems(filter);
    let prevNum = selected.number - 1;
    if (prevNum >= 0) {
      let action = items[prevNum];
      let step = ActionInfo[action];
      if (step && step.type === "LIST_HEADER") {
        prevNum = prevNum - 1;
        action = items[prevNum];
        step = ActionInfo[action];
      }
      if (prevNum >= 0) {
        setSelected({ number: prevNum, action });
      }
    }
  }
  if (e.key === "ArrowDown") {
    // toggle menu items down
    e.preventDefault();
    items = filteredItems(filter);
    let nextNum = selected.number + 1;
    if (nextNum < items.length) {
      let action = items[nextNum];
      let step = ActionInfo[action];
      if (step && step.type === "LIST_HEADER") {
        nextNum = nextNum + 1;
        action = items[nextNum];
        step = ActionInfo[action];
      }
      if (nextNum < items.length) {
        setSelected({ number: nextNum, action });
      }
    }
  }
};

/**
 * Action Menu - Used for Selected or Converting Step Types
 *
 * Distinct from Step Menu which is used for actions on Steps
 * Probably not the best naming
 *
 * Can be launched from '/' forward slash command inside a step title field
 *    If their is text in the title field, a new step of the selected type is created
 *    If the title field is blank, that step is converted into the selected type
 * Can be launched from the Step Menu Turn Into menu item
 *    Launching from Turn Into sets the isTurnInto Flag that changes some behvior
 *    and doesn't create a new step but converts the current step
 *
 * TODO (Later): Add arrow keys to step menu
 *
 */
const ActionMenu = ({
  step,
  steps,
  updateSteps,
  setFocus,
  close,
  isTurnInto,
  closeStepMenu,
  classes
}) => {
  const onSelect = selected => {
    createStep(
      selected,
      step,
      steps,
      updateSteps,
      setFocus,
      isTurnInto,
      closeStepMenu
    );
    close();
  };
  const menuRef = useRef(null);
  const [filterRef, setFilterRef] = useState(null);
  const [range, setRange] = useState([0, 100]);
  const [selected, setSelected] = useState({
    number: 1,
    action: "checkbox"
  });
  const [filter, setFilter] = useState("");
  const [priorFilter, setPriorFilter] = useState("");
  const items = getItems(range, onSelect, classes, selected, setSelected);
  const hasMore = range[1] < All_Actions.length;

  useEffect(() => {
    // Don't grab focus if opened from the step
    if (!isTurnInto) {
      menuRef.current.focus();
      setTimeout(
        () => menuRef && menuRef.current && menuRef.current.focus(),
        500
      ); // Need timeout so opened menu gets focus.
      setTimeout(
        () => menuRef && menuRef.current && menuRef.current.focus(),
        1000
      ); // Need extra because first doesn't always work.
      // Not sure what race condition is causing this.
    }
  }, []);

  if (priorFilter !== filter && filter.length > 1) {
    const filteredItems = filter => {
      return filter && filter.length > 1
        ? All_Actions.filter(action => {
            return listFilter(action, filter);
          })
        : All_Actions;
    };
    setPriorFilter(filter);
    setSelected({ number: 0, action: filteredItems[0] });
  }

  return (
    <div
      ref={menuRef}
      tabIndex="0"
      onKeyDown={e =>
        handleKeyDown(
          e,
          selected,
          setSelected,
          filter,
          step,
          steps,
          updateSteps,
          setFocus,
          close,
          filterRef,
          isTurnInto,
          closeStepMenu
        )
      }
    >
      <Paper>
        <TextField
          id="filled-simple-start-adornment"
          variant="filled"
          label="Filter"
          margin="dense"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ marginTop: "16px" }}>
                <Search />
              </InputAdornment>
            )
          }}
          autoComplete="off"
          fullWidth={true}
          style={{
            margin: "8px 0",
            padding: "0 8px"
          }}
          onChange={e => {
            setPriorFilter(filter);
            setFilter(e.target.value);
          }}
          inputRef={node => setFilterRef(node)}
        />
        <MenuList classes={{ root: classes.menuList }}>
          {filter && filter.length > 1 ? (
            <div style={{ height: "300px", padding: "8px" }}>
              {getFilteredItems(
                filter,
                onSelect,
                classes,
                selected,
                setSelected
              )}
            </div>
          ) : (
            <InfiniteScroll
              dataLength={items.length} //This is important field to render the next data
              next={() => {
                setRange([0, range[1] + 100]);
              }}
              hasMore={hasMore}
              loader={<h4>Loading...</h4>}
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
              height={300}
              style={{ padding: "8px" }}
            >
              {items}
            </InfiniteScroll>
          )}
        </MenuList>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(ActionMenu);
