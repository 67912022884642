/* eslint no-unused-vars: "off" */

/**
 * SITE CONFIG
 *
 * All the necessary parts to configure a site:
 * Checklist Zen, Airspace Ops, etc.
 */

// Airspace logo is in assets
import logo from "assets/img/logo-white.png";

import Checklists from "views/Checklists/Checklists.jsx";
import Dashboards from "views/Dashboards/Dashboards.jsx";
import MyIngredients from "views/Other/MyPantryShelf/Ingredients";

// @material-ui/icons
import GridOn from "@material-ui/icons/GridOnOutlined";

/////////////////////////////
// Navigation Links
/////////////////////////////

const ChecklistsRoute = {
  path: "/app/checklists",
  name: "Checklists",
  id: "checklists",
  icon: "content_paste",
  component: Checklists,
  leftNav: true
};

const DashboardsRoute = {
  path: "/app/dashboards",
  name: "Dashboards",
  id: "dashboards",
  icon: GridOn,
  component: Dashboards,
  leftNav: true
};

const MyIngredientsRoute = {
  path: "/app/my-ingredients",
  name: "My Ingredients",
  id: "my-ingredients",
  icon: "list",
  component: MyIngredients,
  leftNav: true
};

/////////////////////////////
// SITE CONFIGURATIONS
////////////////////////////

const MyPantryShelf = {
  name: "My Pantry Shelf",
  logo,
  leftNav: [ChecklistsRoute, DashboardsRoute, MyIngredientsRoute]
};

export default MyPantryShelf;
