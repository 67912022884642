import React from "react";

// Components

// Services

const Version = ({ metadata }) => {
  const version = metadata.template
    ? metadata.template.version
    : metadata.version;

  if (!version) {
    return null;
  }

  return <div>version: {version}</div>;
};

export default Version;
