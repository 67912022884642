import React from "react";
import cx from "classnames";
import { Redirect } from "react-router-dom";
// import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import Joyride from "react-joyride";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// Components
import Header from "components/Header/Header.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

// Subcomponents
import getDashboardRoutes from "./app_dashboard_routes";
import DashboardRoutes from "./AppDashboardSwitch";

// Services
import { steps } from "services/tourSteps.js";

// Assets
import appDashboardStyle from "assets/jss/material-dashboard-pro-react/routes/appDashboardStyle.jsx";
import image from "assets/img/sidebar-2.jpg";

// var ps;

class Dashboard extends React.Component {
  mainPanel = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      runTour: false,
      mobileOpen: false,
      miniActive: false
    };
    this.resizeFunction = this.resizeFunction.bind(this);
  }

  componentDidMount() {
    // if (navigator.platform.indexOf("Win") > -1) {
    //   this.initializePerfectScrollbar();
    // }
    window.addEventListener("resize", this.resizeFunction);
  }

  componentWillUnmount() {
    // if (navigator.platform.indexOf("Win") > -1 && ps) {
    //   ps.destroy();
    // }
    window.removeEventListener("resize", this.resizeFunction);
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.mainPanel.current.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  // initializePerfectScrollbar = () => {
  //   console.log("this.mainPanel", this.mainPanel);
  //   if (this.mainPanel) {
  //     ps = new PerfectScrollbar(this.mainPanel, {
  //       suppressScrollX: true,
  //       suppressScrollY: false
  //     });
  //     document.body.style.overflow = "hidden";
  //   }
  //   // Could add retry if no this.mainPanel, but for now just don't use perfect scrollbar
  // };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  getRoute() {
    return this.props.location.pathname !== "/maps/full-screen-maps";
  }

  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }

  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }

  tourChange = state => {
    const { action } = state;
    if (action === "reset" || action === "close") {
      this.setState({ runTour: false });
    }
  };

  render() {
    const { classes, ...rest } = this.props;
    const { user } = rest;
    const { runTour } = this.state;
    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
      });
    if (!user.loading && !user.username) {
      return (
        <Redirect
          push
          to={{
            pathname: "/pages/login-page",
            state: { referrer: this.props.location }
          }}
        />
      );
    }

    // Need site config to set left nav and icon / name in side bar
    if (!user || user.loading || !user.siteConfig) {
      return null;
    }

    const dashboardRoutes = getDashboardRoutes(user.siteConfig.leftNav);
    return (
      <div className={classes.wrapper}>
        <Joyride
          steps={steps}
          continuous={true}
          run={runTour}
          floaterProps={{
            styles: {
              wrapper: {
                zIndex: 10000
              }
            }
          }}
          styles={{
            options: {
              zIndex: 10000,
              primaryColor: "#00acc1",
              textAlign: "left"
            }
          }}
          callback={this.tourChange}
        />
        <Sidebar
          routes={dashboardRoutes.filter(route => route.leftNav)}
          image={image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color="blue"
          bgColor="black"
          miniActive={this.state.miniActive}
          runTour={() => this.setState({ runTour: true })}
          {...rest}
        />
        <div className={mainPanel} ref={this.mainPanel}>
          <Header
            sidebarMinimize={this.sidebarMinimize.bind(this)}
            miniActive={this.state.miniActive}
            routes={dashboardRoutes}
            handleDrawerToggle={this.handleDrawerToggle}
            runTour={() => this.setState({ runTour: true })}
            {...rest}
          />
          {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {/* {this.getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>
                <SwitchRoutes user={user} />
              </div>
            </div>
          ) : (
            <div className={classes.map}>
              <SwitchRoutes user={user} />
            </div>
          )} */}
          <div className={classes.content}>
            <div className={classes.container}>
              <DashboardRoutes user={user} {...rest} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(appDashboardStyle)(Dashboard);
