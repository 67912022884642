/** Import React and 3rd party libs */
import React from "react";

// @material-ui/core components
/** Import material ui components */

// @material-ui/icons
/** Import material ui icons */

// Components
import CustomInput from "components/CustomInput/CustomInput.jsx";

// Subcomponents
/** Import components used only by this component - relative path imports */

// Services
/** Import all services and variables */

// Queries
/** Import all graphql queries */

// Assets
/** Import styles */

// Variables
/** Declare all constant variables */

// Functions
const updateNote = (step, event) => {
  let newStep = Object.assign({}, step);
  newStep.note = event.target.value;
  return newStep;
};

/**
 * Declare JSX Component - class or
 */
const StepNote = ({ step, updateStep }) => {
  return (
    <CustomInput
      labelText="Note"
      id="note"
      formControlProps={{
        fullWidth: true
      }}
      inputProps={{
        onChange: event => updateStep(updateNote(step, event)),
        type: "text",
        value: step.note || "",
        multiline: true,
        rows: "4"
      }}
    />
  );
};

// Data Connectors

export default StepNote;
