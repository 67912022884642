import React from "react";
import { Auth } from "aws-amplify";

// Federated sign in from Google
class LoginWithGoogle extends React.Component {
  constructor(props) {
    super(props);
    this.signIn = this.signIn.bind(this);
  }

  signIn() {
    Auth.federatedSignIn({ provider: "Google" });
  }

  render() {
    return <span onClick={this.signIn}>{this.props.children}</span>;
  }
}

export default LoginWithGoogle;
