/** Import React and 3rd party libs */
import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Cancel from "@material-ui/icons/Cancel";
import Done from "@material-ui/icons/Done";
import Edit from "@material-ui/icons/Edit";
import FileCopy from "@material-ui/icons/FileCopy";

// Components
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";

// Subcomponents
/** Import components used only by this component - relative path imports */

// Services

// Queries
/** Import all graphql queries */

// Assets
import logbookStyle from "assets/jss/material-dashboard-pro-react/components/logbookStyle";

// Variables
/** Declare all constant variables */

// Functions
/** Declare all functions - utility followed by JSX*/

/**
 * Declare JSX Component - class or
 */
const DashboardButtons = ({
  classes,
  logbookFn,
  showDetails,
  setShowDetails
}) => {
  const showCopyButton = true;
  const showDetailsButton = !showDetails;
  const showSaveButton = true;

  const buttons = [];

  const copyButton = (
    <GridItem key="copy_button">
      <Button
        color="info"
        size="sm"
        className={classes.marginRight}
        onClick={logbookFn.copyLogbook}
      >
        <FileCopy className={classes.icons} /> Copy Dashboard
      </Button>
    </GridItem>
  );
  const detailsButton = (
    <GridItem key="edit_button">
      <Button
        color="info"
        size="sm"
        className={classes.marginRight}
        onClick={() => setShowDetails(true)}
      >
        <Edit className={classes.icons} /> Edit Details
      </Button>
    </GridItem>
  );
  const saveButton = (
    <GridItem key="save_button">
      <Button
        color="info"
        size="sm"
        className={classes.marginRight}
        onClick={logbookFn.updateLogbook}
      >
        <Done className={classes.icons} /> Save Dashboard
      </Button>
    </GridItem>
  );
  const cancelButton = (
    <GridItem key="cancel_button">
      <Button
        color="info"
        size="sm"
        className={classes.marginRight}
        onClick={() => setShowDetails(false)}
      >
        <Cancel className={classes.icons} /> Hide Details
      </Button>
    </GridItem>
  );

  if (showDetailsButton) {
    buttons.push(detailsButton);
  } else {
    buttons.push(cancelButton);
  }

  if (showSaveButton) {
    buttons.push(saveButton);
  }
  if (showCopyButton) {
    buttons.push(copyButton);
  }

  return buttons;
};

// Data Connectors

export default withStyles(logbookStyle)(DashboardButtons);
