import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// Components
import PagesHeader from "components/Header/PagesHeader.jsx";
import Footer from "components/Footer/Footer.jsx";
import TermsWrapper from "components/Terms/TermsWrapper";

// Subcomponents
import pagesRoutes from "./landing_pages_routes";
import InfoPage from "views/Pages/InfoPage.jsx";

// Assets
import pagesStyle from "assets/jss/material-dashboard-pro-react/routes/pagesStyle.jsx";

class Pages extends React.Component {
  constructor(props) {
    super(props);
    this.fullPage = React.createRef();
    this.state = {
      scrolled: false
    };
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.body.style.overflow = "unset";
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.fullPage.current.scrollTo(0, 0);
    }
  }

  render() {
    const { classes, ...rest } = this.props;
    return (
      <div className={classes.wrapper} ref={this.wrapperRef}>
        <div className={classes.pageContainer}>
          <div
            className={classes.fullPage}
            onScroll={() => {
              if (
                !this.state.scrolled &&
                rest.location.pathname === "/pages/info-page"
              ) {
                this.setState({ scrolled: true });
              }
            }}
            ref={this.fullPage}
          >
            <PagesHeader {...rest} />
            <Switch>
              {pagesRoutes.map((prop, key) => {
                if (
                  rest.user.username &&
                  (prop.name === "Login Page" || prop.name === "Register Page")
                ) {
                  return null;
                }
                if (prop.collapse) {
                  return null;
                }
                if (prop.redirect) {
                  return (
                    <Redirect from={prop.path} to={prop.pathTo} key={key} />
                  );
                }
                if (prop.name === "Info Page") {
                  return (
                    <Route
                      path={prop.path}
                      component={props => (
                        <InfoPage
                          {...props}
                          user={rest.user}
                          scrolled={this.state.scrolled}
                        />
                      )}
                      key={key}
                    />
                  );
                }
                if (
                  prop.name === "Terms Page" ||
                  prop.name === "Privacy Page" ||
                  prop.name === "Data Deletion Page"
                ) {
                  return (
                    <Route
                      key={key}
                      path={prop.path}
                      component={props => (
                        <TermsWrapper name={prop.name}>
                          <prop.component {...props} />
                        </TermsWrapper>
                      )}
                    />
                  );
                }
                return (
                  <Route
                    key={key}
                    path={prop.path}
                    component={props => (
                      <prop.component {...props} user={rest.user} />
                    )}
                  />
                );
              })}
            </Switch>
            <Footer white />
          </div>
        </div>
      </div>
    );
  }
}

Pages.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(pagesStyle)(Pages);
