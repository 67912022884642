import _ from "lodash";
import { graphql } from "react-apollo";
import queryString from "query-string";

import {
  CreateChecklistTemplateMutation,
  IndexChecklistTemplateMutation,
  CreateChecklistInstanceMutation,
  DeleteChecklistTemplateMutation,
  BatchDeleteChecklistTemplateMutation,
  DeleteChecklistInstanceMutation,
  AddEventMutation
} from "api/mutations";
import {
  ChecklistTemplateQuery,
  ChecklistInstanceQuery,
  ChecklistTemplatesQuery,
  ChecklistInstancesQuery
} from "api/queries";

export const ChecklistTemplate = graphql(ChecklistTemplateQuery, {
  options: props => {
    const { location, user } = props;
    const { search } = location;
    let cleanSearch = search;
    while (cleanSearch.startsWith("?")) {
      cleanSearch = cleanSearch.slice(1);
    }
    const orgId = user.currentOrganization.id;
    const value = queryString.parse(cleanSearch);
    const id = value.id;
    return {
      fetchPolicy: "network-only",
      variables: {
        orgId,
        id
      }
    };
  },
  skip: props => {
    const { location } = props;
    const { search } = location;
    let cleanSearch = search;
    while (cleanSearch.startsWith("?")) {
      cleanSearch = cleanSearch.slice(1);
    }
    const value = queryString.parse(cleanSearch);
    const id = value.id;
    const isInstance = value.instance;
    return !id || isInstance;
  }
});

export const ChecklistInstance = graphql(ChecklistInstanceQuery, {
  options: props => {
    const { location, user } = props;
    const { search } = location;
    let cleanSearch = search;
    while (cleanSearch.startsWith("?")) {
      cleanSearch = cleanSearch.slice(1);
    }
    const orgId = user.currentOrganization.id;
    const value = queryString.parse(cleanSearch);
    const id = value.id;
    return {
      fetchPolicy: "network-only",
      variables: {
        orgId,
        id
      }
    };
  },
  skip: props => {
    const { location } = props;
    const { search } = location;
    let cleanSearch = search;
    while (cleanSearch.startsWith("?")) {
      cleanSearch = cleanSearch.slice(1);
    }
    const value = queryString.parse(cleanSearch);
    const id = value.id;
    const isInstance = value.instance;
    return !id || !isInstance;
  }
});

export const CreateChecklistTemplate = graphql(
  CreateChecklistTemplateMutation,
  {
    props: props => ({
      createChecklistTemplate: (orgId, metadata, steps, id) => {
        return props.mutate({
          variables: {
            orgId,
            metadata,
            steps,
            id
          },
          update: (proxy, { data }) => {
            const { createChecklistTemplate: checklist } = data;
            // Don't update if checklist is null
            // Sometimes data is empty object?!? even though update calls always return normally
            // Seems to happen with lots of quick updates (i.e. typing)
            if (!checklist) return;

            // Update Templates query
            try {
              // Errors here when can't read, even if data is in Cache?
              // Link down below says it's due to different fields?
              const cachedData = proxy.readQuery({
                query: ChecklistTemplatesQuery,
                variables: {
                  orgId
                }
              });

              // Need to filter because called twice
              // https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/282
              // Only set if cached exists since errros if getChecklistTemplates hasn't been called
              if (cachedData.getChecklistTemplates) {
                cachedData.getChecklistTemplates.items = [
                  ...cachedData.getChecklistTemplates.items.filter(
                    e => e.id !== checklist.id
                  ),
                  checklist
                ];
              }

              proxy.writeQuery({
                query: ChecklistTemplatesQuery,
                variables: {
                  orgId
                },
                data: cachedData
              });
            } catch (error) {
              // NOOP - Apollo has an error where a MissingFieldError is thrown if the query doesn't exist in the cache
              // Fix with upgrade to 3.3. which is dependent on appsync
              // https://www.apollographql.com/docs/react/caching/cache-interaction/

              // Issue with updating schema.  If add a field, it may not exist in the cache which can cause errors
              // Reseting the store doesn't seem to clear the cache but prevents errors such as the failed templates
              // query resulting in missing items in the left nav.
              if (error.message.startsWith("Can't find field")) {
                const { client } = props.ownProps.user;
                if (!error.message.endsWith("on object undefined.")) {
                  // This seems
                  console.log("--- ERROR CLEARING STORE ---");
                  console.log(error.message);
                  // Current Bug -> clear & reset store don't flush the cache.
                  // Need to manually update appsync-metadata which is store in redux-offline
                  // https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/591
                  // May be tied to issues with Apollo 2.6 and offline
                  // May need to switch to offlineDisabled: true
                  client.store.cache.store.getState()[
                    "appsync-metadata"
                  ].snapshot.cache = {};
                  // client.clearStore()
                  client.clearStore();
                } else {
                  // TODO TODO TODO: Need to Fix this.   or Refactor Solution
                  // Or Possibly issue with fields: https://github.com/apollographql/apollo-client/issues/1701
                  // The problem was my optimistic response didn't contain the same fields as the ones in the strore. Which seems weird but that fixed it.
                  // One Problem:
                  //  https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/448
                  // Options:
                  //  1. Disable Offline (not long term, won't work for mobile)
                  //  2. Wait -> ? Need to decide by mobile app, will impact perf
                  //  3. Switch to Apollo Server -> Keep Apollo Client, upgrade to 3.0
                  //  4. Keep Appsync -> Switch to Amplify Datastore
                  // Big Fix:
                  //   - Think through users, orgs, permissions, sharing, collaboration, query patterns, offline
                  console.log("--- ERROR READING rootQuery in CACHE ---");
                  console.log(error.message);
                  // client.resetStore();
                }
              }
              return null;
            }
          }
        });
      }
    })
  }
);

export const CreateChecklistInstance = graphql(
  CreateChecklistInstanceMutation,
  {
    props: ({ mutate }) => ({
      createChecklistInstance: (orgId, metadata, steps, id) => {
        return mutate({
          variables: {
            orgId,
            metadata,
            steps,
            id
          },
          update: (proxy, { data }) => {
            const { createChecklistInstance: checklist } = data;

            // Don't update if checklist is null
            // Sometimes data is empty object?!? even though update calls always return normally
            // Seems to happen with lots of quick updates (i.e. typing)
            if (!checklist) return;

            // Update My Instances
            try {
              const cachedData = proxy.readQuery({
                query: ChecklistInstancesQuery,
                variables: {
                  orgId
                }
              });

              // Need to filter because called twice
              // https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/282
              // Only set if cached exists since errros if getChecklistInstances hasn't been called
              if (cachedData.getChecklistInstances) {
                cachedData.getChecklistInstances.items = [
                  ...cachedData.getChecklistInstances.items.filter(e => {
                    return e.id !== checklist.id;
                  }),
                  checklist
                ];
              }

              proxy.writeQuery({
                query: ChecklistInstancesQuery,
                variables: {
                  orgId
                },
                data: cachedData
              });
            } catch (error) {
              // NOOP - Apollo has an error where a MissingFieldError is thrown if the query doesn't exist in the cachd
              // Fix with upgrade to 3.3. which is dependent on appsync
              // https://www.apollographql.com/docs/react/caching/cache-interaction/
            }
          }
        });
      }
    })
  }
);

// TODO: Not used, Dynamo streams to ES on Dynamo CRUD
export const IndexChecklistTemplate = graphql(IndexChecklistTemplateMutation, {
  props: ({ mutate }) => ({
    indexChecklistTemplate: (id, metadata) => {
      return mutate({
        variables: {
          id,
          metadata
        }
      });
    }
  })
});

export const ChecklistTemplates = graphql(ChecklistTemplatesQuery, {
  options: props => {
    return {
      fetchPolicy: "network-only",
      variables: {
        orgId: props.user.currentOrganization.id
      }
    };
  },
  skip: props => {
    const { location } = props;
    const { search } = location;
    const value = queryString.parse(search);
    const type = value.type;
    return type && type !== "template";
  }
});

export const ChecklistInstances = graphql(ChecklistInstancesQuery, {
  options: props => ({
    fetchPolicy: "network-only",
    variables: {
      orgId: props.user.currentOrganization.id
    }
  }),
  skip: props => {
    const { location } = props;
    const { search } = location;
    const value = queryString.parse(search);
    const type = value.type;
    return !type || type !== "instance";
  }
});

export const DeleteChecklistTemplate = graphql(
  DeleteChecklistTemplateMutation,
  {
    props: ({ mutate }) => ({
      deleteChecklistTemplate: (orgId, id, version) => {
        return mutate({
          variables: {
            orgId,
            id,
            version
          },
          update: (proxy, { data }) => {
            const { deleteChecklistTemplate: checklist } = data;

            try {
              // need clone: https://github.com/apollographql/apollo-client/issues/3909
              const cachedDataQuery = _.cloneDeep(
                proxy.readQuery({
                  query: ChecklistTemplatesQuery,
                  variables: {
                    orgId
                  }
                })
              );

              // Filter out checklist
              cachedDataQuery.getChecklistTemplates.items = [
                ...cachedDataQuery.getChecklistTemplates.items.filter(
                  e => e.id !== checklist.id
                )
              ];

              proxy.writeQuery({
                query: ChecklistTemplatesQuery,
                variables: {
                  orgId
                },
                data: cachedDataQuery
              });
            } catch (error) {
              // NOOP - Apollo has an error where a MissingFieldError is thrown if the query doesn't exist in the cachd
              // Fix with upgrade to 3.3. which is dependent on appsync
              // https://www.apollographql.com/docs/react/caching/cache-interaction/
            }
          }
        });
      }
    })
  }
);

export const BatchDeleteChecklistTemplate = graphql(
  BatchDeleteChecklistTemplateMutation,
  {
    props: ({ mutate }) => ({
      batchDeleteChecklistTemplate: (orgId, checklists) => {
        return mutate({
          variables: {
            orgId,
            checklists
          },
          update: (proxy, { data }) => {
            const { batchDeleteChecklistTemplate: returnedChecklists } = data;
            const checklistIds = returnedChecklists.map(
              checklist => checklist.id
            );

            try {
              // need clone: https://github.com/apollographql/apollo-client/issues/3909
              const cachedDataQuery = _.cloneDeep(
                proxy.readQuery({
                  query: ChecklistTemplatesQuery,
                  variables: {
                    orgId
                  }
                })
              );

              // Filter out checklist
              cachedDataQuery.getChecklistTemplates.items = [
                ...cachedDataQuery.getChecklistTemplates.items.filter(
                  e => !checklistIds.includes(e.id)
                )
              ];

              proxy.writeQuery({
                query: ChecklistTemplatesQuery,
                variables: {
                  orgId
                },
                data: cachedDataQuery
              });
            } catch (error) {
              // NOOP - Apollo has an error where a MissingFieldError is thrown if the query doesn't exist in the cachd
              // Fix with upgrade to 3.3. which is dependent on appsync
              // https://www.apollographql.com/docs/react/caching/cache-interaction/
            }
          }
        });
      }
    })
  }
);

export const DeleteChecklistInstance = graphql(
  DeleteChecklistInstanceMutation,
  {
    props: ({ mutate }) => ({
      deleteChecklistInstance: (orgId, id, version) => {
        return mutate({
          variables: {
            orgId,
            id,
            version
          },
          update: (proxy, { data }) => {
            const { deleteChecklistInstance: checklist } = data;
            // need clone: https://github.com/apollographql/apollo-client/issues/3909
            try {
              const cachedDataQuery = _.cloneDeep(
                proxy.readQuery({
                  query: ChecklistInstancesQuery,
                  variables: {
                    orgId
                  }
                })
              );
              // const cachedDataFind = _.cloneDeep(
              //   proxy.readQuery({
              //     query: FindInstancesByTemplateIdQuery,
              //     variables: {
              //       id
              //     }
              //   })
              // );

              // Filter out checklist
              cachedDataQuery.getChecklistInstances.items = [
                ...cachedDataQuery.getChecklistInstances.items.filter(
                  e => e.id !== checklist.id
                )
              ];
              // cachedDataFind.getChecklistTemplates.items = [
              //   ...cachedDataFind.getChecklistTemplates.items.filter(
              //     e => e.id !== checklist.id
              //   )
              // ];

              proxy.writeQuery({
                query: ChecklistInstancesQuery,
                variables: {
                  orgId
                },
                data: cachedDataQuery
              });
              // proxy.writeQuery({
              //   query: FindInstancesByTemplateIdQuery,
              //   variables: {
              //     id
              //   },
              //   data: cachedDataFind
              // });
            } catch (error) {
              // NOOP - Apollo has an error where a MissingFieldError is thrown if the query doesn't exist in the cachd
              // Fix with upgrade to 3.3. which is dependent on appsync
              // https://www.apollographql.com/docs/react/caching/cache-interaction/
            }
          }
        });
      }
    })
  }
);

// TODO: figure out optimistic updates
export const AddEvent = graphql(AddEventMutation, {
  props: ({ mutate }) => ({
    addEvent: event => {
      return mutate({
        variables: {
          event
        }
      });
    }
  })
});
