import gql from "graphql-tag";
import { User } from "../fragments";

export const MyProfileQuery = gql`
  query MyProfileQuery {
    getMyProfile {
      ...UserData
    }
  }
  ${User.data}
`;
