import React from "react";
import { S3Image } from "aws-amplify-react"; // Amplify S3 Image Viewer

import { LeaderboardStyle, imageTheme } from "../leaderboardStyle";

const Header = ({ aTemplate }) => {
  if (!aTemplate) return null;
  const steps = aTemplate.steps.map(step => {
    return (
      <th key={step.stepId} style={LeaderboardStyle.headerCell}>
        {step.icon ? <S3Image imgKey={step.icon} theme={imageTheme} /> : null}
        {step.name}
      </th>
    );
  });
  return (
    <thead>
      <tr style={LeaderboardStyle.headerRow}>
        <td>&nbsp;</td>
        {steps}
      </tr>
    </thead>
  );
};

export default Header;
