import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

// @material-ui/icons
// Components
// Subcomponents

// Services
import {
  neverRequired,
  StepType,
  NEW_DESCRIPTION_MARKER
} from "services/StepService";

// Queries

// Assetss
import stepsStyle from "assets/jss/material-dashboard-pro-react/components/stepsStyle.jsx";

///////////////////////////////////////
// DEPRECATED for Action Menu
///////////////////////////////////////

// Variables
export const StepTypeMenuItems = [
  {
    name: "Checkbox",
    value: StepType.CHECKBOX
  },
  {
    name: "Note",
    value: StepType.NOTE
  },
  {
    name: "Caution",
    value: StepType.CAUTION
  },
  {
    name: "Warning",
    value: StepType.WARNING
  },
  {
    name: "Text Input",
    value: StepType.TEXT_INPUT
  },
  {
    name: "Number Input",
    value: StepType.NUMBER_INPUT
  },
  {
    name: "Link Input",
    value: StepType.LINK_INPUT
  },
  {
    name: "Image Input",
    value: StepType.IMAGE_INPUT
  },
  {
    name: "Image Display",
    value: StepType.IMAGE_DISPLAY
  },
  // {
  //   name: "Upload Input",
  //   value: StepType.UPLOAD_INPUT
  // },
  {
    name: "Date Time Input",
    value: StepType.DATE_TIME_INPUT
  },
  {
    name: "List Input",
    value: StepType.LIST_INPUT
  },
  // {
  //   name: "Select Input",
  //   value: StepType.SELECT_INPUT
  // },
  {
    name: "Completed Checklist",
    value: StepType.COMPLETED_CHECKLIST
  },
  {
    name: "Child Checklist",
    value: StepType.SUB_CHECKLIST
  },
  {
    name: "Calculated",
    value: StepType.CALCULATED
  },
  {
    name: "Plugin",
    value: StepType.PLUGIN
  }
];

// Functions
// const updateStepType = (step, event) => {
//   const newStep = Object.assign({}, step);
//   newStep.type = event.target.value;
//   newStep.isRequired = neverRequired(newStep) ? false : true;
//   return newStep;
// };

const togglePublicCheckbox = step => {
  const newStep = Object.assign({}, step);
  newStep.isRequired = !newStep.isRequired;
  return newStep;
};

const toggleDescription = step => {
  const newStep = Object.assign({}, step);
  newStep.description = newStep.description ? "" : NEW_DESCRIPTION_MARKER;
  return newStep;
};

/**
 * Type selector for a Step
 */
const StepTypeEdit = ({ classes, step, updateStep }) => {
  return (
    <FormControl fullWidth className={classes.selectFormControl}>
      {/* <InputLabel htmlFor="step-type-select" className={classes.selectLabel}>
        Step Type
      </InputLabel>
      <Select
        MenuProps={{
          className: classes.selectMenu
        }}
        classes={{
          select: classes.select
        }}
        value={step.type}
        onChange={event => updateStep(updateStepType(step, event))}
        inputProps={{
          name: "stepTypeSelect",
          id: "step-type-select"
        }}
      >
        <MenuItem
          disabled
          classes={{
            root: classes.selectMenuItem
          }}
          value="1"
        >
          Step Type
        </MenuItem>
        {StepTypeMenuItems.map(item => (
          <MenuItem
            key={item.value}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected
            }}
            value={item.value}
          >
            {item.name}
          </MenuItem>
        ))}
      </Select>
      <br /> */}
      <FormControlLabel
        control={
          <Switch
            checked={step.isRequired}
            onChange={() => updateStep(togglePublicCheckbox(step))}
            value="isPublic"
            classes={{
              switchBase: classes.switchBase,
              checked: classes.switchChecked,
              thumb: classes.switchIcon,
              track: classes.switchBar
            }}
            inputProps={{
              disabled: neverRequired(step)
            }}
          />
        }
        classes={{
          label: classes.label
        }}
        label="Required"
      />
      <br />
      <FormControlLabel
        control={
          <Switch
            checked={!!step.description}
            onChange={() => updateStep(toggleDescription(step))}
            value="description"
            classes={{
              switchBase: classes.switchBase,
              checked: classes.switchChecked,
              thumb: classes.switchIcon,
              track: classes.switchBar
            }}
            inputProps={{
              disabled: neverRequired(step)
            }}
          />
        }
        classes={{
          label: classes.label
        }}
        label="Description"
      />
    </FormControl>
  );
};

export default withStyles(stepsStyle)(StepTypeEdit);
