/* eslint no-unused-vars: "off" */

/**
 * SITE CONFIG
 *
 * All the necessary parts to configure a site:
 * Checklist Zen, Airspace Ops, etc.
 */

// inidividual site configurations
import AIRSPACE from "./airspace";
import CHECKLIST_ZEN from "./checklist-zen";
import DRONE_OPS from "./drone-ops";
import FAMILY_LEADERBOARD from "./family-leaderboard";
import EEG_TECH from "./eeg-tech";
import PROPERTY_OPS from "./property-ops";
import ROOFING_OPS from "./roofing-ops";
import TEST_ORG_PRIVATE from "./test-org-private";
import TEST_ORG_PUBLIC from "./test-org-public";
import MY_PANTRY_SHELF from "./my-pantry-shelf";

/**
 * Return the site configuration for the specified org
 *
 * These site / org ids tie the static data files from the backend
 * to the hardcoded site configurations
 */
export const getConfig = currentOrgId => {
  switch (currentOrgId) {
    case "AIR00000-0000-0000-0000-000000000000":
      return AIRSPACE;
    case "CZ000000-0000-0000-0000-000000000000":
      return CHECKLIST_ZEN;
    case "DRONE000-0000-0000-0000-000000000000":
      return DRONE_OPS;
    case "FAM00000-0000-0000-0000-000000000000":
      return FAMILY_LEADERBOARD;
    case "EEG00000-0000-0000-0000-000000000000":
      return EEG_TECH;
    case "PROPERTY-0000-0000-0000-000000000000":
      return PROPERTY_OPS;
    case "ROOF0000-0000-0000-0000-000000000000":
      return ROOFING_OPS;
    case "TESTPRI0-0000-0000-0000-000000000000":
      return TEST_ORG_PRIVATE;
    case "TESTPUB0-0000-0000-0000-000000000000":
      return TEST_ORG_PUBLIC;
    case "MYPANTRY-0000-0000-0000-000000000000":
      return MY_PANTRY_SHELF;
    default:
      // TODO: return org configuration from database
      return AIRSPACE;
  }
};
