import React from "react";

// @material-ui/core components
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// @material-ui/icons

// Components
import Button from "components/CustomButtons/Button.jsx";

// Services
import { LogbookMode, DateRangeTypes } from "services/LogbookService";

const RangesMenuItems = [
  {
    name: "None",
    value: DateRangeTypes.NONE
  },
  {
    name: "Day",
    value: DateRangeTypes.DAY
  },
  {
    name: "Week",
    value: DateRangeTypes.WEEK
  },
  {
    name: "Month",
    value: DateRangeTypes.MONTH
  },
  {
    name: "Year",
    value: DateRangeTypes.YEAR
  },
  {
    name: "Custom",
    value: DateRangeTypes.CUSTOM
  },
  {
    name: "Selector",
    value: DateRangeTypes.SELECTOR
  },
  {
    name: "Custom Selector",
    value: DateRangeTypes.CUSTOM_SELECTOR
  }
];

const getColor = (globalSettings, rangeType) => {
  if (!globalSettings.globalDateRange && rangeType === DateRangeTypes.DAY) {
    return "info_solid";
  }
  return globalSettings.globalDateRange === rangeType
    ? "info_solid"
    : "info_outline";
};

const Selector = ({ classes, globalSettings, updateGlobalSettings }) => {
  const update = rangeType => {
    updateGlobalSettings(
      Object.assign({}, globalSettings, {
        globalDateRange: rangeType
      })
    );
  };
  return (
    <div className={classes.buttonGroup + " " + classes.buttonGroupZ}>
      <Button
        color={getColor(globalSettings, DateRangeTypes.DAY)}
        onClick={() => update(DateRangeTypes.DAY)}
        size="sm"
        round
        className={classes.firstButton}
      >
        Day
      </Button>
      <Button
        color={getColor(globalSettings, DateRangeTypes.WEEK)}
        onClick={() => update(DateRangeTypes.WEEK)}
        size="sm"
        round
        className={classes.middleButton}
      >
        Week
      </Button>
      <Button
        color={getColor(globalSettings, DateRangeTypes.MONTH)}
        onClick={() => update(DateRangeTypes.WEEK)}
        size="sm"
        round
        className={classes.lastButton}
      >
        Month
      </Button>
    </div>
  );
};

const DateRanges = ({
  metadata,
  updateMetadata,
  mode,
  classes,
  globalSettings,
  updateGlobalSettings,
  update
}) => {
  const { dateRange: globalDateRange } =
    metadata && metadata.global ? metadata.global : {};
  const type = globalDateRange ? globalDateRange.type : DateRangeTypes.NONE;
  if (mode === LogbookMode.VIEW) {
    switch (type) {
      case DateRangeTypes.NONE:
        return null;
      case DateRangeTypes.DAY:
        return "Global Range: Day";
      case DateRangeTypes.WEEK:
        return "Global Range: Week";
      case DateRangeTypes.MONTH:
        return "Global Range: Month";
      case DateRangeTypes.YEAR:
        return "Global Range: Year";
      case DateRangeTypes.CUSTOM:
        return "TODO: Global Range: Custom";
      case DateRangeTypes.SELECTOR:
        return (
          <Selector
            classes={classes}
            globalSettings={globalSettings}
            updateGlobalSettings={updateGlobalSettings}
          />
        );
      case DateRangeTypes.CUSTOM_SELECTOR:
        return "TODO: Global Range: Custom";
      default:
        console.log("Error: Unexpected global date range");
        return null;
    }
  }

  return (
    <FormControl
      fullWidth
      className={classes.selectFormControl + " " + classes.left}
    >
      <InputLabel htmlFor="step-type-select" className={classes.iconLabel}>
        Global Date Range
      </InputLabel>
      <Select
        MenuProps={{
          className: classes.selectMenu
        }}
        classes={{
          select: classes.select
        }}
        value={type}
        onChange={event => {
          const newGlobal = Object.assign({}, metadata.global, {
            dataRange: { type: event.target.value }
          });
          update("global", newGlobal, metadata, updateMetadata);
        }}
        inputProps={{
          name: "widgetTypeSelect",
          id: "widget-type-select-date"
        }}
      >
        <MenuItem
          disabled
          classes={{
            root: classes.selectMenuItem
          }}
          value="1"
        >
          Widget Type
        </MenuItem>
        {RangesMenuItems.map(item => (
          <MenuItem
            key={item.value}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected
            }}
            value={item.value}
          >
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DateRanges;
