/* eslint no-unused-vars: "off" */

/**
 * SITE CONFIG
 *
 * All the necessary parts to configure a site:
 * Checklist Zen, Airspace Ops, etc.
 */

import logo from "./Test_Logo_White.png";

import Dashboard from "views/Dashboard-Deprecated/Dashboard.jsx";
import Checklists from "views/Checklists/Checklists.jsx";
import Dashboards from "views/Dashboards/Dashboards.jsx";
import Checklist from "views/Checklists/Checklist.jsx";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import GridOn from "@material-ui/icons/GridOn";
import Build from "@material-ui/icons/Build";
import FlightTakeoff from "@material-ui/icons/FlightTakeoff";
import Language from "@material-ui/icons/Language";

/////////////////////////////
// Navigation Links
/////////////////////////////

// TODO: move to Dashboard
const DashboardRoute = {
  path: "/app/ops-dash",
  name: "Ops Dashboard",
  id: "ops-dashboard",
  icon: DashboardIcon,
  component: Dashboard,
  leftNav: true
};

const ChecklistsRoute = {
  path: "/app/checklists",
  name: "Checklists",
  id: "checklists",
  icon: "content_paste",
  component: Checklists,
  leftNav: true
};

const DashboardsRoute = {
  path: "/app/dashboards",
  name: "Dashboards",
  id: "dashboards",
  icon: GridOn,
  component: Dashboards,
  leftNav: true
};

const FlightRoute = {
  path:
    "/app/checklist/new-flight?id=6aea0fde-b296-42e0-8e56-5c3bb91dfec2&od=us-east-1:75246bba-3523-4699-b82b-6bf8901e98f3",
  name: "New Flight",
  id: "new-flight",
  icon: FlightTakeoff,
  component: Checklist,
  leftNav: true
};

const MaintenanceRoute = {
  path:
    "/app/checklist/maintenance?id=de297b3a-6b07-46d4-ac90-9bff5fb19852&od=us-east-1:75246bba-3523-4699-b82b-6bf8901e98f3",
  name: "Maintenance",
  id: "maintenance",
  icon: Build,
  component: Checklist,
  leftNav: true
};

const RampCheckRoute = {
  path: "/app/rampcheck",
  name: "FAA Ramp Check",
  id: "faa-ramp-check",
  icon: Language, // pan_tool, language, account_balance, alarm_on, announcement, assignment_ind
  component: Checklist, // TODO: Dashboard<RampCheck>
  leftNav: true
};

/////////////////////////////
// SITE CONFIGURATIONS
////////////////////////////

const TEST_ORG_PUBLIC = {
  name: "Test Org Public",
  logo,
  leftNav: [
    DashboardRoute,
    ChecklistsRoute,
    DashboardsRoute,
    FlightRoute,
    MaintenanceRoute,
    RampCheckRoute
  ]
};

export default TEST_ORG_PUBLIC;
