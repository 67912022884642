import React from "react";

// @material-ui/core components
import Icon from "@material-ui/core/Icon/Icon";

// @material-ui/icons
import Cancel from "@material-ui/icons/Cancel";
import Done from "@material-ui/icons/Done";
import Edit from "@material-ui/icons/Edit";
import Autorenew from "@material-ui/icons/Autorenew";
import FileCopy from "@material-ui/icons/FileCopy";
import Code from "@material-ui/icons/Code";
import DoneOutline from "@material-ui/icons/DoneOutline";
import Assignment from "@material-ui/icons/Assignment";

// Components

// Subcomponents

// Services
import { ChecklistMode } from "services/ChecklistService";

export const getMenuItems = (
  checklist,
  user,
  mode,
  classes,
  parents,
  checklistFn,
  navigateToTab,
  currentTab
) => {
  const ownerId = checklist.metadata.owner
    ? checklist.metadata.owner.ownerId
    : null;
  const userId = user.profile ? user.profile.id : null;
  const hasEditPermission = ownerId === userId;
  // Maybe also copy instances so can create a checklist off an older version
  // TODO: ability to copy should require edit level perms
  // User could have edit perms for org but be in view only for template (i.e. public and need to clone);
  const showCopy =
    mode === ChecklistMode.TEMPLATE_VIEW || ChecklistMode.TEMPLATE_EDIT;
  const showStart =
    mode === ChecklistMode.TEMPLATE_VIEW ||
    mode === ChecklistMode.TEMPLATE_EDIT;
  // Show only in completed with permissions (template defaults to edit with permissions);
  const showEdit = mode === ChecklistMode.INSTANCE_DONE && hasEditPermission;
  const showPublish = mode === ChecklistMode.TEMPLATE_EDIT;
  const showCancel = mode === ChecklistMode.INSTANCE_EDIT;
  const showViewTemplate =
    mode === ChecklistMode.INSTANCE_DONE ||
    mode === ChecklistMode.INSTANCE_EXEC; // Show only in instance mode (show the template for the instance)
  const notTemplate =
    mode === ChecklistMode.INSTANCE_EXEC ||
    mode === ChecklistMode.INSTANCE_DONE ||
    mode === ChecklistMode.INSTANCE_EDIT;
  const showSteps = currentTab !== 0 ? true : false;
  const showDetails = currentTab !== 1 ? true : false;
  const showStatus = currentTab !== 2 && notTemplate ? true : false;

  const menuItems = [];

  const steps = {
    title: "Steps",
    icon: <Assignment />,
    onClick: () => navigateToTab("steps")
  };

  const details = {
    title: "Details",
    icon: <Code />,
    onClick: () => navigateToTab("details")
  };

  const status = {
    title: "Status",
    icon: <DoneOutline />,
    onClick: () => navigateToTab("status")
  };

  const start = {
    title: "Start",
    icon: <Autorenew />,
    onClick: checklistFn.startChecklist
  };

  const copy = {
    title: "Copy",
    icon: <FileCopy />,
    onClick: checklistFn.copyChecklist
  };

  const edit = {
    title: "Edit",
    icon: <Edit />,
    onClick: checklistFn.editChecklist
  };

  const publish = {
    title: "Publish",
    icon: <Done />,
    onClick: checklistFn.publishChecklist
  };

  const cancel = {
    title: "Cancel",
    icon: <Cancel />,
    onClick: checklistFn.cancelEditChecklist
  };

  const template = {
    title: "Template",
    icon: <Icon>content_paste</Icon>,
    onClick: checklistFn.viewTemplate
  };

  if (showSteps) {
    menuItems.push(steps);
  }

  if (showDetails) {
    menuItems.push(details);
  }

  if (showStatus) {
    menuItems.push(status);
  }

  if (showStart) {
    menuItems.push(start);
  }

  if (showCancel) {
    menuItems.push(cancel);
  }

  if (showPublish) {
    menuItems.push(publish);
  }

  if (showEdit) {
    menuItems.push(edit);
  }
  if (showCopy) {
    menuItems.push(copy);
  }
  if (showViewTemplate) {
    menuItems.push(template);
  }

  return menuItems;
};
