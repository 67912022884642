import React from "react";
import { flowRight as compose } from "lodash";
import queryString from "query-string";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Icon from "@material-ui/core/Icon/Icon";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import AssignmentTurnedIn from "@material-ui/icons/AssignmentTurnedIn";
import Add from "@material-ui/icons/Add";

// Components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Confirm from "components/Modal/Confirm";

// Subcomponents
import ChecklistsTypeToggle from "./ChecklistComponents/ChecklistsTypeToggle";
import ChecklistsTable from "./ChecklistComponents/ChecklistsTable";

// Services
import { ChecklistsModes, createNewChecklist } from "services/ChecklistService";

// Queries
import {
  ChecklistTemplates,
  ChecklistInstances,
  DeleteChecklistTemplate,
  DeleteChecklistInstance
} from "./ChecklistComponents/ChecklistQueries";

// Assets
import checklistsStyle from "assets/jss/material-dashboard-pro-react/components/checklistsStyle.jsx";

class Checklists extends React.Component {
  constructor(props) {
    super(props);
    const { search } = props.location;
    const value = queryString.parse(search);
    this.state = {
      confirmModal: false,
      confirmTitle: "",
      confirmParagraph: "",
      handleConfirm: this.handleClose,
      handleClose: this.handleClose,
      typeTemplate: !value.type || value.type === "template",
      mode: this.calculateMode(search)
    };
  }

  calculateMode(search) {
    const value = queryString.parse(search);
    if (value.type === "instance") {
      if (value.status === "complete") {
        return ChecklistsModes.COMPLETE;
      } else {
        return ChecklistsModes.ACTIVE;
      }
    } else {
      return ChecklistsModes.TEMPLATE;
    }
  }

  handleClose = () => {
    this.setState({ confirmModal: false });
  };

  handleDelete = (e, id, metadata) => {
    this.setState({
      confirmModal: true,
      confirmTitle: `Delete "${metadata.name}" Checklist?`,
      handleConfirm: () => {
        this.setState(
          {
            confirmModal: false
          },
          () => {
            const {
              user,
              deleteChecklistTemplate,
              deleteChecklistInstance
            } = this.props;
            const { typeTemplate } = this.state;
            const { id: orgId } = user.currentOrganization;
            if (typeTemplate) {
              deleteChecklistTemplate(orgId, id, metadata.version);
            } else {
              deleteChecklistInstance(orgId, id, metadata.version);
            }
          }
        );
      }
    });
    e.stopPropagation();
  };

  render() {
    const { classes, data, location, history } = this.props;
    const {
      confirmModal,
      confirmTitle,
      confirmParagraph,
      handleConfirm,
      handleClose,
      typeTemplate,
      mode
    } = this.state;

    if (data.loading || data.error) {
      if (data.error) console.log("Error:", data.error);
      return (
        <div className={classes.loadingContainer}>
          <CircularProgress className={classes.progress} color="secondary" />
        </div>
      );
    }

    const results = typeTemplate
      ? data.getChecklistTemplates
      : data.getChecklistInstances;

    const items = results ? results.items : [];

    let modeDetails = {};
    switch (mode) {
      case ChecklistsModes.ACTIVE:
        modeDetails = {
          name: "Active",
          color: "warning",
          icon: <Assignment /> // "assignment"
        };
        break;
      case ChecklistsModes.COMPLETE:
        modeDetails = {
          name: "Completed",
          color: "success",
          icon: <AssignmentTurnedIn /> // "assignment_turned_in"
        };
        break;
      case ChecklistsModes.TEMPLATE:
      default:
        modeDetails = {
          name: "Templates",
          color: "info",
          icon: <Icon>content_paste</Icon> //"content_paste"
        };
    }

    return (
      <div>
        <GridContainer justifyContent="flex-end">
          <GridItem>
            <ChecklistsTypeToggle
              mode={mode}
              location={location}
              history={history}
            />
          </GridItem>
          <GridItem>
            <Button
              style={{ marginTop: "15px" }}
              color="info"
              size="sm"
              className={classes.marginRight}
              onClick={() => createNewChecklist(history)}
            >
              <Add className={classes.icons} /> New Checklist
            </Button>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color={modeDetails.color} icon>
                <CardIcon
                  color={modeDetails.color}
                  className={classes.iconImage}
                >
                  {modeDetails.icon}
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{modeDetails.name}</h4>
              </CardHeader>
              <CardBody>
                <ChecklistsTable
                  items={items}
                  mode={mode}
                  typeTemplate={typeTemplate}
                  history={history}
                  handleDelete={this.handleDelete}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <Confirm
          isOpen={confirmModal}
          handleConfirm={handleConfirm}
          handleClose={handleClose}
          title={confirmTitle}
          paragraph={confirmParagraph}
        />
      </div>
    );
  }
}

export default compose(
  ChecklistTemplates,
  ChecklistInstances,
  DeleteChecklistTemplate,
  DeleteChecklistInstance,
  withStyles(checklistsStyle)
)(Checklists);
