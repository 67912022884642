/** Import React and 3rd party libs */
import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

// @material-ui/icons
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// Components
/** Import generic components - internal and creative tim */

// Subcomponents
/** Import components used only by this component - relative path imports */

// Services
import { ChecklistsModes } from "services/ChecklistService";

// Queries
/** Import all graphql queries */

// Assets
import checklistsStyle from "assets/jss/material-dashboard-pro-react/components/checklistsStyle.jsx";

// Variables
/** Declare all constant variables */

// Functions
const handleModeToggle = (e, location, history) => {
  let search;
  switch (e.target.value) {
    case ChecklistsModes.ACTIVE:
      search = "?type=instance&status=active";
      break;
    case ChecklistsModes.COMPLETE:
      search = "?type=instance&status=complete";
      break;
    case ChecklistsModes.TEMPLATE:
    default:
      search = "?type=template";
  }
  const newLocation = Object.assign({}, location, { search });
  history.replace(newLocation);
};

/**
 * Declare JSX Component - class or
 */
const ChecklistsTypeToggle = ({ classes, mode, location, history }) => {
  return (
    <div className={classes.inlineChecks}>
      <FormControlLabel
        control={
          <Radio
            checked={mode === ChecklistsModes.TEMPLATE}
            onChange={e => handleModeToggle(e, location, history)}
            value={ChecklistsModes.TEMPLATE}
            name="mode"
            aria-label="Checklist Templates"
            icon={<FiberManualRecord className={classes.radioUnchecked} />}
            checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
            classes={{
              checked: classes.radio,
              root: classes.radioRoot
            }}
          />
        }
        classes={{
          label: classes.label
        }}
        label="Templates"
      />
      <FormControlLabel
        control={
          <Radio
            checked={mode === ChecklistsModes.ACTIVE}
            onChange={e => handleModeToggle(e, location, history)}
            value={ChecklistsModes.ACTIVE}
            name="mode"
            aria-label="Active Checklist"
            icon={<FiberManualRecord className={classes.radioUnchecked} />}
            checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
            classes={{
              checked: classes.radio,
              root: classes.radioRoot
            }}
          />
        }
        classes={{
          label: classes.label
        }}
        label="Active"
      />
      <FormControlLabel
        control={
          <Radio
            checked={mode === ChecklistsModes.COMPLETE}
            onChange={e => handleModeToggle(e, location, history)}
            value={ChecklistsModes.COMPLETE}
            name="radio button demo"
            aria-label="Complete Checklists"
            icon={<FiberManualRecord className={classes.radioUnchecked} />}
            checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
            classes={{
              checked: classes.radio,
              root: classes.radioRoot
            }}
          />
        }
        classes={{
          label: classes.label
        }}
        label="Completed"
      />
    </div>
  );
};

// Data Connectors

export default withStyles(checklistsStyle)(ChecklistsTypeToggle);
