import React from "react";

// Apollo Data Layer
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Add from "@material-ui/icons/Add";

// Components
import Button from "components/CustomButtons/Button.jsx";
import Autocomplete from "components/Autocomplete/Autocomplete.jsx";

import {
  ChecklistTemplatesQuery,
  ChecklistTemplateQuery
} from "api/queries/index";

class StepSelector extends React.Component {
  state = {
    selectedStep: null
  };

  render() {
    const {
      ChecklistsQuery,
      checklistTemplateQuery,
      checklist,
      steps,
      onChecklistChange,
      onStepChange,
      onClearSteps
    } = this.props;

    const { getChecklistTemplates } = ChecklistsQuery
      ? ChecklistsQuery
      : { getChecklistTemplates: { items: [] } };

    const { getChecklistTemplate } = checklistTemplateQuery
      ? checklistTemplateQuery
      : { getChecklistTemplate: { steps: [] } };

    const availableChecklists = getChecklistTemplates
      ? getChecklistTemplates.items.map(checklist => {
          return {
            label: checklist.metadata.name,
            value: checklist.id,
            version: checklist.metadata.version
          };
        })
      : [];

    const availableSteps = getChecklistTemplate
      ? getChecklistTemplate.steps.map(step => {
          return {
            label: step.name,
            value: step.stepId,
            type: step.type
          };
        })
      : [];

    const _onChecklistChange = results => {
      const checklist = results
        ? {
            name: results.label,
            checklistId: results.value,
            version: results.version
          }
        : null;
      onChecklistChange(checklist);
    };

    const classes = {};

    return (
      <div>
        <span>
          <legend>Checklist Template</legend>
          <Autocomplete
            placeholder={"Type to find a checklist"}
            value={checklist}
            onChange={_onChecklistChange}
            choices={availableChecklists}
          />
          <legend>Checklist Steps</legend>
          {steps.map((step, i) => (
            <div key={i}>{step.name}</div>
          ))}
          <Autocomplete
            placeholder={"Type to find a step"}
            value={this.state.selectedStep}
            onChange={val =>
              this.setState({
                selectedStep: {
                  name: val.label,
                  stepId: val.value,
                  type: val.type
                }
              })
            }
            choices={availableSteps}
          />
          <Button
            onClick={() => onStepChange(this.state.selectedStep)}
            size="sm"
            color="primary"
            round
          >
            <Add className={classes.icons} />
            Add Step
          </Button>
          <Button
            onClick={() => onClearSteps()}
            size="sm"
            color="primary"
            round
          >
            <Close className={classes.icons} />
            Clear Step
          </Button>
        </span>
      </div>
    );
  }
}

const ChecklistTemplates = graphql(ChecklistTemplatesQuery, {
  name: "ChecklistsQuery",
  options: props => {
    return {
      fetchPolicy: "network-only",
      variables: {
        orgId: props.user.currentOrganization.id
      }
    };
  }
});

export const ChecklistTemplate = graphql(ChecklistTemplateQuery, {
  name: "checklistTemplateQuery",
  options: props => {
    const { checklist, user } = props;
    const orgId = user.currentOrganization.id;
    const id = checklist.checklistId;
    return {
      fetchPolicy: "network-only",
      variables: {
        orgId,
        id
      }
    };
  },
  skip: props => {
    const { checklist } = props;
    return !checklist || !checklist.checklistId;
  }
});

export default compose(ChecklistTemplates, ChecklistTemplate)(StepSelector);
