import gql from "graphql-tag";

////////////////////////////////////////////////
// ORG FRAGMENTS
////////////////////////////////////////////////

export const Organization = {
  orgMetadataCurrent: gql`
    fragment OrgMetadataCurrent on OrganizationMetadata {
      name
      description
    }
  `,
  orgAccess: gql`
    fragment OrgAccess on OrganizationAccess {
      users {
        id
        callsign
        status
        email
        phone
        groups
      }
    }
  `
};
