/** Import React and 3rd party libs */
import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";

// @material-ui/icons
/** Import material ui icons */

// Components
import InfoText from "components/Typography/Info.jsx";
import Warning from "components/Typography/Warning.jsx";
import Danger from "components/Typography/Danger.jsx";

// Subcomponents
import StepNote from "../../StepComponents/StepNote";
import ViewTitle from "../../StepComponents/ViewTitle";

// Services
import { getCommonTabs, EditRow } from "../StepTypesUtility";
import { StepType, getStepRowClass } from "services/StepService";

// Queries
/** Import all graphql queries */

// Assets
import stepsStyle from "assets/jss/material-dashboard-pro-react/components/stepsStyle.jsx";

// Variables
/** Declare all constant variables */

// Functions
const StepIcon = ({ classes, step }) => {
  let iconType;
  switch (step.type) {
    case StepType.NOTE:
      iconType = <InfoText>NOTE</InfoText>;
      break;
    case StepType.CAUTION:
      iconType = <Warning>CAUTION</Warning>;
      break;
    case StepType.WARNING:
      iconType = <Danger>WARNING</Danger>;
      break;
    default:
      iconType = <InfoText>NOTE</InfoText>;
  }

  return (
    <TableCell className={classes.tableCheck}>
      <div className={classes.textType}>{iconType}</div>
    </TableCell>
  );
};

const StepContent = props => {
  const { classes } = props;
  return (
    <TableCell className={classes.tableContent}>
      <ViewTitle {...props} />
    </TableCell>
  );
};

/**
 * Note Step
 * TODO: refactor to different note types in config (note, caution, and warning)
 * Instead of having as a top level type.
 */
const Note = props => {
  const {
    classes,
    step,
    updateStep,
    editing,
    setEditState,
    noteOpen,
    setNoteState
  } = props;
  const editProps = { editing, setEditState, noteOpen, setNoteState };
  const tabs = getCommonTabs(step, updateStep);
  const showRowDividers = true;
  const primaryTableRowClass = getStepRowClass(
    classes,
    showRowDividers,
    editing || noteOpen
  );

  return (
    <>
      <TableRow key={step.stepId} className={primaryTableRowClass}>
        <StepIcon {...props} />
        <StepContent {...props} {...editProps} />
      </TableRow>
      <EditRow classes={classes} open={editing}>
        <CustomTabs title="Configure" headerColor="gray" tabs={tabs} />
      </EditRow>
      <EditRow classes={classes} open={noteOpen}>
        <StepNote step={step} updateStep={updateStep} />
      </EditRow>
    </>
  );
};

export default withStyles(stepsStyle)(Note);
