import React from "react";
import { Storage } from "aws-amplify"; // Amplify S3 Storage
import queryString from "query-string"; // Handle query string parsing
import { withRouter } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fade from "@material-ui/core/Fade";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import AddAlert from "@material-ui/icons/AddAlert";
import Verified from "@material-ui/icons/VerifiedUserOutlined";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";

import ProfilePic from "components/ProfilePic/ProfilePic.jsx";

// Services
import {
  getRandomProfilePic,
  copyObjectWithoutNull
} from "services/utility.js";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

const MAX_PHOTO_SIZE = 1024 * 1024; // 1MB

const UploadButton = ({
  isEditMode,
  uploading,
  classes,
  uploadButton,
  onChange
}) => {
  function uploadPicture() {
    uploadButton.current.click();
  }

  if (!isEditMode) return null;

  if (uploading) {
    return (
      <div>
        <CircularProgress className={classes.progress} color="secondary" />
      </div>
    );
  }
  return (
    <Button
      color="rose"
      className={classes.uploadButton}
      onClick={uploadPicture}
      round
    >
      Upload Picture
      <input
        type="file"
        accept="image/*"
        className={classes.fileUploadInput}
        onChange={e => onChange(e)}
        ref={uploadButton}
      />
    </Button>
  );
};

/**
 * TODO: Need to clean up UI / UX
 * TODO: Need to add email and phone updating
 *  - changes need to be reflected not just in the profile but saved to cognito for login
 *  - changes need to be verified via email or text, as done on signup
 * TODO: Need to allow users to reinitiate the verification process (send an email or text again)
 */
class MyProfile extends React.Component {
  constructor(props) {
    super(props);
    const { user } = props;
    const profile = copyObjectWithoutNull(user.profile);
    Object.keys(user.profile || {}).forEach(key => {
      if (!user.profile[key]) {
        profile[key] = "";
      } else {
        profile[key] = user.profile[key];
      }
    });
    this.state = {
      loading: user.loading,
      profile,
      notification: false,
      message: "",
      firstNameInputState: "success",
      callsignInputState: "success",
      uploading: false
    };
    this.uploadButton = React.createRef();
  }

  editProfile = () => {
    this.props.history.push({
      search: "?edit=true"
    });
  };

  resetChanges = () => {
    const profile = copyObjectWithoutNull(this.props.user.profile);
    this.setState(
      {
        profile,
        firstNameInputState: "success",
        callsignInputState: "success"
      },
      () => {
        this.props.history.push({
          search: ""
        });
      }
    );
  };

  updateProfile = () => {
    const { profile } = this.state;
    if (!profile.callsign || !profile.firstName) {
      const message = "First Name and Callsign are required fields.";
      return this.setState({
        notification: true,
        message,
        firstNameInputState: "error",
        callsignInputState: "error"
      });
    }
    this.props.user.updateProfile(profile);
  };

  updateInput = (key, value) => {
    const { profile } = this.state;
    profile[key] = value;
    this.setState({
      profile,
      firstNameInputState: "success",
      callsignInputState: "success"
    });
  };

  onChange = e => {
    const image = e.target.files[0];
    const { type, size } = image;

    if (size > MAX_PHOTO_SIZE) {
      const message = `Your photo size was ${size /
        MAX_PHOTO_SIZE} MB which is greater than the max size of 1MB.  Please upload a smaller photo.`;
      return this.setState({ notification: true, message });
    }

    this.setState({ uploading: true }, () => {
      Storage.put("profile-pic", image, {
        level: "protected",
        contentType: type
        // progressCallback(progress) {
        //   const progressPercent = (progress.loaded / progress.total) * 100;
        //   this.setState({ uploadProgress: progressPercent });
        // }
      })
        .then(result => {
          const { profile } = this.state;
          profile.profilePicUrl = result.key;
          this.setState({ uploading: false }, () =>
            this.props.user.updateProfile(profile)
          );
        })
        .catch(err => {
          this.setState({
            uploading: false,
            notification: true,
            message: `Error uploading file: ${err}`
          });
        });
    });
  };

  render() {
    const { classes, location, user } = this.props;
    const {
      profile,
      loading,
      notification,
      message,
      firstNameInputState,
      callsignInputState,
      uploading
    } = this.state;
    const { search } = location;
    const value = queryString.parse(search);
    const isEditMode = value.edit;
    const cardTitle = profile.title || "Title";
    const cardName =
      profile.firstName !== profile.callsign
        ? `${profile.firstName} "${profile.callsign}" ${profile.lastName}`
        : profile.callsign;
    const cardAbout = profile.about || "About...";
    const { profilePicUrl } = profile;
    let profileLink;

    if (loading || !profilePicUrl) {
      return (
        <div className={classes.progressContainer}>
          <CircularProgress className={classes.progress} color="secondary" />
        </div>
      );
    }

    if (profilePicUrl === "profile-pic" || profilePicUrl.indexOf("http") > -1) {
      profileLink = "#noop";
    }
    const profileString =
      profilePicUrl === "profile-pic" || profilePicUrl.indexOf("http") > -1
        ? getRandomProfilePic()
        : profilePicUrl;
    const profileName = profileString.split(".");
    const profileParts = profileName[0].split("-");
    profileLink = `https://www.google.com/search?q=${profileParts[0]}+${profileParts[1]}`;

    const emailVerificationColor = profile.emailVerified ? "green" : "red";
    const emailVerificationMessage = profile.emailVerified
      ? "Email is verfied."
      : "Email is not verified.";
    const phoneVerificationColor = profile.phoneVerified ? "green" : "red";
    const phoneVerificationMessage = profile.phoneVerified
      ? "Phone is verfied."
      : "Phone is not verified.";
    console.log("user", user);
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <PermIdentity />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  {isEditMode ? (
                    <span>
                      Edit Profile - <small>Complete your profile</small>
                    </span>
                  ) : (
                    <span>User Profile</span>
                  )}
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="First Name"
                      id="first-name"
                      success={firstNameInputState === "success"}
                      error={firstNameInputState === "error"}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: !isEditMode,
                        value: profile.firstName,
                        onChange: evt =>
                          this.updateInput("firstName", evt.target.value)
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Middle Name"
                      id="middle-name"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: !isEditMode,
                        value: profile.middleName,
                        onChange: evt =>
                          this.updateInput("middleName", evt.target.value)
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Last Name"
                      id="last-name"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: !isEditMode,
                        value: profile.lastName,
                        onChange: evt =>
                          this.updateInput("lastName", evt.target.value)
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Callsign"
                      id="callsign"
                      success={callsignInputState === "success"}
                      error={callsignInputState === "error"}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: !isEditMode,
                        value: profile.callsign,
                        onChange: evt =>
                          this.updateInput("callsign", evt.target.value)
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Title"
                      id="title"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: !isEditMode,
                        value: profile.title,
                        onChange: evt =>
                          this.updateInput("title", evt.target.value)
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="City"
                      id="city"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: !isEditMode,
                        value: profile.city,
                        onChange: evt =>
                          this.updateInput("city", evt.target.value)
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Country"
                      id="country"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: !isEditMode,
                        value: profile.country,
                        onChange: evt =>
                          this.updateInput("country", evt.target.value)
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Postal Code"
                      id="postal-code"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: !isEditMode,
                        value: profile.postalCode,
                        onChange: evt =>
                          this.updateInput("postalCode", evt.target.value)
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={6}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Tooltip
                      id="tooltip-top"
                      title={emailVerificationMessage}
                      placement="top"
                      disableFocusListener={true}
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Verified
                        style={{
                          marginRight: "8px",
                          color: emailVerificationColor
                        }}
                      />
                    </Tooltip>

                    <CustomInput
                      labelText="Email address"
                      id="email-address"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: profile.email
                        // onChange: evt =>
                        //   this.updateInput("email", evt.target.value)
                      }}
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={6}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Tooltip
                      id="tooltip-top"
                      title={phoneVerificationMessage}
                      placement="top"
                      disableFocusListener={true}
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Verified
                        style={{
                          marginRight: "8px",
                          color: phoneVerificationColor
                        }}
                      />
                    </Tooltip>
                    <CustomInput
                      labelText="Phone number"
                      id="phone-number"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: profile.phone
                        // onChange: evt =>
                        //   this.updateInput("phone", evt.target.value)
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <InputLabel style={{ color: "#AAAAAA" }}>
                      About me
                    </InputLabel>
                    <CustomInput
                      id="about-me"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 5,
                        disabled: !isEditMode,
                        value: profile.about,
                        onChange: evt =>
                          this.updateInput("about", evt.target.value)
                      }}
                    />
                  </GridItem>
                </GridContainer>
                {isEditMode ? (
                  <span>
                    <Button
                      color="rose"
                      className={classes.updateProfileButton}
                      onClick={this.updateProfile}
                    >
                      Update Profile
                    </Button>
                    <Button
                      color="rose"
                      className={classes.resetProfileButton}
                      onClick={this.resetChanges}
                    >
                      Cancel Changes
                    </Button>
                  </span>
                ) : (
                  <Button
                    color="rose"
                    className={classes.editProfileButton}
                    onClick={this.editProfile}
                  >
                    Edit Profile
                  </Button>
                )}
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card profile>
              <CardAvatar profile>
                <a href={profileLink} rel="noopener noreferrer" target="_blank">
                  <Fade in={true} timeout={3000}>
                    <ProfilePic
                      profilePicUrl={profilePicUrl}
                      theme={{
                        photoImg: {
                          minHeight: "130px"
                        }
                      }}
                    />
                  </Fade>
                </a>
              </CardAvatar>
              <CardBody profile>
                <h6 className={classes.cardCategory}>{cardTitle}</h6>
                <h4 className={classes.cardTitle}>{cardName}</h4>
                <p className={classes.description}>{cardAbout}</p>
                <UploadButton
                  isEditMode={isEditMode}
                  uploading={uploading}
                  classes={classes}
                  uploadButton={this.uploadButton}
                  onChange={this.onChange}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <Snackbar
          place="bc"
          color="danger"
          icon={AddAlert}
          message={message}
          open={notification}
          closeNotification={() => this.setState({ notification: false })}
          close
        />
      </div>
    );
  }
}

export default withRouter(withStyles(userProfileStyles)(MyProfile));
