import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Done from "@material-ui/icons/Done";
import Note from "@material-ui/icons/Note";

// Subcomponents
import { ChecklistMode } from "services/ChecklistService";

// Assets
import stepsStyle from "assets/jss/material-dashboard-pro-react/components/stepsStyle.jsx";

function EditButtons({ classes, editing, setEditState }) {
  return (
    <Tooltip
      id="tooltip-top"
      title={editing ? "Done Editing" : "Configure Step"}
      placement="top"
      disableFocusListener={true}
      classes={{ tooltip: classes.tooltip }}
    >
      {editing ? (
        <IconButton
          aria-label="Done Editing"
          className={classes.tableActionButton}
          onClick={() => setEditState(!editing)}
        >
          <Done
            className={classes.tableActionButtonIcon + " " + classes.success}
          />
        </IconButton>
      ) : (
        <IconButton
          aria-label="Configure Step"
          className={classes.tableActionButton}
          onClick={() => setEditState(!editing)}
        >
          <Edit
            className={classes.tableActionButtonIcon + " " + classes.subdued}
          />
        </IconButton>
      )}
    </Tooltip>
  );
}

function NoteButtons({ classes, noteOpen, setNoteState }) {
  return (
    <Tooltip
      id="tooltip-top"
      title={noteOpen ? "Done" : "Add Note"}
      placement="top"
      disableFocusListener={true}
      classes={{ tooltip: classes.tooltip }}
    >
      {noteOpen ? (
        <IconButton
          aria-label="Done"
          className={classes.tableActionButton}
          onClick={() => setNoteState(!noteOpen)}
        >
          <Done
            className={classes.tableActionButtonIcon + " " + classes.success}
          />
        </IconButton>
      ) : (
        <IconButton
          aria-label="Add Note"
          className={classes.tableActionButton}
          onClick={() => setNoteState(!noteOpen)}
        >
          <Note
            className={classes.tableActionButtonIcon + " " + classes.subdued}
          />
        </IconButton>
      )}
    </Tooltip>
  );
}

function StepButtons({
  classes,
  editing,
  setEditState,
  editVisible,
  noteOpen,
  setNoteState,
  mode
}) {
  if (
    mode === ChecklistMode.TEMPLATE_VIEW ||
    mode === ChecklistMode.INSTANCE_DONE
  ) {
    return <div className={classes.tableActions}>&nbsp;</div>;
  } else if (
    mode === ChecklistMode.INSTANCE_EXEC ||
    mode === ChecklistMode.INSTANCE_EDIT
  ) {
    return (
      <div className={classes.tableActions}>
        <NoteButtons
          classes={classes}
          noteOpen={noteOpen}
          setNoteState={setNoteState}
        />
      </div>
    );
  }

  return (
    <div className={classes.tableActions}>
      <span
        className={editVisible ? classes.stepButtons : classes.stepButtonsHide}
      >
        <EditButtons
          classes={classes}
          editing={editing}
          setEditState={setEditState}
        />
      </span>
    </div>
  );
}

export default withStyles(stepsStyle)(StepButtons);
