/** Import React and 3rd party libs */
import React, { useState } from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// Components
/** Import generic components - internal and creative time */

// Subcomponents
/** Import components used only by this component - relative path imports */

// Services
import { verifyUrl } from "services/utility";

// Queries
/** Import all graphql queries */

// Assets
import widgetModalStyle from "assets/jss/material-dashboard-pro-react/components/widgetModalStyle.jsx";

// Variables
/** Declare all constant variables */

// Functions
const updateInfoText = (widget, event) => {
  let newWidget = Object.assign({}, widget);
  newWidget.info.text = event.target.value || null;
  return newWidget;
};

const updateInfoLink = (widget, event, setFormState) => {
  let newWidget = Object.assign({}, widget);
  const stateName = "url";
  if (verifyUrl(event.target.value)) {
    setFormState({ [stateName + "State"]: "success" });
  } else {
    setFormState({ [stateName + "State"]: "error" });
  }
  newWidget.info.link = event.target.value || null;
  return newWidget;
};

/**
 * Declare JSX Component - class or
 */
const WidgetInfoEdit = ({ classes, widget, updateWidget }) => {
  const [formState, setFormState] = useState({});

  return (
    <FormControl fullWidth className={classes.selectFormControl}>
      <CustomInput
        labelText="Text"
        id="text"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          onChange: event => updateWidget(updateInfoText(widget, event)),
          type: "text",
          value: widget.info.text || ""
        }}
      />
      <CustomInput
        success={formState.urlState === "success"}
        error={formState.urlState === "error"}
        labelText="Link"
        id="link"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          onChange: event =>
            updateWidget(updateInfoLink(widget, event, setFormState)),
          type: "text",
          value: widget.info.link || "",
          endAdornment:
            formState.urlState === "error" ? (
              <InputAdornment position="end">
                <Close className={classes.danger} />
              </InputAdornment>
            ) : (
              undefined
            )
        }}
      />
    </FormControl>
  );
};

// Data Connectors

export default withStyles(widgetModalStyle)(WidgetInfoEdit);
