import React, { useState, useEffect } from "react";

import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";

// services
import { MAX_STEPS, cloneInstance } from "../awardboardService";

import { AwardboardStyle, ANIMATION_TIME } from "../awardboardStyle";

import starIcon from "assets/img/lemonadepixel/lemonadepixel_weddingicons-38.png";

const secondsTime = ANIMATION_TIME / 1000;

const starsStyle = {
  starStyle: {
    height: "28px",
    width: "28px",
    marginTop: "-70px",
    verticalAlign: "middle",
    border: "0",
    visibility: "hidden"
  }
};

// Construct 50 animations (so max steps is 50)
for (let i = 1; i <= MAX_STEPS; i++) {
  let distance = 110 + 245 * i;
  starsStyle[`@keyframes slideRight-${i}`] = {
    from: { marginLeft: "0px", marginTop: "-85px", visibility: "visible" },
    to: {
      marginLeft: `-${distance}px`,
      marginTop: "-70px",
      visibility: "visible",
      transform: "rotate(-360deg)"
    }
  };
  starsStyle[`shootingStar-${i}`] = {
    animationName: `slideRight-${i}`,
    animationDuration: `${secondsTime}s`,
    animationTimingFunction: "ease-out"
  };
}

// Award details exist in the values for each step so pull out and put in object
const destructureAward = award => {
  const [
    name,
    description,
    link,
    imgSrc,
    userId,
    targetVal,
    currVal
  ] = award.steps;

  return {
    name: name.value.text,
    description: description.value.text,
    link: link.value.link,
    imgSrc: imgSrc.value.link,
    userId: userId.value.text,
    targetVal: targetVal.value.number,
    currVal: currVal.value.number
  };
};

// Add a star for the award and update total count / chore checklist
const incrementUsedStars = user => {
  let instanceToUpdate = null;

  // update oldest checklist step without a used star
  for (let i = 0; i < user.instances.length; i++) {
    let currInstance = user.instances[i];
    for (let j = 0; j < currInstance.steps.length; j++) {
      let currStep = currInstance.steps[j];
      // Only check step if it has a star (number)
      if (
        currStep.value &&
        currStep.value.number &&
        currStep.value.number > 0
      ) {
        if (!currStep.value.value) {
          // If no stars are used, increment value (used stars)
          currStep.value.value = 1;
          instanceToUpdate = currInstance;
          break;
        } else if (parseInt(currStep.value.value) < currStep.value.number) {
          // If fewer stars are used than count, increment
          currStep.value.value = parseInt(currStep.value.value) + 1;
          instanceToUpdate = currInstance;
          break;
        } else {
          continue;
        }
      }
    }
    if (instanceToUpdate) {
      break;
    }
  }

  if (!instanceToUpdate) {
    // no-op since no stars to add
    return null;
  }

  return cloneInstance(instanceToUpdate);
};

// Remove a star from the award and update total count / chore checklist
const decrementUsedStars = user => {
  let instanceToUpdate = null;

  // Start from newest to remove used star (so stays longer before 90 day expiration)
  for (let i = user.instances.length - 1; i >= 0; i--) {
    let currInstance = user.instances[i];
    for (let j = 0; j < currInstance.steps.length; j++) {
      let currStep = currInstance.steps[j];
      // Only check step if it has a value
      if (
        currStep.value &&
        currStep.value.value &&
        parseInt(currStep.value.value) > 0
      ) {
        currStep.value.value = currStep.value.value - 1;
        instanceToUpdate = currInstance;
        break;
      }
    }
    if (instanceToUpdate) {
      // no-op since no stars to remove
      break;
    }
  }

  if (!instanceToUpdate) {
    return null;
  }

  return cloneInstance(instanceToUpdate);
};

const addStarToAward = award => {
  award.steps[6].value.number = award.steps[6].value.number + 1;
  return cloneInstance(award);
};

const removeStarFromAward = award => {
  award.steps[6].value.number = award.steps[6].value.number - 1;
  return cloneInstance(award);
};

const Award = ({ user, award, updateAward, classes }) => {
  const [animate, setAnimate] = useState(false);
  const awardObj = destructureAward(award);
  const starClasses = animate
    ? classes.starStyle + " " + classes[`shootingStar-${award.id}`]
    : classes.starStyle;
  let timeout = {};

  const animateStar = () => {
    setAnimate(true);
    timeout = setTimeout(() => {
      setAnimate(false);
    }, ANIMATION_TIME);
  };

  useEffect(() => {
    return () => {
      clearInterval(timeout);
    };
  }, []);

  return (
    <td style={AwardboardStyle.body.cell}>
      <div style={AwardboardStyle.body.cellContainer}>
        <div style={AwardboardStyle.body.addDeleteContainer}>
          <div>
            <Add
              style={AwardboardStyle.body.addDeleteButton}
              onClick={async () => {
                const starInstance = incrementUsedStars(user);
                if (starInstance) {
                  const awardInstance = addStarToAward(award);
                  updateAward(user, awardInstance, starInstance, animateStar);
                }
              }}
            />
          </div>
        </div>
        <div style={AwardboardStyle.body.awardOuterContainer}>
          <div style={AwardboardStyle.body.awardContainer}>
            <img
              src={awardObj.imgSrc}
              alt="award"
              style={AwardboardStyle.body.image}
            />
            <h5>{awardObj.name}</h5>
            <img
              src={starIcon}
              alt="star icon"
              style={AwardboardStyle.body.star}
            />
            <small style={AwardboardStyle.user.countText}>
              {/* {user.stats.count - (animate === user.name ? 1 : 0)} */}
              {awardObj.currVal} / {awardObj.targetVal}
            </small>
          </div>
          <img src={starIcon} alt="star icon" className={starClasses} />
        </div>
        <div style={AwardboardStyle.body.addDeleteContainer}>
          <div>
            <Remove
              style={AwardboardStyle.body.addDeleteButton}
              onClick={async () => {
                const starInstance = decrementUsedStars(user);
                if (starInstance) {
                  const awardInstance = removeStarFromAward(award);
                  updateAward(user, awardInstance, starInstance, animateStar);
                }
              }}
            />
          </div>
        </div>
      </div>
    </td>
  );
};

export default withStyles(starsStyle)(Award);
