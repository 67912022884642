/** Import React and 3rd party libs */
import React from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";

// @material-ui/core components
/** Import material ui components */

// @material-ui/icons
/** Import material ui icons */

// Components
import Autocomplete from "components/Autocomplete/Autocomplete.jsx";

// Subcomponents
/** Import components used only by this component - relative path imports */

// Services
import { StepType, getStepValue } from "services/StepService";
import { omitDeep } from "services/utility";
import {
  constructInstanceName,
  ChecklistMode
} from "services/ChecklistService";

// Queries
import { FindCompletedChecklistsQuery } from "api/queries";

// Assets
/** Import styles */

// Variables
/** Declare all constant variables */

// Functions
const onAutocompleteChange = (results, step) => {
  const newStep = Object.assign({}, step);
  const name = results ? results.label.split(" - ")[0] : "";
  newStep.value = results
    ? {
        checklist: {
          name: name,
          checklistId: results.value,
          version: results.version
        }
      }
    : {
        checklist: null
      };
  return newStep;
};

const constructLabel = (checklist, checklistSelector) => {
  let name;
  if (checklistSelector.steps && checklistSelector.steps.length > 0) {
    const steps = [];
    checklistSelector.steps.forEach(stepSelector => {
      const stepInfo = checklist.steps.find(
        step => step.stepId === stepSelector.stepId
      );
      steps.push(stepInfo);
    });
    const strings = steps.map(step => getStepValue(step));
    name = strings.join(" ");
  }

  if (!name) {
    name = constructInstanceName(checklist);
  }

  return name;
};

/**
 * Use to select a comleted checklist
 */
const UserSelector = ({ step, updateStep, isDisabled, data }) => {
  if (!step.configuration || !step.configuration.checklist) {
    return null;
  }
  const { findCompletedChecklists } = data;
  const availableChecklists =
    findCompletedChecklists && findCompletedChecklists.items
      ? findCompletedChecklists.items.map(checklist => {
          return {
            label: constructLabel(checklist, step.configuration.checklist),
            value: checklist.id,
            version: checklist.metadata.version
          };
        })
      : [];
  return (
    <div>
      <Autocomplete
        placeholder={`Type to find a ${step.configuration.checklist.name}`}
        value={step.value ? step.value.checklist : {}}
        onChange={result => {
          updateStep(onAutocompleteChange(result, step));
        }}
        choices={availableChecklists}
        disabled={isDisabled}
      />
    </div>
  );
};

// Data Connectors
const FindCompletedChecklists = graphql(FindCompletedChecklistsQuery, {
  options: props => {
    const template = omitDeep(props.step.configuration.checklist, "__typename");
    return {
      fetchPolicy: "network-only",
      variables: {
        template
      }
    };
  },
  skip: props => {
    const { step, mode } = props;
    const isEditMode = mode === ChecklistMode.TEMPLATE_EDIT;
    return (
      isEditMode ||
      step.type !== StepType.COMPLETED_CHECKLIST ||
      !step.configuration.checklist
    );
  }
});

export default compose(FindCompletedChecklists)(UserSelector);
