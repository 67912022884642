import React from "react";

// @material-ui/core components
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

// services
import { ChecklistMode } from "services/ChecklistService";

// functions
function updateEventsEnabled(step, updateStep) {
  const newStep = Object.assign({}, step);
  const eventConfig = step.eventConfig || {};
  const newEventConfig = Object.assign({}, eventConfig, {
    isEnabled: !eventConfig.isEnabled
  });
  newStep.eventConfig = newEventConfig;
  updateStep(newStep);
}

export const EventsEnable = ({ step, updateStep, mode, classes }) => {
  const eventsEnabled =
    step.eventConfig && step.eventConfig.isEnabled ? true : false;
  return (
    <div style={{ margin: "16px 0" }}>
      <FormControlLabel
        control={
          <Switch
            checked={eventsEnabled}
            onChange={() => updateEventsEnabled(step, updateStep)}
            value="eventsEnabled"
            classes={{
              switchBase: classes.switchBase,
              checked: classes.switchChecked,
              thumb: classes.switchIcon,
              track: classes.switchBar
            }}
            inputProps={{
              disabled: mode !== ChecklistMode.TEMPLATE_EDIT
            }}
          />
        }
        classes={{
          label: classes.label
        }}
        label="Enabled"
      />
    </div>
  );
};

export default EventsEnable;
