import buttonGroupStyle from "assets/jss/material-dashboard-pro-react/buttonGroupStyle.jsx";

const styles = {
  ...buttonGroupStyle,
  buttonGroupZ: { zIndex: 1000 },
  widgetsContainer: {
    width: "100%",
    padding: "0 5px !important"
  },
  loadingContainer: {
    minHeight: "80vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  cardTitle: {
    display: "flex",
    width: "100%",
    marginLeft: "26px",
    marginTop: "12px",
    color: "rgba(0, 0, 0, 0.76)",
    cursor: "text",
    fontSize: "1.6em",
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "0.00938em",
    paddingBottom: "4px"
  },
  cardEditTitle: {
    marginLeft: "26px",
    marginTop: "4px"
  },
  inputTitle: {
    paddingBottom: "4px !important",
    color: "rgba(0, 0, 0, 0.76)",
    cursor: "text",
    fontSize: "1.5em",
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "0.00938em"
  },
  inputTitleRoot: {
    flexGrow: 1
  },
  cardDescription: {
    display: "flex",
    width: "100%",
    marginLeft: "78px"
  },
  cardEditDescription: {
    marginLeft: "0px"
  },
  inputDescription: {
    paddingBottom: "4px !important",
    fontSize: "0.9em",
    color: "rgba(0, 0, 0, 0.54)",
    fontWeight: "300"
  },
  inputDescriptionRoot: {
    flexGrow: 1,
    paddingTop: "0px"
  },
  iconEditButton: {},
  iconButtonRoot: {
    padding: 0
  },
  iconEdit: {
    // color: "white"
    height: "48px",
    width: "48px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgba(0, 0, 0, 0.76)"
  },
  iconNoEdit: {
    // color: "white",
    height: "48px",
    width: "48px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgba(0, 0, 0, 0.76)"
  }
};

export default styles;
