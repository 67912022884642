import _ from "lodash";
import { graphql } from "react-apollo";
import queryString from "query-string";

// Services
import {
  ReferenceTypes,
  getReference,
  DefaultLogbooks
} from "services/LogbookService";

// Queries
import {
  ChecklistTemplatesQuery,
  LogbooksQuery,
  LogbookQuery,
  FindInstancesByTemplateIdFullQuery
} from "api/queries";
import { DeleteLogbookMutation, CreateLogbookMutation } from "api/mutations";

export const Logbooks = graphql(LogbooksQuery, {
  options: props => ({
    fetchPolicy: "network-only",
    variables: {
      orgId: props.user.currentOrganization.id
    }
  })
});

export const DeleteLogbook = graphql(DeleteLogbookMutation, {
  props: ({ mutate }) => ({
    deleteLogbook: (orgId, id, version) => {
      return mutate({
        variables: {
          orgId,
          id,
          version
        },
        update: (proxy, { data }) => {
          const { deleteLogbook: logbook } = data;

          try {
            // need clone: https://github.com/apollographql/apollo-client/issues/3909
            const cachedData = _.cloneDeep(
              proxy.readQuery({
                query: LogbooksQuery,
                variables: {
                  orgId
                }
              })
            );

            // Filter out checklist
            cachedData.getLogbooks.items = [
              ...cachedData.getLogbooks.items.filter(e => e.id !== logbook.id)
            ];

            proxy.writeQuery({
              query: LogbooksQuery,
              variables: {
                orgId
              },
              data: cachedData
            });
          } catch (error) {
            // NOOP - Apollo has an error where a MissingFieldError is thrown if the query doesn't exist in the cachd
            // Fix with upgrade to 3.3. which is dependent on appsync
            // https://www.apollographql.com/docs/react/caching/cache-interaction/
          }
        }
      });
    }
  })
});

export const CreateLogbook = graphql(CreateLogbookMutation, {
  props: ({ mutate }) => ({
    createLogbook: (orgId, metadata, widgets, id) => {
      return mutate({
        variables: {
          orgId,
          metadata,
          widgets,
          id
        },
        update: (proxy, { data }) => {
          const { createLogbook: logbook } = data;

          try {
            const cachedData = proxy.readQuery({
              query: LogbooksQuery,
              variables: {
                orgId
              }
            });

            // Need to filter because called twice
            // https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/282
            cachedData.getLogbooks.items = [
              ...cachedData.getLogbooks.items.filter(e => {
                return e.id !== logbook.id;
              }),
              logbook
            ];

            proxy.writeQuery({
              query: LogbooksQuery,
              variables: {
                orgId
              },
              data: cachedData
            });
          } catch (error) {
            // NOOP - Apollo has an error where a MissingFieldError is thrown if the query doesn't exist in the cachd
            // Fix with upgrade to 3.3. which is dependent on appsync
            // https://www.apollographql.com/docs/react/caching/cache-interaction/
          }
        }
      });
    }
  })
});

export const _LogbookQuery = graphql(LogbookQuery, {
  options: props => {
    const { location, user } = props;
    const orgId = user.currentOrganization.id;
    const { search } = location;
    const value = queryString.parse(search);
    const id = value.id;
    return {
      fetchPolicy: "network-only",
      variables: {
        orgId,
        id
      }
    };
  },
  skip: props => {
    const { location } = props;
    const { search } = location;
    const value = queryString.parse(search);
    const id = value.id;
    return !id || DefaultLogbooks[id];
  }
});

// TODO: figure out how to make sure it gets only the most recent active for the pilot / user
// Currently fetch 50 and not sure it is ordered, but works for demo
export const FindInstancesByTemplateIdFull = graphql(
  FindInstancesByTemplateIdFullQuery,
  {
    name: "referenceInstance",
    options: props => {
      const reference = getReference(props);
      const id =
        reference && reference.checklist
          ? reference.checklist.checklistId
          : null;
      if (!id) {
        return console.log(
          "Error fetching reference instance: no checklist id"
        );
      }
      return {
        fetchPolicy: "network-only",
        variables: {
          templateId: id
        }
      };
    },
    skip: props => {
      const reference = getReference(props);
      const shouldFetch = reference
        ? reference.type === ReferenceTypes.USER_MOST_RECENT_ACTIVE
        : false;
      return !shouldFetch;
    }
  }
);

// Currently queries all checklist templates (TODO: and filters for root in client)
// TODO: switch to ES and just query for root checklists and fields needed
export const RootTemplates = graphql(ChecklistTemplatesQuery, {
  name: "rootTemplates",
  options: props => ({
    fetchPolicy: "network-only",
    variables: {
      orgId: props.user.currentOrganization.id
    }
  }),
  skip: props => {
    const reference = getReference(props);
    const shouldFetch = reference
      ? reference.type === ReferenceTypes.ROOT_CHECKLISTS
      : false;

    return !shouldFetch;
  }
});
