import React from "react";
import { Link } from "react-router-dom";
import { flowRight as compose } from "lodash";

import ingredients from "./ingredient-list.json";

// @material-ui/core components
// import withStyles from "@material-ui/core/styles/withStyles";
// import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Add from "@material-ui/icons/Add";

// Components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

// Subcomponents

// Services

// Assets

import { useTheme, alpha, makeStyles } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import SettingsIcon from "@material-ui/icons/Settings";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ButtonBase from "@material-ui/core/ButtonBase";
import InputBase from "@material-ui/core/InputBase";

const GrayColor = "#aaa";

const useStyles = makeStyles(theme => ({
  root: {
    width: 221,
    fontSize: 13
  },
  button: {
    fontSize: 13,
    width: "100%",
    textAlign: "left",
    paddingBottom: 8,
    color: "#586069",
    fontWeight: 600,
    "&:hover,&:focus": {
      color: "#0366d6"
    },
    "& span": {
      width: "100%"
    },
    "& svg": {
      width: 16,
      height: 16
    }
  },
  tag: {
    marginTop: 3,
    height: 20,
    padding: ".15em 4px",
    fontWeight: 600,
    lineHeight: "15px",
    borderRadius: 2
  },
  popper: {
    border: "1px solid rgba(27,31,35,.15)",
    boxShadow: "0 3px 12px rgba(27,31,35,.15)",
    borderRadius: 3,
    width: 300,
    zIndex: 1,
    fontSize: 13,
    color: "#586069",
    backgroundColor: "#f6f8fa"
  },
  header: {
    borderBottom: "1px solid #e1e4e8",
    padding: "8px 10px",
    fontWeight: 600
  },
  inputBase: {
    padding: 10,
    width: "100%",
    borderBottom: "1px solid #dfe2e5",
    "& input": {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 8,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      border: "1px solid #ced4da",
      fontSize: 14,
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main
      }
    }
  },
  paper: {
    boxShadow: "none",
    margin: 0,
    color: "#586069",
    fontSize: 13
  },
  option: {
    minHeight: "auto",
    alignItems: "flex-start",
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: "transparent"
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover
    }
  },
  popperDisablePortal: {
    position: "relative"
  },
  iconSelected: {
    width: 17,
    height: 17,
    marginRight: 5,
    marginLeft: -2
  },
  color: {
    width: 14,
    height: 14,
    flexShrink: 0,
    borderRadius: 3,
    marginRight: 8,
    marginTop: 2
  },
  text: {
    flexGrow: 1
  },
  close: {
    opacity: 0.6,
    width: 18,
    height: 18,
    "&:hover": {
      cursor: "pointer"
    }
  }
}));

function GitHubLabel() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState([labels[1], labels[11]]);
  const [pendingValue, setPendingValue] = React.useState([]);
  const theme = useTheme();

  const handleClick = event => {
    setPendingValue(value);
    setAnchorEl(event.currentTarget);
  };

  const handleRemove = (ingredient, value, setValue) => {
    const newValue = value.filter(item => {
      return item.name !== ingredient.name;
    });
    setValue(newValue);
  };

  const handleClose = (event, reason) => {
    if (reason === "toggleInput") {
      return;
    }
    setValue(pendingValue);
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "github-label" : undefined;

  return (
    <React.Fragment>
      <div className={classes.root}>
        <ButtonBase
          disableRipple
          className={classes.button}
          aria-describedby={id}
          onClick={handleClick}
        >
          <span>Ingredients</span>
          <SettingsIcon />
        </ButtonBase>
        {value.map((ingredient, i) => (
          <div key={i} style={{ display: "flex", alignItems: "center" }}>
            <div
              key={ingredient.name}
              className={classes.tag}
              style={{
                backgroundColor: ingredient.color || GrayColor,
                color: theme.palette.getContrastText(
                  ingredient.color || GrayColor
                ),
                flexGrow: 1
              }}
            >
              {ingredient.name}
            </div>
            <CloseIcon
              className={classes.close}
              style={{ marginLeft: "8px" }}
              onClick={() => handleRemove(ingredient, value, setValue)}
            />
          </div>
        ))}
      </div>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        className={classes.popper}
      >
        <div className={classes.header}>Choose ingredients in your pantry</div>
        <Autocomplete
          open
          onClose={handleClose}
          multiple
          classes={{
            paper: classes.paper,
            option: classes.option,
            popperDisablePortal: classes.popperDisablePortal
          }}
          value={pendingValue}
          onChange={(event, newValue) => {
            setPendingValue(newValue);
          }}
          disableCloseOnSelect
          disablePortal
          renderTags={() => null}
          noOptionsText="No labels"
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <DoneIcon
                className={classes.iconSelected}
                style={{ visibility: selected ? "visible" : "hidden" }}
              />
              <span
                className={classes.color}
                style={{ backgroundColor: option.color || GrayColor }}
              />
              <div className={classes.text}>
                {option.name}
                {/* <br />
                {option.description} */}
              </div>
              <CloseIcon
                className={classes.close}
                style={{ visibility: selected ? "visible" : "hidden" }}
              />
            </React.Fragment>
          )}
          options={[...labels].sort((a, b) => {
            // Display the selected labels first.
            let ai = value.indexOf(a);
            ai = ai === -1 ? value.length + labels.indexOf(a) : ai;
            let bi = value.indexOf(b);
            bi = bi === -1 ? value.length + labels.indexOf(b) : bi;
            return ai - bi;
          })}
          getOptionLabel={option => option.name}
          renderInput={params => (
            <InputBase
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              autoFocus
              className={classes.inputBase}
            />
          )}
        />
      </Popper>
    </React.Fragment>
  );
}

class Dashboards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmModal: false,
      confirmTitle: "",
      handleConfirm: this.handleClose,
      handleClose: this.handleClose
    };
  }

  render() {
    return (
      <div>
        <GridContainer justifyContent="flex-end">
          <GridItem>
            <Link to={"dashboard?edit=true"}>
              <Button color="info" size="sm">
                <Add /> New Logbook
              </Button>
            </Link>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <Assignment />
                </CardIcon>
              </CardHeader>
              <CardBody>
                <GitHubLabel />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const labels = ingredients
  .map(ingredient => {
    return {
      name: ingredient[0]
    };
  })
  .slice(0, 500);

// From https://github.com/abdonrd/github-labels
// const labels1 = [
//   {
//     name: "good first issue"
//     // color: "#7057ff",
//     // description: "Good for newcomers"
//   },
//   {
//     name: "help wanted",
//     color: "#008672",
//     description: "Extra attention is needed"
//   },
//   {
//     name: "priority: critical",
//     color: "#b60205",
//     description: ""
//   },
//   {
//     name: "priority: high",
//     color: "#d93f0b",
//     description: ""
//   },
//   {
//     name: "priority: low",
//     color: "#0e8a16",
//     description: ""
//   },
//   {
//     name: "priority: medium",
//     color: "#fbca04",
//     description: ""
//   },
//   {
//     name: "status: can't reproduce",
//     color: "#fec1c1",
//     description: ""
//   },
//   {
//     name: "status: confirmed",
//     color: "#215cea",
//     description: ""
//   },
//   {
//     name: "status: duplicate",
//     color: "#cfd3d7",
//     description: "This issue or pull request already exists"
//   },
//   {
//     name: "status: needs information",
//     color: "#fef2c0",
//     description: ""
//   },
//   {
//     name: "status: wont do/fix",
//     color: "#eeeeee",
//     description: "This will not be worked on"
//   },
//   {
//     name: "type: bug",
//     color: "#d73a4a",
//     description: "Something isn't working"
//   },
//   {
//     name: "type: discussion",
//     color: "#d4c5f9",
//     description: ""
//   },
//   {
//     name: "type: documentation",
//     color: "#006b75",
//     description: ""
//   },
//   {
//     name: "type: enhancement",
//     color: "#84b6eb",
//     description: ""
//   },
//   {
//     name: "type: epic",
//     color: "#3e4b9e",
//     description: "A theme of work that contain sub-tasks"
//   },
//   {
//     name: "type: feature request",
//     color: "#fbca04",
//     description: "New feature or request"
//   },
//   {
//     name: "type: question",
//     color: "#d876e3",
//     description: "Further information is requested"
//   }
// ];

export default compose()(Dashboards);
