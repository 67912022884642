/** Import React and 3rd party libs */
import React, { useState } from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Settings from "@material-ui/icons/Settings";
import Close from "@material-ui/icons/Close";
import Add from "@material-ui/icons/Add";

// Components
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Autocomplete from "components/Autocomplete/Autocomplete.jsx";

// Subcomponents
import StepNote from "../../StepComponents/StepNote";
import ViewTitle from "../../StepComponents/ViewTitle";
import ChecklistInput from "./UserSelector";
import ChecklistTemplateSelector from "../../StepComponents/StepEdit/ChecklistTemplateSelector";

// Services
import {
  getCommonTabs,
  EditRow,
  isInputDisabled,
  getIconClass
} from "../StepTypesUtility";
import { getStepRowClass } from "services/StepService";
import { ChecklistMode } from "services/ChecklistService";

// Queries
import { ChecklistTemplateQuery } from "api/queries";

// Assets
import stepsStyle from "assets/jss/material-dashboard-pro-react/components/stepsStyle.jsx";

// Variables
/** Declare all constant variables */

// Functions
const StepIcon = ({ classes, step }) => {
  // ballot or assignment_turned_in
  return (
    <TableCell className={classes.tableCheck}>
      <div className={getIconClass(step, classes)}>
        <Icon>person_outline</Icon>
      </div>
    </TableCell>
  );
};

const StepContent = props => {
  const { classes, step, updateStep, mode, user } = props;
  const isDisabled = isInputDisabled(mode);
  const isTemplateMode =
    mode === ChecklistMode.TEMPLATE_EDIT ||
    mode === ChecklistMode.TEMPLATE_VIEW;
  const isConfigureDisabled = mode !== ChecklistMode.TEMPLATE_EDIT;

  return (
    <TableCell className={classes.tableContent}>
      <ViewTitle {...props} />
      {isTemplateMode ? (
        <StepConfigure
          step={step}
          updateStep={updateStep}
          isDisabled={isConfigureDisabled}
          user={user}
        />
      ) : (
        <StepInput
          step={step}
          updateStep={updateStep}
          isDisabled={isDisabled}
          mode={mode}
        />
      )}
    </TableCell>
  );
};

const StepConfigure = ({ step, updateStep, isDisabled, user }) => {
  return (
    <ChecklistTemplateSelector
      step={step}
      updateStep={updateStep}
      user={user}
      isDisabled={isDisabled}
    />
  );
};

const StepInput = ({ step, updateStep, isDisabled, mode }) => {
  return (
    <ChecklistInput
      step={step}
      mode={mode}
      updateStep={updateStep}
      isDisabled={isDisabled}
    />
  );
};

const addField = (selectField, step, updateStep) => {
  if (!selectField) {
    return;
  }

  let newStep = Object.assign({}, step);
  if (!step.configuration.checklist.steps) {
    step.configuration.checklist.steps = [];
  }
  step.configuration.checklist.steps.push(selectField);
  newStep.configuration = Object.assign({}, step.configuration);

  updateStep(newStep);
};

const clearFields = (step, updateStep) => {
  let newStep = Object.assign({}, step);
  step.configuration.checklist.steps = [];
  newStep.configuration = Object.assign({}, step.configuration);
  updateStep(newStep);
};

const updateStepAndClear = (step, updateStep, setSelectField) => {
  if (setSelectField) {
    setSelectField(null);
  }
  updateStep(step);
};

/**
 * Completed Step
 *
 * Completed a completed checklist
 */
const UserStep = props => {
  const [selectField, setSelectField] = useState(null);
  const {
    classes,
    step,
    updateStep,
    editing,
    setEditState,
    user,
    data,
    noteOpen,
    setNoteState
  } = props;
  const editProps = { editing, setEditState, noteOpen, setNoteState };
  const tabs = getCommonTabs(step, updateStep);
  const showRowDividers = true;
  const primaryTableRowClass = getStepRowClass(
    classes,
    showRowDividers,
    editing || noteOpen
  );

  console.log("USER");

  const { checklist } = step.configuration;
  const template =
    data && data.getChecklistTemplate ? data.getChecklistTemplate : null;
  const availableSteps = template
    ? template.steps.map(step => {
        return {
          label: step.name,
          value: step.stepId,
          type: step.type
        };
      })
    : [];

  const selectInfo = checklist && checklist.steps ? checklist.steps : [];

  // Old configuration - currently do it inline and selection fields should be part of checklist template
  tabs.push({
    tabName: "Configuration",
    tabIcon: Settings,
    tabContent: (
      <span>
        <legend>Choose a Checklist</legend>
        <ChecklistTemplateSelector
          step={step}
          updateStep={step =>
            updateStepAndClear(step, updateStep, setSelectField)
          }
          user={user}
        />
        {checklist ? (
          <span>
            <legend>Choose a Field for Selecting</legend>
            {selectInfo.map(field => field.name).join(" - ")}
            <Autocomplete
              placeholder={"Type to find a checklist"}
              value={selectField}
              onChange={val =>
                setSelectField({
                  name: val.label,
                  stepId: val.value,
                  type: val.type
                })
              }
              choices={availableSteps}
            />
            <Button
              onClick={() => addField(selectField, step, updateStep)}
              size="sm"
              color="primary"
              round
            >
              <Add className={classes.icons} />
              Add Field
            </Button>
            <Button
              onClick={() => clearFields(step, updateStep)}
              size="sm"
              color="primary"
              round
            >
              <Close className={classes.icons} />
              Clear Fields
            </Button>
          </span>
        ) : null}
      </span>
    )
  });

  return (
    <>
      <TableRow key={step.stepId} className={primaryTableRowClass}>
        <StepIcon {...props} step={step} />
        <StepContent {...props} {...editProps} step={step} />
      </TableRow>
      <EditRow classes={classes} open={editing}>
        <CustomTabs title="Configure" headerColor="gray" tabs={tabs} />
      </EditRow>
      <EditRow classes={classes} open={noteOpen}>
        <StepNote step={step} updateStep={updateStep} />
      </EditRow>
    </>
  );
};

export const ChecklistTemplate = graphql(ChecklistTemplateQuery, {
  options: props => {
    const { step, user } = props;
    const orgId = user.currentOrganization.id;
    const { checklistId } = step.configuration.checklist;
    return {
      fetchPolicy: "network-only",
      variables: {
        orgId,
        id: checklistId
      }
    };
  },
  skip: props => {
    const { step } = props;
    const isChecklist = step.configuration.checklist;
    return !isChecklist;
  }
});

export default compose(ChecklistTemplate, withStyles(stepsStyle))(UserStep);
