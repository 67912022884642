import React, { useState } from "react";
import TagsInput from "react-tagsinput";

import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";

import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/components
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Add from "@material-ui/icons/Add";

// Components
// import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Autocomplete from "components/Autocomplete/Autocomplete.jsx";

// Services
import {
  ChecklistMode,
  SharedType,
  NEW_CHECKLIST_DESCRIPTION_MARKER,
  ChecklistNavType,
  isTemplate,
  constructTopics
} from "services/ChecklistService";

function update(property, value, metadata, updateMetadata) {
  const newMetadata = Object.assign({}, metadata);
  newMetadata[property] = value;
  updateMetadata(newMetadata);
}

function updateEventsEnabled(metadata, updateMetadata) {
  const newMetadata = Object.assign({}, metadata);
  const newEventConfig = Object.assign({}, metadata.eventConfig, {
    isEnabled: !metadata.eventConfig.isEnabled
  });
  newMetadata.eventConfig = newEventConfig;
  updateMetadata(newMetadata);
}

function updateEventActionNames(property, value, metadata, updateMetadata) {
  const newMetadata = Object.assign({}, metadata);
  const newEventConfig = Object.assign({}, metadata.eventConfig);
  const newEventActionNames = Object.assign(
    {},
    metadata.eventConfig.actionNames,
    { [property]: value }
  );
  newEventConfig.actionNames = newEventActionNames;
  newMetadata.eventConfig = newEventConfig;
  updateMetadata(newMetadata);
}

function updatePublic(metadata, updateMetadata) {
  const newMetadata = Object.assign({}, metadata);
  switch (metadata.sharing.type) {
    case SharedType.PUBLIC:
      newMetadata.sharing.type = SharedType.PRIVATE;
      break;
    case SharedType.PRIVATE:
      newMetadata.sharing.type = SharedType.PUBLIC;
      break;
    default:
      console.log("Error: unhandled share type");
  }
  updateMetadata(newMetadata);
}

function updatePinnedNav(event, metadata, updateMetadata) {
  const { name, checked } = event.target;
  const newMetadata = Object.assign({}, metadata);
  if (!newMetadata.checklistNav) {
    newMetadata.checklistNav = [];
  }
  if (name === "active") {
    if (checked) {
      newMetadata.checklistNav.push(ChecklistNavType.ACTIVE);
    } else {
      newMetadata.checklistNav = newMetadata.checklistNav.filter(
        item => item !== ChecklistNavType.ACTIVE
      );
    }
  } else if (name === "template") {
    if (checked) {
      newMetadata.checklistNav.push(ChecklistNavType.PRIVATE);
    } else {
      newMetadata.checklistNav = newMetadata.checklistNav.filter(
        item => item !== ChecklistNavType.PRIVATE
      );
    }
  } else {
    console.log("Error: unsupported name");
  }

  updateMetadata(newMetadata);
}

const addField = (selectField, metadata, updateMetadata) => {
  if (!selectField) {
    return;
  }

  const newInstanceName = Object.assign({}, metadata.instanceName);
  if (!newInstanceName.steps) {
    newInstanceName.steps = [];
  }
  newInstanceName.steps.push(selectField);

  update("instanceName", newInstanceName, metadata, updateMetadata);
};

const clearFields = (metadata, updateMetadata) => {
  const newInstanceName = {};
  update("instanceName", newInstanceName, metadata, updateMetadata);
};

// TODO: use publicVersion instead of internal version that increments all the time
// Will also need to clear out internal versions on publish.
const Version = ({ metadata }) => {
  const version = metadata.template
    ? metadata.template.version
    : metadata.version;

  if (!version) {
    return null;
  }

  return <div>version: {version}</div>;
};

const Description = ({ metadata, updateMetadata, mode, classes }) => {
  const currentValue =
    metadata.description &&
    metadata.description !== NEW_CHECKLIST_DESCRIPTION_MARKER
      ? metadata.description
      : "A description of the checklist...";

  return (
    <div style={{ display: "flex", margin: "16px 0" }}>
      <InputBase
        id="description"
        autoFocus={false}
        autoComplete="off"
        value={currentValue}
        placeholder={"Your new checklist description"}
        multiline={true}
        onChange={event =>
          update("description", event.target.value, metadata, updateMetadata)
        }
        // onClick={() => setFocus(step.position)}
        type="text"
        classes={{
          root: classes.inputDescription,
          input: classes.checklistDescription
        }}
        disabled={mode !== ChecklistMode.TEMPLATE_EDIT}
        // inputRef={node => {
        //   // Need to use useState vs useRef here to get it to work
        //   // useRef would set the element than set null on subsequent renders
        //   setInputAnchorElDesc(node);
        // }}
      />
    </div>
  );
};

const Tags = ({ metadata, updateMetadata, mode }) => {
  return (
    <div style={{ margin: "16px 0 16px -8px" }}>
      <TagsInput
        value={metadata.tags}
        disabled={mode !== ChecklistMode.TEMPLATE_EDIT}
        onChange={tags => update("tags", tags, metadata, updateMetadata)}
        tagProps={{ className: "react-tagsinput-tag info" }}
        inputProps={
          mode !== ChecklistMode.TEMPLATE_EDIT
            ? {
                style: { visibility: "hidden" }
              }
            : {}
        }
      />
    </div>
  );
};

const IsPublic = ({ classes, metadata, updateMetadata, mode }) => {
  return (
    <div style={{ margin: "16px 0" }}>
      <FormControlLabel
        control={
          <Switch
            checked={metadata.sharing.type === SharedType.PUBLIC}
            onChange={() => updatePublic(metadata, updateMetadata)}
            value="isPublic"
            classes={{
              switchBase: classes.switchBase,
              checked: classes.switchChecked,
              thumb: classes.switchIcon,
              track: classes.switchBar
            }}
            inputProps={{
              disabled: mode !== ChecklistMode.TEMPLATE_EDIT
            }}
          />
        }
        classes={{
          label: classes.label
        }}
        label="Public"
      />
    </div>
  );
};

const NameString = ({ selectInfo, metadata, steps }) => {
  // Show definition if exists
  let name = selectInfo.map(field => field.name).join(" - ");
  if (!name) {
    // name step
    const nameStep = steps.filter(step => step.name.toLowerCase() === "name");
    if (nameStep.length === 1) {
      name = "Name Step Value";
    }
  }
  if (!name) {
    // checklist name + created time
    name = metadata.name + " + (Short ID)";
  }
  return <span style={{ fontSize: "16px" }}>{name}</span>;
};

const InstanceName = ({
  classes,
  instanceName,
  steps,
  metadata,
  updateMetadata
}) => {
  const [selectField, setSelectField] = useState(null);
  const selectInfo =
    instanceName && instanceName.steps ? instanceName.steps : [];
  const availableSteps = steps
    ? steps.map(step => {
        return {
          label: step.name,
          value: step.stepId,
          type: step.type
        };
      })
    : [];
  return (
    <div>
      <span
        style={{
          opacity: 0.66,
          fontWeight: 500,
          paddingRight: "16px",
          fontSize: "16px"
        }}
      >
        Name Selector:
      </span>
      <NameString selectInfo={selectInfo} metadata={metadata} steps={steps} />
      <Autocomplete
        placeholder={"Type to choose a name field..."}
        value={selectField}
        onChange={val =>
          setSelectField({
            name: val.label,
            stepId: val.value,
            type: val.type
          })
        }
        choices={availableSteps}
      />
      <Button
        onClick={() => addField(selectField, metadata, updateMetadata)}
        size="sm"
        color="info"
      >
        <Add className={classes.icons} />
        Add Field
      </Button>
      <Button
        onClick={() => clearFields(metadata, updateMetadata)}
        size="sm"
        color="info"
      >
        <Close className={classes.icons} />
        Clear Fields
      </Button>
    </div>
  );
};

const BlueCheckbox = withStyles({
  root: {
    "&$checked": {
      color: "#5bc0de"
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

const MetaData = ({
  checklist,
  updateMetadata,
  mode,
  saveButtons,
  classes
}) => {
  const { metadata, steps } = checklist;
  const topics = constructTopics(checklist);
  const { actionNames } = metadata.eventConfig;

  return (
    <div>
      <form>
        <Version metadata={metadata} />
        <Description
          metadata={metadata}
          updateMetadata={updateMetadata}
          mode={mode}
          classes={classes}
        />
      </form>
      <Tags metadata={metadata} updateMetadata={updateMetadata} mode={mode} />
      {isTemplate(checklist) ? (
        <>
          <div style={{ padding: "16px 0 0" }}>
            <Divider />
            <h6 style={{ opacity: "0.76" }}>Sharing</h6>
          </div>
          <IsPublic
            classes={classes}
            metadata={metadata}
            updateMetadata={updateMetadata}
            mode={mode}
          />
          <div style={{ padding: "16px 0 16px" }}>
            <Divider />
            <h6 style={{ opacity: "0.76" }}>Configuration</h6>
          </div>
          <InstanceName
            classes={classes}
            instanceName={metadata.instanceName}
            steps={steps}
            metadata={metadata}
            updateMetadata={updateMetadata}
          />
          <FormGroup row style={{ padding: "32px 0", alignItems: "center" }}>
            <span
              style={{
                opacity: 0.66,
                fontWeight: 500,
                paddingRight: "16px",
                fontSize: "16px"
              }}
            >
              Pin to Left Nav:
            </span>
            <FormControlLabel
              control={
                <BlueCheckbox
                  checked={
                    metadata.checklistNav
                      ? metadata.checklistNav.includes(ChecklistNavType.PRIVATE)
                      : false
                  }
                  onChange={event =>
                    updatePinnedNav(event, metadata, updateMetadata)
                  }
                  name="template"
                />
              }
              label="Template"
            />
            <FormControlLabel
              control={
                <BlueCheckbox
                  checked={
                    metadata.checklistNav
                      ? metadata.checklistNav.includes(ChecklistNavType.ACTIVE)
                      : false
                  }
                  onChange={event =>
                    updatePinnedNav(event, metadata, updateMetadata)
                  }
                  name="active"
                />
              }
              label="Active"
            />
          </FormGroup>
          <div>Event Streams</div>
          <div style={{ margin: "16px 0" }}>
            <FormControlLabel
              control={
                <Switch
                  checked={metadata.eventConfig.isEnabled}
                  onChange={() => updateEventsEnabled(metadata, updateMetadata)}
                  value="eventsEnabled"
                  classes={{
                    switchBase: classes.switchBase,
                    checked: classes.switchChecked,
                    thumb: classes.switchIcon,
                    track: classes.switchBar
                  }}
                  inputProps={{
                    disabled: mode !== ChecklistMode.TEMPLATE_EDIT
                  }}
                />
              }
              classes={{
                label: classes.label
              }}
              label="Enabled"
            />
          </div>
          <div>Event Stream Topics</div>
          <List className={classes.list}>
            {topics.map((topic, key) => {
              return (
                <ListItem key={key} className={classes.listItem}>
                  <ListItemText
                    primary={`${topic.templateName} - ${topic.type}`}
                    secondary={topic.templateId}
                  />
                </ListItem>
              );
            })}
          </List>
          <div>Checklist Creation Action Name</div>
          <div style={{ display: "flex", margin: "16px 0" }}>
            <InputBase
              id="creationName"
              autoFocus={false}
              autoComplete="off"
              value={actionNames.created}
              placeholder={"The checklist creation action name"}
              onChange={event =>
                updateEventActionNames(
                  "created",
                  event.target.value,
                  metadata,
                  updateMetadata
                )
              }
              type="text"
              classes={{
                root: classes.inputDescription,
                input: classes.checklistDescription
              }}
              disabled={mode !== ChecklistMode.TEMPLATE_EDIT}
            />
          </div>
          <div>Checklist Completion Action Name</div>
          <div style={{ display: "flex", margin: "16px 0" }}>
            <InputBase
              id="completionName"
              autoFocus={false}
              autoComplete="off"
              value={actionNames.completed}
              placeholder={"The checklist completion action name"}
              onChange={event =>
                updateEventActionNames(
                  "completed",
                  event.target.value,
                  metadata,
                  updateMetadata
                )
              }
              type="text"
              classes={{
                root: classes.inputDescription,
                input: classes.checklistDescription
              }}
              disabled={mode !== ChecklistMode.TEMPLATE_EDIT}
            />
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
        </>
      ) : null}

      {saveButtons}
    </div>
  );
};

export default MetaData;
