// Hardcoded here for now, but can use to control required signup info based on onboarding flow
// If not oauth, always require email, or email or phone.  If don't have username or something
// that can swap in like firstName (email & phone doen't work), then need to create random id for username
// Alias will let users signin with email of phone.
export const SignupConfig = {
  oath: {
    enabled: true,
    google: true,
    facebook: true,
    amazon: true,
    apple: false
  },
  username: false,
  firstName: true,
  lastName: false,
  email: true,
  phone: true,
  emailOrPhone: true
};
