// ##############################
// // // InfoPage view styles
// #############################

import {
  container,
  cardTitle,
  logoColor
} from "assets/jss/material-dashboard-pro-react.jsx";

import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

const infoPageStyle = theme => ({
  ...customCheckboxRadioSwitch,
  cardTitle: {
    ...cardTitle,
    textAlign: "center"
  },
  container: {
    ...container,
    position: "relative",
    zIndex: "3",
    paddingTop: "10vh"
  },
  cardSignup: {
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    padding: "40px 0px",
    margin: "0"
  },
  center: {
    textAlign: "center"
  },
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  form: {
    padding: "0 20px",
    position: "relative"
  },
  socialTitle: {
    fontSize: "18px"
  },
  inputAdornment: {
    marginRight: "18px",
    position: "relative"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  customFormControlClasses: {
    margin: "0 12px"
  },
  checkboxLabelControl: {
    margin: "0"
  },
  checkboxLabel: {
    marginLeft: "6px",
    color: "rgba(0, 0, 0, 0.26)"
  },
  sectionOne: {
    marginTop: "108px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "30px"
    }
  },
  section: {
    marginTop: "216px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "108px"
    }
  },
  sectionLast: {
    marginTop: "216px",
    marginBottom: "108px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "108px"
    }
  },
  logoContainer: {
    height: "100%",
    maxWidth: "100%",
    [theme.breakpoints.down("xs")]: {
      margin: 0
    }
  },
  logoSection: {
    [theme.breakpoints.up("sm")]: {
      width: "340px"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 15px",
      maxWidth: "100%"
    }
  },
  logoImage: {
    width: "36px"
  },
  droneOps: {
    marginTop: "4px"
  },
  logoName: {
    margin: "0"
  },
  logoColor: {
    color: logoColor
  },
  mobileSplashImg: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "48px"
    }
  },
  tagLine: {
    fontWeight: "400",
    textAlign: "center"
  },
  aboutSection: {
    width: "400px",
    marginLeft: "60px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0"
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
      padding: "0 15px"
    }
  },
  aboutTitle: {
    [theme.breakpoints.down("xs")]: {
      margin: "0 0 10px"
    }
  },
  aboutIcon: {
    fontSize: "24px",
    margin: "12px"
  },
  valueStatements: {
    width: "400px",
    marginLeft: "50px",
    [theme.breakpoints.down("md")]: {
      width: "380px",
      marginLeft: "15px"
    },
    [theme.breakpoints.down("xs")]: {
      width: "290px",
      marginLeft: "0 10px"
    }
  },
  valueRow: {
    [theme.breakpoints.up("sm")]: {
      height: "50px"
    }
  },
  mobileNavImg: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "48px"
    },
    maxWidth: "300px"
  },
  tabletSOP: {
    maxHeight: "500px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "108px",
      maxHeight: "300px"
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%"
    }
  },
  mobileChecklist: {
    maxWidth: "236px",
    margin: "20px"
  },
  desktopLogbook: {
    maxHeight: "500px",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "300px"
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%"
    }
  },
  tabletRampCheck: {
    maxHeight: "500px",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "300px"
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%"
    }
  },
  pictureTagLine: {
    fontWeight: "400",
    textAlign: "center",
    marginTop: "60px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "30px"
    }
  },
  hiddenLink: {
    visibility: "hidden"
  },
  waitlistTitle: {
    ...cardTitle,
    color: "#FFFFFF",
    marginBottom: "12px"
  },
  signupCardHeader: {
    textAlign: "center",
    padding: "30px !important;"
  },
  signupCardBody: {
    paddingBottom: "0"
  },
  signupCardFooter: {
    paddingTop: "0"
  },
  honey: {
    display: "none"
  }
});

export default infoPageStyle;
