/** Import React and 3rd party libs */
import React from "react";

// @material-ui/core components

// @material-ui/icons
/** Import material ui icons */

// Components
/** Import generic components - internal and creative time */

// Subcomponents
import TableConfig from "../../WidgetTypes/Table/TableConfig";
import ChecklistStepConfig from "../../WidgetTypes/ChecklistStep/ChecklistStepConfig";
import CountConfig from "../../WidgetTypes/Count/CountConfig";
import ChecklistConfig from "../../WidgetTypes/Checklist/ChecklistConfig";
import ActivityTimelineConfig from "../../WidgetTypes/ActivityTimeline/ActivityTimelineConfig";

// Services
import { WidgetType } from "services/LogbookService";

// Queries
/** Import all graphql queries */

// Assets

// Variables
/** Declare all constant variables */

// Functions
/** Declare all functions - utility followed by JSX*/

/**
 * WidgetConfigEdit component
 */
const WidgetConfigEdit = ({ widget, updateWidget, user }) => {
  const { type } = widget;
  let configWidget = <div>Default Config</div>;

  switch (type) {
    case WidgetType.TABLE:
      configWidget = (
        <TableConfig widget={widget} updateWidget={updateWidget} user={user} />
      );
      break;
    case WidgetType.CHECKLIST_STEP:
      configWidget = (
        <ChecklistStepConfig
          widget={widget}
          updateWidget={updateWidget}
          user={user}
        />
      );
      break;
    case WidgetType.COUNT:
      configWidget = (
        <CountConfig widget={widget} updateWidget={updateWidget} user={user} />
      );
      break;
    case WidgetType.ACTIVITY_TIMELINE:
      configWidget = (
        <ActivityTimelineConfig
          widget={widget}
          updateWidget={updateWidget}
          user={user}
        />
      );
      break;
    case WidgetType.CHECKLIST:
      configWidget = (
        <ChecklistConfig
          widget={widget}
          updateWidget={updateWidget}
          user={user}
        />
      );
      break;
    default:
      configWidget = <div>Default Config</div>;
  }

  return <div style={{ textAlign: "left" }}>{configWidget}</div>;
};

// Data Connectors

export default WidgetConfigEdit;
