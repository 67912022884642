// Service
import { StepType } from "services/StepService";
import { WidgetType } from "services/LogbookService";

export const COUNT_TYPES = {
  ACTIVE_CHECKLISTS: "ACTIVE_CHECKLISTS",
  COMPLETED_CHECKLISTS: "COMPLETED_CHECKLISTS",
  ACTIVE_STEPS: "ACTIVE_STEPS",
  COMPLETED_STEPS: "COMPLETED_STEPS"
};
export const DURATION_TYPES = {
  LAST_SEVEN_DAYS: "LAST_SEVEN_DAYS",
  LAST_MONTH: "LAST_MONTH"
};
export const STYLE_TYPES = { DIGIT: "DIGIT", STAR: "STAR" };

// Used to dynamicaly create widgets
export function constructWidget(type) {
  const widget = {
    type,
    values: {},
    info: {}
  };
  widget.configuration = getDefaultConfiguration(widget);
  return widget;
}

// Default that populates the modal
export function getDefaultWidget() {
  const widget = {
    type: WidgetType.STATUS,
    values: {
      color: "danger",
      stats: "25"
    },
    info: {}
  };
  widget.configuration = getDefaultConfiguration(widget);
  return widget;
}

export const calculateInitialLayoutPosition = (widget, widgets) => {
  let maxX = 0;
  let maxY = 0;
  let newX = 0;
  let newY = 0;
  widgets.forEach(currWidget => {
    if (currWidget.layout.lg.x >= maxX) {
      maxX = currWidget.layout.lg.x + currWidget.layout.lg.w;
      if (maxX >= 12) {
        maxX = 0;
        maxY = maxY + currWidget.layout.lg.h;
      }
    }
    // TODO: Delete if can't figure out why I had this.  Works better commented out.
    // if (currWidget.layout.lg.y >= maxY) {
    //   maxY = currWidget.layout.lg.y + currWidget.layout.lg.h;
    //   maxX = 0;
    // }
  });

  switch (widget.type) {
    case WidgetType.CHART:
    case WidgetType.STATUS:
    case WidgetType.EXPIRING:
      newX = maxX <= 9 ? maxX : 0;
      newY = maxX <= 9 ? maxY - 3 : maxY; // TODO: fix this?
      if (newY < 0) {
        newY = 0;
      }
      return {
        lg: { x: newX, y: newY, w: 3, h: 5, minW: 2 },
        md: { x: newX, y: newY, w: 3, h: 5, minW: 2 },
        sm: { x: newX, y: newY, w: 3, h: 5, minW: 2 }
      };
    case WidgetType.COUNT:
      newX = 0;
      newY = maxY;
      return {
        lg: { x: newX, y: newY, w: 12, h: 12, minW: 3 },
        md: { x: newX, y: newY, w: 12, h: 12, minW: 3 },
        sm: { x: newX, y: newY, w: 12, h: 12, minW: 3 }
      };
    case WidgetType.CHECKLIST:
    case WidgetType.ACTIVITY_TIMELINE:
      newX = maxX <= 7 ? maxX : 0;
      newY = maxY;
      return {
        lg: { x: newX, y: newY, w: 6, h: 12, minW: 3 },
        md: { x: newX, y: newY, w: 6, h: 12, minW: 3 },
        sm: { x: newX, y: newY, w: 6, h: 12, minW: 3 }
      };
    case WidgetType.LEADERBOARD:
      newX = 0;
      newY = maxY;
      return {
        lg: { x: newX, y: newY, w: 12, h: 16, minW: 3 },
        md: { x: newX, y: newY, w: 12, h: 16, minW: 3 },
        sm: { x: newX, y: newY, w: 12, h: 16, minW: 3 }
      };
    case WidgetType.AWARDBOARD:
      newX = 0;
      newY = maxY;
      return {
        lg: { x: newX, y: newY, w: 12, h: 16, minW: 3 },
        md: { x: newX, y: newY, w: 12, h: 16, minW: 3 },
        sm: { x: newX, y: newY, w: 12, h: 16, minW: 3 }
      };
    case WidgetType.TABLE:
    case WidgetType.TEMPLATE_TABLE:
      newX = 0;
      newY = maxY;
      return {
        lg: { x: newX, y: newY, w: 12, h: 29, minW: 3 },
        md: { x: newX, y: newY, w: 12, h: 29, minW: 3 },
        sm: { x: newX, y: newY, w: 12, h: 29, minW: 3 }
      };
    case WidgetType.CHECKLIST_STEP:
      var { configuration } = widget;
      var stepType = configuration.step ? configuration.step.type : null;
      newX = 0;
      newY = maxY;
      if (
        stepType === StepType.IMAGE_INPUT ||
        stepType === StepType.IMAGE_DISPLAY
      ) {
        return {
          lg: { x: newX, y: newY, w: 12, h: 14, minW: 3 },
          md: { x: newX, y: newY, w: 12, h: 14, minW: 3 },
          sm: { x: newX, y: newY, w: 12, h: 14, minW: 3 }
        };
      } else {
        return {
          lg: { x: newX, y: newY, w: 12, h: 6, minW: 3 },
          md: { x: newX, y: newY, w: 12, h: 6, minW: 3 },
          sm: { x: newX, y: newY, w: 12, h: 6, minW: 3 }
        };
      }
    default:
      console.log("Error - unkown widget type");
      return {};
  }
};

export function getDefaultConfiguration(widget) {
  switch (widget.type) {
    case WidgetType.STATUS:
      return {
        title: "Status Title",
        icon: "content_copy",
        label: "Status Label"
      };
    case WidgetType.EXPIRING:
      return {
        title: "Expiring Title",
        icon: "store",
        label: "Expiring Label"
      };
    case WidgetType.TABLE:
    case WidgetType.TEMPLATE_TABLE:
      return {
        title: "Table Title"
      };
    case WidgetType.CHECKLIST_STEP:
      return {
        title: "Step Title"
      };
    case WidgetType.COUNT:
      // Strings: diplay type (digit, star), count type (step or checklist, completed or active), days prior to count
      return {
        title: "Count Title",
        icon: "content_copy",
        strings: [
          STYLE_TYPES.DIGIT,
          COUNT_TYPES.COMPLETED_STEPS,
          DURATION_TYPES.LAST_SEVEN_DAYS
        ],
        checklists: []
      };
    case WidgetType.CHECKLIST:
      return {
        title: "Checklist Title"
      };
    case WidgetType.CHART:
      return {
        title: "Chart Title",
        icon: "timeline",
        label: "some label"
      };
    default:
      return {};
  }
}

export const PicTypes = {
  UPLOAD: "UPLOAD",
  NORMAL: "NORMAL"
};

export function constructUploadIconUrl(key) {
  return `${PicTypes.UPLOAD}::${key}`;
}

export function getPicInfo(icon) {
  let picType = PicTypes.NORMAL;
  let iconUrl = "";
  if (icon && icon.startsWith(PicTypes.UPLOAD)) {
    iconUrl = icon.split("::")[1];
    return {
      picType: PicTypes.UPLOAD,
      iconUrl
    };
  }

  return { picType, iconUrl };
}
