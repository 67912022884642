import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Menu from "@material-ui/icons/Menu";
import LockOpen from "@material-ui/icons/LockOpen";

// Components
import Button from "components/CustomButtons/Button";

// Services
import pagesRoutes from "routes/landing-pages/landing_pages_routes";
import { isProd } from "services/utility";

// Assets
import pagesHeaderStyle from "assets/jss/material-dashboard-pro-react/components/pagesHeaderStyle.jsx";

class PagesHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  handleDrawerToggle = () => {
    this.setState({ open: !this.state.open });
  };

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.setState({ open: false });
    }
  }

  renderNavItems = (classes, pagesRoutes, user) => {
    const listItems = [];
    if (user.username) {
      listItems.push(
        <ListItem key={"app-dash"} className={classes.listItem}>
          <NavLink to={"/app/ops-dash"} className={classes.navLink}>
            <ListItemIcon className={classes.listItemIcon}>
              <Dashboard />
            </ListItemIcon>
            <ListItemText
              primary={"App"}
              disableTypography={true}
              className={classes.listItemText}
            />
          </NavLink>
        </ListItem>
      );
    }

    const pageItems = pagesRoutes.map((prop, key) => {
      if (!prop.showTopNav) {
        return null;
      }
      const navLink =
        classes.navLink +
        cx({
          [" " + classes.navLinkActive]: this.activeRoute(prop.path)
        });
      return (
        <ListItem key={key} className={classes.listItem}>
          <NavLink to={prop.path} className={navLink}>
            <ListItemIcon className={classes.listItemIcon}>
              <prop.icon />
            </ListItemIcon>
            <ListItemText
              primary={prop.short}
              disableTypography={true}
              className={classes.listItemText}
            />
          </NavLink>
        </ListItem>
      );
    });

    if (user.username) {
      // Only take the first two links - don't show register or login
      pageItems.splice(2);
      pageItems.push(
        <ListItem key="signout" className={classes.listItem}>
          <NavLink
            to="/pages/login-page"
            onClick={user.logout}
            className={classes.navLink}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <LockOpen />
            </ListItemIcon>
            <ListItemText
              primary="Signout"
              disableTypography={true}
              className={classes.listItemText}
            />
          </NavLink>
        </ListItem>
      );
    }

    return listItems.concat(pageItems);
  };

  render() {
    const { classes, color, user } = this.props;
    const appBarClasses = cx({
      [" " + classes[color]]: color
    });

    var list = (
      <List className={classes.list}>
        {this.renderNavItems(classes, pagesRoutes, user)}
      </List>
    );
    return (
      <AppBar position="static" className={classes.appBar + appBarClasses}>
        <Toolbar className={classes.container}>
          <div className={classes.flex}>
            <NavLink to="/">
              <Button className={classes.title} color="transparent">
                Airsync
                <span
                  style={{
                    color: "#17E4F5",
                    fontSize: "2em",
                    marginTop: "-11px"
                  }}
                >
                  .
                </span>
                ai
              </Button>
            </NavLink>
          </div>
          {isProd() ? (
            <List className={classes.list}>
              <ListItem className={classes.listItem}>
                <a href="#sign-up" className={classes.navLink}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <PersonAdd />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Sign Up"}
                    disableTypography={true}
                    className={classes.listItemText}
                  />
                </a>
              </ListItem>
            </List>
          ) : (
            <Hidden smDown>{list}</Hidden>
          )}
          {isProd() ? null : (
            <Hidden mdUp>
              <Button
                className={classes.sidebarButton}
                color="transparent"
                justIcon
                aria-label="open drawer"
                onClick={this.handleDrawerToggle}
              >
                <Menu />
              </Button>
            </Hidden>
          )}
          {isProd() ? null : (
            <Hidden mdUp>
              <Hidden mdUp>
                <Drawer
                  variant="temporary"
                  anchor={"right"}
                  open={this.state.open}
                  classes={{
                    paper: classes.drawerPaper
                  }}
                  onClose={this.handleDrawerToggle}
                  ModalProps={{
                    keepMounted: true // Better open performance on mobile.
                  }}
                >
                  {list}
                </Drawer>
              </Hidden>
            </Hidden>
          )}
        </Toolbar>
      </AppBar>
    );
  }
}

PagesHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"])
};

export default withStyles(pagesHeaderStyle)(PagesHeader);
