import {
  primaryColor,
  dangerColor,
  successColor,
  infoColor,
  grayColor,
  tooltip,
  cardTitle
} from "assets/jss/material-dashboard-pro-react.jsx";

import hoverCardStyle from "assets/jss/material-dashboard-pro-react/hoverCardStyle.jsx";
// import { AutoScaleAxis } from "chartist";

const customCheckboxRadioSwitch = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: infoColor,
    fontSize: "14px",
    lineHeight: "1.428571429",
    display: "inline-flex",
    transition: "0.3s ease all",
    textTransform: "uppercase",
    opacity: "0.86",
    width: "70px"
  },
  switchBase: {
    color: primaryColor + "!important"
  },
  switchIcon: {
    color: grayColor + " !important",
    opacity: "0.86 !important"
  },
  switchIconChecked: {
    color: infoColor + " !important",
    opacity: "0.86 !important"
  },
  switchBar: {
    backgroundColor: "#999999",
    opacity: "0.2 !important"
  },
  switchChecked: {
    "& + $switchBar": {
      backgroundColor: grayColor + " !important"
    }
  }
};

const widgetStyle = {
  ...hoverCardStyle,
  ...customCheckboxRadioSwitch,
  tooltip,
  cardTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    color: "#000000",
    opacity: 0.44,
    fontSize: "1.5em"
  },
  cardProductTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px",
    textAlign: "center"
  },
  cardCategory: {
    color: "#999999",
    fontSize: "14px",
    paddingTop: "10px",
    marginBottom: "0",
    marginTop: "0",
    margin: "0"
  },
  cardProductDesciprion: {
    textAlign: "center",
    color: "#999999"
  },
  stats: {
    color: "#999999",
    fontSize: "12px",
    lineHeight: "22px",
    display: "inline-flex",
    "& svg": {
      position: "relative",
      top: "4px",
      width: "16px",
      height: "16px",
      marginRight: "3px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "4px",
      fontSize: "16px",
      marginRight: "3px"
    }
  },
  productStats: {
    paddingTop: "7px",
    paddingBottom: "7px",
    margin: "0"
  },
  successText: {
    color: successColor
  },
  upArrowCardCategory: {
    width: 14,
    height: 14
  },
  underChartIcons: {
    width: "17px",
    height: "17px"
  },
  price: {
    color: "inherit",
    "& h4": {
      marginBottom: "0px",
      marginTop: "0px"
    }
  },
  edit: {
    backgroundColor: "transparent",
    color: primaryColor,
    boxShadow: "none"
  },
  close: {
    backgroundColor: "transparent",
    color: dangerColor,
    boxShadow: "none"
  },
  widgetActionButton: {
    width: "27px",
    height: "27px",
    padding: "0"
  },
  widgetActionButtonIcon: {
    width: "17px !important",
    height: "17px !important"
  },
  widgetActionButtons: {
    display: "inline",
    float: "right",
    marginTop: "-34px",
    marginRight: "-10px"
  },
  rootTableCellHeader: {
    border: "none",
    paddingLeft: 0,
    opacity: 0.44
  },
  rootTableCell: {
    border: "none",
    padding: 0
  },
  noDataTableCell: {
    border: "none",
    padding: 0,
    height: 140,
    textAlign: "center"
  },
  rootTableRowHeader: {
    height: "28px"
  },
  rootTableRow: {
    height: "28px",
    "&:hover": {
      cursor: "pointer"
    }
  },
  table: {
    marginTop: "8px"
  },
  widgetEditTitle: {
    fontSize: "21px",
    fontWeight: 300,
    opacity: 0.44,
    marginTop: "4px",
    width: "100%"
  },
  widgetDisplayTitle: {
    fontSize: "21px",
    fontWeight: 300,
    color: "rgba(0, 0, 0, 0.44)",
    marginTop: "18px",
    width: "100%"
  },
  cardEditTitle: {
    display: "inline-block",
    width: "80%"
  },
  iconEditButton: {},
  iconButtonRoot: {
    padding: 0,
    "&:hover": {
      backgroundColor: "rgb(239, 239, 239, 0.2)"
    }
  },
  iconEdit: {
    color: "white",
    // height: "28px !important",
    // width: "28px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "&:hover": {
      color: "white"
    }
  },
  iconNoEdit: {
    // color: "white",
    // height: "28px !important",
    // width: "28px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  activePinned: {
    opacity: 1,
    fontSize: "0.9em",
    "& path": {
      stroke: infoColor
    }
  },
  activeNotPinned: {
    opacity: 0.6,
    fontSize: "0.9em"
  }
};

export default widgetStyle;
