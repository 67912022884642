import React, { useState, useRef } from "react";
import { S3Image } from "aws-amplify-react"; // Amplify S3 Image Viewer
import { Storage } from "aws-amplify"; // Amplify S3 Storage

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
import Add from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";
import AddAlert from "@material-ui/icons/AddAlert";
import Star from "@material-ui/icons/Star";

// Components
import Button from "components/CustomButtons/Button.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

// Sub Component
import ChecklistSelector from "components/ChecklistSelector/ChecklistSelector";

// Services
import { capitalizeFirstLetter } from "services/utility";
import {
  COUNT_TYPES,
  STYLE_TYPES,
  DURATION_TYPES,
  getPicInfo,
  PicTypes,
  constructUploadIconUrl
} from "services/WidgetService";

// Asset
import countWidgetStyle from "assets/jss/material-dashboard-pro-react/components/countWidgetStyle.jsx";

// Variables
const MAX_PHOTO_SIZE = 1024 * 1024; // 1MB

const formatValue = keyString => {
  let value = capitalizeFirstLetter(
    keyString
      .toLowerCase()
      .split("_")
      .join(" ")
  );
  console.log("value", value);
  return value;
};

const CountSelector = ({ classes, count, onCountChange }) => {
  return (
    <>
      <h4 style={{ textAlign: "left", marginBottom: "0" }}>Count:</h4>
      <FormControl fullWidth className={classes.selectFormControl}>
        {/* <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
          Count Type
        </InputLabel> */}
        <Select
          MenuProps={{
            className: classes.selectMenu
          }}
          classes={{
            select: classes.select
          }}
          value={count}
          onChange={event => onCountChange(event.target.value)}
          inputProps={{
            name: "simpleSelect",
            id: "simple-select"
          }}
        >
          <MenuItem
            disabled
            classes={{
              root: classes.selectMenuItem
            }}
            value="1"
          >
            Count Type
          </MenuItem>
          {Object.values(COUNT_TYPES).map(item => (
            <MenuItem
              key={item}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value={item}
            >
              {formatValue(item)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

const DurationSelector = ({ classes, duration, onDurationChange }) => {
  return (
    <>
      <h4 style={{ textAlign: "left", marginTop: "-10px", marginBottom: "0" }}>
        Duration:
      </h4>
      <FormControl fullWidth className={classes.selectFormControl}>
        {/* <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
          Count Type
        </InputLabel> */}
        <Select
          MenuProps={{
            className: classes.selectMenu
          }}
          classes={{
            select: classes.select
          }}
          value={duration}
          onChange={event => onDurationChange(event.target.value)}
          inputProps={{
            name: "simpleSelect",
            id: "simple-select"
          }}
        >
          <MenuItem
            disabled
            classes={{
              root: classes.selectMenuItem
            }}
            value="1"
          >
            Duration Type
          </MenuItem>
          {Object.values(DURATION_TYPES).map(item => (
            <MenuItem
              key={item}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value={item}
            >
              {formatValue(item)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

const DisplayStyleSelector = ({
  classes,
  displayStyle,
  onDisplayStyleChange
}) => {
  return (
    <>
      <h4 style={{ textAlign: "left", marginTop: "30px", marginBottom: "0" }}>
        Display Style:
      </h4>
      <FormControl fullWidth className={classes.selectFormControl}>
        {/* <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
          Count Type
        </InputLabel> */}
        <Select
          MenuProps={{
            className: classes.selectMenu
          }}
          classes={{
            select: classes.select
          }}
          value={displayStyle}
          onChange={event => onDisplayStyleChange(event.target.value)}
          inputProps={{
            name: "simpleSelect",
            id: "simple-select"
          }}
        >
          <MenuItem
            disabled
            classes={{
              root: classes.selectMenuItem
            }}
            value="1"
          >
            Style Type
          </MenuItem>
          {Object.values(STYLE_TYPES).map(item => (
            <MenuItem
              key={item}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value={item}
            >
              {formatValue(item)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

const Checklist = ({ classes, checklist, onDelete }) => {
  return (
    <TableRow className={classes.tableRow}>
      <TableCell className={classes.tableContent}>
        <div>{checklist.name}</div>
      </TableCell>
      <TableCell className={classes.tableButton}>
        <IconButton aria-label="Close" className={classes.tableActionButton}>
          <Close
            className={classes.tableActionButtonIcon + " " + classes.close}
            onClick={() => onDelete(checklist.id)}
          />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

const Checklists = ({ classes, checklists, onAdd, onDelete, user }) => {
  const [checklist, setChecklist] = useState(null);

  return (
    <Table className={classes.table}>
      <TableBody>
        {checklists.map(checklist => (
          <Checklist
            key={checklist.id}
            checklist={checklist}
            classes={classes}
            onDelete={onDelete}
          />
        ))}
        <TableRow>
          <TableCell className={classes.tableContent}>
            <ChecklistSelector
              checklist={checklist}
              onChange={setChecklist}
              hideLegend={true}
              user={user}
            />
          </TableCell>
          <TableCell className={classes.tableButton}>
            <Button
              onClick={() => onAdd(checklist)}
              color="info"
              round
              size="sm"
            >
              <Add className={classes.icons} />
              Add
            </Button>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const StarIcon = ({ classes, widget, starIcon, onStarIconChange }) => {
  const uploadButton = useRef(null);
  const [message, setMessage] = useState("");
  const [notification, setNotification] = useState(false);
  const [uploading, setUploading] = useState(false);
  const picInfo = getPicInfo(starIcon || "star");

  const uploadPicture = () => {
    uploadButton.current.click();
  };

  const updateIcon = key => {
    onStarIconChange(constructUploadIconUrl(key));
  };

  const onChange = e => {
    const image = e.target.files[0];
    const { type, size } = image;

    if (size > MAX_PHOTO_SIZE) {
      const message = `Your photo size was ${size /
        MAX_PHOTO_SIZE} MB which is greater than the max size of 1MB.  Please upload a smaller photo.`;
      setMessage(message);
      return setNotification(true);
    }

    setUploading(true);
    Storage.put(`star-pic-${widget.widgetId}`, image, {
      level: "protected",
      contentType: type
    })
      .then(result => {
        setUploading(false);
        updateIcon(result.key);
      })
      .catch(err => {
        setUploading(false);
        setMessage(`Error uploading file: ${err}`);
        setNotification(true);
      });
  };

  return (
    <div className={classes.left}>
      <h4 style={{ textAlign: "left", marginTop: "30px", marginBottom: "0" }}>
        Star Icon:
      </h4>
      <br />
      {picInfo.picType === PicTypes.UPLOAD ? (
        <CardIcon
          style={{
            marginLeft: "64px",
            height: "69px",
            width: "63px",
            borderRadius: "3px",
            boxShadow:
              "0 12px 20px -10px rgba(0, 0, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 0, 0, 0.2)"
          }}
        >
          <S3Image
            theme={{
              photoImg: {
                height: "69px",
                width: "63px"
              }
            }}
            imgKey={picInfo.iconUrl}
            level="protected"
          />
        </CardIcon>
      ) : (
        <Star style={{ color: "#fff52b", fontSize: 64 }} />
      )}

      <br />
      <br />
      {uploading ? (
        <div>
          <CircularProgress className={classes.progress} color="secondary" />
        </div>
      ) : (
        <Button
          color="rose"
          className={classes.uploadButton}
          onClick={uploadPicture}
          round
        >
          Upload Picture
          <input
            type="file"
            accept="image/*"
            className={classes.fileUploadInput}
            onChange={e => onChange(e)}
            ref={uploadButton}
          />
        </Button>
      )}
      <Snackbar
        place="bc"
        color="danger"
        icon={AddAlert}
        message={message}
        open={notification}
        closeNotification={() => setNotification(false)}
        close
      />
    </div>
  );
};

const CountConfig = props => {
  const { classes, widget, updateWidget, user } = props;
  const [checklists, setChecklists] = useState([]);

  const { configuration } = widget;

  const onAdd = checklist => {
    const newChecklists = [...checklists, checklist];
    setChecklists(newChecklists);
    saveChecklists(newChecklists);
  };

  const onDelete = checklistId => {
    const pos = checklists.findIndex(item => item.id === checklistId);
    const newChecklists = [
      ...checklists.slice(0, pos),
      ...checklists.slice(pos + 1, checklists.length)
    ];
    setChecklists(newChecklists);
    saveChecklists(newChecklists);
  };

  const saveChecklists = checklists => {
    const newConfiguration = checklists
      ? {
          checklists
        }
      : {
          checklists: []
        };
    saveConfiguration(newConfiguration);
  };

  const saveConfiguration = newConfiguration => {
    const newWidget = Object.assign({}, widget);
    newWidget.configuration = Object.assign(
      {},
      widget.configuration,
      newConfiguration
    );
    updateWidget(newWidget);
  };

  const displayStyle = configuration.strings[0];
  const onDisplayStyleChange = style => updateStringConfig(style, 0);
  const count = configuration.strings[1];
  const onCountChange = count => updateStringConfig(count, 1);
  const duration = configuration.strings[2];
  const onDurationChange = duration => updateStringConfig(duration, 2);
  const starIcon = configuration.strings[3];
  const onStarIconChange = starIcon => updateStringConfig(starIcon, 3);
  const updateStringConfig = (str, pos) => {
    const strings = configuration.strings || [];
    const newStrings = [
      ...strings.slice(0, pos),
      str,
      ...strings.slice(pos + 1, strings.length)
    ];
    const newConfiguration =
      strings.length > 0
        ? {
            strings: newStrings
          }
        : {
            strings: []
          };
    saveConfiguration(newConfiguration);
  };

  return (
    <div style={{ padding: "10px", textAlign: "left" }}>
      <CountSelector
        classes={classes}
        count={count}
        onCountChange={onCountChange}
      />
      <h4 style={{ textAlign: "left", marginTop: "30px", marginBottom: "0" }}>
        Checklists:
      </h4>
      <Checklists
        classes={classes}
        checklists={checklists}
        onAdd={onAdd}
        onDelete={onDelete}
        user={user}
      />
      <DurationSelector
        classes={classes}
        duration={duration}
        onDurationChange={onDurationChange}
      />
      <DisplayStyleSelector
        classes={classes}
        displayStyle={displayStyle}
        onDisplayStyleChange={onDisplayStyleChange}
      />
      <StarIcon
        classes={classes}
        widget={widget}
        starIcon={starIcon}
        onStarIconChange={onStarIconChange}
      />
    </div>
  );
};

export default withStyles(countWidgetStyle)(CountConfig);
