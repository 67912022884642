import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/core components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.jsx";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
// Services
import notificationsStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(notificationsStyle);

/**
 * Bad design that shoud be refactored -> confirm title is used in the body.  But now want body content and title.
 * So if paragraph is not null, create a separate confirm modal.  Should refactor all confirm modals to this format
 * to look better.
 */
const Confirm = ({
  isOpen,
  title,
  paragraph,
  icon,
  handleConfirm,
  handleClose
}) => {
  const classes = useStyles();
  if (paragraph) {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal + " " + classes.modalSmall
        }}
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="small-modal-slide-title"
        aria-describedby="small-modal-slide-description"
        style={{ textAlign: "left" }}
      >
        <DialogTitle
          id="small-modal-slide-title"
          className={classes.modalHeader}
          style={{ opacity: "0.74" }}
        >
          {icon}
          {title}
          <Button
            justIcon
            className={classes.modalCloseButtonParagraph}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={handleClose}
          >
            <Close className={classes.modalClose} />
          </Button>
        </DialogTitle>
        <DialogContent
          id="small-modal-slide-description"
          className={classes.modalBody + " " + classes.modalSmallBody}
        >
          <div>{paragraph}</div>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={handleClose}
            color="info"
            simple
            className={classes.modalSmallFooterFirstButton}
          >
            Never Mind
          </Button>
          <Button
            onClick={handleConfirm}
            color="info"
            simple
            className={
              classes.modalSmallFooterFirstButton +
              " " +
              classes.modalSmallFooterSecondButton
            }
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  return (
    <Dialog
      classes={{
        root: classes.center + " " + classes.modalRoot,
        paper: classes.modal + " " + classes.modalSmall
      }}
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="small-modal-slide-title"
      aria-describedby="small-modal-slide-description"
    >
      <DialogTitle
        id="small-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={handleClose}
        >
          <Close className={classes.modalClose} />
        </Button>
      </DialogTitle>
      <DialogContent
        id="small-modal-slide-description"
        className={classes.modalBody + " " + classes.modalSmallBody}
      >
        <h5>{title ? title : "Confirm?"}</h5>
      </DialogContent>
      <DialogActions
        className={classes.modalFooter + " " + classes.modalFooterCenter}
      >
        <Button
          onClick={handleClose}
          color="transparent"
          className={classes.modalSmallFooterFirstButton}
        >
          Never Mind
        </Button>
        <Button
          onClick={handleConfirm}
          color="success"
          simple
          className={
            classes.modalSmallFooterFirstButton +
            " " +
            classes.modalSmallFooterSecondButton
          }
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Confirm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string
};

export default Confirm;
