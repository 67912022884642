export const steps = [
  {
    target: "#my-profile",
    content:
      "Edit your profile.  Update your photo.  Change your callsign.  Get Help.  Run the tour again. Or adjust any other app settings",
    placement: "right",
    title: "My Profile",
    disableBeacon: true
  },
  {
    target: "#ops-dashboard",
    content:
      "See an overview of your flight hours and aircraft inventory.  Track currency and maintenance task.  See the logs for your most recent flights and maintenance work.  Customize as you need since the Ops Dashboard is a logbook.",
    placement: "right",
    title: "Ops Dashboard"
  },
  {
    target: "#checklists",
    content:
      "Checklists are the building blocks of Airspace Ops.  Use them to fly, conduct maintenance, document SOPs, add a qualification or an aircraft to your inventory, and much more.  Checklists are fully customizable to suite your operational needs.",
    placement: "right",
    title: "Checklists"
  },
  {
    target: "#logbooks",
    content:
      "Logbooks are customizable views on your checklists.  The details of any completed checklists can be viewed in your logbooks.  No matter if it's flight hours, qual expirations, up coming maintenance, or much more, you can track it in a logbook.",
    placement: "right",
    title: "Logbooks"
  },
  {
    target: "#new-flight",
    content:
      "A link to launch a new flight checklist.  All completed flight checklists will be summed up in the default flight logbook and operations dashboard.  You can easily configure this checklist to your flying needs.",
    placement: "right",
    title: "New Flight"
  },
  {
    target: "#maintenance",
    content:
      "A link to launch a new maintenance checklist.  All completed maintenance will be summed up in the maintenance logbook and operations dashboard.  Again, you can customize this checklist as you need.  Or you can create new checklists for different types of maintenance.",
    placement: "right",
    title: "Maintenance"
  },
  {
    target: "#faa-ramp-check",
    content:
      "Do FAA ramp checks make you nervous?  Worry no more, just click the button for everything you need to show inspectors.  Oh, and this is just another logbook so customize it as you need.",
    placement: "right",
    title: "FAA Ramp Check"
  },
  {
    target: "#search",
    content:
      "Search for any of your completed checklists or logbooks.  Also search for public checklists so you can get flying faster.",
    placement: "bottom",
    title: "Search"
  },
  {
    target: "#notifications",
    content:
      "Look for notifications about any expiring quals or upcoming maintenance.  Stay current, stay safe, and fly professionally",
    placement: "bottom",
    title: "Notifications"
  }
];
