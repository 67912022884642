import Index from "./index/IndexPage";
import Pages from "./landing-pages/LandingPages";
// TODO: need to figure this out for orgs
// Maybe just redirect to Checklists component (or make a dashboard for all orgs)
// If easy just redirect to first link in left nav
import Dashboard from "./app-dashboard/AppDashboard";

var indexRoutes = [
  { path: "/pages", name: "Pages", component: Pages },
  { path: "/app", name: "App", component: Dashboard },
  { path: "/", name: "Home", component: Index } // Order Matters! Catchall.
];

export default indexRoutes;
