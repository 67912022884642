import React, { useState, useRef } from "react";
import classNames from "classnames";

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

// material-ui icons
import KeyboardVoice from "@material-ui/icons/KeyboardVoice";

// Components
import CardHeader from "components/Card/CardHeader.jsx";
import { MediaTabs } from "components/MediaMenu/MediaMenu";
import { isActive, isCompleted, isTemplate } from "services/ChecklistService";

const StyledButton = withStyles({
  root: {
    color: "rgba(255, 255, 255, 0.84)",
    "&:hover": {
      color: "rgba(255, 255, 255, 1)",
      backgroundColor: "rgba(255, 255, 255, 0.08)"
    }
  }
})(IconButton);

const getLabel = checklist => {
  if (isTemplate(checklist)) {
    return "Template";
  } else if (isActive(checklist)) {
    return "Active";
  } else if (isCompleted(checklist)) {
    return "Completed";
  } else {
    return "Unkown";
  }
};

const handleClose = (setMenuAnchorEl, fn) => {
  setMenuAnchorEl(null);
  if (fn) fn();
};

// Really probably want to pull menu out of here and move else where or different UX pattern
const ActionMenu = ({ menuItems }) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const open = Boolean(menuAnchorEl);
  return (
    <div>
      <StyledButton
        aria-label="More"
        aria-owns={open ? "long-menu" : undefined}
        aria-haspopup="true"
        onClick={event => setMenuAnchorEl(event.currentTarget)}
      >
        <MoreVertIcon />
      </StyledButton>
      <Menu
        id="long-menu"
        anchorEl={menuAnchorEl}
        open={open}
        onClose={() => handleClose(setMenuAnchorEl)}
        // PaperProps={{
        //   style: {
        //     maxHeight: 100,
        //     width: 200
        //   }
        // }}
      >
        {menuItems.map(menuItem => (
          <MenuItem
            key={menuItem.title}
            onClick={() => handleClose(setMenuAnchorEl, menuItem.onClick)}
            className="checklistMenuItem"
          >
            <ListItemIcon className="checklistMenuIcon">
              {menuItem.icon}
            </ListItemIcon>
            <ListItemText
              className="checklistMenuText"
              inset={false}
              primary={menuItem.title}
            />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

/**
 * This is the component for diplaying the banner across the top of a checklist
 * May allow images here, TBD
 * Icon is part of the "banner" data object but rendered separately
 */
const ChecklistBanner = ({
  checklist,
  headerColor,
  isEditMode,
  menuItems,
  handleMediaSelect,
  showMedia,
  handleVoiceClick,
  classes
}) => {
  let bannerButtonAnchorEl = useRef(null);
  const [showEdit, setShowEdit] = useState(false);
  const bannerEditVisible = isEditMode && showEdit;
  const voiceEnabled = true;
  const menuClass = classes.cardMenu;
  const statusClasses = classNames({
    [classes.checklistStatus]: true,
    [classes.checklistStatusTemplate]: isTemplate(checklist),
    [classes.checklistStatusActive]: isActive(checklist),
    [classes.checklistStatusCompleted]: isCompleted(checklist)
  });
  // const menuEditClass = bannerEditVisible
  //   ? classes.menuButton
  //   : classes.menuButtonHide;

  const _showMedia = () => {
    const mediaMenu = {
      open: true,
      config: {
        tabs: [MediaTabs.COLORS]
      },
      handleSelect: handleMediaSelect,
      anchorEl: bannerButtonAnchorEl
    };
    showMedia(mediaMenu);
  };

  return (
    <CardHeader
      color={headerColor}
      className={bannerEditVisible ? classes.editHeader : classes.normalHeader}
      onMouseEnter={() => setShowEdit(true)}
      onMouseLeave={() => setShowEdit(false)}
    >
      {menuItems ? (
        <div className={menuClass}>
          <ActionMenu menuItems={menuItems} />
        </div>
      ) : null}
      {voiceEnabled ? (
        <div
          style={{ float: "right" }}
          className={
            bannerEditVisible ? classes.menuButton : classes.menuButtonHide
          }
        >
          <StyledButton aria-label="More" onClick={handleVoiceClick}>
            <KeyboardVoice />
          </StyledButton>
        </div>
      ) : null}
      <div className={classes.cardHeaderWrapper}>
        <Button
          size="small"
          variant="contained"
          className={
            bannerEditVisible ? classes.editButton : classes.editButtonHide
          }
          onClick={_showMedia}
          ref={node => (bannerButtonAnchorEl = node)}
        >
          Change Banner
        </Button>
        <div className={statusClasses}>{getLabel(checklist)}</div>
      </div>
    </CardHeader>
  );
};

export default ChecklistBanner;
