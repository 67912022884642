import React from "react";
import { S3Image } from "aws-amplify-react"; // Amplify S3 Image Viewer

// sub components

// services
import { getPicInfo } from "services/WidgetService";
import {
  aggregateTotalStars,
  handleChecklistClick
} from "../leaderboardService";

import { LeaderboardStyle, countStarTheme } from "../leaderboardStyle";

const UserCell = ({ user, globalDateRange, history }) => {
  const picInfo = getPicInfo(user.icon);
  const starIcon =
    "checklist-zen/lemonadepixel/weddingicons/PNGs/lemonadepixel_weddingicons-38.png";

  return (
    <td style={LeaderboardStyle.user.cell}>
      <div style={LeaderboardStyle.user.imgContainer} key="key">
        <S3Image
          theme={{
            photoImg: {
              height: "69px",
              width: "63px",
              borderRadius: "3px"
            }
          }}
          imgKey={picInfo.iconUrl}
          level="protected"
        />
      </div>
      <div
        style={LeaderboardStyle.user.detailsContainer}
        onClick={() => handleChecklistClick(user, history)}
      >
        <span style={LeaderboardStyle.user.name}>{user.name}</span>
        <br />
        <div style={LeaderboardStyle.user.countContainer}>
          <S3Image imgKey={starIcon} theme={countStarTheme} />
          <small style={LeaderboardStyle.user.countText}>
            {aggregateTotalStars(user, globalDateRange).count}
          </small>
        </div>
      </div>
    </td>
  );
};

export default UserCell;
