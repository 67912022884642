import "raf/polyfill"; // Need for react-snapshot to work
import "unfetch/polyfill";
import React from "react";
import { render } from "react-snapshot";
import App from "./App";

import "assets/scss/material-dashboard-pro-react.css?v=1.4.0";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

render(<App />, document.getElementById("root"));
