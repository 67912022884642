import React from "react";

import InputBase from "@material-ui/core/InputBase";

function update(property, value, metadata, updateMetadata) {
  const newMetadata = Object.assign({}, metadata);
  newMetadata[property] = value;
  updateMetadata(newMetadata);
}

const NameEdit = ({ metadata, updateMetadata, handleKeyDown, classes }) => {
  const isNew = metadata.name === "New Checklist" || metadata.name === "";
  const currentValue = isNew ? "" : metadata.name;
  const placeholder = isNew ? "New Checklist" : "";
  return (
    <div
      className={classes.cardEditTitle}
      onKeyDown={e => handleKeyDown(e, metadata)}
    >
      <InputBase
        id="checklist-name"
        autoFocus={isNew}
        autoComplete="off"
        value={currentValue}
        placeholder={placeholder}
        onChange={event =>
          update("name", event.target.value, metadata, updateMetadata)
        }
        type="text"
        classes={{ root: classes.inputTitle }}
      />
    </div>
  );
};

const NameDisplay = ({ metadata, classes }) => {
  return <div className={classes.cardTitle}>{metadata.name}</div>;
};

const ChecklistName = ({
  isEditMode,
  metadata,
  updateMetadata,
  classes,
  handleKeyDown
}) => {
  return isEditMode ? (
    <NameEdit
      metadata={metadata}
      updateMetadata={updateMetadata}
      classes={classes}
      handleKeyDown={handleKeyDown}
    />
  ) : (
    <NameDisplay metadata={metadata} classes={classes} />
  );
};

export default ChecklistName;
