/** Import React and 3rd party libs */
import React from "react";
import ReactTable from "react-table";
import moment from "moment";

// @material-ui/core components
import Close from "@material-ui/icons/Close";
import Icon from "@material-ui/core/Icon/Icon";

// @material-ui/icons
/** Import material ui icons */

// Components
import Button from "components/CustomButtons/Button.jsx";

// Subcomponents
/** Import components used only by this component - relative path imports */

// Services
import {
  urlName,
  ChecklistsModes,
  StatusType
} from "services/ChecklistService";

// Queries
/** Import all graphql queries */

// Assets
/** Import styles */

// Variables
/** Declare all constant variables */

// Functions

const handleChecklistClick = (mode, typeTemplate, items, history, rowInfo) => {
  const item = items.find(obj => obj.id === rowInfo.original.id);
  if (item) {
    const { id, metadata } = item;
    const { name } = metadata;
    const instanceString = typeTemplate ? "" : "&instance=true";
    let statusString = "";
    if (mode === ChecklistsModes.ACTIVE) statusString = "&status=active";
    if (mode === ChecklistsModes.COMPLETE) statusString = "&status=complete";
    const urlSafe = `/app/checklist/${urlName(
      name
    )}?id=${id}${instanceString}${statusString}&edit=true`;
    history.push(urlSafe);
  }
};

const filterItems = (items, mode) => {
  // TODO: should filter on server
  if (mode === ChecklistsModes.COMPLETE) {
    return items.filter(item => item.metadata.status === StatusType.COMPLETED);
  } else if (mode === ChecklistsModes.ACTIVE) {
    return items.filter(item => item.metadata.status !== StatusType.COMPLETED);
  } else {
    return items;
  }
};

const getIcon = metadata => {
  let icon = "default";
  if (metadata.banner && metadata.banner.icon && metadata.banner.icon.link) {
    icon = metadata.banner.icon.link;
  }
  return icon;
};

const constructChecklist = (checklists, mode, handleDelete) => {
  return checklists.map(checklist => {
    const { id, metadata } = checklist;

    // const StarButton = (
    //   <Button
    //     key="star"
    //     justIcon
    //     round
    //     simple
    //     onClick={e => {
    //       alert("You've clicked LIKE button on " + metadata.name);
    //       e.stopPropagation();
    //     }}
    //     color="warning"
    //     className="like"
    //   >
    //     <Star />
    //   </Button>
    // );

    const DeleteButton = (
      <Button
        key="delete"
        justIcon
        round
        simple
        onClick={e => handleDelete(e, id, metadata)}
        color="danger"
        className="remove"
      >
        <Close />
      </Button>
    );

    const Actions = [];

    Actions.push(DeleteButton);

    return {
      id,
      name: metadata.name,
      icon: getIcon(metadata),
      tags: metadata.tags.map(tag => tag.toUpperCase()).join(", "),
      sharing: metadata.sharing.type,
      created: metadata.created.time,
      actions: (
        // we've added some custom button actions
        <div className="actions-right">{Actions}</div>
      )
    };
  });
};

/**
 * Declare JSX Component - class or
 */
const ChecklistsTable = ({
  items,
  mode,
  typeTemplate,
  history,
  handleDelete
}) => {
  const checklists = constructChecklist(
    filterItems(items, mode),
    mode,
    handleDelete
  ).sort((_a, _b) => {
    const a = _a.created;
    const b = _b.created;
    // Return either 1 or -1 to indicate a sort priority
    if (a > b) {
      return -1;
    }
    if (a < b) {
      return 1;
    }
    // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
    return 0;
  });

  return (
    <ReactTable
      data={checklists}
      // filterable
      columns={[
        {
          accessor: "icon",
          maxWidth: 50,
          sortable: false,
          filterable: false,
          Cell: function iconCell(props) {
            const icon =
              !props.row.icon || props.row.icon === "default"
                ? "content_paste"
                : props.row.icon;
            return <Icon style={{ opacity: 0.54 }}>{icon}</Icon>;
          }
        },
        {
          Header: "Checklist",
          accessor: "name"
        },
        {
          Header: "Created",
          accessor: "created",
          Cell: function iconCell(props) {
            return (
              <span>{moment(props.row.created).format("MMM Do YYYY")}</span>
            );
          }
        },
        {
          Header: "Sharing",
          accessor: "sharing"
        },
        {
          Header: "Tags",
          accessor: "tags"
        },
        {
          // Header: "Actions",
          accessor: "actions",
          sortable: false,
          filterable: false
        }
      ]}
      getTrProps={(state, rowInfo) => {
        return {
          onClick: () =>
            handleChecklistClick(mode, typeTemplate, items, history, rowInfo)
        };
      }}
      defaultPageSize={10}
      showPaginationBottom={true}
      className="-striped -highlight"
    />
  );
};

// Data Connectors

export default ChecklistsTable;
