import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// @material-ui/icons

// Components

// Subcomponents
import ConfigureUnits from "./ConfigureUnits";

// Services
import { OperationType } from "services/ChecklistService";

// Queries

// Assets
import stepsStyle from "assets/jss/material-dashboard-pro-react/components/stepsStyle.jsx";

// Variables

// Functions
const updateFormulaStep = (step, event, number) => {
  const newStep = Object.assign({}, step);
  const configuration = Object.assign({}, step.configuration);
  const formula = Object.assign({}, step.configuration.formula);
  const parts = event.target.value.split(":");
  const [id, name] = parts;
  if (number === 1) {
    formula.step1 = { name, id };
  } else {
    formula.step2 = { name, id };
  }
  configuration.formula = formula;
  newStep.configuration = configuration;
  return newStep;
};

const getStepMenuItems = steps => {
  return steps.map(step => {
    return {
      name: step.name,
      value: step.stepId + ":" + step.name
    };
  });
};

const getOperatorMenuItems = () => {
  const operators = Object.keys(OperationType);
  return operators.map(operator => {
    return {
      name: operator.toLowerCase(),
      value: operator
    };
  });
};

const updateFormulaOperator = (step, event) => {
  const newStep = Object.assign({}, step);
  const configuration = Object.assign({}, step.configuration);
  const formula = Object.assign({}, step.configuration.formula);
  formula.operation = event.target.value || null;
  configuration.formula = formula;
  newStep.configuration = configuration;
  return newStep;
};

const renderStepSelect = (classes, step, steps, updateStep, number) => {
  const label = number === 1 ? "Step One" : "Step Two";
  // Construct a value using id & name so can set on update
  const value =
    number === 1
      ? step.configuration.formula && step.configuration.formula.step1
        ? step.configuration.formula.step1.stepId +
          ":" +
          step.configuration.formula.step1.name
        : ""
      : step.configuration.formula && step.configuration.formula.step2
      ? step.configuration.formula.step2.stepId +
        ":" +
        step.configuration.formula.step2.name
      : "";

  return (
    <FormControl fullWidth className={classes.selectFormControl}>
      <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
        {label}
      </InputLabel>
      <Select
        MenuProps={{
          className: classes.selectMenu
        }}
        classes={{
          select: classes.select
        }}
        value={value}
        onChange={event => updateStep(updateFormulaStep(step, event, number))}
        inputProps={{
          name: "formulaStepSelect",
          id: "forumla-step-select"
        }}
      >
        <MenuItem
          disabled
          classes={{
            root: classes.selectMenuItem
          }}
          value="1"
        >
          Step
        </MenuItem>
        {getStepMenuItems(steps).map(step => (
          <MenuItem
            key={step.value}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected
            }}
            value={step.value}
          >
            {step.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const renderOperatorSelect = (classes, step, updateStep) => {
  const value =
    step.configuration.formula && step.configuration.formula.operation
      ? step.configuration.formula.operation
      : "";

  return (
    <FormControl fullWidth className={classes.selectFormControl}>
      <InputLabel
        htmlFor="formula-operator-select"
        className={classes.selectLabel}
      >
        Operator
      </InputLabel>
      <Select
        MenuProps={{
          className: classes.selectMenu
        }}
        classes={{
          select: classes.select
        }}
        value={value}
        onChange={event => updateStep(updateFormulaOperator(step, event))}
        inputProps={{
          name: "formulaOperatorSelect",
          id: "formula-operator-select"
        }}
      >
        <MenuItem
          disabled
          classes={{
            root: classes.selectMenuItem
          }}
          value="1"
        >
          Step
        </MenuItem>
        {getOperatorMenuItems().map(operator => (
          <MenuItem
            key={operator.value}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected
            }}
            value={operator.value}
          >
            {operator.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const ConfigureCalculated = ({ classes, step, steps, updateStep }) => {
  return (
    <FormControl fullWidth className={classes.selectFormControl}>
      {renderStepSelect(classes, step, steps, updateStep, 1)}
      <br />
      {renderOperatorSelect(classes, step, updateStep)}
      <br />
      {renderStepSelect(classes, step, steps, updateStep, 2)}
      <br />
      {<ConfigureUnits step={step} updateStep={updateStep} />}
    </FormControl>
  );
};

export default withStyles(stepsStyle)(ConfigureCalculated);
