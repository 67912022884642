import React, { useState } from "react";

import moment from "moment";

import { graphql } from "react-apollo";

import { flowRight as compose } from "lodash";

import MUIDataTable from "mui-datatables";

import { withRouter } from "react-router-dom";

import { IconContext } from "react-icons";

// @material-ui/core
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { GrPin } from "react-icons/gr";
import { RiPushpin2Line } from "react-icons/ri";
// import { MdDelete } from "react-icons/md";

// core components
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CardBody from "components/Card/CardBody";
import Confirm from "components/Modal/Confirm";

// subcomponents
import WidgetEditButtons from "../../WidgetComponents/WidgetEditButtons";
import WidgetTitle from "../../WidgetComponents/WidgetTitle";
import WidgetIcon from "../../WidgetComponents/WidgetIcon";

// services
import {
  urlName,
  updateTemplate,
  ChecklistNavType
} from "services/ChecklistService";

// Queries
import { ChecklistTemplatesQuery } from "api/queries";
import {
  DeleteChecklistTemplate,
  BatchDeleteChecklistTemplate,
  CreateChecklistTemplate
} from "views/Checklists/ChecklistComponents/ChecklistQueries";

function updatePinnedNav(
  event,
  name,
  checked,
  template,
  user,
  createChecklistTemplate
) {
  const newTemplate = Object.assign({}, template);
  const newMetadata = Object.assign({}, template.metadata);

  if (!newMetadata.checklistNav) {
    newMetadata.checklistNav = [];
  } else {
    newMetadata.checklistNav = [...newMetadata.checklistNav];
  }
  if (name === "active") {
    if (!checked) {
      newMetadata.checklistNav.push(ChecklistNavType.ACTIVE);
    } else {
      newMetadata.checklistNav = newMetadata.checklistNav.filter(
        item => item !== ChecklistNavType.ACTIVE
      );
    }
  } else if (name === "template") {
    if (!checked) {
      newMetadata.checklistNav.push(ChecklistNavType.PRIVATE);
    } else {
      newMetadata.checklistNav = newMetadata.checklistNav.filter(
        item => item !== ChecklistNavType.PRIVATE
      );
    }
  } else {
    console.log("Error: unsupported name");
  }

  newTemplate.metadata = newMetadata;

  event.stopPropagation();

  const props = { user, createChecklistTemplate };

  updateTemplate(newTemplate, props);
}

const WidgetTemplateTable = ({
  classes,
  mode,
  widget,
  templates,
  editWidget,
  deleteWidget,
  updateWidget,
  history,
  showMedia,
  user,
  deleteChecklistTemplate,
  batchDeleteChecklistTemplate,
  createChecklistTemplate
}) => {
  const [confirmModal, setConfirmModal] = useState({
    open: false,
    title: "Confirm something...",
    onConfirm: () => console.log("confirm default")
  });

  const orgId = user.currentOrganization.id;
  const { values } = widget;
  const templateItems = templates.getChecklistTemplates
    ? templates.getChecklistTemplates.items
    : [];

  const defaultIcon = "content_paste";
  const data = templateItems.map(template => {
    const { metadata, id } = template;
    const {
      name,
      version,
      banner,
      created,
      modified,
      sharing,
      tags,
      checklistNav
    } = metadata;
    const icon =
      banner && banner.icon && banner.icon.link
        ? banner.icon.link
        : defaultIcon;

    // Functionality: move to, delete, pin to template, pin to active
    return {
      id,
      name,
      version,
      icon,
      created: created.time,
      modified: modified.time,
      sharing: sharing.type,
      tags,
      checklistNav,
      template
    };
  });

  data.sort((a, b) => {
    if (a.modified > b.modified) {
      return -1;
    } else if (a.modified < b.modified) {
      return 1;
    } else {
      return 0;
    }
  });

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: function renderTime(dataIndex) {
          const { icon, name } = data[dataIndex];
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Icon style={{ marginRight: "12px", opacity: 0.67 }}>{icon}</Icon>
              <span style={{ fontSize: "1.1em", opacity: 0.87 }}>{name}</span>
            </div>
          );
        }
      }
    },
    {
      name: "version",
      label: "Version",
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "created",
      label: "Created",
      options: {
        filter: false,
        sort: true,
        customBodyRender: function renderTime(value) {
          return <div>{moment(value).format("MMM Do YYYY")}</div>;
        }
      }
    },
    {
      name: "modified",
      label: "Modified",
      options: {
        filter: false,
        sort: true,
        customBodyRender: function renderTime(value) {
          return <div>{moment(value).format("MMM Do YYYY")}</div>;
        }
      }
    },
    {
      name: "sharing",
      label: "Sharing",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "tags",
      label: "Tags",
      options: {
        filter: true,
        sort: true,
        customBodyRender: function renderTime(value) {
          return <div>{value.join(", ")}</div>;
        }
      }
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: function renderActions(value, tableMeta) {
          const { rowIndex } = tableMeta;
          const templateDetails = data[rowIndex];
          const { id, name, version, checklistNav, template } = templateDetails;
          const templatePinned = checklistNav.includes(
            ChecklistNavType.PRIVATE
          );
          const activePinned = checklistNav.includes(ChecklistNavType.ACTIVE);

          return (
            <div>
              <Tooltip
                id="tooltip-pin-template"
                title="Pin to Templates"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <IconButton
                  aria-label="pin"
                  className={classes.tableActionButton}
                  onClick={e =>
                    updatePinnedNav(
                      e,
                      "template",
                      templatePinned,
                      template,
                      user,
                      createChecklistTemplate
                    )
                  }
                  name="template"
                >
                  <RiPushpin2Line
                    style={{ color: templatePinned ? "#00acc1" : "" }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip
                id="tooltip-pin-active"
                title="Pin to Active"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <IconButton
                  aria-label="pin"
                  className={classes.tableActionButton}
                  onClick={e =>
                    updatePinnedNav(
                      e,
                      "active",
                      activePinned,
                      template,
                      user,
                      createChecklistTemplate
                    )
                  }
                  name="active"
                >
                  <IconContext.Provider
                    value={{
                      className: activePinned
                        ? classes.activePinned
                        : classes.activeNotPinned
                    }}
                  >
                    <GrPin />
                  </IconContext.Provider>
                </IconButton>
              </Tooltip>
              <Tooltip
                id="tooltip-pin-active"
                title="Delete Template"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <IconButton
                  aria-label="pin"
                  className={classes.tableActionButton}
                  onClick={e => {
                    setConfirmModal({
                      open: true,
                      title: `Delete Checklist?`,
                      icon: (
                        <Icon style={{ margin: "0 8px -5px 0" }}>
                          delete_outlined
                        </Icon>
                      ),
                      paragraph: (
                        <span>
                          Confirm you wish to delete the template: <br />
                          <span style={{ fontWeight: 700 }}>{name}</span>{" "}
                        </span>
                      ),
                      onConfirm: () => {
                        deleteChecklistTemplate(orgId, id, version);
                        setConfirmModal(
                          Object.assign({}, confirmModal, { open: false })
                        );
                      }
                    });

                    e.stopPropagation();
                  }}
                >
                  <Icon>delete_outlined</Icon>
                </IconButton>
              </Tooltip>
            </div>
          );
        }
      }
    }
  ];

  const options = {
    filterType: "checkbox",
    onRowsDelete: rowsDeleted => {
      const itemsToBeDeleted = rowsDeleted.data.map(
        item => data[item.dataIndex]
      );
      const names = itemsToBeDeleted.map((item, i) => {
        return i !== itemsToBeDeleted.length - 1 ? (
          <div key={i}>
            <span>{item.name}</span>
            <br />
          </div>
        ) : (
          <div key={i}>
            <span>{item.name}</span>
          </div>
        );
      });
      const checklistIds = itemsToBeDeleted.map(item => {
        const { id, version } = item;
        return {
          checklistId: id,
          version
        };
      });

      setConfirmModal({
        open: true,
        title: `Delete Checklists?`,
        icon: <Icon style={{ margin: "0 8px -5px 0" }}>delete_outlined</Icon>,
        paragraph: (
          <span>
            Confirm you wish to delete the following templates: <br />
            <span style={{ fontWeight: 700 }}>{names}</span>{" "}
          </span>
        ),
        onConfirm: () => {
          batchDeleteChecklistTemplate(orgId, checklistIds);
          setConfirmModal(Object.assign({}, confirmModal, { open: false }));
        }
      });
    },
    onRowClick: (rowData, rowMeta) => {
      const { dataIndex } = rowMeta;
      const templateInfo = data[dataIndex];
      const { id, name } = templateInfo;
      const route = `/app/checklist/${urlName(name)}?id=${id}&edit=true`;
      history.push(route);
    }
  };

  return (
    <Card>
      <CardHeader color={values.color} stats icon style={{ textAlign: "left" }}>
        <WidgetEditButtons
          mode={mode}
          classes={classes}
          editWidget={editWidget}
          deleteWidget={deleteWidget}
        />
        <CardIcon
          style={{ padding: "0px", maxHeight: "65px" }}
          color={values.color}
        >
          <WidgetIcon
            isEditMode={true}
            widget={widget}
            updateWidget={updateWidget}
            classes={classes}
            showMedia={showMedia}
          />
          {/* <Icon>{configuration.icon}</Icon> */}
        </CardIcon>

        <WidgetTitle
          isEditMode={true}
          widget={widget}
          updateWidget={updateWidget}
          classes={classes}
        />
      </CardHeader>
      <CardBody>
        <MUIDataTable
          title={""}
          data={data}
          columns={columns}
          options={options}
        />
      </CardBody>
      <Confirm
        isOpen={confirmModal.open}
        handleConfirm={confirmModal.onConfirm}
        handleClose={() =>
          setConfirmModal(Object.assign({}, confirmModal, { open: false }))
        }
        title={confirmModal.title}
        icon={confirmModal.icon}
        paragraph={confirmModal.paragraph}
      />
    </Card>
  );
};

export const Templates = graphql(ChecklistTemplatesQuery, {
  name: "templates",
  options: props => {
    return {
      fetchPolicy: "network-only", // Returns undefined without a call sometimes?
      variables: {
        orgId: props.user.currentOrganization.id
      }
    };
  }
});

export default compose(
  // withStyles(sidebarStyle),
  DeleteChecklistTemplate,
  BatchDeleteChecklistTemplate,
  CreateChecklistTemplate,
  Templates,
  withRouter
)(WidgetTemplateTable);
