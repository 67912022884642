/**
 * Voice Service
 *
 * Uses Web Speech APIs
 * - Speech Recognition
 * - Voice Synthesis
 *
 * Wakewords can be enabled or disabled.
 * Wakewords: Air, Airspace, Space (special handling for Air Space) [along with other common errors]
 *
 * Top Level Commands control execution flow
 * Top Level Commands: Step, Checklist, Settings?, Help, Off (stop listening)
 *
 * All commands including wakeworkds have help.  Help reads off possible next level commands.
 * Not including anything after a wakeword or command results in help.
 *
 * Checklist Commands: start, start <name>, finish, help, list (all steps)
 * - start by iteself requires a checklist context to be set (i.e. clicking the mic icon on a checklist)
 *
 * Step Commands: complete, skip, repeat, previous, help
 * - need to figure out how to read back step (i.e. "step 1, landing gear down", "airspace landing gear down")
 *
 * Recognition uses phonetic hashing to hash all commands.  These commands are then put into a list.
 * The voice input then has white space trimmed and the words split apart on white space.
 * Each word is then phonetically hashed and fuzzymatches against the set of command words.
 * Current hashing algorithm is eudex from talisman lib: https://github.com/Yomguithereal/talisman
 *
 * Note about State
 * Voice commands are tied to the UI, when an update is done, the UI updates
 * (at least in the current implementation when voice is launced from a checklist page)
 * This means the UI state must be updated and the correct checklist updated.
 * For all updates, a callback is passed to get back and set the current checklist.
 * Also, the checklistFns which contain state (need to refactor) are updated to prevent errors
 *
 * Note about Wakewords
 *
 * It's possible to disable the wakeword.  Which means environmental noise could trigger steps.
 * And the most offending culprit is the text synthesis itself.  It should be disabled (listening while talking)
 * but sometimes the help command, listing other commands, can cause one of those commands to execute.
 */
import eudex from "talisman/phonetics/eudex";
import FuzzySet from "fuzzyset";
import { logger } from "services/utility";
import { ChecklistType, StatusType } from "services/ChecklistService";
import { updateStep, toggleCheckbox } from "services/StepService";

// TODO: Add toggle button to turn off recognition?
// TODO: need to pass updated checklist functions with current state on execute & complete redirects
// TODO: Add other step types: input
// TODO: Add step note type
// TODO: Add step uncomplete / clear

/******************************************
 * VOICE SERVICE CONSTANTS
 ******************************************/

const States = {
  LISTENING: "LISTENTING",
  NO_WAKE_WORD: "NO_WAKE_WORD",
  WAKE_WORD_FOUND: "WAKE_WORD_FOUND",
  WAKE_WORD_SKIP: "WAKE_WORD_SKIP",
  NO_TOP_LEVEL_COMMAND: "NO_TOP_LEVEL_COMMAND",
  CHECKLIST_COMMAND: "CHECKLIST_COMMAND",
  STEP_COMMAND: "STEP_COMMAND",
  HELP_COMMAND: "HELP_COMMAND",
  SETTINGS_COMMAND: "SETTINGS_COMMAND",
  SHUTDOWN_COMMAND: "SHUTDOWN_COMMAND",
  SHUT_COMMAND: "SHUT_COMMAND",
  NO_SUB_COMMAND: "NO_SUB_COMMAND",
  CHECKLIST_START: "CHECKLIST_START",
  CHECKLIST_FINISH: "CHECKLIST_FINISH",
  CHECKLIST_LIST: "CHECKLIST_LIST",
  CHECKLIST_INFO: "CHECKLIST_INFO",
  CHECKLIST_HELP: "CHECKLIST_HELP",
  STEP_COMPLETE: "STEP_COMPLETE",
  STEP_REPEAT: "STEP_REPEAT",
  STEP_SKIP: "STEP_SKIP",
  STEP_PREVIOUS: "STEP_PREVIOUS",
  STEP_HELP: "STEP_HELP",
  SETTINGS_WAKEWORD: "SETTINGS_WAKEWORD",
  SETTINGS_WAKE: "SETTINGS_WAKE",
  SETTINGS_HELP: "SETTINGS_HELP",
  ERROR: "ERROR"
};

/******************************************
 * VOICE SERVICE STATE
 ******************************************/

// Later allow user to select - need to load on ready
let voices = [];
let currentVoice = null;
let currentChecklist = null;
let checklistFn = null;
let currentStep = null;
let currentState = States.LISTENING;
let wakewordEnabled = true;
let recognitionRunning = false;

/******************************************
 * VOICE COMMAND INIT
 ******************************************/

const phoneticEncoder = command => {
  return eudex(command).toString();
};

const wakeUpWords = ["air", "airspace", "space", "ace", "aerospace"];
const topLevelCommands = [
  "step",
  "checklist",
  "help",
  "settings",
  "shutdown",
  "shut"
];
const checklistCommands = ["info", "start", "finish", "list", "help"];
// TODO: add step remove command: skips and doesn't play anymore (mark ignore) - don't use in voice and dehighlight
const stepCommands = ["complete", "repeat", "skip", "previous", "help"];
const settingsCommands = ["wakeword", "wake"];
const miscellaneousCommands = ["word", "on", "off"];
const phoneticToCommand = {};
const commandToPhonetic = {};

// Adds values phoneticToCommand and commandToPhonetic maps
// and returns a fuzzy set of the phonetic commands to use for matching
const initPhoneticCommands = (
  commands,
  phoneticToCommand,
  commandToPhonetic
) => {
  const phoneticCommands = [];
  commands.forEach(command => {
    const phonetic = phoneticEncoder(command);
    phoneticToCommand[phonetic] = command;
    commandToPhonetic[command] = phonetic;
    phoneticCommands.push(phonetic);
  });
  return FuzzySet(phoneticCommands);
};

const fuzzyPhoneticCommandsWakeWords = initPhoneticCommands(
  wakeUpWords,
  phoneticToCommand,
  commandToPhonetic
);
const fuzzyPhoneticCommandsTopLevel = initPhoneticCommands(
  topLevelCommands,
  phoneticToCommand,
  commandToPhonetic
);
const fuzzyPhoneticCommandsChecklist = initPhoneticCommands(
  checklistCommands,
  phoneticToCommand,
  commandToPhonetic
);
const fuzzyPhoneticCommandsStep = initPhoneticCommands(
  stepCommands,
  phoneticToCommand,
  commandToPhonetic
);

const fuzzyPhoneticCommandsSettings = initPhoneticCommands(
  settingsCommands,
  phoneticToCommand,
  commandToPhonetic
);

const fuzzyPhoneticMiscellaneousSettings = initPhoneticCommands(
  miscellaneousCommands,
  phoneticToCommand,
  commandToPhonetic
);

/******************************************
 * SPEECH SYNTHESIS SETUP & HANDLERS
 ******************************************/

// Setup Voice Synthesis
const synth = window.speechSynthesis;

// Only run in non-headless
if (window) {
  window.speechSynthesis.onvoiceschanged = () => {
    voices = synth.getVoices().sort(function(a, b) {
      const aname = a.name.toUpperCase(),
        bname = b.name.toUpperCase();
      if (aname < bname) return -1;
      else if (aname === bname) return 0;
      else return +1;
    });
    currentVoice = voices[52]; // Samantha US-En - 52 || Victoria US-En - 60
  };
}

/******************************************
 * VOICE RECOGNITION SETUP & HANDLERS
 ******************************************/

// Setup Speech Recognition
const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
// const SpeechRecognitionEvent =
//   window.SpeechRecognitionEvent || window.webkitSpeechRecognitionEvent;

const recognition = SpeechRecognition ? new SpeechRecognition() : {};
recognition.continuous = true;
recognition.lang = "en-US";
recognition.interimResults = false;
recognition.maxAlternatives = 1;

recognition.onresult = function(event) {
  const results = event.results;
  const last = results.length - 1;
  const statement = results[last][0].transcript;
  const confidence = results[last][0].confidence;
  const commands = statement.trim().split(" ");
  const command = commands.shift();
  const context = {
    currentCommand: command,
    remainingCommands: commands,
    confidence: confidence,
    state: currentState
  };
  logger.log("context", context);
  processCommand(context);
};

recognition.onspeechend = function() {
  logger.log("speech end");
};

recognition.onnomatch = function(event) {
  logger.log("no match", event.results[0][0].transcript);
};

recognition.onerror = function(event) {
  logger.log("Error occurred in recognition: ", event.error);
};

recognition.onstart = function() {
  logger.log("start recognition");
  recognitionRunning = true;
};

recognition.onend = function() {
  logger.log("recognition end - should be running", recognitionRunning);
  if (recognitionRunning) {
    // Restart if ending due to time
    recognitionRunning = false;
    startRecognition();
  }
};

const startRecognition = () => {
  // Need to make sure not speaking when start recognition or else cuts off speech.
  // And that recognition isn't already running.
  const VOICE_START_RETRY = 250;
  if (
    !window.speechSynthesis.speaking &&
    !window.speechSynthesis.pending &&
    !recognitionRunning
  ) {
    logger.log("Voice Recognition Starting...");
    recognition.start();
  } else {
    setTimeout(() => {
      startRecognition();
    }, VOICE_START_RETRY);
  }
};

const stopRecognition = () => {
  // Need to make sure not speaking when shut down recognition or else cuts off speech.
  // And that recognition is running
  const VOICE_STOP_RETRY = 250;
  if (
    !window.speechSynthesis.speaking &&
    !window.speechSynthesis.pending &&
    recognitionRunning
  ) {
    logger.log("Voice Recognition Stopping...");
    recognitionRunning = false;
    recognition.stop();
  } else {
    setTimeout(() => {
      stopRecognition();
    }, VOICE_STOP_RETRY);
  }
};

/******************************************
 * VOICE COMMAND MATCHING
 ******************************************/

// Processes 3 levels deep - Wakeword, Top Level Command, Sub Command
const matchVoiceCommand = context => {
  const { currentCommand, remainingCommands } = context;

  if (!currentCommand) {
    return getNoCommandContext(context, "No Command");
  }

  const fuzzyPhoneticCommandsList = getFuzzyPhoneticCommandsList(context);
  const commandMatches = fuzzyPhoneticCommandsList.get(
    phoneticEncoder(currentCommand)
  );

  if (!commandMatches || commandMatches.length === 0) {
    return getNoCommandContext(context, "No Match");
  }

  const commandMatch = commandMatches[0]; // Always use highest confidence match
  const [confidence, phoneticCommand] = commandMatch;

  if (confidence < getConfidenceThreshold(context)) {
    return getNoCommandContext(context, "Low Confidence");
  }

  const command = phoneticToCommand[phoneticCommand];
  const commandsList = getCommandsList(context);
  if (commandsList.includes(command)) {
    handleSplitWords(context, command, remainingCommands); // Remove next command from remaining if accidental word split
    const newCurrentCommand = remainingCommands.shift();
    const newContext = Object.assign({}, context, {
      state: getNewState(context, command),
      currentCommand: newCurrentCommand,
      remainingCommands
    });

    return newContext;
  }
  return Object.assign({}, context, { state: States.NO_SUB_COMMAND });
};

const getNoCommandContext = (context, message) => {
  return Object.assign({}, context, {
    state: getNoCommandState(context),
    message
  });
};

const getNoCommandState = context => {
  switch (context.state) {
    case States.LISTENING:
      return States.NO_WAKE_WORD;
    case States.WAKE_WORD_FOUND:
    case States.WAKE_WORD_SKIP:
      return States.NO_TOP_LEVEL_COMMAND;
    case States.CHECKLIST_COMMAND:
    case States.STEP_COMMAND:
    case States.SETTINGS_COMMAND:
      return States.NO_SUB_COMMAND;
    default:
      return States.ERROR;
  }
};

const getConfidenceThreshold = () => {
  // Just return a constant for now but can adjust for different groups: wake, top level, checklist, step, etc.
  return 0.85;
};

const getFuzzyPhoneticCommandsList = context => {
  switch (context.state) {
    case States.LISTENING:
      return fuzzyPhoneticCommandsWakeWords;
    case States.WAKE_WORD_FOUND:
    case States.WAKE_WORD_SKIP:
      return fuzzyPhoneticCommandsTopLevel;
    case States.CHECKLIST_COMMAND:
      return fuzzyPhoneticCommandsChecklist;
    case States.STEP_COMMAND:
      return fuzzyPhoneticCommandsStep;
    case States.SETTINGS_COMMAND:
      return fuzzyPhoneticCommandsSettings;
    default:
      logger.error("No fuzzy phonetic command list for context", context);
      return [];
  }
};

const getCommandsList = context => {
  switch (context.state) {
    case States.LISTENING:
      return wakeUpWords;
    case States.WAKE_WORD_FOUND:
    case States.WAKE_WORD_SKIP:
      return topLevelCommands;
    case States.CHECKLIST_COMMAND:
      return checklistCommands;
    case States.STEP_COMMAND:
      return stepCommands;
    case States.SETTINGS_COMMAND:
      return settingsCommands;
    default:
      logger.error("No command list for context", context);
      return [];
  }
};

const getNewState = (context, command) => {
  switch (context.state) {
    case States.LISTENING:
      return States.WAKE_WORD_FOUND;
    case States.WAKE_WORD_FOUND:
    case States.WAKE_WORD_SKIP:
      return `${command.toUpperCase()}_COMMAND`;
    case States.CHECKLIST_COMMAND:
      return `CHECKLIST_${command.toUpperCase()}`;
    case States.STEP_COMMAND:
      return `STEP_${command.toUpperCase()}`;
    case States.SETTINGS_COMMAND:
      return `SETTINGS_${command.toUpperCase()}`;
    default:
      logger.error("No new state for context", context);
      return [];
  }
};

const handleSplitWords = (context, command, remainingCommands) => {
  // Check for "airspace" becoming "air space"
  if (
    context.state === States.LISTENING &&
    command === "air" &&
    remainingCommands[0]
  ) {
    const fuzzyPhoneticCommandsList = getFuzzyPhoneticCommandsList(context);
    const nextCommandMatch = fuzzyPhoneticCommandsList.get(
      phoneticEncoder(remainingCommands[0])
    )[0];
    const [nextConfidence, nextPhoneticCommandMatch] = nextCommandMatch;
    if (nextConfidence > getConfidenceThreshold(context)) {
      const nextCommand = phoneticToCommand[nextPhoneticCommandMatch];
      if (nextCommand === "space") {
        // If airspace split into two words, just skip space part
        remainingCommands.shift();
      }
    }
  }

  // Check for "wakeword" becoming "wake word"
  if (
    context.state === States.SETTINGS_COMMAND &&
    command === "wake" &&
    remainingCommands[0]
  ) {
    const nextCommandMatch = fuzzyPhoneticMiscellaneousSettings.get(
      phoneticEncoder(remainingCommands[0])
    )[0];
    const [nextConfidence, nextPhoneticCommandMatch] = nextCommandMatch;
    if (nextConfidence > getConfidenceThreshold(context)) {
      const nextCommand = phoneticToCommand[nextPhoneticCommandMatch];
      if (nextCommand === "word") {
        // If airspace split into two words, just skip space part
        remainingCommands.shift();
      }
    }
  }
};

/******************************************
 * CHECKLIST COMMAND PROCESSING
 ******************************************/

const processChecklistHelp = context => {
  // TODO: read off step
  logger.log("checklist help command", context);
  read(
    `Valid checklist commands are info, start, finish, list or list steps, and help, which repeats this message.`
  );
};

// Test Checklist <Template> is the currently loaded checklist <and has been marked complete>.

const processChecklistInfo = () => {
  if (!currentChecklist) {
    return read("No checklist loaded.");
  }
  const { metadata, type } = currentChecklist;
  const { name, status } = metadata;
  const template = type === ChecklistType.TEMPLATE ? "template" : "";
  const complete =
    status === StatusType.COMPLETED ? "and has been marked complete." : ".";
  // Note: can worry about version or data later (maybe detailed info command?)
  const info = `${name} ${template} is the currently loaded checklist ${complete}`;
  read(info);
};

const processChecklistStart = context => {
  // TODO: Execute checklist
  logger.log("checklist start command", context);
  currentStep = 1;
  checklistFn.executeChecklist(currentChecklist, setCurrentChecklist);
  // TODO: starts, but need to feed back in active checklist for updating
  // TODO: should probably read off "Start <test checklist>" then step 1.
  readStep(currentChecklist, currentStep);
};

const processChecklistFinish = context => {
  // TODO: Mark the checklist completed
  logger.log("checklist finish command", context);
  if (!currentChecklist) {
    return read("No checklist context.");
  }
  if (!currentStep) {
    return read("No checklist started.");
  }
  checklistFn.completeChecklistNoConfirm(currentChecklist, setCurrentChecklist);
  const { name } = currentChecklist.metadata;
  read(`${name} complete`);
};

const processChecklistList = context => {
  // TODO: read off all the steps
  logger.log("checklist list command", context);
  readSteps(currentChecklist);
};

const setCurrentChecklist = checklist => {
  currentChecklist = checklist;
};

/******************************************
 * STEP COMMAND PROCESSING
 ******************************************/

const processStepHelp = () => {
  read(
    `Valid step commands are complete, repeat, skip, previous, and help, which repeats this message.`
  );
};

// Handle step types: checkbox, note, caution, warning
// Marks a checkbox complete or simply skips text
// TODO: processStepInput for handling input types
// TODO: processStepLaunch for handling sub-checklists
// TODO: processStepSelect for select type options (completed checklist, branches)
// TODO: processStepNote for adding a note
const processStepComplete = () => {
  // TODO: Mark the step completed
  if (!currentChecklist) {
    return read("No checklist loaded.");
  }
  if (!currentStep) {
    return read("No checklist started.");
  }

  const { steps } = currentChecklist;
  const step = steps[currentStep - 1]; // array is zero based

  // TODO: handle non-checkbox type steps (notify need input)

  // Only update if not completed already
  if (!step.value || step.value.checked === false) {
    const updatedStep = toggleCheckbox(step);
    updateStep(
      updatedStep,
      steps,
      checklistFn.updateSteps,
      setCurrentChecklist
    );
  }

  read(`Step ${currentStep} complete`);

  // If last step, notify
  if (currentStep + 1 > currentChecklist.steps.length) {
    return read("At the last step.");
  }

  // Advance to the next step
  currentStep = currentStep + 1;
  readStep(currentChecklist, currentStep);
};

const processStepRepeat = () => {
  if (!currentChecklist) {
    return read("No checklist context.");
  }
  if (!currentStep) {
    return read("No checklist started.");
  }
  readStep(currentChecklist, currentStep);
};

const processStepSkip = () => {
  if (!currentChecklist) {
    return read("No checklist context.");
  }
  if (!currentStep) {
    return read("No checklist started.");
  }
  if (currentStep + 1 > currentChecklist.steps.length) {
    return read("At the last step.");
  }
  currentStep = currentStep + 1;
  readStep(currentChecklist, currentStep);
};

const processStepPrevious = () => {
  // Note: don't unmark complete for now, may need "uncomplete" command)
  if (!currentChecklist) {
    return read("No checklist context.");
  }
  if (!currentStep) {
    return read("No checklist started.");
  }
  if (currentStep - 1 === 0) {
    return read("At the first step.");
  }
  currentStep = currentStep - 1;
  readStep(currentChecklist, currentStep);
};

/******************************************
 * STEP COMMAND PROCESSING
 ******************************************/

const processSettingsHelp = () => {
  read(
    `Valid settings commands are wake word on, wake word off, and help, which repeats this message.`
  );
};

const processSettingsWakeword = context => {
  const { currentCommand } = context;
  if (!currentCommand) {
    return read("Wake word can be turned on or off");
  }
  const nextCommandMatch = fuzzyPhoneticMiscellaneousSettings.get(
    phoneticEncoder(currentCommand)
  )[0];
  const [nextConfidence, nextPhoneticCommandMatch] = nextCommandMatch;
  if (nextConfidence > getConfidenceThreshold(context)) {
    const nextCommand = phoneticToCommand[nextPhoneticCommandMatch];
    if (nextCommand === "off") {
      read("Wake word off");
      wakewordEnabled = false;
    }
    if (nextCommand === "on") {
      read("Wake word on");
      wakewordEnabled = true;
    }
  }
};

/******************************************
 * TOP LEVEL PROCESSING
 ******************************************/

const processChecklistCommand = context => {
  logger.log("checklist command", context);
  logger.log("current checklist", currentChecklist);
  let newContext = context;
  newContext = matchVoiceCommand(newContext);
  switch (newContext.state) {
    case States.CHECKLIST_START:
      processChecklistStart(newContext);
      break;
    case States.CHECKLIST_FINISH:
      processChecklistFinish(newContext);
      break;
    case States.CHECKLIST_LIST:
      processChecklistList(newContext);
      break;
    case States.CHECKLIST_INFO:
      processChecklistInfo(newContext);
      break;
    case States.CHECKLIST_HELP:
    case States.NO_SUB_COMMAND:
      processChecklistHelp(newContext);
      break;
    default:
      logger.error("Invalid Checklist Command State");
  }
};

const processStepCommand = context => {
  logger.log("step command", context);
  logger.log("current checklist", currentChecklist);
  let newContext = context;
  newContext = matchVoiceCommand(newContext);
  switch (newContext.state) {
    case States.STEP_COMPLETE:
      processStepComplete(newContext);
      break;
    case States.STEP_REPEAT:
      processStepRepeat(newContext);
      break;
    case States.STEP_SKIP:
      processStepSkip(newContext);
      break;
    case States.STEP_PREVIOUS:
      processStepPrevious(newContext);
      break;
    case States.STEP_HELP:
    case States.NO_SUB_COMMAND:
      processStepHelp(newContext);
      break;
    default:
      logger.error("Invalid Step Command State");
  }
};

const processSettingsCommand = context => {
  logger.log("settings command", context);
  logger.log("current checklist", currentChecklist);
  let newContext = context;
  newContext = matchVoiceCommand(newContext);
  switch (newContext.state) {
    case States.SETTINGS_WAKEWORD:
    case States.SETTINGS_WAKE:
      processSettingsWakeword(newContext);
      break;
    case States.SETTINGS_HELP:
    case States.NO_SUB_COMMAND:
      processSettingsHelp(newContext);
      break;
    default:
      logger.error("Invalid Settings Command State");
  }
};

const processShutdownCommand = context => {
  // TODO: read off step
  logger.log("shutdown command", context);
  logger.log("current checklist", currentChecklist);
  read(`Airspace, shutting down listening`);
  stop();
};

const processHelpCommand = context => {
  // TODO: read off step
  logger.log("help command", context);
  read(
    `Valid top level commands are checklist, step, settings, shutdown, and help, which repeats this message.  Additionally, all top level commands have a help subcommand.`
  );
};

const processCommand = context => {
  let newContext = context;
  if (wakewordEnabled) {
    newContext = matchVoiceCommand(newContext);
  } else {
    newContext = Object.assign({}, newContext, {
      state: States.WAKE_WORD_SKIP
    });
  }
  if (newContext.state === States.NO_WAKE_WORD) {
    return;
  }
  newContext = matchVoiceCommand(newContext);
  logger.log("switch top level", newContext);
  switch (newContext.state) {
    case States.CHECKLIST_COMMAND:
      processChecklistCommand(newContext);
      break;
    case States.STEP_COMMAND:
      processStepCommand(newContext);
      break;
    case States.SHUTDOWN_COMMAND:
    case States.SHUT_COMMAND:
      processShutdownCommand(newContext);
      break;
    case States.SETTINGS_COMMAND:
      processSettingsCommand(newContext);
      break;
    case States.HELP_COMMAND:
    case States.NO_TOP_LEVEL_COMMAND:
      processHelpCommand(newContext);
      break;
    default:
      logger.error("Invalid Top Level Command State");
  }
};

/******************************************
 * Utility
 ******************************************/

const read = message => {
  const statement = new SpeechSynthesisUtterance(message);
  statement.lang = "en-US";
  statement.voice = currentVoice;
  synth.speak(statement);
};

const readStep = (checklist, stepNumber) => {
  const step = checklist.steps[stepNumber - 1]; // Assuming in order, should either sort or pull out by position
  read(`Step ${stepNumber}, ${step.name}`);
};

const readSteps = checklist => {
  const { steps } = checklist;
  // Assuming in order
  steps.forEach((step, i) => {
    read(`Step ${i + 1}, ${step.name}`);
  });
};

/******************************************
 * Exports
 ******************************************/

export const start = (checklist, _checklistFn) => {
  console.log("checklist", checklist);
  currentChecklist = checklist;
  checklistFn = _checklistFn;

  // For instance types start on step one
  if (checklist.type === ChecklistType.INSTANCE) {
    currentStep = 1;
  }

  read(`Airspace listening`);
  startRecognition();
};

export const stop = () => {
  currentChecklist = null;
  currentStep = null;
  stopRecognition();
};

// Since checklistFn depend on state, need to reset when checklist is changed
// Relevant when execute or complete happens and a "new" checklist is loaded
export const setChecklistFunctions = _checklistFn => {
  checklistFn = _checklistFn;
};
