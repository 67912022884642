/** Import React and 3rd party libs */
import React from "react";

// @material-ui/core components
/** Import material ui components */

// @material-ui/icons
import Ballot from "@material-ui/icons/Ballot";
import AlarmAdd from "@material-ui/icons/AlarmAdd";
import Info from "@material-ui/icons/Info";
import Settings from "@material-ui/icons/Settings";

// Components
/** Import generic components - internal and creative time */

// Subcomponents
import WidgetInfoEdit from "./WidgetInfoEdit";
import WidgetTypeEdit from "./WidgetTypeEdit";
import WidgetConfigEdit from "./WidgetConfigEdit";

// Services
/** Import all services and variables */

// Queries
/** Import all graphql queries */

// Assets
/** Import styles */

// Variables
/** Declare all constant variables */

// Functions
export const getWidgetEditTabs = (widget, updateWidget, isOpen, user) => {
  return [
    {
      tabName: "Type",
      tabIcon: Ballot,
      tabContent: <WidgetTypeEdit widget={widget} updateWidget={updateWidget} />
    },
    {
      tabName: "Config",
      tabIcon: Settings,
      tabContent: isOpen ? (
        <WidgetConfigEdit
          widget={widget}
          updateWidget={updateWidget}
          user={user}
        />
      ) : (
        <div>holder</div>
      )
    },
    {
      tabName: "Info",
      tabIcon: Info,
      tabContent: <WidgetInfoEdit widget={widget} updateWidget={updateWidget} />
    },
    {
      tabName: "Alert",
      tabIcon: AlarmAdd,
      tabContent: <div>Alert</div>
    }
  ];
};
