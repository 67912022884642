import React from "react";

// sub-components
import Award from "./Award";
import UserCell from "./UserCell";

// services

// style
import { AwardboardStyle } from "../awardboardStyle";

const Rows = ({ users, updateAward, history }) => {
  const userRows = users.map(user => {
    if (!user.instances[0]) {
      return null;
    }

    const awards = user.awards.map(award => {
      return (
        <Award
          key={`${user.id}::${award.id}`}
          user={user}
          award={award}
          updateAward={updateAward}
        />
      );
    });

    return (
      <tr key={user.name} style={AwardboardStyle.bodyRow}>
        <UserCell user={user} history={history} />
        {awards}
      </tr>
    );
  });

  return <tbody>{userRows}</tbody>;
};

export default Rows;
