import React, { useRef, useEffect } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";

function CustomInput({ ...props }) {
  const {
    classes,
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    helpText,
    setFocus
  } = props;

  const inputEl = useRef(null);

  useEffect(() => {
    if (setFocus) {
      inputEl.current.focus();
    }
  }, []);

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });
  // const underlineClasses = classNames({
  //   [classes.underlineError]: error,
  //   [classes.underlineSuccess]: success && !error,
  //   [classes.underline]: true,
  //   [classes.whiteUnderline]: white
  // });
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white
  });
  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }
  var helpTextClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error
  });
  const inputType =
    formControlProps.variant === "outlined" ? (
      <FormControl {...formControlProps} className={formControlClasses}>
        <InputLabel htmlFor={id}>{labelText}</InputLabel>
        <OutlinedInput
          id={id}
          value={name}
          label={labelText}
          {...inputProps}
          aria-describedby={id + "-text"}
        />
        {helpText !== undefined ? (
          <FormHelperText id={id + "-text"} className={helpTextClasses}>
            {helpText}
          </FormHelperText>
        ) : null}
      </FormControl>
    ) : (
      <FormControl {...formControlProps} className={formControlClasses}>
        {labelText !== undefined ? (
          <InputLabel
            className={classes.labelRoot + " " + labelClasses}
            htmlFor={id + "-text"}
            {...labelProps}
          >
            {labelText}
          </InputLabel>
        ) : null}
        <Input
          inputRef={inputEl}
          classes={{
            input: inputClasses,
            root: marginTop,
            disabled: classes.disabled
          }}
          id={id}
          {...inputProps}
        />
        {helpText !== undefined ? (
          <FormHelperText id={id + "-text"} className={helpTextClasses}>
            {helpText}
          </FormHelperText>
        ) : null}
      </FormControl>
    );
  return inputType;
}

CustomInput.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helpText: PropTypes.node,
  setFocus: PropTypes.bool
};

export default withStyles(customInputStyle)(CustomInput);
