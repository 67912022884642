/** Import React and 3rd party libs */
import React, { useState, useEffect } from "react";
import Datetime from "react-datetime";
import moment from "moment";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Button from "components/CustomButtons/Button.jsx";
import FormControl from "@material-ui/core/FormControl";
import Switch from "@material-ui/core/Switch";
import Collapse from "@material-ui/core/Collapse";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import { BsClock } from "react-icons/bs";
import { IoEnterOutline } from "react-icons/io5";
import { IoExitOutline } from "react-icons/io5";

// Components

// Subcomponents
import StepNote from "../../StepComponents/StepNote";
import ViewTitle from "../../StepComponents/ViewTitle";

// Services
import {
  getCommonTabs,
  EditRow,
  isInputDisabled,
  getIconClass
} from "../StepTypesUtility";
import { getStepRowClass } from "services/StepService";

// Queries
/** Import all graphql queries */

// Assets
import stepsStyle from "assets/jss/material-dashboard-pro-react/components/stepsStyle.jsx";

// Variables
/** Declare all constant variables */

// Functions
const StepIcon = ({ classes, step }) => {
  const iconType = <BsClock />;
  return (
    <TableCell className={classes.tableCheck}>
      <div
        className={getIconClass(step, classes)}
        style={{ fontSize: "20px", paddingLeft: "15px" }}
      >
        {iconType}
      </div>
    </TableCell>
  );
};

const StepContent = props => {
  const { classes, mode } = props;
  const isDisabled = isInputDisabled(mode);
  return (
    <TableCell className={classes.tableContent}>
      <ViewTitle {...props} />
      <StepInput isDisabled={isDisabled} {...props} />
    </TableCell>
  );
};

const updateTimeClock = (
  moment,
  property,
  timeClock,
  setTimeClock,
  breakNum
) => {
  // let newStep = Object.assign({}, step);
  // let value = Object.assign({}, step.value);
  let newTimeClock;
  if (typeof breakNum !== "undefined") {
    const newBreak = Object.assign({}, timeClock.breaks[breakNum], {
      [property]: moment.format()
    });
    timeClock.breaks[breakNum] = newBreak;
    const newBreaks = [...timeClock.breaks];
    newTimeClock = Object.assign({}, timeClock, { breaks: newBreaks });
  } else {
    newTimeClock = Object.assign({}, timeClock, {
      [property]: moment.format()
    });
  }
  setTimeClock(newTimeClock);
};

const DateInput = ({ value, updateTimeClock, label, isDisabled, inputRef }) => {
  return (
    <FormControl fullWidth>
      <Datetime
        value={value ? moment(value) : null}
        ref={inputRef}
        inputProps={{
          disabled: isDisabled,
          placeholder: label,
          style: { textAlign: "center" }
        }}
        initialViewMode={"time"}
        onChange={updateTimeClock}
      />
    </FormControl>
  );
};

const calculateTotalDifference = breaks => {
  const totals = breaks.reduce(
    (total, next) => {
      const date_past = new Date(next.start);
      const date_future = next.stop ? new Date(next.stop) : new Date();
      const difference = calculateDateDifference(date_past, date_future);
      return {
        hours: total.hours + difference.hours,
        minutes: total.minutes + difference.minutes,
        seconds: total.seconds + difference.seconds
      };
    },
    {
      hours: 0,
      minutes: 0,
      seconds: 0
    }
  );
  return totals;
};

const calculateDateDifference = (date_past, date_future) => {
  if (date_future - date_past < 0) {
    console.log("Invalid future date");
    return "00:00:00";
  }
  // get total seconds between the times
  let delta = Math.abs(date_future - date_past) / 1000;

  // calculate (and subtract) whole days
  const days = Math.floor(delta / 86400);
  delta -= days * 86400;

  // calculate (and subtract) whole hours
  let hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;
  // Add days back to hours
  hours += days * 24;

  // calculate (and subtract) whole minutes
  const minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  // what's left is seconds
  const seconds = Math.floor(delta % 60); // in theory the modulus is not required

  return { hours, minutes, seconds };
};

const adjustTime = (time, breakTime) => {
  let hours = time.hours - breakTime.hours;
  let minutes = time.minutes - breakTime.minutes;
  let seconds = time.seconds - breakTime.seconds;
  if (seconds < 0) {
    minutes = minutes - 1;
    seconds = seconds + 60;
  }
  if (minutes < 0) {
    hours = hours - 1;
    minutes = minutes + 60;
  }
  if (hours < 0) {
    hours = 0;
    minutes = 0;
    seconds = 0;
    console.log("Invalid break time");
  }
  return { hours, minutes, seconds };
};

const formatTime = times => {
  const { hours, minutes, seconds } = times;
  const hoursString = hours.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false
  });

  const minutesString = minutes.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false
  });

  const secondsString = seconds.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false
  });

  return `${hoursString}:${minutesString}:${secondsString}`;
};

const BlueSwitch = withStyles({
  switchBase: {
    // color: purple[300],
    "&$checked": {
      color: "#00acc1"
    },
    "&$checked + $track": {
      backgroundColor: "rgba(170, 170, 170)"
    }
  },
  checked: {},
  track: {}
})(Switch);

const updateValue = (timeClock, step, updateStep) => {
  let newStep = Object.assign({}, step);
  let newValue = Object.assign({}, step.value);
  newValue.json = JSON.stringify(timeClock);
  newStep.value = newValue;
  updateStep(newStep);
};

const StepInput = props => {
  const { step, updateStep, isDisabled } = props;
  const [timeClock, _setTimeClock] = useState({
    clockIn: "",
    clockOut: "",
    breaks: []
  });
  const [clockedInCount, setClockedInCount] = useState("00:00:00");
  const [currentBreakCount, setCurrentBreakCount] = useState("00:00:00");
  const [totalBreakCount, setTotalBreakCount] = useState("00:00:00");
  const [showBreaks, setShowBreaks] = useState(false);

  const setTimeClock = timeClock => {
    _setTimeClock(timeClock);
    updateValue(timeClock, step, updateStep);
  };

  const { clockIn, clockOut, breaks } = timeClock;

  const currentBreak = breaks[breaks.length - 1];
  const onBreak = currentBreak && !currentBreak.stop ? true : false;
  const breakIcon = onBreak ? "pause_circle_outline" : "play_circle_outline";
  const breakText = onBreak ? "Stop Break" : "Start Break";

  // isDisabled if not edit permissions / mode
  const clockInTimeDisabled = !clockIn || isDisabled; // Disabled when not clocked in
  const clockInButtonDisabled = !!clockIn || isDisabled; // Disabled after clocked in
  const clockOutTimeDisabled = !clockOut || isDisabled; // Disabled when not clocked out
  const clockOutButtonDisabled = !clockIn || !!clockOut || isDisabled; // Disabled when not clocked in or clocked out
  const breakTimeDisabled = isDisabled;
  const breakButtonDisabled = !clockIn || !!clockOut || isDisabled; // Disabled when not clocked in or clocked out

  const clockTimeFn = prop => {
    const currentTime = moment(new Date()).format();
    let newTimeClock = Object.assign({}, timeClock, {
      [prop]: currentTime
    });

    // If on break at clockOut, end break
    if (prop === "clockOut" && currentBreak && !currentBreak.stop) {
      const newBreak = Object.assign({}, currentBreak, { stop: currentTime });
      breaks.pop(); // Remove last break from list
      breaks.push(newBreak);
      const newBreaks = [...breaks];
      const newTimeClock = Object.assign({}, newTimeClock, {
        breaks: newBreaks
      });
    }
    setTimeClock(newTimeClock);
  };

  const breakTimeFn = () => {
    const currentTime = moment(new Date()).format();
    let newBreak;
    if (breaks.length === 0) {
      newBreak = { start: currentTime };
    } else {
      const latestBreak = breaks[breaks.length - 1];
      if (latestBreak.stop) {
        newBreak = { start: currentTime };
      } else {
        newBreak = Object.assign({}, latestBreak, { stop: currentTime });
        breaks.pop(); // Remove last break from list
      }
    }
    breaks.push(newBreak);
    const newBreaks = [...breaks];
    const newTimeClock = Object.assign({}, timeClock, { breaks: newBreaks });
    setTimeClock(newTimeClock);
  };

  useEffect(() => {
    if (step && step.value && step.value.json) {
      const timeClock = JSON.parse(step.value.json);
      setTimeClock(timeClock);
    }
  }, []);

  useEffect(() => {
    if (clockIn) {
      // const x = true;
      // if (x) return;

      if (!clockOut && !onBreak) {
        let clockedInTimer = setInterval(() => {
          const timeDifference = calculateDateDifference(
            new Date(clockIn),
            new Date()
          );
          const totalBreakTime = calculateTotalDifference(breaks);
          const breakAdjustedTime = adjustTime(timeDifference, totalBreakTime);
          const newTimer = formatTime(breakAdjustedTime);
          setClockedInCount(newTimer);
          const newTotalTime = formatTime(totalBreakTime);
          setTotalBreakCount(newTotalTime);
        }, 1000);

        return () => {
          clearTimeout(clockedInTimer);
        };
      } else if (onBreak) {
        // Set main timer to stop at difference
        const timeDifference = calculateDateDifference(
          new Date(clockIn),
          new Date()
        );

        const totalBreakTime = calculateTotalDifference(breaks);
        const breakAdjustedTime = adjustTime(timeDifference, totalBreakTime);
        const newTimer = formatTime(breakAdjustedTime);
        setClockedInCount(newTimer);

        // Start break timer
        let breakTimer = setInterval(() => {
          const currentTimeDifference = calculateDateDifference(
            new Date(currentBreak.start),
            new Date()
          );
          const newCurrentTimer = formatTime(currentTimeDifference);
          setCurrentBreakCount(newCurrentTimer);
          const totalBreakTime = calculateTotalDifference(breaks);
          const newTotalTime = formatTime(totalBreakTime);
          setTotalBreakCount(newTotalTime);
        }, 1000);

        return () => {
          clearTimeout(breakTimer);
        };
      } else {
        const timeDifference = calculateDateDifference(
          new Date(clockIn),
          new Date(clockOut)
        );
        const totalBreakTime = calculateTotalDifference(breaks);
        const breakAdjustedTime = adjustTime(timeDifference, totalBreakTime);
        const newTimer = formatTime(breakAdjustedTime);
        setClockedInCount(newTimer);
        const newTotalTime = formatTime(totalBreakTime);
        setTotalBreakCount(newTotalTime);
      }
    }
  }, [clockIn, clockOut, breaks]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          flex: "100%",
          textAlign: "center",
          fontSize: "36px",
          padding: "12px 16px",
          color: "#00acc1"
        }}
      >
        {clockedInCount}
      </div>
      <div
        style={{ margin: "0 0 16px", display: "flex", flexDirection: "row" }}
      >
        <div style={{ padding: "0 16px" }}>
          <Button
            style={{ marginTop: "15px", flex: "50%" }}
            color="info"
            fullWidth={true}
            onClick={() => clockTimeFn("clockIn")}
            disabled={clockInButtonDisabled}
          >
            <IoEnterOutline /> Clock In
          </Button>
          <DateInput
            value={clockIn}
            label="Clock In Time"
            updateTimeClock={moment =>
              updateTimeClock(moment, "clockIn", timeClock, setTimeClock)
            }
            isDisabled={clockInTimeDisabled}
          />
        </div>
        <div style={{ padding: "0 16px", flex: "50%" }}>
          <Button
            style={{ marginTop: "15px" }}
            color="info"
            fullWidth={true}
            onClick={() => clockTimeFn("clockOut")}
            disabled={clockOutButtonDisabled}
          >
            <IoExitOutline /> Clock Out
          </Button>
          <DateInput
            value={clockOut}
            label="Clock Out Time"
            updateTimeClock={moment =>
              updateTimeClock(moment, "clockOut", timeClock, setTimeClock)
            }
            isDisabled={clockOutTimeDisabled}
          />
        </div>
      </div>
      <div style={{ margin: "0", display: "flex", flexDirection: "row" }}>
        <div style={{ padding: "0 16px", flex: "50%" }}>
          <Button
            style={{ marginTop: "15px" }}
            fullWidth={true}
            onClick={breakTimeFn}
            disabled={breakButtonDisabled}
          >
            <Icon>{breakIcon}</Icon> {breakText}
          </Button>
        </div>

        <div
          style={{
            display: "flex",
            flex: "50%",
            justifyContent: "space-evenly"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <div
              style={{
                paddingTop: "10px",
                color: "rgba(0, 0, 0, 0.24)",
                fontWeight: "500"
              }}
            >
              Current
            </div>
            <div
              style={{
                fontSize: "24px",
                padding: "0px 16px 12px",
                color: "rgba(0, 0, 0, 0.34"
              }}
            >
              {currentBreakCount}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <div
              style={{
                paddingTop: "10px",
                color: "rgba(0, 0, 0, 0.24)",
                fontWeight: "500"
              }}
            >
              Total
            </div>
            <div
              style={{
                fontSize: "24px",
                padding: "0px 16px 12px",
                color: "rgba(0, 0, 0, 0.34"
              }}
            >
              {totalBreakCount}
            </div>
          </div>
        </div>
      </div>
      <FormControlLabel
        style={{ margin: "0 0 8px 12px" }}
        control={
          <BlueSwitch
            size="small"
            checked={showBreaks}
            onChange={() => setShowBreaks(!showBreaks)}
          />
        }
        label="Show Breaks"
      />
      <div>
        <Collapse in={showBreaks}>
          {breaks.map((_break, i) => {
            const topMargin = i === 0 ? "0" : "16px";
            return (
              <div key={`break-${i}`}>
                <div style={{ marginLeft: "16px", marginTop: topMargin }}>
                  <h6 style={{ margin: "0" }}>Break {i + 1}:</h6>
                </div>
                <div
                  style={{
                    margin: "4px 0",
                    display: "flex",
                    flexDirection: "row"
                  }}
                >
                  <div style={{ padding: "0 16px", flex: "50%" }}>
                    <h6 style={{ margin: "0", color: "rgba(0, 0, 0, 0.54)" }}>
                      Start
                    </h6>
                    <DateInput
                      label="Break Start"
                      value={_break.start}
                      updateTimeClock={moment =>
                        updateTimeClock(
                          moment,
                          "start",
                          timeClock,
                          setTimeClock,
                          i
                        )
                      }
                      isDisabled={breakTimeDisabled}
                    />
                  </div>
                  <div style={{ padding: "0 16px", flex: "50%" }}>
                    <h6 style={{ margin: "0", color: "rgba(0, 0, 0, 0.54)" }}>
                      Stop
                    </h6>
                    <DateInput
                      label="Break Stop"
                      value={_break.stop}
                      updateTimeClock={moment =>
                        updateTimeClock(
                          moment,
                          "stop",
                          timeClock,
                          setTimeClock,
                          i
                        )
                      }
                      isDisabled={breakTimeDisabled}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </Collapse>
      </div>
    </div>
  );
};

/**
 * TimeClock
 * Step to track time (i.e. worker time clock - maybe exercise or other timer)
 * Clock-In / Start Timer, Clock-Out / Stop Timer, Toggle Break / Pause Timer, Timer display
 * All Items have an editable date time field for adjustments.  Breaks create a list of start-stop intervals.
 *
 * Configuration:
 * TODO: toggle geolocation
 *
 * Step Value: JSON
 * {
 *   clockIn: String of datetime
 *   clockOut: String of datetime
 *   breaks: [{
 *     start: String of Datetime
 *     stop: String of Datetime
 *   }]
 * }
 */
const TimeClock = props => {
  const {
    classes,
    step,
    updateStep,
    editing,
    setEditState,
    noteOpen,
    setNoteState
  } = props;
  const editProps = { editing, setEditState, noteOpen, setNoteState };
  const tabs = getCommonTabs(step, updateStep);
  const showRowDividers = true;
  const primaryTableRowClass = getStepRowClass(
    classes,
    showRowDividers,
    editing || noteOpen
  );

  // if (step.type === StepType.NUMBER_INPUT) {
  //   tabs.push({
  //     tabName: "Configuration",
  //     tabIcon: Settings,
  //     tabContent: (
  //       <FormControl fullWidth className={classes.selectFormControl}>
  //         {<ConfigureUnits step={step} updateStep={updateStep} />}
  //       </FormControl>
  //     )
  //   });
  // }

  return (
    <>
      <TableRow key={step.stepId} className={primaryTableRowClass}>
        <StepIcon {...props} step={step} />
        <StepContent {...props} {...editProps} step={step} />
      </TableRow>
      <EditRow classes={classes} open={editing}>
        <CustomTabs title="Configure" headerColor="gray" tabs={tabs} />
      </EditRow>
      <EditRow classes={classes} open={noteOpen}>
        <StepNote step={step} updateStep={updateStep} />
      </EditRow>
    </>
  );
};

export default withStyles(stepsStyle)(TimeClock);
