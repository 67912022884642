/** Import React and 3rd party libs */
import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Icon from "@material-ui/core/Icon";
import FormControl from "@material-ui/core/FormControl";

// @material-ui/icons
import Settings from "@material-ui/icons/Settings";
import { BsHash } from "react-icons/bs";

// Components

// Subcomponents
import ConfigureUnits from "../../StepComponents/StepEdit/ConfigureUnits";
import StepNote from "../../StepComponents/StepNote";
import ViewTitle from "../../StepComponents/ViewTitle";
import FormInput from "./FormInput";
import DateInput from "./DateInput";
import ImageInput from "./ImageInput";
import ListInput from "./ListInput";
import LinkStep from "./LinkStep";

// Services
import {
  getCommonTabs,
  EditRow,
  isInputDisabled,
  getIconClass
} from "../StepTypesUtility";
import { StepType, getStepRowClass } from "services/StepService";
// Services
import { ChecklistMode } from "services/ChecklistService";

// Queries
/** Import all graphql queries */

// Assets
import stepsStyle from "assets/jss/material-dashboard-pro-react/components/stepsStyle.jsx";

// Variables
/** Declare all constant variables */

// Functions
const StepIcon = ({ classes, step }) => {
  let iconType;
  switch (step.type) {
    case StepType.TEXT_INPUT:
      // text_fields or note
      iconType = <Icon>notes</Icon>;
      break;
    case StepType.NUMBER_INPUT:
      // exposure_plus or looks_one or all_inclusive
      // iconType = <Icon>all_inclusive</Icon>;
      // iconType = <Icon className="fa fa-plus-circle" />;
      iconType = <BsHash style={{ marginLeft: "-5px", fontSize: "2.0em" }} />;
      break;
    case StepType.LINK_INPUT:
    case StepType.LINK_EMBED:
      // insert_link or link
      iconType = <Icon>insert_link</Icon>;
      break;
    case StepType.IMAGE_INPUT:
      // insert_photo or phot
      iconType = <Icon>photo_camera</Icon>;
      break;
    case StepType.IMAGE_DISPLAY:
      // insert_photo or phot
      iconType = <Icon>insert_photo</Icon>;
      break;
    case StepType.DATE_TIME_INPUT:
      // insert_invitation or today or calendar_today
      iconType = <Icon>insert_invitation</Icon>;
      break;
    case StepType.LIST_INPUT:
      // text_fields or note
      iconType = <Icon>list</Icon>;
      break;
    default:
      iconType = <Icon>notes</Icon>;
  }

  return (
    <TableCell className={classes.tableCheck}>
      <div className={getIconClass(step, classes)}>{iconType}</div>
    </TableCell>
  );
};

const StepContent = props => {
  const { classes, mode } = props;
  const isDisabled = isInputDisabled(mode);
  return (
    <TableCell className={classes.tableContent}>
      <ViewTitle {...props} />
      <StepInput isDisabled={isDisabled} {...props} />
    </TableCell>
  );
};

const _updateStep = (stepTuple, updateStep) => {
  const [step, error] = stepTuple;
  if (!error) {
    updateStep(step);
  }
};

const StepInput = props => {
  const { step, updateStep, isDisabled, editing, mode, user } = props;

  switch (step.type) {
    case StepType.TEXT_INPUT:
    case StepType.NUMBER_INPUT:
      return (
        <FormInput
          step={step}
          updateStepTuple={stepTuple => _updateStep(stepTuple, updateStep)}
          isDisabled={isDisabled}
        />
      );
    case StepType.LINK_INPUT:
    case StepType.LINK_EMBED:
      return (
        <LinkStep
          mode={mode}
          editing={editing}
          step={step}
          updateStepTuple={stepTuple => _updateStep(stepTuple, updateStep)}
        />
      );
    case StepType.IMAGE_INPUT:
      return (
        <ImageInput
          step={step}
          updateStep={updateStep}
          isDisabled={isDisabled}
          user={user}
        />
      );
    case StepType.IMAGE_DISPLAY:
      return (
        <ImageInput
          step={step}
          updateStep={updateStep}
          isDisabled={mode !== ChecklistMode.TEMPLATE_EDIT}
          user={user}
        />
      );
    case StepType.DATE_TIME_INPUT:
      return (
        <DateInput
          step={step}
          updateStep={updateStep}
          isDisabled={isDisabled}
        />
      );
    case StepType.LIST_INPUT:
      return (
        <ListInput
          step={step}
          updateStep={updateStep}
          isDisabled={isDisabled}
        />
      );
    default:
      return null;
  }
};

/**
 * Input Step
 * TODO: possibly refactor to different input types in config (text, number, link, image, date)
 * Instead of having as a top level type.  Maybe not for image & date?
 */
const Input = props => {
  const {
    classes,
    step,
    updateStep,
    editing,
    setEditState,
    noteOpen,
    setNoteState
  } = props;
  const editProps = { editing, setEditState, noteOpen, setNoteState };
  const tabs = getCommonTabs(step, updateStep);
  const showRowDividers = true;
  const primaryTableRowClass = getStepRowClass(
    classes,
    showRowDividers,
    editing || noteOpen
  );

  if (step.type === StepType.NUMBER_INPUT) {
    tabs.push({
      tabName: "Configuration",
      tabIcon: Settings,
      tabContent: (
        <FormControl fullWidth className={classes.selectFormControl}>
          {<ConfigureUnits step={step} updateStep={updateStep} />}
        </FormControl>
      )
    });
  }

  return (
    <>
      <TableRow key={step.stepId} className={primaryTableRowClass}>
        <StepIcon {...props} step={step} />
        <StepContent {...props} {...editProps} step={step} />
      </TableRow>
      <EditRow classes={classes} open={editing}>
        <CustomTabs title="Configure" headerColor="gray" tabs={tabs} />
      </EditRow>
      <EditRow classes={classes} open={noteOpen}>
        <StepNote step={step} updateStep={updateStep} />
      </EditRow>
    </>
  );
};

export default withStyles(stepsStyle)(Input);
