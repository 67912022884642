import React from "react";

// Lib for handling headers
import { Helmet } from "react-helmet";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// assets
import termsPageStyle from "assets/jss/material-dashboard-pro-react/views/termsPageStyle";

class TermsWrapper extends React.PureComponent {
  render() {
    const { classes, children, name } = this.props;
    return (
      <div className={classes.container}>
        <Helmet>
          <title>Airsync | {name} </title>
          <meta
            name="description"
            content="Airsync powers modern operations.  Define programable workflows and customized reporting to match your organization's unique needs."
          />
        </Helmet>
        <br />
        <br />
        {children}
      </div>
    );
  }
}

export default withStyles(termsPageStyle)(TermsWrapper);
