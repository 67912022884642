/** Import React and 3rd party libs */
import React from "react";

// @material-ui/core components
import Avatar from "@material-ui/core/Avatar";

// @material-ui/icons
/** Import material ui icons */

// Components
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";

// Subcomponents
/** Import components used only by this component - relative path imports */

// Services
import { StepType } from "services/StepService";

// Queries
/** Import all graphql queries */

// Assets
/** Import styles */

// Variables
/** Declare all constant variables */

// Functions
/** Declare all functions - utility followed by JSX*/

/**
 * Declare JSX Component - class or
 */
const ImpageInput = ({ step, updateStep, isDisabled, user }) => {
  const propertyType =
    step.type === StepType.IMAGE_INPUT ? "value" : "configuration";
  const jsonConfig =
    step[propertyType] && step[propertyType].json
      ? JSON.parse(step[propertyType].json)
      : {};
  const image_link =
    jsonConfig.image_link || (step.value ? step.value.image_link : "");
  //const { image_link } = step.value ? step.value : { image_link: "" };

  // TODO: probably want different display for show image than uplaod

  // For demo and faker data, show image if starts with "http" (i.e. not an S3 upload)
  if (image_link && image_link.startsWith("http")) {
    return (
      <Avatar
        alt="pilot"
        src={image_link}
        style={{ width: "96px", height: "96px" }}
      />
    );
  }
  return (
    <span>
      <ImageUpload
        addButtonProps={{
          color: "info",
          round: true
        }}
        changeButtonProps={{
          color: "info",
          round: true
        }}
        removeButtonProps={{
          color: "rose",
          round: true
        }}
        submitButtonProps={{
          color: "success",
          round: true
        }}
        step={step}
        updateStep={updateStep}
        disabled={isDisabled}
        user={user}
      />
    </span>
  );
};

// Data Connectors

export default ImpageInput;
