/** Import React and 3rd party libs */
import React from "react";
import Datetime from "react-datetime";

// @material-ui/core components
import FormControl from "@material-ui/core/FormControl";

// @material-ui/icons
/** Import material ui icons */

// Components
/** Import generic components - internal and creative time */

// Subcomponents
/** Import components used only by this component - relative path imports */

// Services
/** Import all services and variables */

// Queries
/** Import all graphql queries */

// Assets
/** Import styles */

// Variables
/** Declare all constant variables */

// Functions
const updateValueTime = (moment, step) => {
  let newStep = Object.assign({}, step);
  let value = Object.assign({}, step.value);
  value.date_time_tz = moment.format();
  newStep.value = value;
  return newStep;
};

/**
 * Declare JSX Component - class or
 */
const DateInput = ({ step, updateStep, isDisabled }) => {
  // insert_invitation or today or calendar_today
  if (!step.value) {
    step.value = {
      date_time_tz: null
    };
  }
  return (
    <FormControl fullWidth>
      <Datetime
        value={step.value.date_time_tz}
        inputProps={{
          disabled: isDisabled,
          placeholder: "Datetime Picker"
        }}
        onChange={moment => updateStep(updateValueTime(moment, step))}
      />
    </FormControl>
  );
};

// Data Connectors

export default DateInput;
