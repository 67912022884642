import shootingStar from "assets/sound/swoosh_ching.mp3";

const CELL_WIDTH = 200;
const USER_CELL_WIDTH = 220;
const ROW_HEIGHT = 180;

export const ANIMATION_TIME = 1000;
export const ANIMATION_SOUND = shootingStar;

export const getTableStyle = aTemplate => {
  const tableStyle = {
    borderCollapse: "collapse"
  };

  if (aTemplate) {
    const stepCount = aTemplate.steps.length;
    tableStyle.width = stepCount * CELL_WIDTH + USER_CELL_WIDTH;
  }

  return tableStyle;
};

export const cardIconTheme = {
  photoImg: {
    height: "48px",
    verticalAlign: "middle",
    border: "0"
  }
};

export const imageTheme = {
  photoImg: {
    height: "36px",
    verticalAlign: "middle",
    border: "0"
  }
};

export const countStarTheme = {
  photoImg: {
    height: "28px",
    verticalAlign: "middle",
    border: "0",
    marginRight: "8px"
  }
};

export const AwardboardStyle = {
  cardIcon: {
    marginLeft: "64px",
    padding: "8px"
  },
  icon: {
    color: "white",
    fontSize: "48px",
    width: "48px",
    height: "42px",
    lineHeight: "48px"
  },
  tableContainerDiv: {
    overflowY: "scroll"
  },
  bodyRow: {
    height: `${ROW_HEIGHT}px`
  },
  user: {
    cell: {
      width: `${USER_CELL_WIDTH}px`,
      minWidth: `${USER_CELL_WIDTH}px`,
      border: "1px solid rgba(155, 155, 155, .3)",
      padding: "12px"
    },
    imgContainer: {
      overflow: "hidden",
      display: "block",
      marginRight: "15px",
      height: "69px",
      width: "63px",
      float: "left",
      boxShadow:
        "0 12px 20px -10px rgba(0, 0, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 0, 0, 0.2)"
    },
    detailsContainer: {
      marginTop: "10px",
      cursor: "pointer"
    },
    name: {
      color: "rgba(0, 0, 0, 0.84)",
      fontSize: "1.5em"
    },
    countContainer: {
      display: "flex",
      alignItems: "center"
    },
    countText: {
      color: "rgba(0, 0, 0, .54)",
      fontSize: "1.5em",
      fontWeight: "300"
    }
  },
  body: {
    cell: {
      width: `${CELL_WIDTH}px`,
      height: `${ROW_HEIGHT}px`,
      border: "1px solid rgba(155, 155, 155, .3)",
      textAlign: "center"
    },
    cellContainer: {
      display: "flex",
      flexDirection: "column",
      height: "100%"
    },
    awardOuterContainer: {
      flexGrow: 1
    },
    awardContainer: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: `${CELL_WIDTH}px`,
      maxWidth: `${CELL_WIDTH}px`,
      flexWrap: "wrap"
    },
    star: {
      height: "28px",
      marginRight: "8px"
    },
    addDeleteContainer: {
      height: "22px",
      padding: "2px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end"
    },
    addDeleteButton: {
      fontSize: "18px",
      color: "rgba(0,0,0,0.25)",
      cursor: "pointer"
    },
    image: {
      maxWidth: "180px"
    }
  }
};
