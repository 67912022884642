import _ from "lodash";
import queryString from "query-string";
import React from "react";

// Icons
import { GrCloudUpload } from "react-icons/gr";
import { GrRevert } from "react-icons/gr";
import { BiRun } from "react-icons/bi";
import AssignmentTurnedInOutlined from "@material-ui/icons/AssignmentTurnedInOutlined";
import Cancel from "@material-ui/icons/Cancel";
import Save from "@material-ui/icons/Save";

// Services
import {
  ChecklistMode,
  cloneChecklistForExecute,
  StatusType,
  sendChecklistEvent
} from "services/ChecklistService";
import { omitDeep } from "services/utility.js";
import {
  redirectOnCancelEditChecklist,
  redirectOnCompleteChecklist,
  redirectOnCreateChecklist,
  redirectOnEditChecklist,
  redirectOnFirstExecuteChecklist,
  redirectOnViewChecklistTemplate
} from "./ChecklistUrlHandling";

/**
 * WARNING
 *
 * State gets passed into these functions through the constructor.
 * This state may not be up to date.  So may need to update this state with the passed in data.
 * An example is updateSteps where the checklist is updated with the new checklist with the updated step
 */

// Currently not working, just delaying update but not grouping Steps
// https://www.freecodecamp.org/news/debounce-and-throttle-in-react-with-hooks/
const debounceUpdate = _.debounce(
  (checklist, callback, mode, showNotification, incrementalUpdate) => {
    incrementalUpdate(checklist, callback, mode, showNotification);
  },
  1000
);

const cleanChecklistState = state => {
  const { checklist } = state;
  return omitDeep(checklist, "__typename");
};

const cleanChecklist = checklist => {
  return omitDeep(checklist, "__typename");
};

/**
 * Use functional setState to avoid bug with old state.
 * https://reactjs.org/docs/hooks-reference.html#functional-updates
 */
const updateSteps = (
  steps,
  setState,
  incrementalUpdate,
  callback,
  showNotification
) => {
  let mode;
  let newChecklist;
  setState(
    state => {
      mode = state.mode;
      newChecklist = Object.assign({}, state.checklist);
      newChecklist.steps = steps;
      state.checklist = newChecklist;
      return state;
    },
    () => {
      debounceUpdate(
        newChecklist,
        callback,
        mode,
        showNotification,
        incrementalUpdate
      );
    }
  );
};

/**
 * Use functional setState to avoid bug with old state from different steps.
 * https://reactjs.org/docs/hooks-reference.html#functional-updates
 */
const updateStep = (
  step,
  setState,
  incrementalUpdate,
  callback,
  showNotification
) => {
  let newChecklist;
  let mode;
  setState(
    state => {
      mode = state.mode;
      newChecklist = Object.assign({}, state.checklist);
      const newSteps = newChecklist.steps.filter(
        _step => _step.stepId !== step.stepId
      );
      newSteps.push(step);
      newChecklist.steps = newSteps;
      state.checklist = newChecklist;
      return state;
    },
    () => {
      debounceUpdate(
        newChecklist,
        callback,
        mode,
        showNotification,
        incrementalUpdate
      );
    }
  );
};

/**
 * Use functional setState to avoid bug with old state.
 * https://reactjs.org/docs/hooks-reference.html#functional-updates
 */
const updateMetadata = (
  metadata,
  setState,
  incrementalUpdate,
  showNotification
) => {
  let newChecklist;
  let mode;
  setState(
    state => {
      newChecklist = Object.assign({}, state.checklist);
      mode = state.mode;
      newChecklist.metadata = metadata;
      state.checklist = newChecklist;
      return state;
    },
    () => {
      debounceUpdate(
        newChecklist,
        null,
        mode,
        showNotification,
        incrementalUpdate
      );
    }
  );
};

const updateChecklist = (
  metadata,
  steps,
  state,
  setState,
  incrementalUpdate,
  showNotification
) => {
  const newChecklist = Object.assign({}, state.checklist);
  const { mode } = state;
  newChecklist.metadata = metadata;
  newChecklist.steps = steps;
  setState({ checklist: newChecklist }, () => {
    debounceUpdate(
      newChecklist,
      null,
      mode,
      showNotification,
      incrementalUpdate
    );
  });
};

const updateCompletedChecklist = (
  state,
  setState,
  showNotification,
  incrementalUpdate
) => {
  setState({
    confirmModal: true,
    confirmTitle: "Update Checklist?",
    confirmIcon: <Save style={{ margin: "0 8px -5px 0" }} />,
    confirmParagraph:
      "Confirm you want to update the completed checklist. This will overwrite previously entered values.",

    handleConfirm: () => {
      incrementalUpdate(state.checklist).then(() => {
        setState(
          {
            confirmModal: false
          },
          showNotification()
        );
      });
    }
  });
};

const confirmModal = (modal, setState, showNotification) => {
  setState({
    confirmModal: true,
    confirmTitle: modal.title,
    confirmIcon: modal.icon,
    confirmParagraph: modal.paragraph,
    handleConfirm: () => {
      if (modal.action) {
        modal.action();
      }
      setState(
        {
          confirmModal: false
        },
        () => {
          if (modal.notification) {
            showNotification("custom", modal.notification);
          }
        }
      );
    }
  });
};

// TODO: refactor into "Publishing" a new version of publicVersion -> just an old version of save prior to incremental
const publishChecklist = (state, setState, props, showNotification) => {
  const { createChecklistTemplate, location, user } = props;
  const orgId = user.currentOrganization.id;
  const cleanedChecklist = cleanChecklistState(state);
  const { metadata, steps, id } = cleanedChecklist;
  setState({
    confirmModal: true,
    confirmTitle: "Publish Checklist?",
    confirmIcon: <GrCloudUpload style={{ margin: "0 8px -2px 0" }} />,
    confirmParagraph:
      "Confirm you want to publish this checklist. All users on an un-pinned version of this checklist will use this new version going forward.",
    handleConfirm: () => {
      setState(
        {
          confirmModal: false
        },
        () => {
          createChecklistTemplate(orgId, metadata, steps, id).then(results => {
            if (!results.data.createChecklistTemplate) {
              return showNotification("error");
            }
            const { id } = results.data.createChecklistTemplate;
            const { search } = location;
            const value = queryString.parse(search);
            if (value.id) {
              // If update, no need to update url, so just update state
              // Increment version manually (may need to do the same with other server adjusted values)
              cleanedChecklist.metadata.version =
                cleanedChecklist.metadata.version + 1;
              setState({ checklist: cleanedChecklist }, showNotification());
            } else {
              redirectOnCreateChecklist(props, id);
            }
          });
        }
      );
    }
  });
};

// Don't think this is used?  Was using for manually indexing, but not happens on save/update on the backend.
const indexChecklist = (state, props) => {
  const { indexChecklistTemplate } = props;
  const { metadata, id } = cleanChecklistState(state);
  indexChecklistTemplate(id, metadata);
};

const completeChecklistNoConfirm = (
  currentChecklist,
  state,
  props,
  callback
) => {
  const { createChecklistInstance, user } = props;
  const orgId = user.currentOrganization.id;
  const { parents } = state;
  // Use current checklist since one in state may be old with voice updates
  const { metadata, steps, id } = omitDeep(currentChecklist, "__typename");
  metadata.status = StatusType.COMPLETED;
  createChecklistInstance(orgId, metadata, steps, id).then(results => {
    const returnedChecklist = results.data.createChecklistInstance;
    const actionName = _.get(
      returnedChecklist,
      "metadata.eventConfig.actionNames.completed",
      "Completed"
    );
    sendChecklistEvent(actionName, returnedChecklist, props);
    if (callback) {
      callback(results.data.createChecklistInstance);
    }
    redirectOnCompleteChecklist(props, id, parents);
  });
};

const completeChecklist = (state, setState, props) => {
  setState({
    confirmModal: true,
    confirmTitle: "Complete Checklist?",
    confirmIcon: (
      <AssignmentTurnedInOutlined style={{ margin: "0 8px -5px 0" }} />
    ),
    confirmParagraph:
      "Confirm you wish to complete the checklist.  You can still edit information later if needed.",
    handleConfirm: () => {
      const { createChecklistInstance, user } = props;
      const orgId = user.currentOrganization.id;
      const { parents } = state;
      const { metadata, steps, id } = cleanChecklistState(state);
      metadata.status = StatusType.COMPLETED;
      createChecklistInstance(orgId, metadata, steps, id).then(results => {
        const returnedChecklist = results.data.createChecklistInstance;
        const actionName = _.get(
          returnedChecklist,
          "metadata.eventConfig.actionNames.completed",
          "Completed"
        );
        sendChecklistEvent(actionName, returnedChecklist, props);
        redirectOnCompleteChecklist(props, id, parents);
      });
      setState({
        confirmModal: false,
        loading: true
      });
    }
  });
};

const startChecklist = (state, setState, executeChecklist) => {
  setState({
    confirmModal: true,
    confirmTitle: "Start Checklist?",
    confirmIcon: <BiRun style={{ margin: "0 8px -5px 0" }} />,
    confirmParagraph:
      "Confirm you wish to start a new active instance of this checklist.",
    handleConfirm: () => {
      executeChecklist(state.checklist);
      setState({
        confirmModal: false,
        loading: true
      });
    }
  });
};

const copyChecklist = setState => {
  setState({
    confirmModal: true,
    confirmTitle: "Copy to a new Checklist?",
    handleConfirm: () =>
      setState({
        confirmModal: false
      })
  });
};

const cancelEditChecklist = (setState, props) => {
  setState({
    confirmModal: true,
    confirmTitle: "Cancel Changes?",
    confirmIcon: <Cancel style={{ margin: "0 8px -5px 0" }} />,
    confirmParagraph:
      "Confirm you wish to cancel editing.  All changes not saved will be lost.",
    handleConfirm: () => redirectOnCancelEditChecklist(props)
  });
};

const revertChanges = setState => {
  setState({
    confirmModal: true,
    confirmTitle: "Revert Changes?",
    confirmIcon: <GrRevert style={{ margin: "0 8px -2px 0" }} />,
    confirmParagraph:
      "Confirm you wish to revert back to the lasted published version of this checklist.  All current changes will be lost.",
    handleConfirm: () =>
      console.log("TODO: revert changes to last published version")
  });
};

const viewTemplate = (state, props) => {
  const { checklist } = state;
  redirectOnViewChecklistTemplate(props, checklist);
};

// Deprecate - uses set state, really just to launch a modal - use newChecklist in ChecklistService
// Creates a checklist Instance in an active state from a Template
// TODO: refactor other locations to use this and ditch modal popup
const executeChecklist = (checklist, setState, props, callback) => {
  const { createChecklistInstance, location, user } = props;
  const orgId = user.currentOrganization.id;
  const newChecklist = cloneChecklistForExecute(checklist);
  createChecklistInstance(
    orgId,
    newChecklist.metadata,
    newChecklist.steps
  ).then(results => {
    const returnedChecklist = results.data.createChecklistInstance;
    const actionName = _.get(
      returnedChecklist,
      "metadata.eventConfig.actionNames.created",
      "Created"
    );
    sendChecklistEvent(actionName, returnedChecklist, props);
    if (callback) {
      callback(results.data.createChecklistInstance);
    }
    const { search } = location;
    const value = queryString.parse(search);
    if (value.id && value.instance) {
      // If update, no need to update url, so just update state
      // Increment version manually (may need to do the same with other server adjusted values)
      checklist.metadata.version = checklist.metadata.version + 1;
      setState({ checklist });
    } else {
      redirectOnFirstExecuteChecklist(props, returnedChecklist.id);
    }
  });
};

class ChecklistFunctions {
  constructor(state, setState, props, showNotification) {
    this.state = state;
    this.setState = setState;
    this.props = props;
    this.showNotification = showNotification;
  }

  updateMetadata = metadata =>
    updateMetadata(
      metadata,
      this.setState,
      this.incrementalUpdate,
      this.showNotification
    );

  updateSteps = (steps, callback) => {
    updateSteps(
      steps,
      this.setState,
      this.incrementalUpdate,
      callback,
      this.showNotification
    );
  };

  updateStep = (step, callback) => {
    updateStep(
      step,
      this.setState,
      this.incrementalUpdate,
      callback,
      this.showNotification
    );
  };

  updateChecklist = (metadata, steps) => {
    updateChecklist(
      metadata,
      steps,
      this.state,
      this.setState,
      this.incrementalUpdate,
      this.showNotification
    );
  };

  incrementalUpdate = (checklist, callback, mode, showNotification) => {
    const {
      createChecklistInstance,
      createChecklistTemplate,
      user
    } = this.props;
    const { metadata, steps, id } = cleanChecklist(checklist);
    const orgId = user.currentOrganization.id;

    if (mode === ChecklistMode.INSTANCE_EXEC) {
      return createChecklistInstance(orgId, metadata, steps, id).then(
        results => {
          if (!results.data.createChecklistInstance) {
            return showNotification("error");
          }
          if (callback) {
            callback(results.data.createChecklistInstance);
          }
        }
      );
    } else if (mode === ChecklistMode.TEMPLATE_EDIT) {
      return createChecklistTemplate(orgId, metadata, steps, id).then(
        results => {
          if (!results.data.createChecklistTemplate) {
            return showNotification("error");
          }
        }
      );
    }
  };

  updateCompletedChecklist = () =>
    updateCompletedChecklist(
      this.state,
      this.setState,
      this.showNotification,
      this.incrementalUpdate
    );

  publishChecklist = () =>
    publishChecklist(
      this.state,
      this.setState,
      this.props,
      this.showNotification
    );

  indexChecklist = () => indexChecklist(this.state, this.props);

  completeChecklist = () =>
    completeChecklist(this.state, this.setState, this.props);

  completeChecklistNoConfirm = (currentChecklist, callback) => {
    completeChecklistNoConfirm(
      currentChecklist,
      this.state,
      this.props,
      callback
    );
  };

  startChecklist = () =>
    startChecklist(this.state, this.setState, this.executeChecklist);

  copyChecklist = () => copyChecklist(this.setState);

  editChecklist = () => redirectOnEditChecklist(this.props);

  cancelEditChecklist = () => cancelEditChecklist(this.setState, this.props);

  revertChanges = () => revertChanges(this.setState);

  viewTemplate = () => viewTemplate(this.state, this.props);

  executeChecklist = (checklist, callback) =>
    executeChecklist(checklist, this.setState, this.props, callback);

  confirmModal = modal =>
    confirmModal(modal, this.setState, this.showNotification);

  getChecklistFunctions = () => {
    return {
      startChecklist: this.startChecklist,
      copyChecklist: this.copyChecklist,
      editChecklist: this.editChecklist,
      cancelEditChecklist: this.cancelEditChecklist,
      revertChanges: this.revertChanges,
      viewTemplate: this.viewTemplate,
      updateMetadata: this.updateMetadata,
      publishChecklist: this.publishChecklist,
      updateCompletedChecklist: this.updateCompletedChecklist,
      updateSteps: this.updateSteps,
      updateStep: this.updateStep,
      updateChecklist: this.updateChecklist,
      completeChecklist: this.completeChecklist,
      completeChecklistNoConfirm: this.completeChecklistNoConfirm,
      indexChecklist: this.indexChecklist,
      executeChecklist: this.executeChecklist,
      confirmModal: this.confirmModal
    };
  };
}

export default ChecklistFunctions;
