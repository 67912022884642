import { defaultFont } from "assets/jss/material-dashboard-pro-react.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

const widgetModalStyle = theme => ({
  ...modalStyle(theme),
  cardTitle: {
    marginTop: "0",
    marginBottom: "3px",
    color: "#3C4858",
    fontSize: "18px"
  },
  cardHeader: {
    zIndex: "3"
  },
  cardSubtitle: {
    ...defaultFont,
    color: "#999999",
    fontSize: "14px",
    margin: "0 0 10px"
  },
  center: {
    textAlign: "center"
  },
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  marginRight: {
    marginRight: "5px"
  },
  modalSectionTitle: {
    marginTop: "30px"
  },
  modalLarge: {
    width: "800px",
    maxWidth: "800px"
  },
  uploadButton: {
    margin: "1em"
  },
  fileUploadInput: {
    display: "none"
  },
  progress: {
    margin: "0.85em"
  },
  iconLabel: {
    color: "#AAAAAA !important",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: 1.42857
  }
});

export default widgetModalStyle;
