/* eslint no-unused-vars: "off" */

/**
 * SITE CONFIG
 *
 * All the necessary parts to configure a site:
 * Checklist Zen, Airspace Ops, etc.
 */

import logo from "./FamilyLeaderboard_Logo_White.png";

import Dashboard from "views/Dashboard-Deprecated/Dashboard.jsx";
import Checklists from "views/Checklists/Checklists.jsx";
import Dashboards from "views/Dashboards/Dashboards.jsx";

// @material-ui/icons
import GridOn from "@material-ui/icons/GridOnOutlined";
import Wc from "@material-ui/icons/WcOutlined";

/////////////////////////////
// Navigation Links
/////////////////////////////

/**
 * Airspace Ops Routes - TODO: Move to Checklist or Dashboard Based
 */
const FamilyLeaderboard = {
  path:
    "/app/dashboard/family_dashboard?id=FAM00000-0000-0000-0000-00FAMILYDASH",
  name: "Family Leaderboard",
  id: "ops-dashboard",
  icon: Wc,
  component: Dashboard,
  leftNav: true
};

const ChecklistsRoute = {
  path: "/app/checklists",
  name: "Lists",
  id: "checklists",
  icon: "content_paste",
  component: Checklists,
  leftNav: true
};

const DashboardsRoute = {
  path: "/app/dashboards",
  name: "Logs",
  id: "dashboards",
  icon: GridOn,
  component: Dashboards,
  leftNav: true
};

/////////////////////////////
// SITE CONFIGURATIONS
////////////////////////////

const FAMILY_LEADERBOARD = {
  name: "Family Leaderboard",
  logo,
  leftNav: [FamilyLeaderboard, ChecklistsRoute, DashboardsRoute]
};

export default FAMILY_LEADERBOARD;
