import gql from "graphql-tag";
import { checklistIdFields } from "./common";

////////////////////////////////////////////////
// CHECKLIST & STEP FRAGMENTS
////////////////////////////////////////////////

const checklistDetails = gql`
  fragment ChecklistDetails on ChecklistMetadata {
    name
    version
    pinned
    checklistNav
    tags
  }
`;

const checklistOwner = gql`
  fragment ChecklistOwner on ChecklistMetadata {
    owner {
      ownerId
      type
    }
  }
`;

const checklistOrganization = gql`
  fragment ChecklistOrganization on ChecklistMetadata {
    organization {
      orgId
      type
    }
  }
`;

const checklistSharing = gql`
  fragment ChecklistSharing on ChecklistMetadata {
    sharing {
      type
      list
    }
  }
`;

const checklistFork = gql`
  fragment ChecklistFork on ChecklistMetadata {
    fork {
      isFork
      checklist {
        ...ChecklistIdFields
      }
    }
  }
  ${checklistIdFields}
`;

const checklistCreated = gql`
  fragment ChecklistCreated on ChecklistMetadata {
    created {
      by
      time
    }
  }
`;

const checklistModified = gql`
  fragment ChecklistModified on ChecklistMetadata {
    modified {
      by
      time
    }
  }
`;

const checklistBanner = gql`
  fragment ChecklistBanner on ChecklistMetadata {
    banner {
      icon {
        type
        link
      }
      image {
        type
        link
      }
      color
    }
  }
`;

const checklistEventConfig = gql`
  fragment ChecklistEventConfig on ChecklistMetadata {
    eventConfig {
      isEnabled
      filters {
        filterId
      }
      metadata
      actionNames {
        created
        completed
      }
    }
  }
`;

const checklistInstanceName = gql`
  fragment ChecklistInstanceName on ChecklistMetadata {
    instanceName {
      steps {
        stepId
        name
        type
      }
    }
  }
`;

const checklistTemplate = gql`
  fragment ChecklistTemplate on ChecklistMetadata {
    template {
      ...ChecklistIdFields
    }
  }
  ${checklistIdFields}
`;

const stepEventConfig = gql`
  fragment StepEventConfig on ChecklistStep {
    eventConfig {
      isEnabled
      metadata
    }
  }
`;

const stepHelpInfo = gql`
  fragment StepHelpInfo on ChecklistStep {
    info {
      text
      link
    }
  }
`;

const stepConfiguration = gql`
  fragment StepConfiguration on ChecklistStep {
    configuration {
      unit
      rounding
      checklist {
        ...ChecklistIdFields
      }
      formula
      independentCompletion
      link {
        url
        title
        description
        imageSrc
        faviconSrc
      }
      json
    }
  }
  ${checklistIdFields}
`;

const stepExpiration = gql`
  fragment StepExpiration on ChecklistStep {
    expiration {
      type
      date
      value
      checklist {
        ...ChecklistIdFields
      }
      step {
        stepId
        name
        type
      }
    }
  }
  ${checklistIdFields}
`;

export const Checklist = {
  metadataInfo: gql`
    fragment MetadataInfo on ChecklistMetadata {
      description
      ...ChecklistDetails
      ...ChecklistOwner
      ...ChecklistOrganization
      ...ChecklistSharing
      ...ChecklistFork
      ...ChecklistCreated
      ...ChecklistModified
      ...ChecklistBanner
      ...ChecklistEventConfig
      ...ChecklistInstanceName
    }
    ${checklistDetails}
    ${checklistOwner}
    ${checklistOrganization}
    ${checklistSharing}
    ${checklistFork}
    ${checklistCreated}
    ${checklistModified}
    ${checklistBanner}
    ${checklistEventConfig}
    ${checklistInstanceName}
  `,
  metadataValues: gql`
    fragment MetadataValues on ChecklistMetadata {
      root
      parent
      status
      note
      ...ChecklistTemplate
    }
    ${checklistTemplate}
  `,
  metadataQuery: gql`
    fragment MetadataQuery on ChecklistMetadata {
      ...ChecklistDetails
      ...ChecklistBanner
      ...ChecklistEventConfig
      ...ChecklistInstanceName
      sharing {
        type
      }
      created {
        time
      }
      modified {
        time
      }
      owner {
        ownerId
      }
    }
    ${checklistDetails}
    ${checklistBanner}
    ${checklistEventConfig}
    ${checklistInstanceName}
  `,
  metadataInstanceQuery: gql`
    fragment MetadataInstanceQuery on ChecklistMetadata {
      ...ChecklistDetails
      sharing {
        type
      }
      created {
        time
      }
      modified {
        time
      }
      owner {
        ownerId
      }
      template {
        checklistId
      }
    }
    ${checklistDetails}
  `,
  stepInfo: gql`
    fragment StepInfo on ChecklistStep {
      stepId
      name
      description
      icon
      type
      isRequired
      position
      ...StepHelpInfo
      ...StepConfiguration
      ...StepExpiration
      ...StepEventConfig
    }
    ${stepHelpInfo}
    ${stepConfiguration}
    ${stepExpiration}
    ${stepEventConfig}
  `,
  stepValues: gql`
    fragment StepValues on ChecklistStep {
      value {
        value
        checked
        text
        number
        list
        link {
          url
          title
          description
          imageSrc
          faviconSrc
        }
        date_time_tz
        image_link
        json
        checklist {
          ...ChecklistIdFields
        }
      }
      note
    }
    ${checklistIdFields}
  `
};
