// ##############################
// // // Notifications view styles
// #############################

const mediaMenuStyle = () => ({
  container: {
    width: "500px",
    maxWidth: "500px"
    // Set height in tabs/subcomponents for scroll
  }
});

export default mediaMenuStyle;
