const styles = {
  webEmbed: {
    display: "flex",
    maxHeight: "106px",
    padding: "2px 8px 0 8px",
    overflow: "hidden",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(0, 0, 0, .04)"
    }
  },
  webEmbedTextContainer: {
    display: "flex",
    flexDirection: "column"
  },
  webEmbedTitle: {
    fontSize: "14px",
    fontWeight: 400,
    margin: "0 0 4px",
    color: "rgba(0, 0, 0, 0.94)"
  },
  webEmbedDescription: {
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.54)",
    maxHeight: "50px",
    overflow: "hidden"
  },
  webEmbedLinkContainer: {
    display: "flex",
    marginTop: "4px"
  },
  webEmbedFavicon: {
    display: "inline-block",
    maxWidth: "24px",
    maxHeight: "24px",
    marginRight: "8px"
  },
  webEmbedUrl: {
    color: "rgba(0, 0, 0, 0.94)",
    height: "24px",
    overflow: "hidden"
  },
  webEmbedImage: {
    width: "244px",
    maxWidth: "244px",
    marginTop: "-8px"
  }
};

export default styles;
