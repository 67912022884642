/** Import React and 3rd party libs */
import React, { useState } from "react";
import { ApolloConsumer } from "react-apollo";
import { flowRight as compose } from "lodash";
import { withRouter } from "react-router-dom";
import queryString from "query-string";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Icon from "@material-ui/core/Icon";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
import AlarmAdd from "@material-ui/icons/AlarmAdd";
import Settings from "@material-ui/icons/Settings";
import Check from "@material-ui/icons/Check";

// Components
import StepExpirationEdit from "../../StepComponents/StepEdit/StepExpirationEdit";
import ChecklistTemplateSelector from "../../StepComponents/StepEdit/ChecklistTemplateSelector";

// Subcomponents
import StepNote from "../../StepComponents/StepNote";
import ViewTitle from "../../StepComponents/ViewTitle";

// Services
import { getCommonTabs, EditRow, getIconClass } from "../StepTypesUtility";
import { omitDeep, guid } from "services/utility";
import {
  urlName,
  cloneChecklistForExecute,
  ChecklistMode,
  createNewChecklist
} from "services/ChecklistService";
import { getStepRowClass } from "services/StepService";

// Queries
import {
  ChecklistTemplateQuery,
  FindInstancesByTemplateIdFullQuery
} from "api/queries";
import {
  CreateChecklistTemplate,
  CreateChecklistInstance
} from "../../../ChecklistComponents/ChecklistQueries";

// Assets
import stepsStyle from "assets/jss/material-dashboard-pro-react/components/stepsStyle.jsx";

// Variables
/** Declare all constant variables */

// Functions
const fetchChecklist = async (orgId, configuration, client) => {
  const { checklistId: id, version } = configuration;
  const variables = { id, orgId };
  if (version) variables.version = 0; // Always grab latest (maybe later allow using pinned version set in config)
  const { data } = await client.query({
    query: ChecklistTemplateQuery,
    variables,
    fetchPolicy: "network-only"
  });
  return data;
};

const fetchActive = async (orgId, configuration, client) => {
  const { checklistId: templateId } = configuration;
  const variables = { orgId, templateId };
  const { data } = await client.query({
    query: FindInstancesByTemplateIdFullQuery,
    variables,
    fetchPolicy: "network-only"
  });
  return data;
};

const createSubchecklist = async (props, client, isIndependent) => {
  // Fetch Template
  const {
    step,
    steps,
    metadata,
    id,
    createChecklistInstance,
    parents,
    location,
    history,
    user
  } = props;
  const { configuration } = step;
  const orgId = user.currentOrganization.id;
  const templateData = await fetchChecklist(
    orgId,
    configuration.checklist,
    client
  );
  const { getChecklistTemplate } = templateData;
  const newChecklist = cloneChecklistForExecute(getChecklistTemplate);
  newChecklist.metadata.root = metadata.root ? metadata.root : id;
  newChecklist.metadata.parent = id;

  // Create Instance
  const instanceData = await createChecklistInstance(
    orgId,
    newChecklist.metadata,
    newChecklist.steps,
    newChecklist.id
  );

  const { data } = instanceData;
  const { createChecklistInstance: checklistInstanceData } = data;
  const { id: instanceId, metadata: instanceMetadata } = checklistInstanceData;

  // Update Step with value pointing to instance if directly tied (dependent) on parent
  if (!isIndependent) {
    const value = {
      checklist: {
        checklistId: instanceId,
        name: instanceMetadata.name
      }
    };
    step.value = value;
    const newSteps = steps.filter(_step => _step.stepId !== step.stepId);
    newSteps.push(step);

    // Save Parent Instance with value for Sub Instance
    const cleanMetadata = omitDeep(metadata, "__typename");
    const cleanSteps = omitDeep(newSteps, "__typename");
    await createChecklistInstance(orgId, cleanMetadata, cleanSteps, id);
  }

  const checklistSlug = `${id}__${urlName(metadata.name)}`;
  const newParents = parents ? parents + "," + checklistSlug : checklistSlug;
  // Navigate to Instance
  navigateToChecklist(
    instanceMetadata.name,
    instanceId,
    newParents,
    location,
    history
  );
};

const navigateToChecklist = (name, id, newParents, location, history) => {
  const { search } = location;
  const newPath = `/app/checklist/${urlName(name)}`;
  const value = queryString.parse(search);
  const { edit, instance, status } = value;
  let newSearch = `?id=${id}&parents=${newParents}`;
  newSearch = newSearch + `&edit=${edit}`;
  if (instance) newSearch = newSearch + `&instance=${instance}`;
  if (status) newSearch = newSearch + `&status=${status}`;

  const newLocation = Object.assign({}, location, {
    pathname: newPath,
    search: newSearch
  });

  history.push(newLocation);
};

const navigateTemplate = props => {
  const { parents, step, metadata, id, location, history } = props;
  const { configuration } = step;
  const { checklist } = configuration;
  const { name, checklistId: subId } = checklist;
  const checklistSlug = `${id}__${urlName(metadata.name)}`;
  const newParents = parents ? parents + "," + checklistSlug : checklistSlug;
  navigateToChecklist(name, subId, newParents, location, history);
};

const navigateCompleted = props => {
  const { parents, step, metadata, id, location, history } = props;
  const { value } = step;
  const { checklist } = value;
  const { name, checklistId: subId } = checklist;
  const checklistSlug = `${id}__${urlName(metadata.name)}`;
  const newParents = parents ? parents + "," + checklistSlug : checklistSlug;
  navigateToChecklist(name, subId, newParents, location, history);
};

const executeSubChecklist = async (props, client) => {
  const {
    step,
    metadata,
    id,
    parents,
    history,
    location,
    setCreatingSub,
    user
  } = props;
  const { value, configuration } = step;
  const orgId = user.currentOrganization.id;

  const checklistSlug = `${id}__${urlName(metadata.name)}`;
  const newParents = parents ? parents + "," + checklistSlug : checklistSlug;

  if (value) {
    // Navigate to Instance
    const { checklist } = value;
    const { checklistId: instanceId, name: instanceName } = checklist;

    // Navigate to Instance
    navigateToChecklist(
      instanceName,
      instanceId,
      newParents,
      location,
      history
    );
  } else if (configuration && configuration.checklist) {
    // Indedpendent checklist (not always tied to parent)
    if (configuration.independentCompletion) {
      // TODO: need spinner for fetching active in case hangs
      const activeChecklists = await fetchActive(
        orgId,
        configuration.checklist,
        client
      );

      const { findInstancesByTemplateId } = activeChecklists;
      const items = findInstancesByTemplateId.items || [];

      // TODO: need to figure out completed vs active
      // Current behavior just shows latest, completed or active.  So if create new active it will show.
      let activeChecklistIndex =
        items.length > 1
          ? Object.keys(items).reduce((a, b) => {
              return Date.parse(items[a].metadata.created.time) >
                Date.parse(items[b].metadata.created.iime)
                ? a
                : b;
            })
          : items[0];

      const activeChecklist = items[activeChecklistIndex];

      if (activeChecklist) {
        // Navigate to current active
        const { metadata, id } = activeChecklist;
        navigateToChecklist(metadata.name, id, newParents, location, history);
      } else {
        // Need to create checklist onClick
        setCreatingSub(true);
        createSubchecklist(props, client, true);
      }
    } else {
      // Need to create checklist onClick
      setCreatingSub(true);
      createSubchecklist(props, client);
    }
  } else {
    console.log("ERROR: SubChecklist configuration not set in template");
  }
};

const handleCompleteToggle = (step, updateStep) => {
  let newStep = Object.assign({}, step);
  newStep.configuration = Object.assign({}, step.configuration, {
    independentCompletion: !step.configuration.independentCompletion
  });
  updateStep(newStep);
};

const StepIcon = ({ classes, step }) => {
  return (
    <TableCell className={classes.tableCheck}>
      <div className={getIconClass(step, classes)}>
        <Icon>assignment</Icon>
      </div>
    </TableCell>
  );
};

const StepContent = props => {
  const { classes, step, mode, updateStep, user, editing } = props;
  let onClickFn = () => {};
  let cellClasses;

  const isInstanceEditable =
    mode === ChecklistMode.INSTANCE_EXEC ||
    mode === ChecklistMode.INSTANCE_EDIT;
  if (isInstanceEditable) {
    cellClasses = classes.linkCell;
    onClickFn = executeSubChecklist;
  }

  const isTemplate = mode === ChecklistMode.TEMPLATE_VIEW;
  if (isTemplate) {
    cellClasses = classes.linkCell;
    onClickFn = navigateTemplate;
  }

  const isCompleted = mode === ChecklistMode.INSTANCE_DONE;
  if (isCompleted) {
    cellClasses = classes.linkCell;
    onClickFn = navigateCompleted;
  }

  const isTemplateEditMode = mode === ChecklistMode.TEMPLATE_EDIT;

  return (
    <TableCell className={classes.tableContent}>
      <ApolloConsumer>
        {client => (
          <div className={cellClasses} onClick={() => onClickFn(props, client)}>
            <ViewTitle {...props} />
            {isTemplateEditMode ? (
              <StepConfigure
                step={step}
                updateStep={updateStep}
                user={user}
                editing={editing}
                props={props}
              />
            ) : null}
          </div>
        )}
      </ApolloConsumer>
    </TableCell>
  );
};

// 1. Create a GUID, then pass the id, name, and version to onchange
// 2. take step config from onChange and create a new checklist with starting name and id
const createNew = (step, updateStep, props) => {
  const checklistId = guid();
  let newStep = Object.assign({}, step);
  const checklistConfig = {
    checklist: {
      checklistId,
      name: step.name,
      version: 1
    }
  };

  newStep.configuration = Object.assign(
    {},
    step.configuration,
    checklistConfig
  );

  updateStep(newStep);
  createNewChecklist(history, checklistId, step.name, props);
};

const StepConfigure = ({ step, updateStep, user, editing, props }) => {
  // If not edit mode and a checklist has been linked, hide new & selector
  const showEditing = editing || !step.configuration.checklist;
  return (
    <ChecklistTemplateSelector
      step={step}
      updateStep={updateStep}
      user={user}
      editing={showEditing}
      createNew={() => createNew(step, updateStep, props)}
    />
  );
};

/**
 * Subchecklist
 *
 * This enables checklists to be created recursively.
 */
const Subchecklist = props => {
  const [creatingSub, setCreatingSub] = useState(false);
  const {
    classes,
    step,
    updateStep,
    editing,
    setEditState,
    user,
    noteOpen,
    setNoteState
  } = props;
  const editProps = {
    editing,
    setEditState,
    noteOpen,
    setNoteState,
    creatingSub,
    setCreatingSub
  };
  const tabs = getCommonTabs(step, updateStep);
  const showRowDividers = true;
  const primaryTableRowClass = getStepRowClass(
    classes,
    showRowDividers,
    editing || noteOpen
  );

  // add button to add another expiration
  tabs.push({
    tabName: "Expiration",
    tabIcon: AlarmAdd,
    tabContent: <StepExpirationEdit step={step} updateStep={updateStep} />
  });

  tabs.push({
    tabName: "Configuration",
    tabIcon: Settings,
    tabContent: (
      <span>
        <legend>Choose a Checklist</legend>
        <ChecklistTemplateSelector
          step={step}
          updateStep={updateStep}
          user={user}
        />
        <FormControlLabel
          control={
            <Checkbox
              tabIndex={-1}
              checked={step.configuration.independentCompletion}
              onClick={() => handleCompleteToggle(step, updateStep)}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          }
          classes={{
            label: classes.label,
            root: classes.labelRoot
          }}
          label="Complete independently from parent"
        />
      </span>
    )
  });

  return (
    <>
      <TableRow key={step.stepId} className={primaryTableRowClass}>
        <StepIcon {...props} step={step} />
        <StepContent {...props} {...editProps} step={step} />
      </TableRow>
      <EditRow classes={classes} open={editing}>
        <CustomTabs title="Configure" headerColor="gray" tabs={tabs} />
      </EditRow>
      <EditRow classes={classes} open={noteOpen}>
        <StepNote step={step} updateStep={updateStep} />
      </EditRow>
    </>
  );
};

export default compose(
  CreateChecklistTemplate,
  CreateChecklistInstance,
  withRouter,
  withStyles(stepsStyle)
)(Subchecklist);
