import React from "react";
import { NavLink } from "react-router-dom";
import cx from "classnames";

// @material-ui/core components
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";

// Services
import { getItemIcon } from "../Links/LinksUtility";

export const getDrawerPaperClass = (classes, miniActive) => {
  return (
    classes.drawerPaper +
    " " +
    cx({
      [classes.drawerPaperMini]: miniActive
    })
  );
};

export const getSidebarWrapperClass = (classes, miniActive) => {
  return (
    classes.sidebarWrapper +
    " " +
    cx({
      [classes.drawerPaperMini]: miniActive,
      [classes.sidebarWrapperWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1
    })
  );
};

export const getItemTextClass = (classes, miniActive) => {
  return (
    classes.itemText +
    " " +
    cx({
      [classes.itemTextMini]: miniActive
    })
  );
};

export const getCollapseItemTextClass = (classes, miniActive) => {
  return (
    classes.collapseItemText +
    " " +
    cx({
      [classes.collapseItemTextMini]: miniActive
    })
  );
};

export const getUserWrapperClass = (classes, bgColor) => {
  return (
    classes.user +
    " " +
    cx({
      [classes.whiteAfter]: bgColor === "white"
    })
  );
};

export const getListItemTextClass = (classes, openAvatar) => {
  return (
    classes.caret +
    " " +
    classes.userCaret +
    " " +
    (openAvatar ? classes.caretActive : "")
  );
};

const getImage = (classes, link) => {
  if (link.logo) {
    return (
      <img src={link.logo} alt="logo" className={classes.orgSelectorImg} />
    );
  }
  if (link.icon) {
    return (
      <ListItemIcon className={classes.itemIconCollapse}>
        {getItemIcon({ icon: "add" })}
      </ListItemIcon>
    );
  }
  // return mini
  return <span className={classes.collapseItemMini}>{link.itemMini}</span>;
};

// todo icon here
export const LinkContent = ({ classes, link, miniActive }) => {
  const collapseItemText = getCollapseItemTextClass(classes, miniActive);
  return (
    <>
      {getImage(classes, link)}
      <ListItemText
        primary={link.name}
        disableTypography={true}
        className={collapseItemText}
      />
    </>
  );
};

const LinkWrapper = props => {
  const { classes, link, miniActive } = props;
  if (link.clickFn) {
    return (
      <div className={classes.itemLink + " " + classes.userCollapseLinks}>
        <LinkContent classes={classes} link={link} miniActive={miniActive} />
      </div>
    );
  } else {
    return (
      <NavLink
        to={link.to}
        className={classes.itemLink + " " + classes.userCollapseLinks}
      >
        <LinkContent {...props} />
      </NavLink>
    );
  }
};

const LinkWrapperOrg = props => {
  const { classes, link, miniActive } = props;
  if (link.clickFn) {
    return (
      <div className={classes.itemLink + " " + classes.orgCollapseLinks}>
        <LinkContent classes={classes} link={link} miniActive={miniActive} />
      </div>
    );
  } else {
    return (
      <NavLink
        to={link.to}
        className={classes.itemLink + " " + classes.orgCollapseLinks}
      >
        <LinkContent {...props} />
      </NavLink>
    );
  }
};

export const LinkItem = props => {
  const { classes, link } = props;
  const clickFn = link.clickFn ? link.clickFn : () => {};
  const listItemClass =
    link.hide === "sm"
      ? classes.collapseItem + " " + classes.hideSm
      : classes.collapseItem;

  return (
    <ListItem className={listItemClass} onClick={clickFn}>
      <LinkWrapper {...props} />
    </ListItem>
  );
};

export const LinkItemOrg = props => {
  const { classes, link } = props;
  const clickFn = link.clickFn ? link.clickFn : () => {};
  const listItemClass =
    link.hide === "sm"
      ? classes.collapseItem + " " + classes.hideSm
      : classes.collapseItem;

  return (
    <ListItem className={listItemClass} onClick={clickFn}>
      <LinkWrapperOrg {...props} />
    </ListItem>
  );
};
