import gql from "graphql-tag";
import { Logbook } from "../fragments";

export const LogbooksQuery = gql`
  query LogbooksQuery($orgId: String!) {
    getLogbooks(orgId: $orgId) {
      items {
        id
        metadata {
          ...LogbookMetadataInfo
        }
      }
      nextToken
      scannedCount
    }
  }
  ${Logbook.logbookMetadataInfo}
`;

export const LogbookQuery = gql`
  query LogbookQuery($orgId: String!, $id: String!, $version: Int) {
    getLogbook(orgId: $orgId, id: $id, version: $version) {
      id
      metadata {
        ...LogbookMetadataInfo
      }
      widgets {
        ...WidgetInfo
      }
    }
  }
  ${Logbook.logbookMetadataInfo}
  ${Logbook.widgetInfo}
`;
