import Checklist from "views/Checklists/Checklist.jsx";
import Dashboard from "views/Dashboards/Dashboard.jsx";
import MyProfile from "views/Pages/MyProfile.jsx";
import OrgProfile from "views/Pages/OrgProfile.jsx";
import Search from "views/Search/Search.jsx";

export const CheckListRoute = {
  path: "/app/checklist",
  name: "Checklist",
  component: Checklist
};

export const CheckListDynamicRoute = {
  path: "/app/checklist/:name",
  name: "Checklist",
  component: Checklist
};

export const DashboardRoute = {
  path: "/app/dashboard",
  name: "Dashboard",
  component: Dashboard
};

export const DashboardDynamicRoute = {
  path: "/app/dashboard/:name",
  name: "Dashboard",
  component: Dashboard
};

export const SearchRoute = {
  path: "/app/search",
  name: "Search",
  component: Search
};

export const MyProfileRoute = {
  path: "/app/my-profile",
  name: "My Profile",
  component: MyProfile
};

export const OrgProfileRoute = {
  path: "/app/org-profile",
  name: "Organization Profile",
  component: OrgProfile
};

// Use to test Collapse
// const Collapse = {
//   collapse: true,
//   name: "Collapse",
//   icon: GridOn,
//   state: "componentsCollapse",
//   leftNav: true,
//   views: [
//     {
//       path: "/app/dashboards",
//       collapse: true,
//       name: "Sub Menu",
//       mini: "MC"
//     }
//   ]
// };

// Order Matters - Last Win
const getDashboardRoutes = leftNav => {
  return [
    CheckListRoute,
    CheckListDynamicRoute,
    DashboardRoute,
    // Collapse,
    DashboardDynamicRoute,
    SearchRoute,
    MyProfileRoute,
    OrgProfileRoute,
    ...leftNav
  ];
};
export default getDashboardRoutes;
