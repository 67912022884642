import queryString from "query-string";

/**
 * Lots o' hacks.
 * Clean up an move more general handling to checklist service
 */

const isSubChecklist = parents => {
  return parents && parents.split(",") && parents.split(",").length > 0;
};

// Returns the immedate parents id & name and a new parent list without the immediate parent
// Parent checklists are saved in a list of format id__name
const extractParentInfo = parents => {
  const parentItems = parents.split(","); // Split apart parent list
  const parentParts = parentItems[parentItems.length - 1].split("__");
  const parentId = parentParts[0];
  const parentName = parentParts[1];
  // const parentName = parentParts.join(""); // Why join here, not just name is idname with no space?
  // parentParts.shift(); // Why do this???
  const newParentList = parentItems.slice(0, -1).join(",");
  return { parentId, parentName, newParentList };
};

// On checklist completion - redirect to parent or completed version (change search params)
const constructCompletedRedirectUrl = (id, parents, location) => {
  if (isSubChecklist(parents)) {
    // After completing sub auto jump to parent
    const { parentId, parentName, newParentList } = extractParentInfo(parents);
    const value = queryString.parse(location.search);
    const { edit, instance, status } = value;
    let urlSafe = `/app/checklist/${parentName}?id=${parentId}`;
    if (newParentList.length > 0)
      urlSafe = `${urlSafe}&parents=${newParentList}`;
    if (edit) urlSafe = urlSafe + `&edit=${edit}`;
    if (instance) urlSafe = urlSafe + `&instance=${instance}`;
    if (status) urlSafe = urlSafe + `&status=${status}`;
    return urlSafe;
  } else {
    // Show checklist as completed by changing search info
    const newSearch = `id=${id}&instance=true&status=complete`;
    return Object.assign({}, location, {
      search: newSearch
    });
  }
};

export const redirectOnCompleteChecklist = (props, id, parents) => {
  const { location, history } = props;
  const newLocation = constructCompletedRedirectUrl(id, parents, location);
  history.replace(newLocation);
};

const constructFirstCreateRedirectUrl = (id, location) => {
  const { search } = location;
  const newSearch = search ? search + `&id=${id}` : `?id=${id}`;
  return Object.assign({}, location, {
    search: newSearch
  });
};

export const redirectOnCreateChecklist = (props, id) => {
  const { location, history } = props;
  // Add id to URL which will refetch updated version
  const newLocation = constructFirstCreateRedirectUrl(id, location);
  history.push(newLocation);
};

const constructEditRedirectUrl = location => {
  const { search } = location;
  const newSearch = search ? search + "&edit=true" : "?edit=true";
  return Object.assign({}, location, { search: newSearch });
};

export const redirectOnEditChecklist = props => {
  const { location, history } = props;
  const newLocation = constructEditRedirectUrl(location);
  history.push(newLocation);
};

const constructCancelEditRedirectUrl = location => {
  const { search } = location;
  const value = queryString.parse(search);
  const newSearch = Object.keys(value)
    .filter(key => key !== "edit")
    .map(key => {
      return `${key}=${value[key]}`;
    })
    .join("&");
  return Object.assign({}, location, {
    search: `?${newSearch}`
  });
};

export const redirectOnCancelEditChecklist = props => {
  const { location, history } = props;
  const newLocation = constructCancelEditRedirectUrl(location);
  history.push(newLocation);
};

const constructViewTemplateRedirectUrl = (location, checklist) => {
  const { metadata } = checklist;
  const { template } = metadata;
  const { checklistId, version } = template;
  const newSearch = `?id=${checklistId}&v=${version}&edit=true`; // Remove ownerId info, assume edit for now
  return Object.assign({}, location, { search: newSearch });
};

export const redirectOnViewChecklistTemplate = (props, checklist) => {
  const { user, location, history } = props;
  const userId = user.profile.id;
  const newLocation = constructViewTemplateRedirectUrl(
    location,
    checklist,
    userId
  );
  history.push(newLocation);
};

const constructExecuteRedirectUrl = (location, id) => {
  const newPathname = "/app/checklist/checklists";
  const newSearch = `?id=${id}&instance=true`;
  return Object.assign({}, location, {
    pathname: newPathname,
    search: newSearch
  });
};

export const redirectOnFirstExecuteChecklist = (props, id) => {
  const { location, history } = props;
  const newLocation = constructExecuteRedirectUrl(location, id);
  history.push(newLocation);
};
