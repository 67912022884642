/* eslint no-unused-vars: "off" */

/**
 * SITE CONFIG
 *
 * All the necessary parts to configure a site:
 * Checklist Zen, Airspace Ops, etc.
 */

import logo from "./DroneOps_Logo_White.png";

import Dashboard from "views/Dashboard-Deprecated/Dashboard.jsx"; // Should this be Dashboard?  Why does this work.
import Checklists from "views/Checklists/Checklists.jsx";
import Dashboards from "views/Dashboards/Dashboards.jsx";
import Checklist from "views/Checklists/Checklist.jsx";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/DashboardOutlined";
import GridOn from "@material-ui/icons/GridOnOutlined";
import Build from "@material-ui/icons/BuildOutlined";
import FlightTakeoff from "@material-ui/icons/FlightTakeoffOutlined";
import Language from "@material-ui/icons/LanguageOutlined";
import Book from "@material-ui/icons/BookOutlined";

/////////////////////////////
// Navigation Links
/////////////////////////////

// TODO: move to Dashboard
const DashboardRoute = {
  path: "/app/dashboard/ops_dashboard?id=ef507950-ec6d-4331-a865-066edfce7d14",
  name: "Ops Dashboard",
  id: "ops-dashboard",
  icon: DashboardIcon,
  component: Dashboard,
  leftNav: true
};

// const FlightRoute = {
//   path: "/app/checklist/new-flight?id=DRONE000-0000-0000-0000-000000FLIGHT",
//   name: "New Flight",
//   id: "new-flight",
//   icon: FlightTakeoff,
//   component: Checklist,
//   leftNav: true
// };

// const MaintenanceRoute = {
//   path: "/app/checklist/maintenance?id=DRONE000-0000-0000-0000-0000000MAINT",
//   name: "Maintenance",
//   id: "maintenance",
//   icon: Build,
//   component: Checklist,
//   leftNav: true
// };

const RampCheckRoute = {
  path: "/app/dashboard/faa_ramp_check?id=0a14245c-fe5f-4f05-90fc-b191c095d1b1",
  name: "FAA Ramp Check",
  id: "faa-ramp-check",
  icon: Language, // pan_tool, language, account_balance, alarm_on, announcement, assignment_ind
  component: Dashboard, // TODO: Dashboard<RampCheck>
  leftNav: true
};

// const SOP = {
//   path: "/app/checklist/sop?id=DRONE000-0000-0000-0000-000000000SOP",
//   name: "SOP",
//   id: "sop",
//   icon: Book,
//   component: Checklist,
//   leftNav: true
// };

const ChecklistsRoute = {
  path: "/app/dashboard/checklists?id=d47b062f-35dd-4af1-ac17-86294e55e136",
  name: "Checklists",
  id: "checklists",
  icon: "content_paste",
  component: Dashboard,
  leftNav: true
};

const ChecklistsRouteOld = {
  path: "/app/checklists-old",
  name: "Checklists",
  id: "checklists",
  icon: "content_paste",
  component: Checklists,
  leftNav: false
};

const DashboardsRoute = {
  path: "/app/dashboards",
  name: "Dashboards",
  id: "dashboards",
  icon: GridOn,
  component: Dashboards,
  leftNav: true
};

const AppRoute = {
  redirect: true,
  path: "/app",
  pathTo:
    "/app/dashboard/ops_dashboard?id=ef507950-ec6d-4331-a865-066edfce7d14",
  component: Dashboard
};

/////////////////////////////
// SITE CONFIGURATIONS
////////////////////////////

const DRONE_OPS = {
  name: "Drone Ops",
  logo,
  leftNav: [
    DashboardRoute,
    RampCheckRoute,
    ChecklistsRoute,
    DashboardsRoute,
    ChecklistsRouteOld,
    AppRoute
  ]
};

export default DRONE_OPS;
