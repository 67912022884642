import {
  defaultFont,
  cardTitle,
  roseColor
} from "assets/jss/material-dashboard-pro-react.jsx";

const checklistStyles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  },
  cardCategory: {
    color: "#999999",
    marginTop: "10px"
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px"
    }
  },
  iconRose: {
    color: roseColor
  },
  marginTop30: {
    marginTop: "30px"
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px"
    }
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: "#999999"
  },
  breadcrumbContainer: {
    marginLeft: "-15px"
  },
  breadcrumb: {
    display: "flex",
    alignItems: "center",
    lineHeight: "0",
    fontSize: "1em",
    marginBottom: "-8px",
    marginLeft: "6px"
  },
  breadcrumbText: {
    display: "inline-block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    height: "20px",
    paddingTop: "9px"
  },
  loadingContainer: {
    minHeight: "80vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputDescription: {
    width: "100%",
    padding: 0
  },
  checklistDescription: {
    ...defaultFont,
    display: "inline-block",
    flexGrow: 1,
    margin: "0px",
    fontSize: "0.9em",
    color: "rgba(0, 0, 0, 0.54)",
    fontWeight: "300"
  }
};

export default checklistStyles;
