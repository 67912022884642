import React from "react";

// @material-ui/core components
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

// Components
import StepSelector from "components/StepSelector/StepSelector";

const createNewWidget = (widget, json) => {
  let newWidget = Object.assign({}, widget);
  // const checklistJson = checklist ? { checklist } : {};
  const newJson = Object.assign({}, newWidget.configuration.json, json);
  const newConfiguration = { json: newJson };
  newWidget.configuration = Object.assign(
    {},
    widget.configuration,
    newConfiguration
  );
  return newWidget;
};

/**
 * Configuration for checklist step widget
 * Allows a checklist template and steps to be displayed
 * Can use an actually template or a reference to a global checklist
 * If reference type will use steps from that checklist
 * Or if template is a different type, it will search for a subchecklsit of that type
 * fetch it and use the steps from it
 */
class ChecklistStepConfig extends React.Component {
  render() {
    const { widget, updateWidget, user } = this.props;
    const { configuration } = widget;
    const { json } = configuration;
    const checklist = json ? json.checklist : null;
    const reference =
      json && typeof json.reference !== "undefined" ? json.reference : false;
    const steps = json && json.steps ? json.steps : [];

    const onChecklistChange = checklist => {
      const checklistJson = checklist ? { checklist } : {};
      const newWidget = createNewWidget(widget, checklistJson);
      updateWidget(newWidget);
    };

    const onStepChange = step => {
      const stepsJson = { steps: steps.concat([step]) };
      const newWidget = createNewWidget(widget, stepsJson);
      updateWidget(newWidget);
    };

    const onClearSteps = () => {
      const stepsJson = { steps: [] };
      const newWidget = createNewWidget(widget, stepsJson);
      updateWidget(newWidget);
    };

    const onReferenceChange = () => {
      const referenceJson = { reference: !reference };
      const newWidget = createNewWidget(widget, referenceJson);
      updateWidget(newWidget);
    };

    return (
      <>
        <StepSelector
          checklist={checklist}
          steps={steps}
          onChecklistChange={onChecklistChange}
          onStepChange={onStepChange}
          onClearSteps={onClearSteps}
          user={user}
        />
        <FormControlLabel
          control={
            <Switch
              checked={reference}
              onChange={onReferenceChange}
              value="reference"
            />
          }
          label="Reference"
        />
      </>
    );
  }
}

export default ChecklistStepConfig;
