import React from "react";
import { SignupConfig } from "services/ConfigService";

export const LOGIN_TYPE = {
  GOOGLE: "GOOGLE",
  FACEBOOK: "FACEBOOK",
  AMAZON: "AMAZON",
  COGNITO: "COGNITO"
};

export const FORM_STATE = {
  SIGN_IN: "SIGN_IN",
  RESET_PASSWORD: "RESET_PASSWORD",
  NEW_PASSWORD: "NEW_PASSWORD"
};

export const getInputConfig = () => {
  if (SignupConfig.username) {
    // TODO: handle username + email and/or phone
    return {
      label: "Username",
      error: "Username can not be empty"
    };
  } else if (SignupConfig.email) {
    if (SignupConfig.phone) {
      return {
        label: "Email or phone",
        error: "Not a valid email or phone number."
      };
    } else {
      return {
        label: "Email",
        error: "Not a valid email address."
      };
    }
  } else {
    return {
      label: "Phone",
      error: "Not a valid phone number."
    };
  }
};

export const INPUT_TYPE = {
  USERNAME: "USERNAME",
  PASSWORD: "PASSWORD",
  NEW_PASSWORD: "NEW_PASSWORD",
  CODE: "CODE"
};

export const ERROR_MESSAGE = {
  PASSWORD:
    "Not a valid password.  Must container at least 8 characters, one upercase letter, one lowercase letter, one number, and one special character.",

  CODE: "Code must not be empty."
};

export const NOTICE_TYPE = {
  RESEND_SUCCESS: "RESEND_SUCCESS",
  RESEND_ERROR: "RESEND_ERROR",
  RESET_SUCCESS: "RESET_SUCCESS",
  RESET_ERROR: "RESET_ERROR",
  CODE_SENT: "CODE_SENT",
  SOCIAL_SIGNIN_ERROR: "SOCIAL_SIGNIN_ERROR"
};

export function getNoticeTitle(noticeType, classes, error, email) {
  switch (noticeType) {
    case NOTICE_TYPE.RESEND_SUCCESS:
      return (
        <div>
          <h3 className={classes.successColor}>Resend Success</h3>
          <p>
            The verification link was sent. The link can sometimes take 5 - 10
            minutes to arrive. Also, you should check your spam folder. Feel
            free to contact us with any issues at{" "}
            <a href="mailto:team@airsync.ai">team@airsync.ai</a> if the link
            doesn&apos;t arrive.
          </p>
        </div>
      );
    case NOTICE_TYPE.RESEND_ERROR:
      return (
        <div>
          <h3 className={classes.dangerColor}>Resend Error</h3>
          <p>
            There was an error resending the verification link. Please contact
            us at <a href="mailto:team@airsync.ai">team@airsync.ai</a> to help
            trouple shoot the issue.
          </p>
          <p>{typeof error === "string" ? error : JSON.stringify(error)}</p>
        </div>
      );
    case NOTICE_TYPE.RESET_SUCCESS:
      return (
        <div>
          <h3 className={classes.successColor}>Password Reset</h3>
          <p>Your password was successfully reset</p>
        </div>
      );
    case NOTICE_TYPE.RESET_ERROR:
      return (
        <div>
          <h3 className={classes.dangerColor}>Reset Error</h3>
          <p>
            There was an error reseting your password. Please contact us at{" "}
            <a href="mailto:team@airsync.ai">team@airsync.ai</a> to help trouple
            shoot the issue.
          </p>
          <p>{typeof error === "string" ? error : JSON.stringify(error)}</p>
        </div>
      );
    case NOTICE_TYPE.CODE_SENT:
      return (
        <div>
          <h3 className={classes.successColor}>Password Reset Code Sent</h3>
          <p>
            A verification code was sent to your email at {email}. Please use
            that code to set a new password.
            <br />
            <br />
            Email us at <a href="mailto:team@airsync.ai">team@airsync.ai</a> if
            the code doesn&apos;t arrive in 5 - 10 minutes.
          </p>
        </div>
      );
    case NOTICE_TYPE.SOCIAL_SIGNIN_ERROR:
      return (
        <div>
          <h3 className={classes.danger}>Error Signing In</h3>
          <p>
            There was an error signing in.
            <br />
            <br />
            Email us at <a href="mailto:team@airsync.ai">team@airsync.ai</a> to
            help trouple shoot the issue.
          </p>
          <p>{typeof error === "string" ? error : JSON.stringify(error)}</p>
        </div>
      );
    default:
      return "";
  }
}
