// ##############################
// // // Terms view styles
// #############################

import { container } from "assets/jss/material-dashboard-pro-react.jsx";

const termsPageStyle = {
  container: {
    ...container,
    position: "relative",
    zIndex: "3",
    padding: "10vh 0"
  }
};

export default termsPageStyle;
