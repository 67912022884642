import bgImage from "assets/img/background-full.jpeg";
import bgImageSm from "assets/img/background-1200.jpeg";
import bgImageXs from "assets/img/background-992.jpeg";

const pagesStyle = theme => ({
  wrapper: {
    height: "auto",
    minHeight: "100vh",
    position: "relative",
    top: "0",
    "&:before": {
      backgroundColor: "rgba(0, 0, 0, 0.65)"
    },
    "&:before,&:after": {
      display: "block",
      content: '""',
      position: "absolute",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      zIndex: "1"
    }
  },
  fullPage: {
    // padding: "120px 0",
    position: "relative",
    overflowY: "scroll",
    minHeight: "100vh",
    // display: "flex!important",
    margin: "0",
    border: "0",
    color: "#fff",
    // alignItems: "center",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    height: "100%",
    // [theme.breakpoints.down("sm")]: {
    //   minHeight: "fit-content!important"
    // }
    // "& footer": {
    //   position: "absolute",
    //   bottom: "0",
    //   width: "100%",
    //   border: "none !important"
    // },
    overflowX: "hidden",
    backgroundImage: "url(" + bgImage + ")",
    [theme.breakpoints.down("xs")]: {
      backgroundImage: "url(" + bgImageXs + ")"
    },
    [theme.breakpoints.only("sm")]: {
      backgroundImage: "url(" + bgImageSm + ")"
    }
  },
  pageContainer: {
    height: "100vh",
    maxHeight: "100vh",
    minHeight: "100vh"
  }
});

export default pagesStyle;
