import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { flowRight as compose } from "lodash";
import { S3Image } from "aws-amplify-react"; // Amplify S3 Image Viewer

// @material-ui/core components

// @material-ui/icons

// core components
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CardBody from "components/Card/CardBody";

// subcomponents
import WidgetEditButtons from "../../WidgetComponents/WidgetEditButtons";
import Rows from "./AwardboardComponents/Rows";

// services
import {
  FetchCompletedChecklist,
  CreateChecklistInstance
} from "services/LogbookService";
import { processData, FetchAwards } from "./awardboardService";

import {
  AwardboardStyle,
  getTableStyle,
  cardIconTheme
} from "./awardboardStyle";

// Figure out PERF issues
// TODO: continue refactor => check with Shahla about one at a time or all at once
// maybe switch to a button "Claim Prize!"

/**
 * Awardboard
 */
const Awardboard = props => {
  const {
    classes,
    mode,
    widget,
    editWidget,
    deleteWidget,
    globalSettings,
    awards,
    completedChecklists,
    createChecklistInstance,
    history,
    user
  } = props;
  const [users, setUsers] = useState([]);
  const { values, configuration } = widget;
  const config = configuration.json;
  const { globalDateRange } = globalSettings;
  const aUser = users[0];
  const aTemplate = aUser ? aUser.template : null;
  const CARD_ICON_URL =
    "checklist-zen/lemonadepixel/kidschores/kidschores-color/PNGs/lemonadepixel_kidschores-53.png";

  useEffect(() => {
    if (
      users &&
      completedChecklists.aggregateInstances &&
      awards.aggregateInstances
    ) {
      const newUsersData = processData(
        completedChecklists,
        awards,
        config.users
      );
      setUsers(newUsersData);
    }
  }, [completedChecklists, awards]);

  const updateAward = async (intUser, awardInstance, starInstance, user) => {
    const orgId = user.currentOrganization.id;
    const {
      id: awardId,
      metadata: awardMetadata,
      steps: awardSteps
    } = awardInstance;
    const {
      id: starId,
      metadata: starMetadata,
      steps: starSteps
    } = starInstance;

    createChecklistInstance(orgId, awardMetadata, awardSteps, awardId);
    createChecklistInstance(orgId, starMetadata, starSteps, starId);
  };

  return (
    <Card>
      <CardHeader color={values.color} icon>
        <WidgetEditButtons
          mode={mode}
          classes={classes}
          editWidget={editWidget}
          deleteWidget={deleteWidget}
        />
        <CardIcon style={AwardboardStyle.cardIcon} color={widget.values.color}>
          <S3Image imgKey={CARD_ICON_URL} theme={cardIconTheme} />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>{configuration.title}</h4>
      </CardHeader>
      <CardBody>
        <div style={AwardboardStyle.tableContainerDiv}>
          <table style={getTableStyle(aTemplate)}>
            <Rows
              users={users}
              updateAward={(intUser, awardInstance, starInstance) =>
                updateAward(intUser, awardInstance, starInstance, user)
              }
              globalDateRange={globalDateRange}
              history={history}
            />
          </table>
        </div>
      </CardBody>
    </Card>
  );
};

export default compose(
  FetchCompletedChecklist,
  FetchAwards,
  CreateChecklistInstance,
  withRouter
)(Awardboard);
