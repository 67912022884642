import cardHeaderStyle from "./cardHeaderStyle";

const colorSelectorStyle = {
  ...cardHeaderStyle,
  container: {
    height: "400px"
  },
  selector: {
    height: "40px",
    width: "100%",
    display: "inline-block",
    margin: "15px 8px !important"
  },
  colorButton: {
    width: "40%",
    height: "60px",
    padding: "10px",
    borderRadius: "8px",
    margin: "0"
  },
  colorButtonRoot: {}
};

export default colorSelectorStyle;
