import React from "react";
import { withRouter } from "react-router-dom";
import { graphql } from "react-apollo"; // Apollo Data Layer
import { flowRight as compose } from "lodash";

// @material-ui/core components
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Add from "@material-ui/icons/Add";

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";

// sub components
import WidgetEditButtons from "../../WidgetComponents/WidgetEditButtons";
import {
  CreateChecklistInstance,
  AddEvent
} from "../../../../Checklists/ChecklistComponents/ChecklistQueries";

// services
import { LogbookMode } from "services/LogbookService";
import {
  StatusType,
  newChecklist,
  updateTemplate,
  navigateToChecklist,
  constructInstanceName
} from "services/ChecklistService";

// queries
import {
  FindInstancesByTemplateIdFullQuery,
  ChecklistTemplateQuery
} from "api/queries";
import { CreateChecklistTemplate } from "views/Checklists/ChecklistComponents/ChecklistQueries";

const COMPLETED_COLOR = "#0C63B6";
const COMPLETED_TEXT = "COMPLETE";
const ACTIVE_COLOR = "#5CB460";
const ACTIVE_TEXT = "ACTIVE";

const iconStyle = {
  opacity: "0.86",
  fontWeight: "400",
  width: "18px",
  marginTop: "0px"
};

const INSTANCE_NUMBER = 8;

const OutlineButton = ({ title, icon, styles, onClick }) => {
  const styleDefault = {
    color: "#00acc1",
    fontSize: "14px",
    lineHeight: "1.428571429",
    opacity: "0.86",
    fontWeight: "400",
    border: "1px solid #00acc1",
    padding: "3px 12px 3px 6px",
    marginTop: "10px"
  };

  const style = Object.assign({}, styleDefault, styles || {});
  return (
    <Button style={style} variant="outlined" color="inherit" onClick={onClick}>
      {icon}
      {title}
    </Button>
  );
};

/**
 * Checklist Widget - Displays a template and most recent active/completed.
 * Allows easly find a recent checklist, starting a new one, or managing the template.
 */
const WidgetChecklist = ({
  classes,
  mode,
  widget,
  editWidget,
  deleteWidget,
  templateData,
  instancesData,
  createChecklistInstance,
  createChecklistTemplate,
  location,
  history,
  user
}) => {
  let template =
    templateData && templateData.getChecklistTemplate
      ? templateData.getChecklistTemplate
      : {};
  // If template is attached it's dynamic so use passed in template
  if (widget.template) {
    template = widget.template;
  }
  const { metadata } = template;

  const isPinned = metadata && metadata.pinned ? true : false;

  const instances =
    instancesData &&
    instancesData.findInstancesByTemplateId &&
    instancesData.findInstancesByTemplateId.items
      ? instancesData.findInstancesByTemplateId.items
          .slice(0, INSTANCE_NUMBER)
          .map(instance => {
            const name = constructInstanceName(instance, template);
            const lastEditied = instance.metadata.modified.time
              .replace("T", " ")
              .slice(0, -5);
            return {
              id: instance.id,
              name: name,
              lastEdited: lastEditied,
              status: instance.metadata.status,
              instance: instance
            };
          })
      : [];

  // May need to wait for dynamic templates to load
  if (!metadata) {
    return <div>Loading...</div>;
  }

  const icon =
    metadata.banner && metadata.banner.icon
      ? metadata.banner.icon.link
      : "assignment";
  const color =
    metadata.banner && metadata.banner.color
      ? metadata.banner.color
      : "success";

  const togglePinned = () => {
    const props = { createChecklistTemplate, user };
    const newTemplate = JSON.parse(JSON.stringify(template));
    newTemplate.metadata.pinned = !isPinned;
    updateTemplate(newTemplate, props);
  };

  return (
    <Card>
      <CardHeader color="success" icon>
        <WidgetEditButtons
          mode={mode}
          classes={classes}
          editWidget={editWidget}
          deleteWidget={deleteWidget}
        />
        <CardIcon color={color}>
          <Icon>{icon}</Icon>
        </CardIcon>
        <span
          style={{ display: "inline-block" }}
          className={classes.cardIconTitle}
        >
          {metadata.name}
        </span>
        <OutlineButton
          title="Manage"
          icon={<Edit style={iconStyle} />}
          styles={{ float: "right" }}
          onClick={() => navigateToChecklist(template, history, true)}
        />
        <FormControlLabel
          control={
            <Switch
              checked={isPinned}
              onChange={togglePinned}
              value="isPublic"
              classes={{
                switchBase: classes.switchBase,
                checked: classes.switchChecked,
                thumb: classes.switchIcon,
                track: classes.switchBar
              }}
              inputProps={{
                disabled: mode === LogbookMode.EDIT
              }}
            />
          }
          classes={{
            label: classes.label
          }}
          style={{ float: "right", marginTop: "5px", marginRight: "32px" }}
          label={isPinned ? "Pinned" : "Unpinned"}
        />
      </CardHeader>
      <CardBody>
        <Divider style={{ margin: "-8px 0 8px" }} />
        <OutlineButton
          title="New Checklist"
          icon={<Add style={iconStyle} />}
          onClick={() => {
            const props = { createChecklistInstance, history, location, user };
            newChecklist(template, props);
          }}
        />
        <Table className={classes.table}>
          <TableHead>
            <TableRow classes={{ root: classes.rootTableRowHeader }}>
              <TableCell
                align="left"
                classes={{ root: classes.rootTableCellHeader }}
              >
                CHECKLIST NAME
              </TableCell>
              <TableCell
                align="left"
                classes={{ root: classes.rootTableCellHeader }}
              >
                LAST EDITED
              </TableCell>
              <TableCell
                align="left"
                classes={{ root: classes.rootTableCellHeader }}
              >
                STATUS
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {instances.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="3"
                  classes={{ root: classes.noDataTableCell }}
                >
                  <h5>No Active or Completed {metadata.name} Checklists</h5>
                </TableCell>
              </TableRow>
            ) : null}
            {instances.map(row => {
              return (
                <TableRow
                  key={row.id}
                  classes={{ root: classes.rootTableRow }}
                  onClick={() => navigateToChecklist(row.instance, history)}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    classes={{ root: classes.rootTableCell }}
                  >
                    {row.name}
                  </TableCell>
                  <TableCell
                    align="left"
                    classes={{ root: classes.rootTableCell }}
                  >
                    {row.lastEdited}
                  </TableCell>
                  <TableCell
                    align="left"
                    classes={{ root: classes.rootTableCell }}
                  >
                    <div
                      style={{
                        backgroundColor:
                          row.status === StatusType.COMPLETED
                            ? COMPLETED_COLOR
                            : ACTIVE_COLOR,
                        display: "inline-block",
                        padding: "1px 10px",
                        borderRadius: "5px",
                        marginBottom: "6px"
                      }}
                    >
                      <span
                        style={{
                          color: "white",
                          fontSize: "12px",
                          fontWeight: 700
                        }}
                      >
                        {row.status === StatusType.COMPLETED
                          ? COMPLETED_TEXT
                          : ACTIVE_TEXT}
                      </span>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </CardBody>
    </Card>
  );
};

// Only fetch if not passed in dynamically
const ChecklistTemplate = graphql(ChecklistTemplateQuery, {
  name: "templateData",
  options: props => {
    const { widget, user } = props;
    const id = widget.configuration.checklist.checklistId;
    const orgId = user.currentOrganization.id;
    return {
      fetchPolicy: "network-only",
      variables: {
        orgId,
        id
      }
    };
  },
  skip: props => {
    // If widget has a template attached it was created dynamically so don't query
    return props.globalSettings.dynamic;
  }
});

// TODO: Optimize for only parts need to show
const FindInstancesByTemplateId = graphql(FindInstancesByTemplateIdFullQuery, {
  name: "instancesData",
  options: props => {
    const templateId = props.widget.template
      ? props.widget.template.id
      : props.widget.configuration.checklist.checklistId;
    return {
      fetchPolicy: "network-only",
      variables: {
        templateId
      }
    };
  },
  skip: props => {
    const isConfiguredTemplate =
      props.widget &&
      props.widget.configuration &&
      props.widget.configuration.checklist &&
      props.widget.configuration.checklist.checklistId;
    const isDynamicTemplate = props.widget && props.widget.template;
    return !isConfiguredTemplate && !isDynamicTemplate;
  }
});

export default compose(
  ChecklistTemplate,
  FindInstancesByTemplateId,
  CreateChecklistInstance,
  CreateChecklistTemplate,
  AddEvent,
  withRouter
)(WidgetChecklist);
