import React from "react";

// @material-ui/core components
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

// Services
import { LogbookMode } from "services/LogbookService";
import { SharedType } from "services/ChecklistService";

// Functions
function updatePublic(metadata, updateMetadata) {
  const newMetadata = Object.assign({}, metadata);
  switch (metadata.sharing.type) {
    case SharedType.PUBLIC:
      newMetadata.sharing.type = SharedType.PRIVATE;
      break;
    case SharedType.PRIVATE:
      newMetadata.sharing.type = SharedType.PUBLIC;
      break;
    default:
      console.log("Error: unhandled share type");
  }
  updateMetadata(newMetadata);
}

const IsPublic = ({ classes, metadata, updateMetadata, mode }) => {
  if (mode === LogbookMode.VIEW) {
    return null;
  }

  return (
    <FormControlLabel
      control={
        <Switch
          checked={metadata.sharing.type === SharedType.PUBLIC}
          onChange={() => updatePublic(metadata, updateMetadata)}
          value="isPublic"
          classes={{
            switchBase: classes.switchBase,
            checked: classes.switchChecked,
            thumb: classes.switchIcon,
            track: classes.switchBa
          }}
          inputProps={{
            disabled: mode !== LogbookMode.EDIT
          }}
        />
      }
      classes={{
        label: classes.label
      }}
      label="Public"
    />
  );
};

export default IsPublic;
