import React from "react";

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Popover from "@material-ui/core/Popover";

// material-ui icons

// Components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import MediaMenu, {
  MediaTabs,
  MediaType
} from "components/MediaMenu/MediaMenu";

// Subcomponents
import ChecklistBanner from "./ChecklistBanner";
import ChecklistName from "./ChecklistName";
import ChecklistIcon from "./ChecklistIcon";
import ChecklistSuggestions from "./ChecklistSuggestions";

// Services
import { getMenuItems } from "./ChecklistMenuItems";
import { start } from "services/VoiceService";
import { ChecklistMode, calculateModeInfo } from "services/ChecklistService";

// Asset
import checklistCardStyle from "assets/jss/material-dashboard-pro-react/components/checklistCardStyle.jsx";

/**
 * Note:
 * Edit Mode - isEditMode = User is in an edit mode with edit permisions - gate edit functionality (also on server)
 * Show Edit - showEdit = User has hovered over a component and edit functionality should be shown
 */

// Old UX construct using tabs for Details, Steps, Status
// Switched to main / default focus on Steps (with Name, Icon, Banner)
// Need to figure out the details & status piece.
const ChecklistTabs = ({ tabs, tabValue }) => {
  return tabs.map((prop, key) => {
    if (key === tabValue) {
      return <div key={key}>{prop.tabContent}</div>;
    }
    return null;
  });
};

function update(property, value, metadata, updateMetadata) {
  const newMetadata = Object.assign({}, metadata);
  newMetadata[property] = value;
  updateMetadata(newMetadata);
}

class ChecklistCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      anchorEl: null,
      editTitle: false,
      mediaMenu: {
        open: false,
        config: {}
      },
      showEdit: {
        banner: false
      },
      showSuggestions: true
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { tabs } = props;
    const { tabValue } = state;
    // Validate that the tab exists else set to 0
    if (tabValue >= tabs.length) {
      state.tabValue = 0;
    }

    if (props.checklist.steps && props.checklist.steps.length > 0) {
      state.showSuggestions = false;
    }
    return state;
  }

  handleVoiceClick = (checklist, checklistFn) => {
    start(checklist, checklistFn);
  };

  handleMediaSelect = (media, tabType) => {
    const { metadata, updateMetadata } = this.props;
    const banner = Object.assign({}, metadata.banner);
    switch (tabType) {
      case MediaTabs.ICONS:
        banner.icon = {
          type: MediaType.ICON,
          link: media
        };
        break;
      case MediaTabs.COLORS:
        banner.color = media;
        break;
      default:
        // noop
        console.log("Unsupported Tab Type");
    }
    update("banner", banner, metadata, updateMetadata);
    this.handleMediaMenuClose();
  };

  handleMediaMenuClose = () => {
    const mediaMenu = {
      open: false
    };
    this.setState({ mediaMenu });
  };

  render() {
    const {
      classes,
      tabs,
      metadata,
      updateMetadata,
      checklist,
      checklistFn,
      mode,
      parents,
      user,
      handleKeyDown,
      setFocus
    } = this.props;
    const { mediaMenu, tabValue, showSuggestions } = this.state;

    // Override banner color if set explicitly, which it always is now
    // TODO: figure out how to distinguish mode (template, edit, run, complete) other than banner color
    const modeInfo = calculateModeInfo(mode, metadata.status);
    let tabColor = modeInfo.color;
    if (metadata.banner && metadata.banner.color) {
      tabColor = metadata.banner.color;
    }
    // One of:  "warning", "success", "danger", "info", "primary", "rose", "gray"
    const headerColor = tabColor;

    const navigateToTab = tab => {
      if (tab === "steps") {
        this.setState({ tabValue: 0 });
      } else if (tab === "details") {
        this.setState({ tabValue: 1 });
      } else if (tab === "status") {
        this.setState({ tabValue: 2 });
      }
    };

    const menuItems = getMenuItems(
      checklist,
      user,
      mode,
      classes,
      parents,
      checklistFn,
      navigateToTab,
      tabValue
    );

    const isEditMode = mode === ChecklistMode.TEMPLATE_EDIT; // Check for edit mode
    const showingStepsTab = tabValue === 0;

    return (
      <>
        <Card>
          <ChecklistBanner
            checklist={checklist}
            headerColor={headerColor}
            isEditMode={isEditMode}
            menuItems={menuItems}
            handleMediaSelect={this.handleMediaSelect}
            showMedia={mediaMenu => this.setState({ mediaMenu })}
            handleVoiceClick={() =>
              this.handleVoiceClick(checklist, checklistFn)
            }
            classes={classes}
          />
          <div className={classes.cardBodyWrapper}>
            <CardBody checklist={true}>
              <ChecklistIcon
                isEditMode={isEditMode}
                classes={classes}
                metadata={metadata}
                handleMediaSelect={this.handleMediaSelect}
                showMedia={mediaMenu => this.setState({ mediaMenu })}
              />
              <ChecklistName
                isEditMode={isEditMode}
                metadata={metadata}
                updateMetadata={updateMetadata}
                classes={classes}
                handleKeyDown={(e, _metadata) => {
                  if (e.key === "Enter") {
                    this.setState({ showSuggestions: false });
                  }
                  handleKeyDown(e, _metadata);
                }}
              />
              {showingStepsTab ? (
                <ChecklistSuggestions
                  classes={classes}
                  showSuggestions={showSuggestions}
                  hideSuggestions={() =>
                    this.setState({ showSuggestions: false })
                  }
                  confirmModal={checklistFn.confirmModal}
                  updateSteps={checklistFn.updateSteps}
                  setFocus={setFocus}
                />
              ) : null}
              <ChecklistTabs tabs={tabs} tabValue={tabValue} />
            </CardBody>
          </div>
        </Card>
        <Popover
          open={mediaMenu.open || false}
          anchorEl={mediaMenu.anchorEl}
          anchorPosition={{ top: 200, left: 400 }}
          onClose={this.handleMediaMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <MediaMenu
            onSelect={mediaMenu.handleSelect}
            config={mediaMenu.config}
          />
        </Popover>
      </>
    );
  }
}

export default withStyles(checklistCardStyle)(ChecklistCard);
