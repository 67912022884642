import React from "react";

// @material-ui/icons

// Components
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

// Sub Components
import { ChecklistMode, propertyExists } from "services/ChecklistService";
import { StepType } from "services/StepService";

function calculateCompletion(steps) {
  let total = 0;
  let completed = 0;
  steps.forEach(step => {
    if (step.isRequired) {
      if (step.type === StepType.SUB_CHECKLIST) {
        // TODO: get subchecklist steps to check quality of completion
      }
      total = total + 1;
      const isValue = propertyExists(step.value);
      completed = isValue ? completed + 1 : completed;
    }
  });
  return { completed, total };
}

const ChecklistStatus = ({ steps, mode, saveButtons }) => {
  const modeStatus =
    mode === ChecklistMode.INSTANCE_EXEC ? "In Progress" : "Done";
  const stepStatus = calculateCompletion(steps);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={4}>
        <h2>Status: {modeStatus}</h2>
        <legend>
          {stepStatus.completed} of {stepStatus.total} Required Steps Completed
        </legend>
        <CustomLinearProgress
          variant="determinate"
          color="success"
          value={(stepStatus.completed / stepStatus.total) * 100}
          style={{ width: "100%", display: "inline-block" }}
        />
        <br />
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        {saveButtons}
      </GridItem>
    </GridContainer>
  );
};

export default ChecklistStatus;
