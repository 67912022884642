import React from "react";
import PropTypes from "prop-types";

// Lib for handling headers
import { Helmet } from "react-helmet";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import FlightTakeoff from "@material-ui/icons/FlightTakeoff";
import ImportantDevices from "@material-ui/icons/ImportantDevices";
import Group from "@material-ui/icons/Group";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import GridOn from "@material-ui/icons/GridOn";
import PersonPin from "@material-ui/icons/PersonPin";
import GroupAdd from "@material-ui/icons/GroupAdd";
import Assignment from "@material-ui/icons/Assignment";
import Email from "@material-ui/icons/Email";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

// components & services
import Notice from "components/Modal/Notice";
import { encode, verifyEmail } from "services/utility";

// assets
import logoBlue from "assets/img/logo-blue.png";
import logoWhite from "assets/img/logo-white.png";
import mobileSplash from "assets/img/Phone-Splash.png";
import mobileNav from "assets/img/Phone-Activity-3x.png";
import mobileChecklist from "assets/img/Mobile-Checklist-Preflight.png";
import tabletSOP from "assets/img/Tablet-Checklist-SOP-1000.png";
import tabletSOPSm from "assets/img/Tablet-Checklist-SOP-600.png";
import desktopLogbook from "assets/img/Reports-2x.png";
import desktopLogbookSm from "assets/img/Desktop-Logbook-OpsDashboard-600.png";
import tabletRampCheck from "assets/img/AI-Comms-2x.png";
import tabletRampCheckSm from "assets/img/Tablet-Logbook-FAARampCheck-600.png";
import infoPageStyle from "assets/jss/material-dashboard-pro-react/views/infoPageStyle";

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      honey: "",
      emailState: "",
      noticeModal: false,
      noticeTitle: ""
    };
  }

  handleSubmit = e => {
    const { classes } = this.props;
    const { email, honey } = this.state;
    const data = { "form-name": "beta-waitlist", email: email };

    e.preventDefault();

    if (honey) {
      console.log("error - honey pot full", honey);
      return;
    }
    if (!verifyEmail(email)) {
      console.log("error - bad email", email);
      return this.setState({ emailState: "error" });
    }

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode(data)
    })
      .then(() => {
        this.setState({
          noticeModal: true,
          noticeTitle: (
            <span>
              <span>Your spot for our beta release has been saved!</span>
              <br />
              <br />
              <span>
                Don&#39;t hesitate to reach out to{" "}
                <span className={classes.logoColor}>
                  <strong>team@airsync.ai</strong>
                </span>{" "}
                with any questions.
              </span>
            </span>
          )
        });
      })
      .catch(error => {
        this.setState({
          noticeModal: true,
          noticeTitle: (
            <span>
              <span>
                There was an error saving your spot for our beta release.
              </span>
              <br />
              <br />
              <span>Error: {error}</span>
              <br />
              <br />
              <span>
                Please reach out to{" "}
                <span className={classes.logoColor}>
                  <strong>team@airsync.com</strong>
                </span>{" "}
                for help.
              </span>
            </span>
          )
        });
      });
  };

  handleEmailChange = e => {
    if (verifyEmail(e.target.value)) {
      this.setState({
        emailState: "success",
        email: e.target.value
      });
    } else {
      this.setState({ emailState: "error", email: e.target.value });
    }
  };

  handleHoneyChange = e => this.setState({ honey: e.target.value });

  handleNoticeClose = () => {
    this.setState({ noticeModal: false, email: "", emailState: "" });
  };

  render() {
    const { classes, scrolled } = this.props;
    const { email, honey, emailState, noticeModal, noticeTitle } = this.state;

    return (
      <div className={classes.container}>
        <Helmet>
          <meta
            name="description"
            content="Airsync powers modern operations.  Define programable workflows and customized reporting to match your organization's unique needs."
          />
        </Helmet>
        <GridContainer justifyContent="center" className={classes.sectionOne}>
          <GridItem xs={12} sm={12} md={6}>
            <GridContainer
              justifyContent="center"
              alignItems="center"
              className={classes.logoContainer}
            >
              <div className={classes.logoSection}>
                <GridContainer
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <div>
                    <img
                      src={logoBlue}
                      alt="airsync logo"
                      className={classes.logoImage}
                    />
                  </div>
                  <h4 className={classes.droneOps}>
                    Digital Operations Platform
                  </h4>
                  <h2 className={classes.logoName}>
                    <strong>
                      <span className={classes.logoColor}>Air</span>sync
                    </strong>
                  </h2>
                  <h4>
                    No code workflows, custom reporting, and seamless
                    ai-automated communication.
                  </h4>
                </GridContainer>
              </div>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <GridContainer justifyContent="center">
              <img
                src={mobileSplash}
                className={classes.mobileSplashImg}
                alt="Airsync ops splashscreen"
              />
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer justifyContent="center">
              <h3 className={classes.tagLine}>
                &#34;Simplify your work, simplify your life&#34;
              </h3>
            </GridContainer>
          </GridItem>
        </GridContainer>

        <GridContainer justifyContent="center" className={classes.section}>
          <GridItem xs={12} sm={12} md={6}>
            <GridContainer
              justifyContent="center"
              alignItems="center"
              className={classes.logoContainer}
            >
              <div className={classes.aboutSection}>
                <GridContainer
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <h3 className={classes.aboutTitle}>
                    <strong>ABOUT AIRSYNC</strong>
                  </h3>
                  <p>
                    Whether landing on an aircraft carrier or installing a roof,
                    every operation is unique. Your tooling should adapt to you.
                    Airsync is a flexible operations platform that allows you to
                    define your processes and reporting needs, then helps your
                    team execute those operations with some AI enahancements.
                  </p>
                  <div className={classes.valueStatements}>
                    <GridContainer direction="column">
                      <div className={classes.valueRow}>
                        <GridContainer justifyContent="flex-start">
                          <GridItem xs={12} sm={5} md={5}>
                            <GridContainer
                              justifyContent="flex-start"
                              alignItems="center"
                            >
                              <Icon className={classes.aboutIcon}>
                                assignment
                              </Icon>
                              <h5>Easy To Use</h5>
                            </GridContainer>
                          </GridItem>
                          <GridItem xs={12} sm={7} md={7}>
                            <GridContainer
                              justifyContent="flex-start"
                              alignItems="center"
                            >
                              <Icon className={classes.aboutIcon}>build</Icon>
                              <h5>Easy To Customize</h5>
                            </GridContainer>
                          </GridItem>
                        </GridContainer>
                      </div>
                      <div className={classes.valueRow}>
                        <GridContainer justifyContent="flex-start">
                          <GridItem xs={12} sm={5} md={5}>
                            <GridContainer
                              justifyContent="flex-start"
                              alignItems="center"
                            >
                              <Icon className={classes.aboutIcon}>
                                flight_takeoff
                              </Icon>
                              <h5>AI Automation</h5>
                            </GridContainer>
                          </GridItem>
                          <GridItem xs={12} sm={7} md={7}>
                            <GridContainer
                              justifyContent="flex-start"
                              alignItems="center"
                            >
                              <Icon className={classes.aboutIcon}>people</Icon>
                              <h5>Flexible Collaboration</h5>
                            </GridContainer>
                          </GridItem>
                        </GridContainer>
                      </div>
                    </GridContainer>
                  </div>
                </GridContainer>
              </div>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <GridContainer justifyContent="center">
              <img
                src={mobileNav}
                className={classes.mobileNavImg}
                alt="airsync navigation"
              />
            </GridContainer>
          </GridItem>
        </GridContainer>

        <GridContainer justifyContent="center" className={classes.section}>
          <GridItem xs={12} sm={12} md={10}>
            <Card className={classes.cardSignup}>
              <h2 className={classes.cardTitle}>Features</h2>
              <CardBody>
                <GridContainer justifyContent="center">
                  <GridItem xs={12} sm={12} md={5}>
                    <InfoArea
                      title="AI Automation"
                      description="Reduce the manual work in your organization."
                      icon={FlightTakeoff}
                      iconColor="rose"
                    />
                    <InfoArea
                      title="Documentation"
                      description="Easily satisfy your compliance obligations."
                      icon={LibraryBooks}
                      iconColor="teal"
                    />
                    <InfoArea
                      title="Custom Reporting"
                      description="Create reports and dashboards for your operations."
                      icon={GridOn}
                      iconColor="indigo"
                    />
                    <InfoArea
                      title="Community"
                      description="Leverage templates for industry best practices."
                      icon={GroupAdd}
                      iconColor="lightBlue"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={5}>
                    <InfoArea
                      title="Team Collaboration"
                      description="Sync with people in the office or the field."
                      icon={Group}
                      iconColor="cyan"
                    />
                    <InfoArea
                      title="No-Code Workflows"
                      description="Define your unique operational processes."
                      icon={Assignment}
                      iconColor="purple"
                    />
                    <InfoArea
                      title="Own Your Data"
                      description="Import and export data, it’s yours."
                      icon={PersonPin}
                      iconColor="red"
                    />
                    <InfoArea
                      title="Multi-Device"
                      description="Work on the phone, computer or Alexa."
                      icon={ImportantDevices}
                      iconColor="green"
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer justifyContent="center" className={classes.section}>
          <GridItem xs={12} sm={12} md={4} lg={4}>
            <GridContainer
              justifyContent="center"
              alignItems="center"
              direction="column"
            >
              {scrolled ? (
                <img
                  src={mobileChecklist}
                  alt="airsync checklist"
                  className={classes.mobileChecklist}
                />
              ) : null}
              <h3 className={classes.pictureTagLine}>Checklists</h3>
            </GridContainer>
          </GridItem>
          <GridItem xs={false} sm={false} md={1} lg={2}>
            &nbsp;
          </GridItem>
          <GridItem xs={12} sm={12} md={7} lg={6}>
            <GridContainer
              justifyContent="center"
              alignItems="center"
              direction="column"
            >
              {scrolled ? (
                <img
                  src={tabletSOP}
                  srcSet={`${tabletSOPSm} 761w, ${tabletSOP} 1269w`}
                  sizes="(max-width: 940px) 380px, 655px"
                  alt="airsync SOP"
                  className={classes.tabletSOP}
                />
              ) : null}
              <h3 className={classes.pictureTagLine}>
                Documentation &amp; SOPs
              </h3>
            </GridContainer>
          </GridItem>
        </GridContainer>

        <GridContainer justifyContent="center" className={classes.section}>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer
              justifyContent="center"
              alignItems="center"
              direction="column"
            >
              {scrolled ? (
                <img
                  src={desktopLogbook}
                  srcSet={`${desktopLogbookSm} 1027w, ${desktopLogbook} 1711w`}
                  sizes="(max-width: 940px) 513px, 856px"
                  alt="airsync dashboard"
                  className={classes.desktopLogbook}
                />
              ) : null}
              <h3 className={classes.pictureTagLine}>
                Reports &amp; Dashboards
              </h3>
            </GridContainer>
          </GridItem>
        </GridContainer>

        <GridContainer justifyContent="center" className={classes.section}>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer
              justifyContent="center"
              alignItems="center"
              direction="column"
            >
              {scrolled ? (
                <img
                  src={tabletRampCheck}
                  srcSet={`${tabletRampCheckSm} 787w, ${tabletRampCheck} 1312w`}
                  sizes="(max-width: 940px) 394px, 655px"
                  alt="airsync comms center"
                  className={classes.tabletRampCheck}
                />
              ) : null}
              <h3 className={classes.pictureTagLine}>AI Automated Comms</h3>
            </GridContainer>
          </GridItem>
        </GridContainer>

        <GridContainer justifyContent="center" className={classes.sectionLast}>
          <GridItem xs={12} sm={6} md={4}>
            <a href="#sign-up" name="sign-up" className={classes.hiddenLink}>
              Sign Up
            </a>
            <form
              name="beta-waitlist"
              onSubmit={this.handleSubmit}
              data-netlify="true"
              netlify-honeypot="honey2"
            >
              <Card login className={classes.signupCard}>
                <CardHeader
                  className={`${classes.signupCardHeader} ${classes.textCenter}`}
                  color="rose"
                >
                  <h4 className={classes.waitlistTitle}>
                    Join Our Beta Waitlist
                  </h4>
                  <img
                    src={logoWhite}
                    alt="airsync logo"
                    className={classes.logoImage}
                  />
                </CardHeader>
                <CardBody className={classes.signupCardBody}>
                  <CustomInput
                    success={emailState === "success"}
                    error={emailState === "error"}
                    labelText="Email..."
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "email",
                      name: "email",
                      value: email,
                      onChange: this.handleEmailChange,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      )
                    }}
                  />
                  <span className={classes.honey}>
                    <CustomInput
                      labelText="Honey..."
                      id="honey"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        name: "honey",
                        value: honey,
                        onChange: this.handleHoneyChange
                      }}
                    />
                  </span>
                </CardBody>
                <CardFooter className={classes.signupCardFooter}>
                  <Button type="submit" color="rose" simple size="lg" block>
                    Sign Up
                  </Button>
                </CardFooter>
              </Card>
            </form>
            <h3 className={classes.pictureTagLine}>
              Take Control of Your Operations!
            </h3>
          </GridItem>
        </GridContainer>
        <Notice
          isOpen={noticeModal}
          title={noticeTitle}
          handleClose={this.handleNoticeClose}
        />
      </div>
    );
  }
}

RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(infoPageStyle)(RegisterPage);
