/** Import React and 3rd party libs */
import React, { useState } from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Add from "@material-ui/icons/Add";
import Clear from "@material-ui/icons/Clear";

// Components
import CustomInput from "components/CustomInput/CustomInput.jsx";

// Subcomponents
/** Import components used only by this component - relative path imports */

// Service

// Queries
/** Import all graphql queries */

// Assets
import stepsStyle from "assets/jss/material-dashboard-pro-react/components/stepsStyle.jsx";

// Variables
/** Declare all constant variables */

// Functions
// const updateValue = (event, step, setInputState, setHelpText) => {

//   value.text = event.target.value;
//   if (value.text) {
//     setInputState("success");
//   } else {
//     setInputState("error");
//   }

//   newStep.value = value;

//   return [newStep, error];
// };

const addItem = (inputValue, step, setInputState, items, updateStep) => {
  const newStep = Object.assign({}, step);
  const newValue = Object.assign({}, step.value);
  const newItems = [...items];
  if (inputValue) {
    setInputState("success");
    newItems.push(inputValue);
    newValue.list = newItems;
    newStep.value = newValue;
    updateStep(newStep);
  } else {
    setInputState("error");
  }
};

const removeItem = (index, step, items, updateStep) => {
  const newStep = Object.assign({}, step);
  const newValue = Object.assign({}, step.value);
  const head = items.slice(0, index);
  const tail = items.slice(index + 1, items.length + 1);
  const newItems = head.concat(tail);
  newValue.list = newItems;
  newStep.value = newValue;
  updateStep(newStep);
};

/**
 * Declare JSX Component - class or
 */
const ListInput = ({ classes, step, updateStep, isDisabled }) => {
  const [inputValue, setInputValue] = useState("");
  const [inputState, setInputState] = useState("");
  const items = step.value && step.value.list ? step.value.list : [];
  return (
    <div>
      <ul>
        {items.map((item, i) => {
          return (
            <li key={i}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ flexGrow: 1 }}>{item}</div>
                <div>
                  <IconButton
                    color="secondary"
                    aria-label="remove item"
                    onClick={() => removeItem(i, step, items, updateStep)}
                  >
                    <Clear />
                  </IconButton>
                </div>
              </div>
            </li>
          );
        })}
      </ul>

      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ flexGrow: 1 }}>
          <CustomInput
            success={inputState === "success"}
            error={inputState === "error"}
            labelText="List Item"
            id={step.stepId}
            formControlProps={{
              fullWidth: true,
              variant: "outlined"
            }}
            style={{ marginTop: "8px" }}
            inputProps={{
              onChange: event => {
                setInputValue(event.target.value);
              },
              type: "text",
              value: inputValue,
              autoComplete: "off",
              disabled: isDisabled,
              endAdornment:
                inputState === "error" ? (
                  <InputAdornment position="end">
                    <Close className={classes.danger} />
                  </InputAdornment>
                ) : (
                  undefined
                )
            }}
          />
        </div>
        <div>
          <IconButton
            color="primary"
            aria-label="add item"
            onClick={() =>
              addItem(inputValue, step, setInputState, items, updateStep)
            }
          >
            <Add />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

// Data Connectors

export default withStyles(stepsStyle)(ListInput);
