import gql from "graphql-tag";

////////////////////////////////////////////////
// USER FRAGMENTS
////////////////////////////////////////////////

export const User = {
  data: gql`
    fragment UserData on AirspaceUser {
      id
      email
      emailVerified
      phone
      phoneVerified
      callsign
      firstName
      middleName
      lastName
      city
      country
      postalCode
      title
      about
      profilePicUrl
      organizationIds
      currentOrganization
    }
  `
};
