/** Import React and 3rd party libs */
import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import MaterialCheckbox from "@material-ui/core/Checkbox";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";

// @material-ui/icons
import Check from "@material-ui/icons/Check";
import Pin from "@material-ui/icons/PinDropOutlined";

// Components
import EventsEnable from "../../StepComponents/StepEdit/EventsEnable";
import EventsActionName from "../../StepComponents/StepEdit/EventsActionName";

// Subcomponents
import StepNote from "../../StepComponents/StepNote";
import ViewTitle from "../../StepComponents/ViewTitle";

// Services
import { getCommonTabs, isInputDisabled, EditRow } from "../StepTypesUtility";
import { toggleCheckbox, getStepRowClass } from "services/StepService";

// Queries
/** Import all graphql queries */

// Assets
import stepsStyle from "assets/jss/material-dashboard-pro-react/components/stepsStyle.jsx";

// Variables

// Functions

const StepIcon = ({ classes, step, updateStep, mode, sendEvent }) => {
  return (
    <TableCell className={classes.tableCheck}>
      <MaterialCheckbox
        checked={step.value ? step.value.checked : false}
        disabled={isInputDisabled(mode)}
        tabIndex={-1}
        onClick={() => updateStep(toggleCheckbox(step, sendEvent))}
        checkedIcon={<Check className={classes.checkedSuccessIcon} />}
        icon={<Check className={classes.uncheckedIcon} />}
        classes={{
          checked: classes.checkedSuccess,
          root: classes.checkRoot
        }}
      />
    </TableCell>
  );
};

const StepContent = props => {
  const { classes } = props;
  return (
    <TableCell className={classes.tableContent}>
      <ViewTitle {...props} />
    </TableCell>
  );
};

/**
 * Checkbox Step
 */
const Checkbox = props => {
  const {
    classes,
    step,
    updateStep,
    editing,
    setEditState,
    noteOpen,
    setNoteState,
    mode
  } = props;
  const editProps = { editing, setEditState, noteOpen, setNoteState };
  const tabs = getCommonTabs(step, updateStep);
  const showRowDividers = true;
  const primaryTableRowClass = getStepRowClass(
    classes,
    showRowDividers,
    editing || noteOpen
  );

  tabs.push({
    tabName: "Events",
    tabIcon: Pin,
    tabContent: (
      <div>
        <div>Event Streams</div>
        <EventsEnable
          step={step}
          updateStep={updateStep}
          mode={mode}
          classes={classes}
        />
        <div>Step Checked Action Name</div>
        <EventsActionName
          property="checked"
          step={step}
          updateStep={updateStep}
          mode={mode}
          classes={classes}
        />
        <div>Step Unchecked Action Name</div>
        <EventsActionName
          property="unchecked"
          step={step}
          updateStep={updateStep}
          mode={mode}
          classes={classes}
        />
      </div>
    )
  });

  return (
    <>
      <TableRow key={step.stepId} className={primaryTableRowClass}>
        <StepIcon {...props} step={step} />
        <StepContent {...props} {...editProps} step={step} />
      </TableRow>
      <EditRow classes={classes} open={editing}>
        <CustomTabs title="Configure" headerColor="gray" tabs={tabs} />
      </EditRow>
      <EditRow classes={classes} open={noteOpen}>
        <StepNote step={step} updateStep={updateStep} />
      </EditRow>
    </>
  );
};

export default withStyles(stepsStyle)(Checkbox);
