import gql from "graphql-tag";
import { Checklist } from "../fragments";

export const ChecklistTemplatesQuery = gql`
  query ChecklistTemplatesQuery($orgId: String!) {
    getChecklistTemplates(orgId: $orgId) {
      items {
        id
        type
        metadata {
          ...MetadataQuery
        }
        steps {
          ...StepInfo
        }
      }
      nextToken
      scannedCount
    }
  }
  ${Checklist.metadataQuery}
  ${Checklist.stepInfo}
`;

export const ChecklistInstancesQuery = gql`
  query ChecklistInstancesQuery($orgId: String!) {
    getChecklistInstances(orgId: $orgId) {
      items {
        id
        type
        metadata {
          ...MetadataInstanceQuery
          status
        }
      }
      nextToken
      scannedCount
    }
  }
  ${Checklist.metadataInstanceQuery}
`;

export const SearchQuery = gql`
  query SearchQuery($searchTerm: String!) {
    search(searchTerm: $searchTerm) {
      items {
        id
        metadata {
          ...MetadataQuery
        }
      }
    }
  }
  ${Checklist.metadataQuery}
`;

export const SearchInstancesQuery = gql`
  query SearchInstancesQuery($searchTerm: String!) {
    searchInstances(searchTerm: $searchTerm) {
      items {
        id
        metadata {
          ...MetadataInstanceQuery
          status
        }
      }
    }
  }
  ${Checklist.metadataInstanceQuery}
`;

// TODO: Add orgId check in template
export const FindInstancesByTemplateIdQuery = gql`
  query FindInstancesByTemplateIdQuery($templateId: String!) {
    findInstancesByTemplateId(templateId: $templateId) {
      items {
        id
        metadata {
          ...MetadataInstanceQuery
          status
        }
      }
    }
  }
  ${Checklist.metadataInstanceQuery}
`;

// TODO: refactor logic here to non-full version
// Shouldn't be updating the same cache with different
// data shapes
export const FindInstancesByTemplateIdFullQuery = gql`
  query FindInstancesByTemplateIdFullQuery($templateId: String!) {
    findInstancesByTemplateId(templateId: $templateId) {
      items {
        id
        metadata {
          ...MetadataInstanceQuery
          ...MetadataValues
        }
        steps {
          ...StepInfo
          ...StepValues
        }
      }
    }
  }
  ${Checklist.metadataInstanceQuery}
  ${Checklist.metadataValues}
  ${Checklist.stepInfo}
  ${Checklist.stepValues}
`;

export const AggregateInstancesQuery = gql`
  query AggregateInstancesQuery($filter: String!, $aggs: String!) {
    aggregateInstances(filter: $filter, aggs: $aggs) {
      items {
        id
        metadata {
          ...MetadataInstanceQuery
          status
        }
        steps {
          ...StepInfo
          ...StepValues
        }
      }
      aggregations
    }
  }
  ${Checklist.metadataInstanceQuery}
  ${Checklist.stepInfo}
  ${Checklist.stepValues}
`;

export const FindCompletedChecklistsQuery = gql`
  query FindCompletedChecklistsQuery($template: ChecklistIdInput!) {
    findCompletedChecklists(template: $template) {
      items {
        id
        metadata {
          ...MetadataInstanceQuery
          status
        }
        steps {
          ...StepInfo
          ...StepValues
        }
      }
    }
  }
  ${Checklist.metadataInstanceQuery}
  ${Checklist.stepInfo}
  ${Checklist.stepValues}
`;

export const ChecklistTemplateQuery = gql`
  query ChecklistTemplateQuery($orgId: String!, $id: String!, $version: Int) {
    getChecklistTemplate(orgId: $orgId, id: $id, version: $version) {
      id
      type
      metadata {
        ...MetadataInfo
      }
      steps {
        ...StepInfo
      }
    }
  }
  ${Checklist.metadataInfo}
  ${Checklist.stepInfo}
`;

export const ChecklistInstanceQuery = gql`
  query ChecklistInstanceQuery($orgId: String!, $id: String!, $version: Int) {
    getChecklistInstance(orgId: $orgId, id: $id, version: $version) {
      id
      type
      metadata {
        ...MetadataInfo
        ...MetadataValues
      }
      steps {
        ...StepInfo
        ...StepValues
      }
    }
  }
  ${Checklist.metadataInfo}
  ${Checklist.stepInfo}
  ${Checklist.metadataValues}
  ${Checklist.stepValues}
`;
