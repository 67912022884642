import React from "react";
import Icon from "@material-ui/core/Icon";
// @material-ui/core
// @material-ui/icons
import DateRange from "@material-ui/icons/DateRange";
// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
// sub components
import WidgetEditButtons from "../../WidgetComponents/WidgetEditButtons";

const WidgetExpiring = ({
  classes,
  mode,
  widget,
  editWidget,
  deleteWidget
}) => {
  const { values, configuration } = widget;
  return (
    <Card>
      <CardHeader color={values.color} stats icon>
        <WidgetEditButtons
          mode={mode}
          classes={classes}
          editWidget={editWidget}
          deleteWidget={deleteWidget}
        />
        <CardIcon color={values.color}>
          <Icon>{configuration.icon}</Icon>
        </CardIcon>
        <p className={classes.cardCategory}>{configuration.title}</p>
        <h3 className={classes.cardTitle}>{values.stats}</h3>
      </CardHeader>
      <CardFooter stats>
        <div className={classes.stats}>
          <DateRange />
          {configuration.label}
        </div>
      </CardFooter>
    </Card>
  );
};

export default WidgetExpiring;
