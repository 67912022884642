export const getUserName = profile => {
  const { firstName, lastName, callsign } = profile;
  let userName = callsign;
  if (firstName && lastName) {
    if (firstName !== callsign) {
      userName = `${firstName} "${callsign}" ${lastName}`;
      if (userName.length > 22) {
        userName = `${firstName} "${callsign}" ${lastName}`;
        if (userName.length > 22) {
          userName = callsign;
        }
      }
    } else {
      userName = `${callsign} ${lastName}`;
      if (userName.length > 22) {
        userName = callsign;
      }
    }
  }
  return userName;
};
