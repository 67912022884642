/** Import React and 3rd party libs */
import React from "react";

// @material-ui/core components
import Drawer from "@material-ui/core/Drawer";

// @material-ui/icons
/** Import material ui icons */

// Components

// Subcomponents
import SidebarWrapper from "./SidebarWrapper";

// Services
import { getDrawerPaperClass, getSidebarWrapperClass } from "./SidebarUtility";

// Queries
/** Import all graphql queries */

// Assets
/** Import assets: jss styles & images */

// Variables
/** Declare all constant variables */

// Functions
/** Declare all functions - utility followed by JSX*/

/**
 * Large Sidebar - Visible for medium views and up
 * shows a sidebard that can be toggled to icons only on the left
 */
const LargeSidebar = ({
  classes,
  image,
  bgColor,
  miniActive,
  userComponent,
  organizationComponent,
  links,
  pinnedChecklistsActive,
  pinnedChecklistsTemplate,
  setMiniActive,
  newChecklistButton
}) => {
  const drawerPaper = getDrawerPaperClass(classes, miniActive);
  const sidebarWrapper = getSidebarWrapperClass(classes, miniActive);
  return (
    <Drawer
      onMouseEnter={() => setMiniActive(false)}
      onMouseLeave={() => setMiniActive(true)}
      anchor="left"
      variant="permanent"
      open
      classes={{
        paper: drawerPaper + " " + classes[bgColor + "Background"]
      }}
    >
      {organizationComponent}
      <SidebarWrapper
        className={sidebarWrapper}
        userComponent={userComponent}
        links={links}
        pinnedChecklistsTemplate={pinnedChecklistsTemplate}
        pinnedChecklistsActive={pinnedChecklistsActive}
        newChecklistButton={newChecklistButton}
      />
      {image !== undefined ? (
        <div
          className={classes.background}
          style={{ backgroundImage: "url(" + image + ")" }}
        />
      ) : null}
    </Drawer>
  );
};

// Data Connectors

export default LargeSidebar;
