import React from "react";
import { flowRight as compose } from "lodash";
import queryString from "query-string";
import { withRouter } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Popover from "@material-ui/core/Popover";

// @material-ui/icons;
import AddAlert from "@material-ui/icons/AddAlert";

// Components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Confirm from "components/Modal/Confirm";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import MediaMenu, {
  MediaTabs,
  MediaType
} from "components/MediaMenu/MediaMenu";

// Subcomponents
import LogbookMetadata from "./DashboardComponents/LogbookMetadata";
import LogbookWidgets from "./DashboardComponents/LogbookWidgets";
import LogbookButtons from "./DashboardComponents/LogbookButtons";
import DashboardName from "./DashboardComponents/DashboardName";
import DashboardDescription from "./DashboardComponents/DashboardDescription";
import DashboardIcon from "./DashboardComponents/DashboardIcon";

// Services
import {
  _LogbookQuery,
  CreateLogbook,
  FindInstancesByTemplateIdFull,
  RootTemplates
} from "./DashboardComponents/LogbookQueries";
import LogbookFunctions from "./DashboardComponents/LogbookFunctions";
import { SharedType } from "services/ChecklistService";
import {
  LogbookMode,
  getReference,
  DefaultLogbooks,
  updateDashboard,
  NEW_DASHBOARD_NAME_MARKER,
  NEW_DASHBOARD_DESCRIPTION_MARKER
} from "services/LogbookService";

// Assets
import logbookStyle from "assets/jss/material-dashboard-pro-react/components/logbookStyle";
import { ReferenceTypes } from "../../services/LogbookService";

// Variables
const defaultLogbook = {
  id: null, // Set on server
  metadata: {
    name: NEW_DASHBOARD_NAME_MARKER,
    description: NEW_DASHBOARD_DESCRIPTION_MARKER,
    tags: [],
    sharing: {
      type: SharedType.PRIVATE,
      list: []
    },
    dashboardNav: []
  },
  widgets: []
};

// Functions
const shouldUpdateLogbook = (prevProps, props) => {
  return (
    prevProps.data &&
    prevProps.data.loading &&
    !props.data.loading &&
    props.data.getLogbook
  );
};

// Deserialize widget configurations
const deserializeLogbook = logbook => {
  logbook.widgets = logbook.widgets.map(widget => {
    const json = widget.configuration.json;
    if (json && typeof json === "string") {
      try {
        widget.configuration.json = JSON.parse(json);
      } catch (e) {
        console.log("JSON Parsing error", e);
      }
    }
    return widget;
  });
  return logbook;
};

const getLogbook = (id, data) => {
  if (!id) {
    return defaultLogbook;
  } else if (data && !data.loading) {
    return deserializeLogbook(data.getLogbook);
  } else if (DefaultLogbooks[id]) {
    return DefaultLogbooks[id];
  }
};

const parseQueryString = location => {
  const { search } = location;
  return queryString.parse(search);
};

function update(property, value, metadata, updateMetadata) {
  const newMetadata = Object.assign({}, metadata);
  newMetadata[property] = value;
  updateMetadata(newMetadata);
}

/**
 * Logbook (or Dashboard or Report) - A view on Checklist data with interactive widgets.
 *
 * TODO: Probably want to memoize widgets similar to steps
 */
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    const { id, edit } = parseQueryString(props.location);
    const { data } = props;

    this.state = {
      mode: edit ? LogbookMode.EDIT : LogbookMode.VIEW,
      logbook: getLogbook(id, data),
      confirmModal: false,
      confirmTitle: "",
      handleConfirm: this.handleClose,
      handleClose: this.handleClose,
      loading: false,
      notification: false,
      notificationType: "success",
      globalSettings: {},
      mediaMenu: {
        open: false,
        config: {}
      },
      showDetails: false
    };
  }

  componentDidUpdate(prevProps) {
    // When the logbook is loaded
    if (shouldUpdateLogbook(prevProps, this.props)) {
      const logbook = deserializeLogbook(this.props.data.getLogbook);
      this.setState({
        logbook,
        loading: false
      });
    }

    const globalSettings = this.state.globalSettings;
    const reference = getReference(this.props);
    if (
      reference &&
      reference.type === ReferenceTypes.ROOT_CHECKLISTS &&
      !globalSettings.dynamic
    ) {
      globalSettings.dynamic = true;
      this.setState({ globalSettings });
    }
  }

  componentWillUnmount() {
    this.isCancelled = true;
  }

  handleClose = () => {
    this.setState({ confirmModal: false });
  };

  updateGlobalSettings = globalSettings => {
    this.setState({ globalSettings });
  };

  renderName = (logbook, classes) => {
    const { name } = logbook.metadata;
    return (
      <GridItem>
        <div>
          <div className={classes.breadcrumb}>
            <strong>{name}</strong>
          </div>
        </div>
      </GridItem>
    );
  };

  showNotification(type) {
    const notificationType = type === "error" ? "error" : "success";
    this.setState({ notification: true, notificationType });
    setTimeout(
      function() {
        !this.isCancelled && this.setState({ notification: false });
      }.bind(this),
      6000
    );
  }

  handleKeyDown = (e, dashboard, props, metadata) => {
    if (e.metaKey && e.key === "s") {
      e.preventDefault();
      // Save the checklist with notification
      if (metadata) {
        dashboard.metadata = metadata;
      }
      updateDashboard(
        dashboard,
        props,
        this.showNotification.bind(this),
        logbook => this.setState({ logbook })
      );
    }
    if (e.key === "Tab") {
      console.log("Tab into description");
    }
    if (e.key === "Enter") {
      console.log("Enter to create description");
    }
    if (e.key === "Backspace") {
      console.log("Possibly delete description");
    }
  };

  handleMediaSelect = (media, tabType, logbook, updateMetadata) => {
    const { metadata } = logbook;
    const banner = Object.assign({}, metadata.banner);
    switch (tabType) {
      case MediaTabs.ICONS:
        banner.icon = {
          type: MediaType.ICON,
          link: media
        };
        break;
      case MediaTabs.COLORS:
        banner.color = media;
        break;
      default:
        // noop
        console.log("Unsupported Tab Type");
    }
    console.log(updateMetadata);
    update("banner", banner, metadata, updateMetadata);
    this.handleMediaMenuClose();
  };

  handleMediaMenuClose = () => {
    const mediaMenu = {
      open: false
    };
    this.setState({ mediaMenu });
  };

  setShowDetails = show => this.setState({ showDetails: show });

  render() {
    const { classes, data, user } = this.props;
    const {
      mode,
      logbook,
      confirmModal,
      confirmTitle,
      handleConfirm,
      handleClose,
      notification,
      notificationType,
      globalSettings,
      mediaMenu,
      showDetails
    } = this.state;

    // TODO: move to site config - just hide for leaderboards
    const hideLogbookMetadata = false;
    const isEditMode = mode === LogbookMode.EDIT; // TODO: check user for edit perms

    // Seperate from global settings so updates when new data is fetched
    // without worrying about updating state and infinite loops
    const globalData = {};

    const logbookFn = new LogbookFunctions(
      this.state,
      this.setState.bind(this),
      this.props,
      this.showNotification.bind(this)
    ).getLogbookFunctions();

    let notificationColor = "success";
    let notificationMessage = "Logbook Saved";
    if (notificationType === "error") {
      notificationColor = "danger";
      notificationMessage = "Error Saving Logbook!";
    }

    if (data && (data.loading || data.error || !logbook)) {
      if (data.error) console.log("Error:", data.error);
      return (
        <div className={classes.loadingContainer}>
          <CircularProgress className={classes.progress} color="secondary" />
        </div>
      );
    }

    const reference = getReference(this.props);
    if (reference.type === ReferenceTypes.USER_MOST_RECENT_ACTIVE) {
      globalData.referenceInstance = this.props.referenceInstance.findInstancesByTemplateId;
    } else if (reference.type === ReferenceTypes.URL) {
      // TODO: implement
    } else if (reference.type === ReferenceTypes.ROOT_CHECKLISTS) {
      // Sort by name alphabetically to avoid re-ordering issues on update (i.e. pinned);
      globalData.referenceTemplates = this.props.rootTemplates
        .getChecklistTemplates
        ? this.props.rootTemplates.getChecklistTemplates.items.sort(
            (t1, t2) => {
              const name1 = t1.metadata.name.toLowerCase();
              const name2 = t2.metadata.name.toLowerCase();
              if (name1 < name2) {
                return -1;
              } else if (name2 < name1) {
                return 1;
              }
              return 0;
            }
          )
        : [];
    } else {
      // console.log("No Reference Type");
    }

    return (
      <div
        onKeyDown={e => this.handleKeyDown(e, logbook, this.props)}
        tabIndex="0"
      >
        {hideLogbookMetadata ? null : (
          <GridContainer justifyContent="flex-start">
            <GridItem md={5}>
              <GridContainer
                direction="column"
                alignItems="flex-start"
                style={{ height: "100%", paddingLeft: "22px" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    opacity: 0.76
                  }}
                >
                  <DashboardIcon
                    isEditMode={isEditMode}
                    metadata={logbook.metadata}
                    handleMediaSelect={(media, tabType) =>
                      this.handleMediaSelect(
                        media,
                        tabType,
                        logbook,
                        logbookFn.updateMetadata
                      )
                    }
                    classes={classes}
                    showMedia={mediaMenu => this.setState({ mediaMenu })}
                  />
                  <DashboardName
                    isEditMode={isEditMode}
                    metadata={logbook.metadata}
                    updateMetadata={logbookFn.updateMetadata}
                    classes={classes}
                    handleKeyDown={(e, metadata) =>
                      this.handleKeyDown(e, logbook, this.props, metadata)
                    }
                  />
                </div>
                <DashboardDescription
                  isEditMode={isEditMode}
                  metadata={logbook.metadata}
                  updateMetadata={logbookFn.updateMetadata}
                  classes={classes}
                  handleKeyDown={(e, metadata) =>
                    this.handleKeyDown(e, logbook, this.props, metadata)
                  }
                />
              </GridContainer>
            </GridItem>
            <GridItem md={7}>
              <GridContainer justifyContent="flex-end">
                <LogbookButtons
                  showDetails={showDetails}
                  setShowDetails={this.setShowDetails}
                  logbookFn={logbookFn}
                />
              </GridContainer>
            </GridItem>
          </GridContainer>
        )}
        {showDetails ? (
          <GridContainer
            style={
              hideLogbookMetadata
                ? { justifyContent: "flex-end", marginBottom: "-36px" }
                : {}
            }
          >
            <GridItem style={hideLogbookMetadata ? { marginRight: "8px" } : {}}>
              <LogbookMetadata
                metadata={logbook.metadata}
                updateMetadata={logbookFn.updateMetadata}
                mode={mode}
                classes={classes}
                globalSettings={globalSettings}
                updateGlobalSettings={this.updateGlobalSettings}
                hideLogbookMetadata={hideLogbookMetadata}
                user={user}
              />
            </GridItem>
          </GridContainer>
        ) : null}

        <GridContainer>
          <GridItem className={classes.widgetsContainer}>
            <LogbookWidgets
              widgets={logbook.widgets}
              updateWidgets={logbookFn.updateWidgets}
              mode={mode}
              globalSettings={globalSettings}
              globalData={globalData}
              user={user}
              showMedia={mediaMenu => this.setState({ mediaMenu })}
            />
          </GridItem>
        </GridContainer>
        <Popover
          open={mediaMenu.open || false}
          anchorEl={mediaMenu.anchorEl}
          anchorPosition={{ top: 200, left: 400 }}
          onClose={this.handleMediaMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <MediaMenu
            onSelect={mediaMenu.handleSelect}
            config={mediaMenu.config}
          />
        </Popover>
        <Confirm
          isOpen={confirmModal}
          handleConfirm={handleConfirm}
          handleClose={handleClose}
          title={confirmTitle}
        />
        <Snackbar
          place="bc"
          color={notificationColor}
          icon={AddAlert}
          message={notificationMessage}
          open={notification}
          closeNotification={() => this.setState({ notification: false })}
          close
        />
      </div>
    );
  }
}

export default compose(
  CreateLogbook,
  _LogbookQuery,
  FindInstancesByTemplateIdFull,
  RootTemplates,
  withRouter,
  withStyles(logbookStyle)
)(Dashboard);
