import React from "react";

// @material-ui/core
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";

// Services
import { LogbookMode } from "services/LogbookService";

function WidgetEditButtons({ classes, mode, editWidget, deleteWidget }) {
  if (mode !== LogbookMode.EDIT) {
    return null;
  }
  return (
    <div className={classes.widgetActionButtons}>
      <Tooltip
        id="tooltip-top-start"
        title="Edit Widget"
        placement="top"
        disableFocusListener={true}
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          aria-label="Edit"
          className={classes.widgetActionButton}
          onClick={editWidget}
        >
          <Edit
            className={classes.widgetActionButtonIcon + " " + classes.edit}
          />
        </IconButton>
      </Tooltip>
      <Tooltip
        id="tooltip-top-start"
        title="Delete Widget"
        placement="top"
        disableFocusListener={true}
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          aria-label="Close"
          className={classes.widgetActionButton}
          onClick={deleteWidget}
        >
          <Close
            className={classes.widgetActionButtonIcon + " " + classes.close}
          />
        </IconButton>
      </Tooltip>
    </div>
  );
}

export default WidgetEditButtons;
