import React from "react";

// @material-ui/core components
import InputBase from "@material-ui/core/InputBase";

// services
import { ChecklistMode } from "services/ChecklistService";
import { capitalizeFirstLetter } from "services/utility";

// functions
function updateEventActionName(property, value, step, updateStep) {
  const newStep = Object.assign({}, step);
  const newEventConfig = Object.assign({}, step.eventConfig);
  const jsonMetadata =
    step.eventConfig && step.eventConfig.metadata
      ? step.eventConfig.metadata
      : "{}";
  const metadata = JSON.parse(jsonMetadata);
  const newMetadata = Object.assign({}, metadata, { [property]: value });
  newEventConfig.metadata = JSON.stringify(newMetadata);
  newStep.eventConfig = newEventConfig;
  updateStep(newStep);
}
// selected
export const EventsActionName = ({
  property,
  step,
  updateStep,
  mode,
  classes
}) => {
  const eventMetadata =
    step.eventConfig && step.eventConfig.metadata
      ? JSON.parse(step.eventConfig.metadata)
      : {};
  return (
    <div style={{ display: "flex", margin: "16px 0" }}>
      <InputBase
        id={`${property}-action-name-input`}
        autoFocus={false}
        autoComplete="off"
        value={eventMetadata[property] || ""}
        placeholder={capitalizeFirstLetter(property)}
        onChange={event =>
          updateEventActionName(property, event.target.value, step, updateStep)
        }
        type="text"
        classes={{
          root: classes.inputDescription,
          input: classes.checklistDescription
        }}
        disabled={mode !== ChecklistMode.TEMPLATE_EDIT}
      />
    </div>
  );
};

export default EventsActionName;
