import React from "react";
import PropTypes from "prop-types";
import { flowRight as compose } from "lodash";
import { withRouter, Link } from "react-router-dom";

// Lib for handling headers
import { Helmet } from "react-helmet";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";

// components and services
import CustomInput from "components/CustomInput/CustomInput";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import Danger from "components/Typography/Danger";
import { simpleHash } from "services/utility";

// assets
import verificationPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle";

const FORM_STATE = {
  VERIFICATION_SUCCESS: "VERIFICATION_SUCCESS",
  VERIFICATION_FAIlURE: "VERIFICATION_FAIlURE",
  VERIFYING: "VERIFYING",
  CODE: "CODE",
  NOT_SIGNED_IN: "NOT_SIGNED_IN"
};

const INPUT_TYPE = {
  CODE: "CODE"
};

/**
 * Login Page - Handles Login flows: Sign Up, Forgot Password, Reset Password.
 * Doesn't handle Register which is in RegisterPage
 *
 * Notes: formInputState is old and should be removed.  Don't need success state, just show
 * user error or normal.  So can check for error state by presence of an error message.
 */
class VerificationPage extends React.PureComponent {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: props.user.loading ? "cardHidden" : "",
      error: "", // Old style for reset password / new password - need to refactor to be like signin / register
      code: "",
      username: "",
      errorMessage: {
        [INPUT_TYPE.CODE]: "",
        general: ""
      },
      formInputValue: {
        [INPUT_TYPE.CODE]: ""
      },
      formInputState: {
        [INPUT_TYPE.CODE]: ""
      },
      formState: FORM_STATE.CODE
    };
  }

  componentDidMount() {
    if (
      !this.props.user.loading &&
      this.state.formState !== FORM_STATE.VERIFYING &&
      this.state.formState !== FORM_STATE.VERIFICATION_SUCCESS
    ) {
      const search = location.search;
      const params = new URLSearchParams(search);
      const userId = params.get("user");
      const urlCode = params.get("cd");
      if (!this.props.user.username) {
        this.updateFormState(FORM_STATE.NOT_SIGNED_IN);
      } else if (urlCode && userId) {
        this.verifyCode(urlCode, this.props.user, this.props.history);
      }
    }

    // we add a hidden class to the card and after 400 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      400
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  updateInput = (evt, inputType) => {
    const formInputValue = Object.assign({}, this.state.formInputValue);
    const formInputState = Object.assign({}, this.state.formInputState);
    const error = "";
    formInputValue[inputType] = evt.target.value;
    formInputState[inputType] = "";
    const errorMessage = Object.assign({}, this.state.errorMessage);
    errorMessage[inputType] = "";
    errorMessage.general = "";
    this.setState({ formInputValue, formInputState, errorMessage, error });
  };

  updateFormState = formState => {
    this.setState({ formState });
  };

  updateErrorState = (error, inputType) => {
    const formInputState = Object.assign({}, this.state.formInputState);
    formInputState[inputType] = "error";
    const errorMessage = Object.assign({}, this.state.errorMessage);
    errorMessage[inputType] = error;
    this.setState({
      error,
      formInputState,
      errorMessage,
      signingIn: false
    });
  };

  // Format if link clicked:
  //  https://staging.airsync.ai/pages/verify?user=Scott-glenn-b2L4cb&cd=1alaw8x
  // Cant use code in query param: https://github.com/aws-amplify/amplify-js/issues/3494
  // Need to push search string to force state change, because updating user causes
  //  the component to unmount.
  verifyCode = (code, user, history) => {
    const isEmail = simpleHash(`${user.username}-email`) === code;
    const isPhone = simpleHash(`${user.username}-phone`) === code;
    if (isEmail) {
      if (!user.profile.emailVerified) {
        this.updateFormState(FORM_STATE.VERIFYING);
        this.props.user.updateProfile({ emailVerified: true }, () =>
          history.push({ search: `?user=${user.username}&cd=${code}` })
        );
      } else {
        this.updateFormState(FORM_STATE.VERIFICATION_SUCCESS);
      }
    } else if (isPhone) {
      if (!user.profile.phoneVerified) {
        this.updateFormState(FORM_STATE.VERIFYING);
        this.props.user.updateProfile({ phoneVerified: true }, () =>
          history.push({ search: `?user=${user.username}&cd=${code}` })
        );
      } else {
        this.updateFormState(FORM_STATE.VERIFICATION_SUCCESS);
      }
    } else {
      this.updateErrorState(
        "Code doesn't match phone or email",
        INPUT_TYPE.CODE
      );
      console.log("Not phone or email");
    }
  };

  renderFormBody(
    formState,
    formInputState,
    code,
    user,
    history,
    error,
    errorMessage,
    classes
  ) {
    switch (formState) {
      case FORM_STATE.VERIFICATION_SUCCESS:
        return (
          <span>
            <CardBody>
              <div>
                <h3 style={{ opacity: "0.84", textAlign: "center" }}>
                  Verification Success
                </h3>
              </div>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <Button simple size="lg" block>
                <Link
                  style={{ color: "rgb(233, 30, 99)" }}
                  to={{
                    pathname: "/app"
                  }}
                >
                  Go to App
                </Link>
              </Button>
            </CardFooter>
          </span>
        );
      case FORM_STATE.VERIFICATION_FAIlURE:
        return (
          <span>
            <CardBody>
              <div>
                <h3 style={{ opacity: "0.84", textAlign: "center" }}>
                  Verification Failed: {errorMessage}
                </h3>
              </div>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <Button simple size="lg" block>
                <Link
                  style={{ color: "rgb(233, 30, 99)" }}
                  to={{
                    pathname: "/app"
                  }}
                >
                  Go to App
                </Link>
              </Button>
            </CardFooter>
          </span>
        );
      case FORM_STATE.NOT_SIGNED_IN:
        return (
          <span>
            <CardBody>
              <div>
                <h3 style={{ opacity: "0.84", textAlign: "center" }}>
                  Please login to verify your contact information.
                </h3>
              </div>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <Button simple size="lg" block>
                <Link
                  style={{ color: "rgb(233, 30, 99)" }}
                  to={{
                    pathname: "/pages/login-page",
                    state: {
                      referrer: {
                        pathname: "/pages/verify",
                        search: this.props.location.search
                      }
                    }
                  }}
                >
                  Login
                </Link>
              </Button>
            </CardFooter>
          </span>
        );
      case FORM_STATE.VERIFYING:
        return (
          <span>
            <CardBody>
              <div
                style={{
                  height: "200px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <CircularProgress
                  className={classes.progress}
                  color="secondary"
                />
              </div>
            </CardBody>
          </span>
        );
      case FORM_STATE.CODE:
        return (
          <span>
            <CardBody>
              <CustomInput
                id="code"
                labelText="Verification Code"
                success={formInputState[INPUT_TYPE.CODE] === "success"}
                error={formInputState[INPUT_TYPE.CODE] === "error"}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: evt => this.updateInput(evt, INPUT_TYPE.CODE),
                  type: "text",
                  autoComplete: "verification code",
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon className={classes.inputAdornmentIcon}>
                        lock_outline
                      </Icon>
                    </InputAdornment>
                  )
                }}
              />
              {error ? <Danger>{error.message || error}</Danger> : null}
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <Button
                color="rose"
                simple
                size="lg"
                block
                onClick={() => this.verifyCode(code, user, history)}
              >
                Verify Code
              </Button>
            </CardFooter>
          </span>
        );
      default:
        return this.renderSignIn();
    }
  }

  render() {
    const { classes, user, history } = this.props;
    const {
      formState,
      formInputState,
      formInputValue,
      error,
      errorMessage
    } = this.state;
    const code = formInputValue[INPUT_TYPE.CODE];

    return (
      <div className={classes.container}>
        <Helmet>
          <title>Airsync | Verify Contact Info</title>
          <meta
            name="description"
            content="Airsync powers modern operations.  Define programable workflows and customized reporting to match your organization's unique needs."
          />
        </Helmet>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={12} md={12}>
            <div className={classes.scrollContainer}>
              <GridContainer justifyContent="center">
                <GridItem xs={12} sm={6} md={4}>
                  <form>
                    <Card
                      login
                      className={
                        classes[this.state.cardAnimaton] +
                        " " +
                        classes.loginCard
                      }
                    >
                      <CardHeader
                        className={`${classes.cardHeader} ${classes.textCenter}`}
                        color="rose"
                      >
                        <h4
                          className={classes.cardTitle}
                          style={{ fontWeight: "500" }}
                        >
                          Verify Your Information
                        </h4>
                      </CardHeader>
                      {this.renderFormBody(
                        formState,
                        formInputState,
                        code,
                        user,
                        history,
                        error,
                        errorMessage,
                        classes
                      )}
                    </Card>
                  </form>
                </GridItem>
              </GridContainer>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

VerificationPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(verificationPageStyle)
)(VerificationPage);
