import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import Danger from "components/Typography/Danger";

// @material-ui/icons
import Email from "@material-ui/icons/Email";

// core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

// Services and Subcomponents
import { FORM_STATE, INPUT_TYPE, getInputConfig } from "./loginVariables";

// assets
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

class SignIn extends React.PureComponent {
  render() {
    const {
      classes,
      handleSubmit,
      updateInput,
      updateFormState,
      showConfirmationError,
      resendSignUp,
      email,
      errorMessage,
      signingIn
    } = this.props;
    const inputConfig = getInputConfig();

    return (
      <span>
        <CardBody>
          <CustomInput
            id="username"
            labelText={inputConfig.label}
            success={false}
            error={!!errorMessage[INPUT_TYPE.USERNAME]}
            helpText={errorMessage[INPUT_TYPE.USERNAME]}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: "text",
              onChange: evt => updateInput(evt, INPUT_TYPE.USERNAME),
              endAdornment: (
                <InputAdornment position="end">
                  <Email className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
          <CustomInput
            id="password"
            labelText="Password"
            success={false}
            error={!!errorMessage[INPUT_TYPE.PASSWORD]}
            helpText={errorMessage[INPUT_TYPE.PASSWORD]}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: "password",
              onChange: evt => updateInput(evt, INPUT_TYPE.PASSWORD),
              endAdornment: (
                <InputAdornment position="end">
                  <Icon className={classes.inputAdornmentIcon}>
                    lock_outline
                  </Icon>
                </InputAdornment>
              )
            }}
          />
          {errorMessage.general && errorMessage.general.message ? (
            <Danger>{errorMessage.general.message}</Danger>
          ) : null}
          {showConfirmationError ? (
            <div>
              <p>
                You need to confirm your email address to complete the signup
                process. The email can take 5-10 minutes. To resend a
                confirmation link to {email} please click the button below.
              </p>
              <div className={classes.resendContainer}>
                <Button
                  color="primary"
                  onClick={resendSignUp}
                  round
                  className={classes.marginRight}
                >
                  <Email className={classes.icons} /> Resend Confirmation Email
                </Button>
              </div>
            </div>
          ) : null}
          <div className={classes.justifyContentCenter}>
            <Button
              onClick={() => updateFormState(FORM_STATE.RESET_PASSWORD)}
              color="info"
              simple
              size="sm"
              block
            >
              Forgot Password
            </Button>
          </div>
        </CardBody>
        <CardFooter className={classes.justifyContentCenter}>
          {signingIn ? (
            <div>
              <CircularProgress
                className={classes.progress}
                color="secondary"
              />
            </div>
          ) : (
            <Button onClick={handleSubmit} color="rose" simple size="lg" block>
              Let&apos;s Go
            </Button>
          )}
        </CardFooter>
      </span>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(loginPageStyle)(SignIn);
