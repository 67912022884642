import { graphql } from "react-apollo"; // Apollo Data Layer

// services
import { AggregateInstancesQuery } from "api/queries";
import { omitDeep } from "services/utility";
import { aggregateChecklistsByTemplateId } from "services/LogbookService";

// Queries

// Currently just used for animations
export const MAX_STEPS = 50;

///////////////////////
// DATA UTILITIES
//////////////////////

export const cloneInstance = instance => {
  const clonedInstance = JSON.parse(JSON.stringify(instance));
  const newInstance = omitDeep(clonedInstance, "__typename");
  return newInstance;
};

// Updates number +/- 1 and the step
export const updateStepValue = (step, clonedInstance, icon, add) => {
  const { steps } = clonedInstance;
  for (let i = 0; i < steps.length; i++) {
    if (steps[i].stepId === step.stepId) {
      if (!steps[i].value) {
        steps[i].value = { number: 0, json: JSON.stringify({ icons: [] }) };
      }
      if (!steps[i].value.json) {
        steps[i].value.json = JSON.stringify({ icons: [] });
      }
      const jsonConfig = JSON.parse(steps[i].value.json);
      steps[i].value.number = add
        ? steps[i].value.number + 1
        : steps[i].value.number - 1;
      if (add) {
        jsonConfig.icons.push(icon);
      } else {
        jsonConfig.icons.pop();
      }
      steps[i].value.json = JSON.stringify(jsonConfig);
    }
  }
  clonedInstance.steps = steps;
  return clonedInstance;
};

export const getStatsObject = user => {
  // just return the users stats since no global date range for awards
  return user.stats;
};

///////////////////////
// API CALLS
///////////////////////

export const FetchAwards = graphql(AggregateInstancesQuery, {
  name: "awards",
  options: props => {
    const { widget } = props;
    const { configuration } = widget;
    const config = configuration.json;

    let aggs = {};
    let filter = [];

    if (config && config.users) {
      // Just use the first users template for awards since all the same
      filter.push({
        term: {
          "metadata.template.checklistId": config.users[0].award_checklist_id
        }
      });
    }

    const filterString = JSON.stringify(filter);
    const aggsString = JSON.stringify(aggs);

    return {
      // fetchPolicy: "network-only",
      variables: {
        filter: filterString,
        aggs: aggsString
      }
    };
  }
});

///////////////////////
// USER DATA & PROCESSING
///////////////////////

const DefaultUserData = {
  name: "",
  icon: "",
  instances: [],
  awards: [],
  stats: {
    count: 0,
    used: 0,
    icons: []
  }
};

export const processData = (completedChecklists, awardData, users) => {
  const instances = completedChecklists.aggregateInstances.items || [];
  const awards = awardData.aggregateInstances.items;
  const usersChecklists = aggregateChecklistsByTemplateId(instances, users);
  const usersData = processUsersData(usersChecklists);
  const usersDataMatched = matchUsers(users, usersData, awards);
  return usersDataMatched;
};

const processUsersData = usersChecklists => {
  const updatedUserData = {};
  Object.keys(usersChecklists).forEach(key => {
    const userChecklists = usersChecklists[key] || [];
    const userData = JSON.parse(JSON.stringify(DefaultUserData));
    updatedUserData[key] = processItems(userChecklists, userData);
  });
  return updatedUserData;
};

const matchAwards = (user, awardsList) => {
  const USER_ID_STEP = 4;
  const awards = [];
  // Just use first users set of awards since currently they are the same for all users
  // and just filter by id step.  TODO: only query by users id when in place.
  if (awardsList) {
    for (let i = 0; i < awardsList.length; i++) {
      const userId = awardsList[i].steps[USER_ID_STEP];
      if (userId.value.text === user.name.toUpperCase()) {
        awards.push(awardsList[i]);
      }
    }
  }
  return awards;
};

// Match users to instance dat
const matchUsers = (users, usersDataMap, awardsList) => {
  const orderedUsersData = [];
  const keys = Object.keys(usersDataMap);

  // No data so just load users
  if (keys.length === 0) {
    users.forEach(user => {
      const userData = JSON.parse(JSON.stringify(DefaultUserData));
      userData.name = user.name;
      userData.icon = user.icon;
      userData.awards = matchAwards(user, awardsList);
      orderedUsersData.push(userData);
    });
    return orderedUsersData;
  }

  users.forEach(user => {
    const userData =
      usersDataMap[user.checklist_id] ||
      JSON.parse(JSON.stringify(DefaultUserData));
    userData.name = user.name;
    userData.icon = user.icon;
    userData.awards = matchAwards(user, awardsList);
    orderedUsersData.push(userData);
  });

  return orderedUsersData;
};

const processItems = (items, userData) => {
  let updatedUserData = userData; // mutation
  items.forEach(checklist => {
    const { metadata } = checklist;
    const { created } = metadata;
    const createdDate = new Date(created.time);
    const dateBoundaries = calculateDateBoundaries();
    if (createdDate > dateBoundaries.prev90) {
      updatedUserData = processLast90(userData, checklist);
    }
  });
  updatedUserData.instances.sort((a, b) => {
    const aDate = new Date(a.metadata.created.time);
    const bDate = new Date(b.metadata.created.time);
    if (aDate > bDate) {
      return 1;
    } else {
      return -1;
    }
  });
  return updatedUserData;
};

const calculateDateBoundaries = () => {
  // Previous 90 days
  const prev90 = new Date();
  prev90.setDate(prev90.getDate() - 90);
  prev90.setHours(0);
  prev90.setMinutes(0, 0, 0);

  const boundaries = {
    prev90
  };

  return boundaries;
};

const processLast90 = (userData, checklist) => {
  userData.instances.push(checklist);
  checklist.steps.forEach(step => {
    const { value } = step;
    const count = value && value.number ? value.number : 0;
    const used = value && value.value ? parseInt(value.value) : 0;
    const jsonConfig =
      value && value.json ? JSON.parse(value.json) : { icons: [] };
    userData.stats.count = userData.stats.count + count;
    userData.stats.used = userData.stats.used + used;
    userData.stats.icons = userData.stats.icons.concat(jsonConfig.icons);
  });
  return userData;
};
