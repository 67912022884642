import React, { useState } from "react";
import { S3Image } from "aws-amplify-react"; // Amplify S3 Image Viewer

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

// core components
import Button from "components/CustomButtons/Button.jsx";

// Services
import notificationsStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const imageTheme = {
  photoImg: {
    maxWidth: "100%",
    width: "100%",
    verticalAlign: "middle",
    border: "0"
  }
};
const categoryButtonStyle = {
  width: "44%",
  minHeight: "97px",
  margin: "8px",
  cursor: "pointer"
};
const iconButtonStyle = {
  width: "24%",
  margin: "4px",
  cursor: "pointer"
};

const prefix = "checklist-zen/lemonadepixel";
const CategoryItems = [
  {
    key: "chores",
    url: `${prefix}/kidschores/kidschores-color`,
    preview: "preview-01.jpg",
    file: "lemonadepixel_kidschores",
    range: { start: 2, end: 90 }
  },
  {
    key: "babyAnimals",
    url: `${prefix}/babyanimalicons`,
    preview: "preview.jpg",
    file: "lemonadepixel_babyanimals",
    range: { start: 2, end: 49 }
  },
  {
    key: "holiday",
    url: `${prefix}/holidayicons`,
    preview: "preview.jpg",
    file: "lemonadepixel_holidayicons",
    range: { start: 2, end: 103 }
  },
  {
    key: "food",
    url: `${prefix}/foodicons`,
    preview: "preview-01.jpg",
    file: "lemonadepixel_foodicons",
    range: { start: 2, end: 123 }
  },
  {
    key: "wedding",
    url: `${prefix}/weddingicons`,
    preview: "preview-01.jpg",
    file: "lemonadepixel_weddingicons",
    range: { start: 1, end: 102 }
  },
  {
    key: "recreation",
    url: `${prefix}/recreation`,
    preview: "preview.jpg",
    file: "lemonadepixel_recreation",
    range: { start: 2, end: 103 }
  },
  {
    key: "everday",
    url: `${prefix}/everydayicons/everydayicons_color`,
    preview: "preview.jpg",
    file: "lemonadepixel_everyday",
    range: { start: 1, end: 90 }
  },
  {
    key: "business",
    url: `${prefix}/businessicons`,
    preview: "preview.jpg",
    file: "lemonadepixel_businessicons",
    range: { start: 3, end: 109 }
  },
  {
    key: "blogger",
    url: `${prefix}/bloggericons`,
    preview: "preview-01.jpg",
    file: "lemonadepixel_blogicons",
    range: { start: 1, end: 88 }
  }
];

const constructIconSet = category => {
  const { range } = category;
  const { start, end } = range;
  const icons = [];
  for (let i = start; i <= end; i++) {
    const number = i < 10 ? ("0" + i).slice(-2) : i;
    const iconUrl = `${category.url}/PNGs/${category.file}-${number}.png`;
    icons.push(iconUrl);
  }
  return icons;
};

const CategoryMenu = ({ selectCategory }) => {
  return (
    <div>
      {CategoryItems.map(item => {
        return (
          <button
            key={item.key}
            style={categoryButtonStyle}
            onClick={() => selectCategory(item)}
          >
            <S3Image
              imgKey={item.url + "/" + item.preview}
              theme={imageTheme}
            />
          </button>
        );
      })}
    </div>
  );
};

const IconCategory = ({ category, handleIconSelect }) => {
  const iconSet = constructIconSet(category);
  return (
    <div>
      {iconSet.map(icon => {
        return (
          <button
            key={icon}
            style={iconButtonStyle}
            onClick={() => {
              handleIconSelect(icon);
            }}
          >
            <S3Image imgKey={icon} theme={imageTheme} />
          </button>
        );
      })}
    </div>
  );
};

function Picker({ classes, isOpen, handleClose, handleIconSelect }) {
  const [category, setCategory] = useState(null);

  return (
    <div>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal + " " + classes.modalMedium
        }}
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="small-modal-slide-title"
        aria-describedby="small-modal-slide-description"
      >
        <DialogTitle
          id="small-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div>
            {category ? (
              <IconButton
                className={classes.modalLeftButton}
                key="left"
                aria-label="Close"
                onClick={() => setCategory(null)}
              >
                <ChevronLeft className={classes.modalLeft} />
              </IconButton>
            ) : (
              <div className={classes.modalLeftButton}>
                <div className={classes.modalLeft}>&nbsp;</div>
              </div>
            )}
            {category ? "Choose an Icon" : "Choose Icon Set"}
            <IconButton
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              onClick={handleClose}
            >
              <Close className={classes.modalClose} />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent
          id="small-modal-slide-description"
          className={classes.modalBody + " " + classes.modalSmallBody}
        >
          {category ? (
            <IconCategory
              category={category}
              handleIconSelect={handleIconSelect}
            />
          ) : (
            <CategoryMenu selectCategory={setCategory} />
          )}
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button
            onClick={handleClose}
            color="transparent"
            className={classes.modalSmallFooterFirstButton}
          >
            Cancel
          </Button>
          <Button
            onClick={handleClose}
            color="success"
            simple
            className={
              classes.modalSmallFooterFirstButton +
              " " +
              classes.modalSmallFooterSecondButton
            }
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withStyles(notificationsStyle)(Picker);
