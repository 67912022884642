import React from "react";
import { Link } from "react-router-dom";
import { flowRight as compose } from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Add from "@material-ui/icons/Add";

// Components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Confirm from "components/Modal/Confirm";

// Subcomponents
import LogbooksTable from "./DashboardComponents/LogbooksTable";

// Services
import {
  Logbooks as LogbooksQuery,
  DeleteLogbook
} from "./DashboardComponents/LogbookQueries";

// Assets
import logbooksStyle from "assets/jss/material-dashboard-pro-react/components/logbooksStyle";

class Dashboards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmModal: false,
      confirmTitle: "",
      handleConfirm: this.handleClose,
      handleClose: this.handleClose
    };
  }

  handleClose = () => {
    this.setState({ confirmModal: false });
  };

  handleDelete = (e, id, metadata) => {
    const { user } = this.props;
    const orgId = user.currentOrganization.id;
    this.setState({
      confirmModal: true,
      confirmTitle: `Delete "${metadata.name}" Logbook?`,
      handleConfirm: () => {
        this.setState(
          {
            confirmModal: false
          },
          () => {
            const { deleteLogbook } = this.props;
            deleteLogbook(orgId, id, metadata.version);
          }
        );
      }
    });
    e.stopPropagation();
  };

  render() {
    const { classes, data, history } = this.props;
    const {
      confirmModal,
      confirmTitle,
      handleConfirm,
      handleClose
    } = this.state;

    if (data.loading || data.error) {
      if (data.error) console.log("Error:", data.error);
      return (
        <div className={classes.loadingContainer}>
          <CircularProgress className={classes.progress} color="secondary" />
        </div>
      );
    }

    return (
      <div>
        <GridContainer justifyContent="flex-end">
          <GridItem>
            <Link to={"dashboard?edit=true"}>
              <Button color="info" size="sm" className={classes.marginRight}>
                <Add className={classes.icons} /> New Logbook
              </Button>
            </Link>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>My Logbooks</h4>
              </CardHeader>
              <CardBody>
                <LogbooksTable
                  items={data.getLogbooks.items}
                  handleDelete={this.handleDelete}
                  history={history}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <Confirm
          isOpen={confirmModal}
          handleConfirm={handleConfirm}
          handleClose={handleClose}
          title={confirmTitle}
        />
      </div>
    );
  }
}

export default compose(
  LogbooksQuery,
  DeleteLogbook,
  withStyles(logbooksStyle)
)(Dashboards);
