/* eslint no-unused-vars: "off" */

/**
 * SITE CONFIG
 *
 * All the necessary parts to configure a site:
 * Checklist Zen, Airspace Ops, etc.
 */

import checklistZenLogo from "./checklist-zen-logo-white.png";

import Dashboard from "views/Dashboard-Deprecated/Dashboard.jsx";
import Checklists from "views/Checklists/Checklists.jsx";
import Dashboards from "views/Dashboards/Dashboards.jsx";
// import Checklist from "views/Checklists/Checklist.jsx";

// @material-ui/icons
// import GridOn from "@material-ui/icons/GridOnOutlined";
// import WbSunny from "@material-ui/icons/WbSunnyOutlined";
// import Hotel from "@material-ui/icons/HotelOutlined";
// import DashboardIcon from "@material-ui/icons/DashboardOutlined";
// import DoneOutline from "@material-ui/icons/DoneOutline";

/////////////////////////////
// Navigation Links
/////////////////////////////

// const Scoreboard = {
//   path: "/app/dashboard/scoreboard?id=CZ000000-0000-0000-0000-00FAMILYDASH",
//   name: "Scoreboard",
//   id: "scoreboard",
//   icon: DashboardIcon,
//   component: Dashboard,
//   leftNav: true
// };

// const MyMorning = {
//   path: "/app/checklist/my-morning?id=CZ000000-0000-0000-0000-00000MORNING",
//   name: "My Morning",
//   id: "my-morning",
//   icon: WbSunny,
//   component: Checklist,
//   leftNav: true
// };

// const MyEvening = {
//   path: "/app/checklist/my-evening?id=CZ000000-0000-0000-0000-00000EVENING",
//   name: "My Evening",
//   id: "my-evening",
//   icon: Hotel,
//   component: Checklist,
//   leftNav: true
// };

// const Daily = {
//   path: "/app/checklist/school?id=CZ000000-0000-0000-0000-0000000DAILY",
//   name: "Daily Items",
//   id: "daily",
//   icon: School,
//   component: Checklist,
//   leftNav: true
// };

// const Weekly = {
//   path: "/app/checklist/school?id=CZ000000-0000-0000-0000-000000WEEKLY",
//   name: "Weekly Items",
//   id: "weekly",
//   icon: School,
//   component: Checklist,
//   leftNav: true
// };

// const Monthly = {
//   path: "/app/checklist/school?id=CZ000000-0000-0000-0000-00000MONTHLY",
//   name: "Montly Items",
//   id: "monthly",
//   icon: School,
//   component: Checklist,
//   leftNav: true
// };

// const Todos = {
//   path: "/app/checklist/todos?id=CZ000000-0000-0000-0000-00000ONETIME",
//   name: "Todo List",
//   id: "todo",
//   icon: DoneOutline,
//   component: Checklist,
//   leftNav: true
// };

const ChecklistsRoute = {
  path: "/app/checklists",
  name: "Checklists",
  id: "checklists",
  icon: "content_paste",
  component: Checklists,
  leftNav: true
};

const ActiveChecklistsRoute = {
  path: "/app/dashboard/checklists?id=ACTIVE_CHECKLISTS",
  name: "Active",
  id: "active_checklists",
  icon: "assignment_outlined",
  component: Dashboard,
  leftNav: true
};

const DashboardsRoute = {
  path: "/app/dashboards",
  name: "Dashboards",
  id: "dashboards",
  icon: "dashboard_outlined",
  component: Dashboards,
  leftNav: true
};

/////////////////////////////
// SITE CONFIGURATIONS
////////////////////////////

const CHECKLIST_ZEN = {
  name: "Checklist Zen",
  logo: checklistZenLogo,
  leftNav: [
    // Scoreboard,
    // MyMorning,
    // MyEvening,
    // Daily,
    // Weekly,
    // Monthly,
    // Todos,
    ChecklistsRoute,
    ActiveChecklistsRoute,
    DashboardsRoute
  ]
};

export default CHECKLIST_ZEN;
