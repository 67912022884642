/* eslint no-unused-vars: "off" */

/**
 * SITE CONFIG
 *
 * All the necessary parts to configure a site:
 * Checklist Zen, Airspace Ops, etc.
 */

// Airspace logo is in assets
import logo from "./RoofingOps_Logo_White.png";

import Dashboard from "views/Dashboard-Deprecated/Dashboard.jsx";
import Checklists from "views/Checklists/Checklists.jsx";
import Dashboards from "views/Dashboards/Dashboards.jsx";
import Checklist from "views/Checklists/Checklist.jsx";

// @material-ui/icons
import GridOn from "@material-ui/icons/GridOnOutlined";
import Build from "@material-ui/icons/BuildOutlined";
import Language from "@material-ui/icons/LanguageOutlined";
import Event from "@material-ui/icons/EventOutlined";
import Receipt from "@material-ui/icons/ReceiptOutlined";
import Ops from "@material-ui/icons/SupervisorAccountOutlined";
import Foam from "@material-ui/icons/HomeOutlined";
import Solar from "@material-ui/icons/WbSunnyOutlined";

/////////////////////////////
// Navigation Links
/////////////////////////////

// TODO: move to Dashboard
const JobsRoute = {
  path: "/app/jobs-dashboard",
  name: "Jobs Dashboard",
  id: "jobs-dashboard",
  icon: Event,
  component: Dashboard,
  leftNav: true
};

const DashboardRoute = {
  path: "/app/ops-dashboard",
  name: "Ops Dashboard",
  id: "ops-dashboard",
  icon: Ops,
  // icon: DashboardIcon,
  component: Dashboard,
  leftNav: true
};

const PayrollRoute = {
  path: "/app/payroll-dashboard",
  name: "Payroll",
  id: "payroll-dashboard",
  icon: Receipt,
  component: Dashboard,
  leftNav: true
};

const FoamRoute = {
  path: "/app/checklist/foam?id=ROOF0000-0000-0000-0000-00000000FOAM",
  name: "Foam",
  id: "foam",
  icon: Foam,
  component: Checklist,
  leftNav: true
};

const SolarRoute = {
  path: "/app/checklist/solar?id=ROOF0000-0000-0000-0000-0000000SOLAR",
  name: "Solar",
  id: "solar",
  icon: Solar,
  component: Checklist,
  leftNav: true
};

const MaintenanceRoute = {
  path: "/app/checklist/maintenance?id=ROOF0000-0000-0000-0000-0000000MAINT",
  name: "Maintenance",
  id: "maintenance",
  icon: Build,
  component: Checklist,
  leftNav: true
};

const ExternalRoute = {
  path: "/app/external",
  name: "External",
  id: "external",
  icon: Language, // pan_tool, language, account_balance, alarm_on, announcement, assignment_ind
  component: Checklist, // TODO: Dashboard<RampCheck>
  leftNav: true
};

const ChecklistsRoute = {
  path: "/app/checklists",
  name: "Checklists",
  id: "checklists",
  icon: "content_paste",
  component: Checklists,
  leftNav: true
};

const DashboardsRoute = {
  path: "/app/dashboards",
  name: "Dashboards",
  id: "dashboards",
  icon: GridOn,
  component: Dashboards,
  leftNav: true
};

/////////////////////////////
// SITE CONFIGURATIONS
////////////////////////////

const ROOFING_OPS = {
  name: "Roofing Ops",
  logo,
  leftNav: [
    JobsRoute,
    DashboardRoute,
    PayrollRoute,
    FoamRoute,
    SolarRoute,
    MaintenanceRoute,
    ExternalRoute,
    ChecklistsRoute,
    DashboardsRoute
  ]
};

export default ROOFING_OPS;
