import React from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { flowRight as compose } from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";

// Components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Confirm from "components/Modal/Confirm";
import Snackbar from "components/Snackbar/Snackbar.jsx";

// Subcomponents
import ChecklistWrapper from "./ChecklistComponents/ChecklistWrapper";
// import BreadCrumbs from "./ChecklistComponents/BreadCrumbs";

// Services
import {
  defaultChecklist,
  calculateMode,
  ChecklistType,
  updateTemplate
} from "services/ChecklistService";
import ChecklistFunctions from "./ChecklistComponents/ChecklistFunctions";
import { setChecklistFunctions } from "services/VoiceService";

// Queries
import {
  IndexChecklistTemplate,
  CreateChecklistInstance,
  CreateChecklistTemplate,
  ChecklistInstance,
  ChecklistTemplate,
  AddEvent
} from "./ChecklistComponents/ChecklistQueries";

// Assets
import checklistStyle from "assets/jss/material-dashboard-pro-react/views/checklistStyle.jsx";

// Variables
/** Declare all constant variables */

// Functions
const getChecklistModeAndParents = props => {
  const { id, edit, instance, status, parents } = fetchUrlData(props);
  const { data } = props;
  const checklist = getChecklist(id, instance, data);
  const mode = calculateMode(checklist, status, instance, edit);
  return { checklist, mode, parents };
};

// Get the correct checklist (new, template, instance) based on URL info
const getChecklist = (id, instance, data) => {
  if (data && !data.loading) {
    if (instance) {
      // Instance flag so Instance
      return data.getChecklistInstance;
    } else {
      // ID and no instance flag so Template
      return data.getChecklistTemplate
        ? data.getChecklistTemplate
        : defaultChecklist(id);
    }
  }
};

const fetchUrlData = props => {
  const { search } = props.location;
  return queryString.parse(search);
};

const shouldUpdateChecklist = (prevProps, props) => {
  return (
    prevProps.data &&
    prevProps.data.loading &&
    !props.data.loading &&
    (props.data.getChecklistTemplate ||
      props.data.getChecklistInstance ||
      props.data.getChecklistTemplate === null)
  );
};

/**
 * Checklist Component
 */
class Checklist extends React.Component {
  constructor(props) {
    super(props);

    const { checklist, mode, parents } = getChecklistModeAndParents(props);

    this.state = {
      mode: mode,
      checklist: checklist,
      parents: parents,
      confirmModal: false,
      confirmTitle: "",
      confirmIcon: null,
      confirmParagraph: "",
      handleConfirm: this.handleClose,
      handleClose: this.handleClose,
      loading: false,
      notification: false,
      notificationType: "success",
      notificationMessage: "Checklist Saved"
    };
  }

  componentDidUpdate(prevProps) {
    if (shouldUpdateChecklist(prevProps, this.props)) {
      const { checklist, mode } = getChecklistModeAndParents(this.props);
      this.setState({
        checklist,
        mode,
        loading: false
      });
    }
  }

  componentWillUnmount() {
    this.isCancelled = true;
  }

  handleClose = () => {
    this.setState({ confirmModal: false });
  };

  showNotification(type, message) {
    let notificationType = type;
    if (!notificationType) {
      notificationType = "success";
    }
    this.setState({
      notification: true,
      notificationType,
      notificationMessage: message
    });
    setTimeout(
      function() {
        !this.isCancelled && this.setState({ notification: false });
      }.bind(this),
      6000
    );
  }

  handleKeyDown = (e, checklist, props) => {
    if (e.metaKey && e.key === "s") {
      e.preventDefault();
      if (checklist.type === ChecklistType.TEMPLATE) {
        updateTemplate(checklist, props, this.showNotification.bind(this));
      }
    }
  };

  render() {
    const { classes, data, user, addEvent } = this.props;
    const {
      mode,
      checklist,
      confirmModal,
      confirmTitle,
      confirmIcon,
      confirmParagraph,
      handleConfirm,
      handleClose,
      parentIds,
      parents,
      loading,
      notification,
      notificationType,
      notificationMessage
    } = this.state;
    console.log("checklist", checklist);
    // WARNING: passing state in here isn't working well as it doesn't get updated correctly
    // Subcomponents can call functions but the state will be old, also creating the object on every render which isn't ideal
    // TODO: this needs massive refactor - should pull as much state out as possible, may be memory leak too
    // Note: have started refactoring.  Many of the update functions are now in StepService and don't use the warning modals
    // which was requiring the state.
    const checklistFn = new ChecklistFunctions(
      this.state,
      this.setState.bind(this),
      this.props,
      this.showNotification.bind(this)
    ).getChecklistFunctions();
    setChecklistFunctions(checklistFn);

    let notificationColor = "success";
    let _notifciationMessage = notificationMessage || "Checklist Saved";
    if (notificationType === "error") {
      notificationColor = "danger";
      _notifciationMessage = "Error Saving Checklist!";
    }

    if (data && (data.loading || data.error || !checklist || loading)) {
      if (data.error) console.log("Error:", data.error);
      return (
        <div className={classes.loadingContainer}>
          <CircularProgress className={classes.progress} color="secondary" />
        </div>
      );
    }

    return (
      <div
        onKeyDown={e => this.handleKeyDown(e, checklist, this.props)}
        tabIndex="0"
      >
        {/* <GridContainer justifyContent="flex-start">
          <GridItem md={12}>
            <BreadCrumbs
              checklist={checklist}
              parents={parents}
              classes={classes}
              mode={mode}
            />
          </GridItem>
        </GridContainer> */}
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <ChecklistWrapper
              checklist={checklist}
              checklistFn={checklistFn}
              classes={classes}
              mode={mode}
              parents={parents}
              parentIds={parentIds}
              user={user}
              addEvent={addEvent}
            />
          </GridItem>
        </GridContainer>
        <Confirm
          isOpen={confirmModal}
          handleConfirm={handleConfirm}
          handleClose={handleClose}
          title={confirmTitle}
          paragraph={confirmParagraph}
          icon={confirmIcon}
        />
        <Snackbar
          place="bc"
          color={notificationColor}
          icon={AddAlert}
          message={_notifciationMessage}
          open={notification}
          closeNotification={() => this.setState({ notification: false })}
          close
        />
      </div>
    );
  }
}

export default compose(
  IndexChecklistTemplate,
  CreateChecklistInstance,
  CreateChecklistTemplate,
  ChecklistInstance,
  ChecklistTemplate,
  AddEvent,
  withRouter,
  withStyles(checklistStyle)
)(Checklist);
