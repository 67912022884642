import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { NavLink } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import pagesRoutes from "routes/landing-pages/landing_pages_routes";

import footerStyle from "assets/jss/material-dashboard-pro-react/components/footerStyle";

function Footer({ ...props }) {
  const { classes, fluid, white } = props;
  const footerItems = pagesRoutes.filter(page => page.showFooter);
  const numItems = footerItems.length;
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white
  });
  return (
    <footer className={classes.footer}>
      <div className={container}>
        <div className={classes.left}>
          <List className={classes.list}>
            {footerItems.map((prop, key) => {
              return (
                <React.Fragment key={key}>
                  <ListItem className={classes.listItem}>
                    <NavLink to={prop.path} className={classes.navLink}>
                      <ListItemText
                        style={{ textAlign: "center" }}
                        primary={prop.short}
                        disableTypography={true}
                        className={classes.listItemText}
                      />
                    </NavLink>
                  </ListItem>
                  {key === numItems - 1 ? null : (
                    <ListItemText primary={"|"} disableTypography={true} />
                  )}
                </React.Fragment>
              );
            })}
          </List>
        </div>
        <p className={classes.right}>
          &copy; {1900 + new Date().getYear()} Airsync
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool
};

export default withStyles(footerStyle)(Footer);
