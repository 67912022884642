import { graphql } from "react-apollo"; // Apollo Data Layer

// services
import { ChecklistTemplatesQuery } from "api/queries";
import {
  DateRangeTypes,
  aggregateChecklistsByTemplateId
} from "services/LogbookService";
import { omitDeep } from "services/utility";
import { urlName } from "services/ChecklistService";

// Currently just used for animations
export const MAX_STEPS = 50;

///////////////////////
// NAVIGATION
//////////////////////

// TODO: move and refactor to common function for checklists
export const handleChecklistClick = (user, history) => {
  const instance = user.day.items[0];
  if (instance) {
    const { id, metadata } = instance;
    const { name } = metadata;
    const instanceString = "&instance=true";
    const urlSafe = `/app/checklist/${urlName(name)}?id=${id}${instanceString}`;
    history.push(urlSafe);
  }
};

///////////////////////
// DATA UTILITIES
//////////////////////

export const cloneDayInstance = user => {
  const instance = user.day.items[0];
  const clonedInstance = JSON.parse(JSON.stringify(instance));
  const newInstance = omitDeep(clonedInstance, "__typename");
  return newInstance;
};

// Updates number +/- 1 and the step
export const updateStepValue = (step, clonedInstance, icon, add) => {
  const { steps } = clonedInstance;
  for (let i = 0; i < steps.length; i++) {
    if (steps[i].stepId === step.stepId) {
      if (!steps[i].value) {
        steps[i].value = { number: 0, json: JSON.stringify({ icons: [] }) };
      }
      if (!steps[i].value.json) {
        steps[i].value.json = JSON.stringify({ icons: [] });
      }
      const jsonConfig = JSON.parse(steps[i].value.json);
      steps[i].value.number = add
        ? steps[i].value.number + 1
        : steps[i].value.number - 1;
      if (add) {
        jsonConfig.icons.push(icon);
      } else {
        jsonConfig.icons.pop();
      }
      steps[i].value.json = JSON.stringify(jsonConfig);
    }
  }
  clonedInstance.steps = steps;
  return clonedInstance;
};

export const getStatsObject = (user, globalDateRange) => {
  switch (globalDateRange) {
    case DateRangeTypes.MONTH:
      return user.month.stats;
    case DateRangeTypes.WEEK:
      return user.week.stats;
    case DateRangeTypes.DAY:
    default:
      return user.day.stats;
  }
};

export const aggregateTotalStars = (user, globalDateRange) => {
  const statsObj = getStatsObject(user, globalDateRange);
  const steps = Object.keys(statsObj);
  const count = steps.reduce(
    (acc, curr) => {
      const { count, used } = statsObj[curr];
      acc.count = acc.count + count;
      acc.used = acc.used + used;
      return acc;
    },
    { count: 0, used: 0 }
  );
  return count;
};

///////////////////////
// API CALLS
///////////////////////

// Just fetch all checklsits (for org / user) then filter (if extras);
export const ChecklistTemplates = graphql(ChecklistTemplatesQuery, {
  name: "templates",
  fetchPolicy: "network-only",
  options: props => {
    return {
      variables: {
        orgId: props.user.currentOrganization.id
      }
    };
  }
});

///////////////////////
// USER DATA & PROCESSING
///////////////////////

const DefaultUserData = {
  name: "",
  icon: "",
  template: null,
  instances: [],
  day: {
    items: [],
    stats: {}
  },
  week: {
    items: [],
    stats: {}
  },
  month: {
    items: [],
    stats: {}
  }
};

export const processData = (completedChecklists, templateData, users) => {
  const instances = completedChecklists.aggregateInstances.items || [];
  const templates = templateData.getChecklistTemplates.items;
  const usersChecklists = aggregateChecklistsByTemplateId(instances, users);
  const usersDataMap = processUsersData(usersChecklists);
  const templatesMap = createTemplateMap(templates);
  const usersDataMatched = matchUsers(users, usersDataMap, templatesMap);
  return usersDataMatched;
};

const processUsersData = usersChecklists => {
  const updatedUserData = {};
  Object.keys(usersChecklists).forEach(key => {
    const userChecklists = usersChecklists[key] || [];
    const userData = JSON.parse(JSON.stringify(DefaultUserData));
    updatedUserData[key] = processItems(userChecklists, userData);
  });
  return updatedUserData;
};

// Match users to instance data
const matchUsers = (users, usersDataMap, templates) => {
  const orderedUsersData = [];
  const keys = Object.keys(usersDataMap);
  // No data so just load users
  if (keys.length === 0) {
    users.forEach(user => {
      const userData = JSON.parse(JSON.stringify(DefaultUserData));
      userData.name = user.name;
      userData.icon = user.icon;
      userData.template = templates[user.checklist_id];
      orderedUsersData.push(userData);
    });
    return orderedUsersData;
  }

  users.forEach(user => {
    const userData =
      usersDataMap[user.checklist_id] ||
      JSON.parse(JSON.stringify(DefaultUserData));
    userData.name = user.name;
    userData.icon = user.icon;
    userData.template = templates[user.checklist_id];
    orderedUsersData.push(userData);
  });

  return orderedUsersData;
};

const createTemplateMap = templateData => {
  const templates = {};
  templateData.forEach(template => {
    templates[template.id] = template;
  });
  return templates;
};

const processItems = (items, userData) => {
  let updatedUserData = userData; // mutation
  items.forEach(checklist => {
    const { metadata } = checklist;
    const { created } = metadata;
    const createdDate = new Date(created.time);
    const dateBoundaries = calculateDateBoundaries();
    if (createdDate > dateBoundaries.month) {
      updatedUserData = processMonth(userData, checklist);
      if (createdDate > dateBoundaries.week) {
        updatedUserData = processWeek(updatedUserData, checklist);
        if (createdDate > dateBoundaries.day) {
          updatedUserData = processDay(updatedUserData, checklist);
        }
      }
    }
  });
  return updatedUserData;
};

const calculateDateBoundaries = () => {
  // Current Day rounded to 0 hours & minutes
  const day = new Date();
  day.setHours(0);
  day.setMinutes(0, 0, 0);

  // Sunday for the current week rounded to 0 hours & minutes
  const week = new Date();
  week.setDate(week.getDate() - week.getDay()); // Prev Sunday
  week.setHours(0);
  week.setMinutes(0, 0, 0);

  // First day of the current month rounded to 0 hours & minutes
  const month = new Date();
  month.setDate(1);
  month.setHours(0);
  month.setMinutes(0, 0, 0);

  const boundaries = {
    day,
    week,
    month
  };
  return boundaries;
};

const processDay = (userData, checklist) => {
  // Add all checklists for the day and calculate stats
  // Disregard the difference completed or active (use all instances)
  // Should be one checklist but won't faile if multiple
  userData.day.items.push(checklist);
  checklist.steps.forEach(step => {
    const { stepId: id, value } = step;
    const count = value && value.number ? value.number : 0;
    const used = value && value.value ? parseInt(value.value) : 0;
    const jsonConfig =
      value && value.json ? JSON.parse(value.json) : { icons: [] };
    if (!userData.day.stats[id]) {
      userData.day.stats[id] = { count: 0, used: 0, icons: [] };
    }
    userData.day.stats[id].count = userData.day.stats[id].count + count;
    userData.day.stats[id].used = userData.day.stats[id].used + used;
    userData.day.stats[id].icons = userData.day.stats[id].icons.concat(
      jsonConfig.icons
    );
  });
  return userData;
};

const processWeek = (userData, checklist) => {
  userData.week.items.push(checklist);
  checklist.steps.forEach(step => {
    const { stepId: id, value } = step;
    const count = value && value.number ? value.number : 0;
    const used = value && value.value ? parseInt(value.value) : 0;
    const jsonConfig =
      value && value.json ? JSON.parse(value.json) : { icons: [] };
    if (!userData.week.stats[id]) {
      userData.week.stats[id] = { count: 0, used: 0, icons: [] };
    }
    userData.week.stats[id].count = userData.week.stats[id].count + count;
    userData.week.stats[id].used = userData.week.stats[id].used + used;
    userData.week.stats[id].icons = userData.week.stats[id].icons.concat(
      jsonConfig.icons
    );
  });
  return userData;
};

const processMonth = (userData, checklist) => {
  userData.instances.push(checklist);
  userData.month.items.push(checklist);
  checklist.steps.forEach(step => {
    const { stepId: id, value } = step;
    const count = value && value.number ? value.number : 0;
    const used = value && value.value ? parseInt(value.value) : 0;
    const jsonConfig =
      value && value.json ? JSON.parse(value.json) : { icons: [] };
    if (!userData.month.stats[id]) {
      userData.month.stats[id] = { count: 0, used: 0, icons: [] };
    }
    userData.month.stats[id].count = userData.month.stats[id].count + count;
    userData.month.stats[id].used = userData.month.stats[id].used + used;
    userData.month.stats[id].icons = userData.month.stats[id].icons.concat(
      jsonConfig.icons
    );
  });
  return userData;
};
