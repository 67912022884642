/** Import React and 3rd party libs */
import React, { useState, useEffect } from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputBase from "@material-ui/core/InputBase";

// @material-ui/icons
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// Components
import Info from "@material-ui/icons/Info";

// Subcomponents
/** Import components used only by this component - relative path imports */

// Services
/** Import all services and variables */
import { NEW_STEP_MARKER, NEW_DESCRIPTION_MARKER } from "services/StepService";

// Queries
/** Import all graphql queries */

// Assets
import stepsStyle from "assets/jss/material-dashboard-pro-react/components/stepsStyle.jsx";

// Variables
const InternalFocusTypes = {
  NAME: "NAME",
  DESCRIPTION: "DESCRIPTION",
  NOT_SET: "NOT_SET"
};

// Functions

const updateName = (step, event) => {
  let newStep = Object.assign({}, step);
  newStep.name = event.target.value;
  return newStep;
};

const updateDescription = (step, event) => {
  if (!event) {
    // Occurs during description delete
    return;
  }
  let newStep = Object.assign({}, step);
  newStep.description = event.target.value;
  if (!newStep.description) {
    newStep.description = NEW_DESCRIPTION_MARKER;
  }
  return newStep;
};

const deleteDescription = step => {
  let newStep = Object.assign({}, step);
  newStep.description = "";
  return newStep;
};

const StepNumber = ({ step, classes, number }) => {
  return step.isRequired ? (
    <div className={classes.stepNumber}>{number}.</div>
  ) : null;
};

const StepName = ({
  step,
  steps,
  classes,
  isEditMode,
  updateStep,
  updateSteps,
  handleKeyDown,
  focus,
  setFocus,
  internalFocus,
  setInternalFocus
}) => {
  return isEditMode ? (
    <NameEdit
      step={step}
      steps={steps}
      updateStep={updateStep}
      updateSteps={updateSteps}
      classes={classes}
      focus={focus}
      setFocus={setFocus}
      handleKeyDown={handleKeyDown}
      internalFocus={internalFocus}
      setInternalFocus={setInternalFocus}
    />
  ) : (
    <NameDisplay step={step} classes={classes} />
  );
};

const StepDescription = ({
  step,
  steps,
  updateStep,
  updateSteps,
  isEditMode,
  handleKeyDown,
  internalFocus,
  setFocus,
  setInternalFocus,
  classes
}) => {
  if (!step.description) {
    return null;
  }
  return (
    <>
      {isEditMode ? (
        <DescriptionEdit
          step={step}
          steps={steps}
          updateStep={updateStep}
          updateSteps={updateSteps}
          setFocus={setFocus}
          internalFocus={internalFocus}
          setInternalFocus={setInternalFocus}
          classes={classes}
          handleKeyDown={handleKeyDown}
        />
      ) : (
        <DescriptionDisplay step={step} classes={classes} />
      )}
    </>
  );
};

const NameDisplay = ({ step, classes }) => {
  return (
    <h3 className={classes.stepName} title={step.name}>
      {step.name}
    </h3>
  );
};

const DescriptionDisplay = ({ step, classes }) => {
  return <p className={classes.stepDescription}>{step.description}</p>;
};

const NameEdit = ({
  step,
  classes,
  updateStep,
  handleKeyDown,
  focus,
  setFocus,
  internalFocus,
  setInternalFocus
}) => {
  const [inputAnchorEl, setInputAnchorEl] = useState(null);
  const isNew = step.name === NEW_STEP_MARKER || step.name === "";
  const currentValue = isNew ? "" : step.name;
  const placeholder = isNew ? "Type: '/' for Steps" : ""; // TODO: should show " / " help text
  const autoFocus = step.position === focus;

  // While seemingly unnecessary this makes it so after using " / " and enter
  // to create a new step from the Action menu that new step receives focus.
  // Issue seems to be that focus is only adjusted via autofocus on load (render)
  // This is a bit risky as it can pull focus from other form menus
  // (at least step edit forms but not searchbar or ones outside the container)
  // Add the [focus, inputAncorEl] trigger seemed to fix most of the problem issues around stealing focus
  // since this should only fire on changes to focus and this widget getting focus, plus changes to
  // the input ref which should only update after the first render when it's initialized
  useEffect(() => {
    if (
      autoFocus &&
      inputAnchorEl &&
      internalFocus !== InternalFocusTypes.DESCRIPTION
    ) {
      inputAnchorEl.focus();
    }
  }, [focus, inputAnchorEl]);
  useEffect(() => {
    if (
      autoFocus &&
      inputAnchorEl &&
      internalFocus === InternalFocusTypes.NAME
    ) {
      inputAnchorEl.focus();
      setInternalFocus(InternalFocusTypes.NOT_SET);
    }
  }, [internalFocus]);

  return (
    <div
      style={{ flexGrow: 1 }}
      title={step.name}
      onKeyDown={e => {
        if (e.key === "Enter") {
          // Cleaner transition to new element focus
          inputAnchorEl.blur();
        }
        if (e.key === "Tab") {
          // Cleaner transition to new element focus
          inputAnchorEl.blur();
          if (!step.description) {
            const newStep = Object.assign({}, step);
            newStep.description = NEW_DESCRIPTION_MARKER;
            updateStep(newStep);
            return; // Don't surface to parent handler
          } else {
            e.preventDefault();
            setInternalFocus(InternalFocusTypes.DESCRIPTION);
            return;
          }
          // Don't prevent default -> transitions cursor to the tab
        }
        // Adding step on tab or enter handled in ChecklistWrapper
        handleKeyDown(e, step, inputAnchorEl);
      }}
    >
      <InputBase
        id={step.id}
        autoFocus={autoFocus}
        autoComplete="off"
        value={currentValue}
        placeholder={placeholder}
        onChange={event => {
          updateStep(updateName(step, event));
        }}
        onClick={() => setFocus(step.position)}
        type="text"
        classes={{ root: classes.inputName, input: classes.stepName }}
        inputRef={node => {
          // Need to use useState vs useRef here to get it to work
          // useRef would set the element than set null on subsequent renders
          setInputAnchorEl(node);
        }}
      />
    </div>
  );
};

/**
 * Not including '/' and 'enter' key handling
 * Adds complexity around step focus. May add later.
 */
const DescriptionEdit = ({
  step,
  steps,
  updateStep,
  handleKeyDown,
  setInternalFocus,
  internalFocus,
  setFocus,
  classes
}) => {
  const [inputAnchorEl, setInputAnchorEl] = useState(null);
  // const [inputAnchorElDesc, setInputAnchorElDesc] = useState(null);
  const isNew = step.description === NEW_DESCRIPTION_MARKER;
  let currentValue = step.description;
  let placeholder = "";
  if (isNew) {
    placeholder = NEW_DESCRIPTION_MARKER;
    currentValue = "";
  }

  // While seemingly unnecessary this makes it so after using " / " and enter
  // to create a new step from the Action menu that new step receives focus.
  // Issue seems to be that focus is only adjusted via autofocus on load (render)
  // This is a bit risky as it can pull focus from other form menus
  // (at least step edit forms but not searchbar or ones outside the container)
  // Add the [focus, inputAncorEl] trigger seemed to fix most of the problem issues around stealing focus
  // since this should only fire on changes to focus and this widget getting focus, plus changes to
  // the input ref which should only update after the first render when it's initialized
  useEffect(() => {
    if (inputAnchorEl && internalFocus === InternalFocusTypes.DESCRIPTION) {
      inputAnchorEl.focus();
      setInternalFocus(InternalFocusTypes.NOT_SET);
    }
  }, [internalFocus]);

  return (
    <div
      style={{ display: "flex" }}
      onKeyDown={e => {
        if (e.key === "Tab") {
          const isLastStep = step.position === steps.length;
          const shouldDelete =
            step.description && step.description === NEW_DESCRIPTION_MARKER;
          if (shouldDelete) {
            const newStep = Object.assign({}, step);
            newStep.description = "";
            updateStep(newStep);
          }
          if (isLastStep) {
            // Cleaner transition to new element focus
            inputAnchorEl.blur();
            // Adding step handled in ChecklistWrapper
            // Avoid passing to checklist wrapper since enter will be handled differently
            handleKeyDown(e, step, inputAnchorEl);
          } else {
            e.preventDefault();
            setFocus(step.position + 1);
          }
        }
        if (e.key === "Backspace") {
          if (currentValue === "") {
            inputAnchorEl.blur();
            updateStep(deleteDescription(step));
            e.preventDefault();
            setTimeout(() => {
              setInternalFocus(InternalFocusTypes.NAME);
            }, 0);
          }
        }
      }}
    >
      <InputBase
        id={step.id}
        autoFocus={false}
        autoComplete="off"
        value={currentValue}
        placeholder={placeholder}
        multiline={true}
        onChange={event => updateStep(updateDescription(step, event))}
        onClick={() => {
          setInternalFocus(InternalFocusTypes.DESCRIPTION);
          setFocus(step.position);
        }}
        type="text"
        classes={{
          root: classes.inputDescription,
          input: classes.stepDescription
        }}
        inputRef={node => {
          // Need to use useState vs useRef here to get it to work
          // useRef would set the element than set null on subsequent renders
          setInputAnchorEl(node);
        }}
      />
    </div>
  );
};

/**
 * Declare JSX Component
 */
const ViewTitle = ({
  classes,
  step,
  steps,
  number,
  isEditMode,
  updateStep,
  updateSteps,
  handleKeyDown,
  focus,
  setFocus
}) => {
  const [internalFocus, setInternalFocus] = useState(
    InternalFocusTypes.NOT_SET
  ); // Sets internal focus back on step name

  return (
    <>
      <div style={{ display: "flex" }}>
        <StepNumber step={step} classes={classes} number={number} />
        <StepName
          step={step}
          steps={steps}
          classes={classes}
          isEditMode={isEditMode}
          updateStep={updateStep}
          updateSteps={updateSteps}
          focus={focus}
          setFocus={setFocus}
          handleKeyDown={handleKeyDown}
          internalFocus={internalFocus}
          setInternalFocus={setInternalFocus}
        />
      </div>

      <StepDescription
        step={step}
        steps={steps}
        updateStep={updateStep}
        updateSteps={updateSteps}
        setFocus={setFocus}
        isEditMode={isEditMode}
        classes={classes}
        handleKeyDown={handleKeyDown}
        internalFocus={internalFocus}
        setInternalFocus={setInternalFocus}
      />
      {step.info && (step.info.text || step.info.link) ? (
        <Tooltip
          id="tooltip-top"
          title={step.info.text}
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <IconButton
            aria-label={"Info"}
            className={classes.tableInfoButton}
            onClick={() => window.open(step.info.link, "_blank")}
          >
            <Info
              className={classes.tableActionButtonIcon + " " + classes.info}
            />
          </IconButton>
        </Tooltip>
      ) : null}
    </>
  );
};

// Data Connectors

export default withStyles(stepsStyle)(ViewTitle);
