import React from "react";

import { flowRight as compose } from "lodash";

// AWS AppSync
import AWSAppSyncClient from "aws-appsync";

// Amplify Auth
import Amplify, { Auth } from "aws-amplify";
import { AUTH_TYPE } from "aws-appsync-auth-link";

// Apollo Data Layer
import { graphql } from "react-apollo";

// Local Persistance
import localForage from "localforage";

// UUID generator
import { v4 as uuid } from "uuid";

// Services & Sub-Components
import { UpdateMyProfileMutation, SwitchOrgMutation } from "api/mutations";
import { MyProfileQuery } from "api/queries";
import { OrganizationsQuery } from "api/queries";
import { getConfig } from "site-config/site-config";

// Don't initialize AppSync client if server side rendering for snapshots
const isHeadlessSnapshotMode = window.name === "nodejs";

// On first social sign-in, fails after linking user accounts so need to execute flow again.
// https://stackoverflow.com/questions/47815161/cognito-auth-flow-fails-with-already-found-an-entry-for-username-facebook-10155
const search = !isHeadlessSnapshotMode ? window.location.search : "";
if (search.length > 0) {
  // Cognito passes the error message in the URL so pull it from there.
  const paramString = window.location.search.slice(1); // Remove ?
  const searchParams = new URLSearchParams(paramString);
  if (searchParams.has("error_description")) {
    const error = searchParams.get("error_description");
    const url = window.location.origin + window.location.pathname;
    if (/already.found.an.entry.for.username.google/gi.test(error.toString())) {
      // Execute google auth flow again - Use timeout so runs after Amplify Auth configuration
      setTimeout(() => {
        Auth.federatedSignIn({ provider: "Google" });
      }, 0);
    } else if (
      /already.found.an.entry.for.username.facebook/gi.test(error.toString())
    ) {
      // Execute FB auth flow again - Use timeout so runs after Amplify Auth configuration
      setTimeout(() => {
        Auth.federatedSignIn({ provider: "Facebook" });
      }, 0);
    } else if (
      /already.found.an.entry.for.username.loginwithamazon/gi.test(
        error.toString()
      )
    ) {
      // Execute federated auth flow again - Use timeout so runs after Amplify Auth configuration
      setTimeout(() => {
        Auth.federatedSignIn({ provider: "LoginWithAmazon" });
      }, 0);
    }
    // Replace the url so Amplify doesn't error during Auth configuration
    window.history.replaceState({}, null, url);
  }
}

// TODO: This is broken for staging???  Need to fix by adjusting redirectSignIn for different env.
Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_AUTH_REGION, // REQUIRED - Amazon Cognito Region
    userPoolId: process.env.REACT_APP_USER_POOL_ID, // OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: process.env.REACT_APP_CLIENT_APP_ID, // User Pool App Client ID
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID, // For Federated (Social) Sign In
    oauth: {
      domain: "airsync.auth.us-east-1.amazoncognito.com",
      scopes: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN, // "http://localhost:3000",
      redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT, //"http://localhost:3000",
      responseType: "code"
    }
  },
  Storage: {
    AWSS3: {
      bucket: `airsync-user-data-${process.env.REACT_APP_ENV}`, // REQUIRED -  Amazon S3 bucket
      region: process.env.REACT_APP_AWS_CLIENT_REGION //OPTIONAL -  Amazon service region
    }
  }
});

console.log("value", process.env.REACT_APP_REDIRECT_SIGN_IN);

// Default org on login
const DEFAULT_ORG = "AIR00000-0000-0000-0000-000000000000";

// Return empty client when creating snapshots for server side rendering
export const client = isHeadlessSnapshotMode
  ? {}
  : new AWSAppSyncClient({
      url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
      region: process.env.REACT_APP_AWS_CLIENT_REGION,
      auth: {
        // IAM
        // type: AUTH_TYPE.AWS_IAM,
        // credentials: async () => await Auth.currentCredentials()
        // COGNITO USER POOLS
        type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
        jwtToken: async () =>
          // use id token so current_org is available on the backend
          // see: https://github.com/aws-amplify/amplify-js/issues/1772
          (await Auth.currentSession()).getIdToken().getJwtToken()
      },
      offlineConfig: {
        storage: localForage
      },
      disableOffline: false
    });

export const dummyUser = { loading: true };

/**
 * AuthProvider - Handles User Login & Signup
 * Populates the user object that is passed throughout the app
 * Creates users upon first login / registration
 * Also handles fetching and setting user org info
 */
class AuthProvider extends React.Component {
  constructor(props) {
    super(props);
    const user = {
      loading: true,
      login: this.login,
      loginFederated: this.loginFederated,
      logout: this.logout,
      updateProfile: this.updateProfile,
      switchOrg: this.switchOrg,
      refreshOrg: this.refreshOrg,
      client: client
    };
    this.state = {
      user
    };
  }

  // Try to log user in on page load / refresh
  componentDidMount() {
    const DEBUG = true;
    if (DEBUG) {
      // Hub.listen("auth", ({ payload: { event, data } }) => {
      //   console.log("hub listen auth debug", event, data);
      // });
    }

    Auth.currentAuthenticatedUser({
      bypassCache: DEBUG ? true : false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then(cognitoUser => {
        this.logUserIn(cognitoUser);
      })
      .catch(error => {
        console.log("not authenticated: ", error);
        this.updateUser({});
      });
  }

  // Allow users to login with email & password (cognito user pools) - Attached to User obeject
  login = (cognitoUser, location) => {
    const { hist } = this.props;
    // Pull out attirbutes to match currentAuthenticatedUser
    // TODO: can potentially grab more here after phone signup refactor
    const { given_name, email } = cognitoUser.signInUserSession.idToken.payload;
    const attributes = {
      given_name,
      email
    };
    cognitoUser.attributes = attributes;
    this.logUserIn(cognitoUser, hist, location);
  };

  // Allow users to log out - Attached to User object
  logout = () => {
    Auth.signOut()
      .then(() => {
        client.resetStore();
        this.updateUser({});
      })
      .catch(err => console.log(err));
  };

  logUserIn = async (cognitoUser, hist, location) => {
    // Get identity pool id for photo uploads and fetching
    const credentials = await Auth.currentUserCredentials();
    if (!cognitoUser.attributes.given_name) {
      const parts = cognitoUser.attributes.name.split(" ");
      cognitoUser.attributes.given_name = parts[0];
      cognitoUser.attributes.family_name = parts[parts.length - 1];
    }
    const user = {
      username: cognitoUser.username || cognitoUser.id,
      attributes: cognitoUser.attributes,
      loginType: cognitoUser.loginType,
      identityId: credentials.identityId
    };

    this.loadUserProfile(user, hist, location);
  };

  // Note: Get org here and load then return -> later move to cognito or pipeline resolver for getMyProfle
  loadUserProfile = async (user, hist, location) => {
    // Make parallel aysnc call and wait for both user and org
    const { data: userData } = await client.query({
      query: MyProfileQuery,
      fetchPolicy: "network-only"
    });
    const { error: errorUser, getMyProfile: userProfile } = userData;

    // TODO: Later move user => org query logic into pipeline resolver
    // TODO: In fact -> should always query user => current org from that data (so can't change in query);
    const { data: orgData } = await client.query({
      query: OrganizationsQuery,
      fetchPolicy: "network-only",
      variables: {
        ids: userProfile ? userProfile.organizationIds : DEFAULT_ORG
      }
    });
    // TODO: why doesn't email get passed from HTML response into here???
    //console.log("here org data", JSON.stringify(orgData));
    const { error: errorOrg, getOrganizations: organizations } = orgData;

    // Currently default to Airspace if no orgs in user profile
    const currentOrganization =
      organizations &&
      organizations.length > 0 &&
      userProfile &&
      userProfile.currentOrganization
        ? organizations.find(org => org.id === userProfile.currentOrganization)
        : organizations[0]; // only one - the default on start

    if (errorUser) {
      // Error loading user profile so just log
      console.log("Error loading user profile", errorUser);
    } else if (errorOrg) {
      console.log("Error loading org data", errorOrg);
    } else if (userProfile && userProfile.id) {
      // User profile exists in DB
      user.profile = userProfile;
      user.currentOrganization = currentOrganization;
      user.organizations = organizations;
      // TODO: pass in current org and use to set configurations if appropriate
      const config = getConfig(userProfile.currentOrganization);
      user.siteConfig = config;
      if (hist) {
        this.updateUserAfterLogin(user, hist, location);
      } else {
        this.updateUser(user);
      }
    } else {
      // TODO: No user profile yet so try again with exponential backoff and log error.
      // Should be enough delay after confirming email for lambda to onboard user in postConfirmation
      console.log("Error no profile");
    }
  };

  updateProfile = (profile, fn) => {
    const { updateMyProfile } = this.props;
    updateMyProfile(
      profile.email,
      profile.phone,
      profile.callsign,
      profile.firstName,
      profile.middleName,
      profile.lastName,
      profile.city,
      profile.country,
      profile.postalCode,
      profile.title,
      profile.about,
      profile.profilePicUrl,
      profile.emailVerified,
      profile.phoneVerified,
      profile.loginType
    )
      .then(({ data }) => {
        const { user } = this.state;
        const { updateMyProfile: userProfile } = data;
        user.profile = userProfile;
        // Currently assumes organizations are the same (if use this function to update, need to refetch orgs)
        // Should populate server side in pipeline with user
        user.currentOrganization = user.organizations.find(
          org => org.id === user.profile.currentOrganization
        );
        // TODO: pass in current org and use to set configurations if appropriate
        const config = getConfig(user.profile.currentOrganization);
        user.siteConfig = config;
        this.updateUser(user, fn);
      })
      .catch(error => {
        console.log("error updating profile", error);
      });
  };

  updateUserAfterLogin(user, hist, location) {
    this.updateUser(user, () => {
      if (location && location.state && location.state.referrer) {
        // Redirect to previous location
        const { pathname, search } = location.state.referrer;
        hist.push(pathname + search);
      } else {
        // Redirect to App Dashboard
        hist.push("/app");
      }
    });
  }

  // Switch to updateUserAndOrg(user, org, fn)
  updateUser = (user, fn) => {
    const _fn = fn ? fn : () => {};
    user.login = this.login;
    user.loginFederated = this.loginFederated;
    user.logout = this.logout;
    user.loading = false;
    user.updateProfile = this.updateProfile;
    user.switchOrg = this.switchOrg;
    user.refreshOrg = this.refreshOrg;
    user.client = client;
    this.setState({ user }, _fn);
  };

  // current_org is set in JWT on the backend.
  // After createOrg or switchOrg need to refresh ID token
  refreshOrg = async org => {
    try {
      const { hist } = this.props;
      const config = getConfig(org.id);
      const newPath = config.onboardPath; // TODO: need to figure out how switching orgs can go to a different default
      // maybe user org list has set default page for each org that they select
      // or org has a default that becomes active once the user completes onboarding?
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      const { user } = this.state;
      cognitoUser.refreshSession(
        currentSession.refreshToken,
        (err, session) => {
          if (err) {
            console.log("session", err, session);
          }
          const updatedUser = Object.assign({}, user, {
            currentOrganization: org
          });
          this.setState({ user: updatedUser }, () => hist.push(newPath));
        }
      );
    } catch (e) {
      console.log("Unable to refresh Token", e);
    }
  };

  switchOrg = async id => {
    const { switchOrg } = this.props;
    const results = await switchOrg(id);
    if (results.error) {
      return console.error(`Error loading data [${results.error}]`);
    }
    const newOrg = results.data.switchOrg;
    this.refreshOrg(newOrg);
  };

  render() {
    const { children } = this.props;
    const { user } = this.state;

    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, { user })
    );

    return <span>{childrenWithProps}</span>;
  }
}

const UpdateMyProfile = graphql(UpdateMyProfileMutation, {
  props: ({ mutate }) => ({
    updateMyProfile: (
      email,
      phone,
      callsign,
      firstName,
      middleName,
      lastName,
      city,
      country,
      postalCode,
      title,
      about,
      profilePicUrl,
      emailVerified,
      phoneVerified,
      loginType,
      organizationIds,
      currentOrganization
    ) => {
      return mutate({
        variables: {
          email,
          phone,
          callsign,
          firstName,
          middleName,
          lastName,
          city,
          country,
          postalCode,
          title,
          about,
          profilePicUrl,
          emailVerified,
          phoneVerified,
          loginType,
          organizationIds,
          currentOrganization
        },
        optimisticResponse: () => ({
          updateMyProfile: {
            id: uuid(),
            email,
            phone,
            callsign,
            firstName,
            middleName,
            lastName,
            city,
            country,
            postalCode,
            title,
            about,
            profilePicUrl,
            emailVerified,
            phoneVerified,
            loginType,
            organizationIds,
            currentOrganization,
            __typename: "AirspaceUser"
          }
        }),
        update: proxy => {
          // Not wrapped in a try catch since every update of profile should have had a prior read
          const data = proxy.readQuery({
            query: MyProfileQuery
          });
          proxy.writeQuery({
            query: MyProfileQuery,
            data
          });
        }
      });
    }
  })
});

// Only fetch if not passed in dynamically
const SwitchOrg = graphql(SwitchOrgMutation, {
  props: ({ mutate }) => ({
    switchOrg: id => {
      return mutate({
        variables: {
          id
        }
      });
    }
  })
});

export default compose(UpdateMyProfile, SwitchOrg)(AuthProvider);
