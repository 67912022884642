import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// Components
import PagesHeader from "components/Header/PagesHeader.jsx";
import Footer from "components/Footer/Footer.jsx";

// Subcomponents
import InfoPage from "views/Pages/InfoPage.jsx";

// Services
import { isProd } from "services/utility";

// Assets
import pagesStyle from "assets/jss/material-dashboard-pro-react/routes/pagesStyle.jsx";

class Pages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: false
    };
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.body.style.overflow = "unset";
  }

  render() {
    const { classes, ...rest } = this.props;
    return (
      <div className={classes.wrapper} ref={this.wrapperRef}>
        <div className={classes.pageContainer}>
          <div
            className={classes.fullPage}
            onScroll={() => {
              if (!this.state.scrolled) {
                this.setState({ scrolled: true });
              }
            }}
          >
            <PagesHeader {...rest} />
            <InfoPage {...rest} scrolled={this.state.scrolled} />
            {isProd() ? null : <Footer white />}
          </div>
        </div>
      </div>
    );
  }
}

Pages.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(pagesStyle)(Pages);
