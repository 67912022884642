import React from "react";
import PropTypes from "prop-types";

// React router
import { NavLink } from "react-router-dom";

// Lib for handling headers
import { Helmet } from "react-helmet";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import Weekend from "@material-ui/icons/Weekend";
import Business from "@material-ui/icons/Business";
import AccountBalance from "@material-ui/icons/AccountBalance";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import pricingPageStyle from "assets/jss/material-dashboard-pro-react/views/pricingPageStyle.jsx";

class PricingPage extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Helmet>
          <title>Airsync Ops | Pricing</title>
          <meta
            name="description"
            content="Airsync powers modern operations.  Define programable workflows and customized reporting to match your organization's unique needs."
          />
        </Helmet>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={12} md={12}>
            <div className={classes.scrollContainer}>
              <GridContainer
                justifyContent="center"
                className={classes.planContainer}
              >
                <GridItem xs={12} sm={12} md={6} className={classes.zAdjust}>
                  <h2 className={classes.title}>
                    Pick the best plan for your team
                  </h2>
                  <h5 className={classes.description}>Automate the Manual</h5>
                </GridItem>
              </GridContainer>
              <GridContainer justifyContent="center">
                <GridItem xs={12} sm={12} md={3} className={classes.zAdjust}>
                  <Card pricing plain>
                    <CardBody pricing plain>
                      <h6 className={classes.cardCategory}>Individual</h6>
                      <div className={classes.icon}>
                        <Icon className={classes.iconWhite}>flighttakeoff</Icon>
                      </div>
                      <h3
                        className={`${classes.cardTitleWhite} ${classes.marginTop30}`}
                      >
                        FREE
                      </h3>
                      <p className={classes.cardCategory}>
                        Your own checklists and reports.
                      </p>
                      <NavLink to="/pages/register-page">
                        <Button round color="white">
                          Choose plan
                        </Button>
                      </NavLink>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={3} className={classes.zAdjust}>
                  <Card pricing raised>
                    <CardBody pricing>
                      <h6 className={classes.cardCategory}>SMALL TEAM</h6>
                      <div className={classes.icon}>
                        <Icon className={classes.iconRose}>group</Icon>
                      </div>
                      <h3
                        className={`${classes.cardTitle} ${classes.marginTop30}`}
                      >
                        $5 / User / Month
                      </h3>
                      <p className={classes.cardDescription}>
                        Keep an organization current.
                      </p>
                      <NavLink to="/pages/contact">
                        <Button round color="rose">
                          Choose plan
                        </Button>
                      </NavLink>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={3} className={classes.zAdjust}>
                  <Card pricing plain>
                    <CardBody pricing plain>
                      <h6 className={classes.cardCategory}>ENTERPRISE</h6>
                      <div className={classes.icon}>
                        <Business className={classes.iconWhite} />
                      </div>
                      <h3
                        className={`${classes.cardTitleWhite} ${classes.marginTop30}`}
                      >
                        CALL
                      </h3>
                      <p className={classes.cardCategory}>
                        Professional operations.
                      </p>
                      <NavLink to="/pages/contact">
                        <Button round color="white">
                          Choose plan
                        </Button>
                      </NavLink>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={3} className={classes.zAdjust}>
                  <Card pricing plain>
                    <CardBody pricing plain>
                      <h6 className={classes.cardCategory}>PUBLIC</h6>
                      <div className={classes.icon}>
                        <AccountBalance className={classes.iconWhite} />
                      </div>
                      <h3
                        className={`${classes.cardTitleWhite} ${classes.marginTop30}`}
                      >
                        CALL
                      </h3>
                      <p className={classes.cardCategory}>
                        Public and academic discounts.
                      </p>
                      <NavLink to="/pages/contact">
                        <Button round color="white">
                          Choose plan
                        </Button>
                      </NavLink>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

PricingPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(pricingPageStyle)(PricingPage);
