import gql from "graphql-tag";
import { Checklist } from "../fragments";

export const CreateChecklistTemplateMutation = gql`
  mutation CreateChecklistTemplateMutation(
    $orgId: String!
    $metadata: ChecklistMetadataInput
    $steps: [ChecklistStepInput]
    $id: String
  ) {
    createChecklistTemplate(
      orgId: $orgId
      metadata: $metadata
      steps: $steps
      id: $id
    ) {
      id
      type
      metadata {
        ...MetadataInfo
      }
      steps {
        ...StepInfo
      }
    }
  }
  ${Checklist.metadataInfo}
  ${Checklist.stepInfo}
`;

export const CreateChecklistInstanceMutation = gql`
  mutation CreateChecklistInstanceMutation(
    $orgId: String!
    $metadata: ChecklistMetadataInput
    $steps: [ChecklistStepInput]
    $id: String
  ) {
    createChecklistInstance(
      orgId: $orgId
      metadata: $metadata
      steps: $steps
      id: $id
    ) {
      id
      type
      metadata {
        ...MetadataInfo
        ...MetadataValues
      }
      steps {
        ...StepInfo
        ...StepValues
      }
    }
  }
  ${Checklist.metadataInfo}
  ${Checklist.stepInfo}
  ${Checklist.metadataValues}
  ${Checklist.stepValues}
`;

export const DeleteChecklistTemplateMutation = gql`
  mutation DeleteChecklistTemplateMutation(
    $orgId: String!
    $id: String
    $version: Int
  ) {
    deleteChecklistTemplate(orgId: $orgId, id: $id, version: $version) {
      id
    }
  }
`;

export const BatchDeleteChecklistTemplateMutation = gql`
  mutation BatchDeleteChecklistTemplateMutation(
    $orgId: String!
    $checklists: [ChecklistIdInput]
  ) {
    batchDeleteChecklistTemplate(orgId: $orgId, checklists: $checklists) {
      id
    }
  }
`;

export const DeleteChecklistInstanceMutation = gql`
  mutation DeleteChecklistInstanceMutation(
    $orgId: String!
    $id: String
    $version: Int
  ) {
    deleteChecklistInstance(orgId: $orgId, id: $id, version: $version) {
      id
    }
  }
`;

export const IndexChecklistTemplateMutation = gql`
  mutation IndexChecklistTemplateMutation(
    $id: String
    $metadata: ChecklistMetadataInput
  ) {
    indexChecklistTemplate(id: $id, metadata: $metadata)
  }
`;

export const AddEventMutation = gql`
  mutation AddEventMutation($event: EventInput!) {
    addEvent(event: $event) {
      id
      orgId
      templateId
      instanceId
      time
      filters
      displayName
      metadata
      data
    }
  }
`;
