/* eslint no-unused-vars: "off" */

/**
 * SITE CONFIG
 *
 * All the necessary parts to configure a site:
 * Checklist Zen, Airspace Ops, etc.
 */

// Airspace logo is in assets
import logo from "./EEG_Logo_White.png";

import Dashboard from "views/Dashboard-Deprecated/Dashboard.jsx";
import Checklists from "views/Checklists/Checklists.jsx";
import Dashboards from "views/Dashboards/Dashboards.jsx";
import Checklist from "views/Checklists/Checklist.jsx";

// @material-ui/icons
import Pattern from "@material-ui/icons/WavesOutlined";
import OutPatient from "@material-ui/icons/DirectionsCarOutlined";
import LongTerm from "@material-ui/icons/HotelOutlined";
import FlightTakeoff from "@material-ui/icons/FlightTakeoff";
import Reading from "@material-ui/icons/LibraryBooksOutlined";
import Ops from "@material-ui/icons/SupervisorAccountOutlined";

/////////////////////////////
// Navigation Links
/////////////////////////////

// (Pattern Recognition, Out Patient, Long Term, NeoNatal, Admissions, CVICU,  Reading(a notebook for training), Hospital (list of Stanford specifics), Scoreboard)

const DashboardRoute = {
  path: "/app/ops-dashboard",
  name: "Ops Dashboard",
  id: "ops-dashboard",
  icon: Ops,
  // icon: DashboardIcon,
  component: Dashboard,
  leftNav: true
};

const PatternRoute = {
  path: "/app/checklist/pattern?id=EEG00000-0000-0000-0000-00000PATTERN",
  name: "Pattern Recognition",
  id: "pattern",
  icon: Pattern,
  component: Checklist,
  leftNav: true
};

const OutPatientRoute = {
  path: "/app/checklist/out-patient?id=EEG00000-0000-0000-0000-000000000OUT",
  name: "Out Patient",
  id: "out-patient",
  icon: OutPatient,
  component: Checklist,
  leftNav: true
};

const LongTermRoute = {
  path: "/app/checklist/longterm?id=EEG00000-0000-0000-0000-00000000LONG",
  name: "Long Term",
  id: "out-patient",
  icon: LongTerm,
  component: Checklist,
  leftNav: true
};

const NeonatalRoute = {
  path: "/app/checklist/neonatal?id=EEG00000-0000-0000-0000-000000000NEO",
  name: "Neonatal",
  id: "neonatal",
  icon: "baby_changing_station_outlined",
  component: Checklist,
  leftNav: true
};

const AdmissionsRoute = {
  path: "/app/checklist/admissions?id=EEG00000-0000-0000-0000-0000000ADMIT",
  name: "Admissions",
  id: "admissions",
  icon: "sensor_door_outlined",
  component: Checklist,
  leftNav: true
};

const CVICURoute = {
  path: "/app/checklist/cvicu?id=EEG00000-0000-0000-0000-0000000CVICU",
  name: "CVICU",
  id: "cvicu",
  icon: "medical_services_outlined",
  component: Checklist,
  leftNav: true
};

const ReadingRoute = {
  path: "/app/checklist/reading?id=EEG00000-0000-0000-0000-00000000READ",
  name: "Reading",
  id: "reading",
  icon: Reading,
  component: Checklist,
  leftNav: true
};

const HospitalRoute = {
  path: "/app/checklist/hospital?id=EEG00000-0000-0000-0000-0000HOSPITAL",
  name: "Hospital",
  id: "hospital",
  icon: "apartment_outlined",
  component: Checklist,
  leftNav: true
};

/////////////////////////////
// SITE CONFIGURATIONS
////////////////////////////

const EEG_TECH = {
  name: "EEG Tech",
  logo,
  leftNav: [
    DashboardRoute,
    PatternRoute,
    OutPatientRoute,
    LongTermRoute,
    NeonatalRoute,
    AdmissionsRoute,
    CVICURoute,
    ReadingRoute,
    HospitalRoute
  ]
};

export default EEG_TECH;
