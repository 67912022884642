import React, { useState } from "react";
import _ from "lodash";

// @material-ui/core components
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Code from "@material-ui/icons/Code";
import DoneOutline from "@material-ui/icons/DoneOutline";

// Components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

// Subcomponents
import ChecklistMetaData from "./ChecklistMetadata";
import ChecklistStatus from "./ChecklistStatus";
import ChecklistSteps from "./ChecklistSteps";
import ChecklistSaveButtons from "./ChecklistSaveButtons";
import ChecklistCard from "./ChecklistCard.jsx";

// Services
import {
  ChecklistMode,
  constructTopics,
  sendEvent
} from "services/ChecklistService";
import {
  NEW_STEP_MARKER,
  addStepNoUpdate,
  deleteStep
} from "services/StepService";

// item is either step (if editing step name) or metadata (if editing checklist name)
const handleKeyDown = (e, item, checklist, checklistFn, setFocus) => {
  if (e.key === "Enter" || e.key === "Tab") {
    // Create new step - used by checklist title.
    const position = item.position ? item.position + 1 : 1;
    // Item is metadata
    const newSteps = addStepNoUpdate(checklist.steps, position, item);
    // Item is metadata for checklist since !item.stepId
    checklistFn.updateSteps(newSteps);
    e.preventDefault();
    setFocus(position);
  }
  if (e.key === "Backspace") {
    if (item.stepId) {
      if (
        (item.name === "" || item.name === NEW_STEP_MARKER) &&
        checklist.steps.length > 1
      ) {
        e.preventDefault();
        deleteStep(item, checklist.steps, checklistFn.updateSteps);
        setFocus(item.position - 1);
      }
    }
    // Only delete step if no text left => at 0 show help, at -1 remove step and put focus on previous step at end
  }

  // Key handling logic also in Step to launch action menu and in action menu for filtering & arrow key & enter selection
  // Do more like here: https://www.notion.so/Learn-the-shortcuts-66e28cec810548c3a4061513126766b0
};

function ChecklistWrapper(props) {
  const {
    checklist,
    checklistFn,
    classes,
    mode,
    parents,
    user,
    addEvent
  } = props;

  if (!checklist) {
    return (
      <Card>
        <CardBody>
          <div
            style={{
              display: "flex",
              minHeight: "400px",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <div>
              <Icon
                style={{
                  marginRight: "8px",
                  marginBottom: "-6px",
                  fontSize: "36px"
                }}
              >
                content_paste
              </Icon>
              <h2 style={{ display: "inline-block" }}>
                Checklist not found...
              </h2>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }

  const [focus, setFocus] = useState(1);
  const saveButtons = (
    <ChecklistSaveButtons
      classes={classes}
      mode={mode}
      checklist={checklist}
      checklistFn={checklistFn}
    />
  );

  const _sendEvent = (eventMessage, checklist, user, addEvent, step) => {
    // Checklist may be stale and not updated due to event trigger step update.
    // So if step is passed in, use that instead of the stale step in checklist
    // But don't swap if no value (i.e. select unnassigned - want to know a worker was unnassigned
    // so should still go to worker topic at removal)
    if (step) {
      for (let i = 0; i < checklist.steps.length; i++) {
        if (checklist.steps[i].stepId === step.stepId) {
          // Only swap in ifa valid checklist
          if (step.value.checklist) {
            checklist.steps[i] = step;
          }
        }
      }
    }

    const topics = constructTopics(checklist);
    const orgId = user.currentOrganization.id;
    sendEvent(eventMessage, orgId, topics, addEvent);
  };

  /**
   * Currently Steps is the main focus
   * Details and Status should probably be refactored to modals?
   * Tab Header bar isn't shown, was replaced by configurable ChecklistBanner
   * With tabs removed this component my be able to be deprecated and merged into Checklist or Checklist Card
   * Will cut down on code in ChecklistCard too
   */
  const tabs = [
    {
      tabName: "Steps",
      tabIcon: Assignment,
      tabContent: (
        <ChecklistSteps
          checklist={checklist}
          parents={parents}
          updateStep={checklistFn.updateStep}
          updateSteps={checklistFn.updateSteps}
          mode={mode}
          classes={classes}
          saveButtons={saveButtons}
          user={user}
          focus={focus}
          handleKeyDown={(e, item) =>
            handleKeyDown(e, item, checklist, checklistFn, setFocus)
          }
          setFocus={setFocus}
          sendEvent={(eventMessage, step) =>
            _sendEvent(eventMessage, checklist, user, addEvent, step)
          }
        />
      )
    },
    {
      tabName: "Details",
      tabIcon: Code,
      tabContent: (
        <ChecklistMetaData
          checklist={checklist}
          updateMetadata={checklistFn.updateMetadata}
          mode={mode}
          saveButtons={saveButtons}
          classes={classes}
          user={user}
        />
      )
    }
  ];

  if (
    mode === ChecklistMode.INSTANCE_EXEC ||
    mode === ChecklistMode.INSTANCE_DONE ||
    mode === ChecklistMode.INSTANCE_EDIT
  ) {
    tabs.push({
      tabName: "Status",
      tabIcon: DoneOutline,
      tabContent: (
        <ChecklistStatus
          steps={checklist.steps}
          completeChecklist={checklistFn.completeChecklist}
          updateCompletedChecklist={checklistFn.updateCompletedChecklist}
          mode={mode}
          classes={classes}
          saveButtons={saveButtons}
        />
      )
    });
  }

  return (
    <ChecklistCard
      metadata={checklist.metadata}
      updateMetadata={checklistFn.updateMetadata}
      handleKeyDown={(e, item) =>
        handleKeyDown(e, item, checklist, checklistFn, setFocus)
      }
      tabs={tabs}
      setFocus={setFocus}
      {..._.omitBy(props, (value, key) => key === "classes")}
    />
  );
}

export default ChecklistWrapper;
