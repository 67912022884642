import React from "react";
import ReactTable from "react-table";
import { Link } from "react-router-dom";

// Apollo Data Layer
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";

// Lib to parse query strings
import queryString from "query-string";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Switch from "@material-ui/core/Switch";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import Confirm from "components/Modal/Confirm";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { urlName } from "services/ChecklistService";

import { SearchQuery, SearchInstancesQuery } from "api/queries/index";

// Queries
import {
  DeleteChecklistTemplate,
  DeleteChecklistInstance
} from "views/Checklists/ChecklistComponents/ChecklistQueries";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  loadingContainer: {
    minHeight: "80vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
};

class Search extends React.Component {
  constructor(props) {
    super(props);
    const { search } = props.location;
    const value = queryString.parse(search);
    this.state = {
      confirmModal: false,
      confirmTitle: "",
      handleConfirm: this.handleClose,
      handleClose: this.handleClose,
      typeTemplate: !value.type || value.type === "template"
    };
  }

  constructChecklist = (checklists, user) => {
    if (!checklists) return [];
    return checklists.map(checklist => {
      const { id, metadata } = checklist;
      const orgId = user.currentOrganization.id;
      return {
        id,
        name: metadata.name,
        tags: metadata.tags,
        sharing: metadata.sharing.type,
        created: metadata.created.time,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a like kind of action */}
            <Button
              justIcon
              round
              simple
              onClick={e => {
                alert("You've clicked LIKE button on " + metadata.name);
                e.stopPropagation();
              }}
              color="info"
              className="like"
            >
              <Favorite />
            </Button>{" "}
            {/* use this button to add a edit kind of action */}
            <Link to={`checklist?edit=true&id=${id}`}>
              <Button
                justIcon
                round
                simple
                onClick={e => {
                  e.stopPropagation();
                }}
                color="warning"
                className="edit"
              >
                <Edit />
              </Button>{" "}
            </Link>
            {/* use this button to remove the data row */}
            <Button
              justIcon
              round
              simple
              onClick={e => {
                this.setState({
                  confirmModal: true,
                  confirmTitle: `Delete "${metadata.name}" Checklist?`,
                  handleConfirm: () => {
                    this.setState(
                      {
                        confirmModal: false
                      },
                      () => {
                        const { deleteChecklistTemplate } = this.props;
                        deleteChecklistTemplate(orgId, id, metadata.version);
                      }
                    );
                  }
                });
                e.stopPropagation();
              }}
              color="danger"
              className="remove"
            >
              <Close />
            </Button>{" "}
          </div>
        )
      };
    });
  };

  handleChecklistClick = (state, rowInfo) => {
    const item = state.data.find(obj => obj.id === rowInfo.original.id);
    if (item) {
      const { id, name } = item;
      const urlSafe = `/app/checklist/${urlName(name)}?id=${id}&edit=true`;
      this.props.history.push(urlSafe);
    }
  };

  handleClose = () => {
    this.setState({ confirmModal: false });
  };

  handleTypeToggle = () => {
    const { location, history } = this.props;
    const { typeTemplate } = this.state;
    const { search } = location;
    const type = typeTemplate ? `type=instance` : `type=template`;
    const newSearch = search ? `${search}&${type}` : `?${type}`;
    const newLocation = Object.assign({}, location, { search: newSearch });
    history.push(newLocation);
  };

  render() {
    const { classes, data, user } = this.props;
    const {
      confirmModal,
      confirmTitle,
      handleConfirm,
      handleClose,
      typeTemplate
    } = this.state;

    if (data && (data.loading || data.error)) {
      if (data.error) console.log("Error:", data.error);
      return (
        <div className={classes.loadingContainer}>
          <CircularProgress className={classes.progress} color="secondary" />
        </div>
      );
    }

    const { items } = typeTemplate ? data.search : data.searchInstances;

    const checklists = this.constructChecklist(items, user);

    return (
      <div>
        <GridContainer>
          <GridContainer justifyContent="flex-end">
            <GridItem>
              <Switch
                checked={typeTemplate}
                onChange={this.handleTypeToggle}
                value="checkedB"
                color="primary"
              />
              Templates
            </GridItem>
          </GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Search Results</h4>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={checklists}
                  // filterable
                  columns={[
                    {
                      Header: "Name",
                      accessor: "name"
                    },
                    {
                      Header: "Tags",
                      accessor: "tags"
                    },
                    {
                      Header: "Sharing",
                      accessor: "sharing"
                    },
                    {
                      Header: "Created",
                      accessor: "created"
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false
                    }
                  ]}
                  getTrProps={(state, rowInfo) => {
                    return {
                      onClick: () => this.handleChecklistClick(state, rowInfo)
                    };
                  }}
                  defaultPageSize={10}
                  showPaginationBottom={true}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <Confirm
          isOpen={confirmModal}
          handleConfirm={handleConfirm}
          handleClose={handleClose}
          title={confirmTitle}
        />
      </div>
    );
  }
}

const _SearchQuery = graphql(SearchQuery, {
  options: props => {
    const { search } = props.location;
    const { term } = queryString.parse(search);
    return {
      fetchPolicy: "network-only",
      variables: {
        searchTerm: term
      }
    };
  },
  skip: props => {
    const { search } = props.location;
    const { term, type } = queryString.parse(search);
    return !term || (type && type !== "template");
  }
});

const SearchInstances = graphql(SearchInstancesQuery, {
  options: props => {
    const { search } = props.location;
    const { term } = queryString.parse(search);
    return {
      fetchPolicy: "network-only",
      variables: {
        searchTerm: term
      }
    };
  },
  skip: props => {
    const { search } = props.location;
    const { term, type } = queryString.parse(search);
    return !term || !type || type !== "instance";
  }
});

export default compose(
  _SearchQuery,
  SearchInstances,
  DeleteChecklistTemplate,
  DeleteChecklistInstance,
  withStyles(styles)
)(Search);
