import React, { useState } from "react";
// import { S3Image } from "aws-amplify-react"; // Amplify S3 Image Viewer

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

// @material-ui/icons

// core components

// subcomponents
import IconSelector from "./IconSelector";
import ColorSelector from "./ColorSelector";

// Services
import mediaMenuStyle from "assets/jss/material-dashboard-pro-react/views/mediaMenuStyle.jsx";

export const MediaType = {
  IMAGE: "IMAGE",
  S3_IMAGE: "S3_IMAGE",
  WEB_LINK: "WEB_LINK",
  ICON: "ICON"
};

export const MediaTabs = {
  ICONS: "ICONS",
  IMAGES: "IMAGES",
  COLORS: "COLORS",
  IMAGE_UPLOAD: "IMAGE_UPLOAD",
  WEB_LINK: "WEB_LINK"
};

const getTabs = (config, onSelect) => {
  return config.tabs.map(tabType => {
    return getTab(tabType, onSelect);
  });
};

const getTab = (tabType, onSelect) => {
  const tabOnSelect = value => onSelect(value, tabType);
  switch (tabType) {
    case MediaTabs.ICONS:
      return {
        label: "Icons",
        component: <IconSelector onSelect={tabOnSelect} />
      };
    case MediaTabs.IMAGES:
      return { label: "Images", component: <div>Images</div> };
    case MediaTabs.COLORS:
      return {
        label: "Colors",
        component: <ColorSelector onSelect={tabOnSelect} />
      };
    case MediaTabs.IMAGE_UPLOAD:
      return { label: "Image Upload", component: <div>Image Upload</div> };
    case MediaTabs.WEB_LINK:
      return { label: "Web Link", component: <div>Web Link</div> };
    default:
      return { label: "Unkown Tab", component: <div>Unknown</div> };
  }
};

const createTabs = tabs => {
  return tabs.map((tab, i) => {
    return <Tab key={i} label={tab.label} />;
  });
};

/**
 * Inspriation - see Notion media menu
 */
const MediaMenu = ({ classes, config, onSelect }) => {
  const [value, setValue] = useState(0);

  if (!config || !config.tabs) {
    return null;
  }

  const tabs = getTabs(config, onSelect);

  return (
    <div className={classes.container}>
      <Tabs
        value={value}
        centered={false}
        indicatorColor="primary"
        onChange={(e, value) => setValue(value)}
      >
        {createTabs(tabs)}
      </Tabs>
      {tabs[value].component}
    </div>
  );
};

export default withStyles(mediaMenuStyle)(MediaMenu);
