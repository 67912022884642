import React from "react";

// Amplify S3 Image Viewer
import { S3Image } from "aws-amplify-react";

import avatar from "assets/img/faces/transparent-avatar.png";

class ProfilePic extends React.Component {
  render() {
    const { profilePicUrl, ...rest } = this.props;

    if (!profilePicUrl) {
      // Return a transparent placeholder
      return (
        <img src={avatar} styles={{ width: "100%" }} alt="..." {...rest} />
      );
    } else if (profilePicUrl === "profile-pic") {
      // User uploaded profile pic
      return <S3Image imgKey={"profile-pic"} level="protected" {...rest} />;
    } else if (profilePicUrl.indexOf("http") > -1) {
      // FB or Google URL for profile pic
      return <img src={profilePicUrl} alt="User" {...rest} />;
    } else {
      // Default public profile pic based on CONTEXT - hard code for airspace-ops
      const avatarUrl = `airspace/avatars/${profilePicUrl}`;
      return <S3Image imgKey={avatarUrl} {...rest} />;
    }
  }
}

export default ProfilePic;
