import React from "react";
import { Responsive, WidthProvider } from "react-grid-layout";

// @material-ui/core components

// @material-ui/icons

// Components

// Subcomponents
import Widget from "./Widget";

// Service
import { LogbookMode, WidgetType } from "services/LogbookService";
import {
  constructWidget,
  calculateInitialLayoutPosition
} from "services/WidgetService";

// Assets

// Variables

// Functions

// Map layout for each widget to layout object for react-grid-layout
function getLayout(widgets) {
  const layout = { lg: [], md: [], sm: [] };
  widgets.forEach(widget => {
    const id = { i: widget.widgetId };
    const lg = { ...id, ...widget.layout.lg };
    const md = { ...id, ...widget.layout.md };
    const sm = { ...id, ...widget.layout.sm };
    layout.lg.push(lg);
    layout.md.push(md);
    layout.sm.push(sm);
  });
  return layout;
}

// HOC to add width to responsive grid
// TODO: figure out how to adjust layout when sidebar is opened and closed
const ResponsiveGridLayout = WidthProvider(Responsive);

const constructDynamicWidgets = globalData => {
  // Currently assume all dynamic based on template (since solving for ChecklistWidget)
  const templates = globalData.referenceTemplates
    ? globalData.referenceTemplates
    : [];
  const baseId = "DYNAMIC0-0000-0000-0000-000000000000";
  const widgets = [];
  for (let i = 0; i < templates.length; i++) {
    const template = templates[i];
    const newWidget = constructWidget(WidgetType.CHECKLIST);
    newWidget.widgetId = baseId.slice(0, -i.length) + i;
    newWidget.layout = calculateInitialLayoutPosition(newWidget, widgets);
    newWidget.template = template;
    widgets.push(newWidget);
  }
  return widgets;
};

class Widgets extends React.Component {
  handleLayoutChange(layout, widgets, updateWidgets) {
    const layoutMap = {};
    layout.forEach(item => {
      layoutMap[item.i] = item;
    });

    const newWidgets = widgets.map(widget => {
      const newWidget = Object.assign({}, widget);
      const newLayout = Object.assign({}, widget.layout);
      const newLg = Object.assign({}, newLayout.lg);
      const newMd = Object.assign({}, newLayout.md);
      const newSm = Object.assign({}, newLayout.sm);
      const { w, h, x, y } = layoutMap[newWidget.widgetId];
      newWidget.layout.lg = Object.assign({}, newLg, { w, h, x, y });
      newWidget.layout.md = Object.assign({}, newMd, { w, h, x, y });
      newWidget.layout.sm = Object.assign({}, newSm, { w, h, x, y });
      return newWidget;
    });

    updateWidgets(newWidgets);
  }

  render() {
    const {
      mode,
      widgets,
      editWidget,
      deleteWidget,
      updateWidget,
      updateWidgets,
      globalSettings,
      globalData,
      user,
      showMedia
    } = this.props;

    const resolvedWidgets = globalSettings.dynamic
      ? constructDynamicWidgets(globalData)
      : widgets;

    const layout = getLayout(resolvedWidgets);

    // Note: Some areas will lose focus, need to specify as draggableCancel
    // See: https://github.com/STRML/react-grid-layout/issues/615
    return (
      <ResponsiveGridLayout
        layouts={layout}
        draggableCancel="input,textarea"
        breakpoints={{ lg: 1200, md: 600, sm: 0 }}
        cols={{ lg: 12, md: 6, sm: 1 }}
        rowHeight={30}
        isDraggable={mode === LogbookMode.EDIT}
        isResizable={mode === LogbookMode.EDIT}
        onDragStop={layout =>
          this.handleLayoutChange(layout, widgets, updateWidgets)
        }
        onResizeStop={layout =>
          this.handleLayoutChange(layout, widgets, updateWidgets)
        }
      >
        {resolvedWidgets.map(widget => (
          <div key={widget.widgetId}>
            <Widget
              mode={mode}
              widget={widget}
              editWidget={() => editWidget(widget, widgets)}
              deleteWidget={() => deleteWidget(widget)}
              updateWidget={updateWidget}
              globalSettings={globalSettings}
              user={user}
              showMedia={showMedia}
            />
          </div>
        ))}
      </ResponsiveGridLayout>
    );
  }
}

export default Widgets;
