import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// Components
import CustomInput from "components/CustomInput/CustomInput.jsx";

// Subcomponents

// Services
import { verifyUrl } from "services/utility";

// Queries

// Assetss
import stepsStyle from "assets/jss/material-dashboard-pro-react/components/stepsStyle.jsx";

// Variables

// Functions
const updateInfoText = (step, event) => {
  let newStep = Object.assign({}, step);
  newStep.info.text = event.target.value || null;
  return newStep;
};

class StepInfoEdit extends React.Component {
  state = { urlState: "success" };

  updateInfoLink = (step, event) => {
    let newStep = Object.assign({}, step);
    const stateName = "url";
    if (verifyUrl(event.target.value)) {
      this.setState({ [stateName + "State"]: "success" });
    } else {
      this.setState({ [stateName + "State"]: "error" });
    }
    newStep.info.link = event.target.value || null;
    return newStep;
  };

  render() {
    const { classes, step, updateStep } = this.props;

    return (
      <FormControl fullWidth className={classes.selectFormControl}>
        <CustomInput
          labelText="Text"
          id="text"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            onChange: event => updateStep(updateInfoText(step, event)),
            type: "text",
            value: step.info.text || ""
          }}
        />
        <CustomInput
          success={this.state.urlState === "success"}
          error={this.state.urlState === "error"}
          labelText="Link"
          id="link"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            onChange: event => updateStep(this.updateInfoLink(step, event)),
            type: "text",
            value: step.info.link || "",
            endAdornment:
              this.state.urlState === "error" ? (
                <InputAdornment position="end">
                  <Close className={classes.danger} />
                </InputAdornment>
              ) : (
                undefined
              )
          }}
        />
      </FormControl>
    );
  }
}

export default withStyles(stepsStyle)(StepInfoEdit);
